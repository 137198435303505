import { NavLink } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { Avatar } from "../ui/Robo/Avatar";

function AdminSidebar() {
  const links = [
    {
      name: "Useri",
      path: "/admin/users",
    },
    {
      name: "Profesori",
      path: "/admin/professors",
    },
    {
      name: "Coduri",
      path: "/admin/codes",
    },
    {
      name: "Setari",
      path: "/admin/settings",
    }
  ];
  return (
    <>
      <aside
        className={`flex flex-col min-h-screen fixed md:sticky top-0 z-30 w-full max-w-[calc(100%-80px)] md:max-w-full md:w-96 h-full bg-x-dark-50 max-h-screen overflow-hidden p-3 md:p-3 md:translate-x-0 transition-all"
        `}
      >
        <div className="space-y-3">
          <div className="flex gap-10 items-center"
            onClick={() => {
              window.location.href = "/admin";
            }}>
            <Avatar />
            <div className="mt-3">Admin Profesorul Virtual</div>
          </div>

          {links.map((link) => {
            return (
              <div className="w-full" key={link.path}>
                <NavLink
                  to={link.path}
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  {({ isActive }) => (
                    <Button
                      className={`w-full justify-start gap-3 ${isActive ? "bg-x-gray-900/80 text-white pointer-events-none" : ""
                        }`}
                      variant="ghost"
                    >
                      {link.name}
                    </Button>
                  )}
                </NavLink>
              </div>
            );
          })}
        </div>
      </aside>
    </>
  );
}

export default AdminSidebar;
