import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";
import { useState } from "react";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { api_login } from "@/lib/chat";
import { Avatar } from "@/components/ui/Robo/Avatar";
import { AlertTriangle, CheckCircle, CircleDashed } from "lucide-react";
import LogoNewEdu from "@/components/ui/logo";

type LoginResponse = {
  status: string;
  // add other properties that the response might have
};
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";

const formSchema = z.object({
  email: z.string().email({ message: "Email nevalid" }),
  password: z.string().min(2, {
    message: "Parola trebuie sa contina minim 2 caractere",
  }),
});

export default function Login() {
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState({});

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
  });

  // 2. Define a submit handler.
  function onSubmit(values: z.infer<typeof formSchema>) {
    // Do something with the form values.
    // ✅ This will be type-safe and validated.

    setIsLoading(true);
    setMessage({});
    api_login(values.email, values.password, (res: LoginResponse) => {
      setIsLoading(false);
      if (res && res?.status == "success") {
        setMessage({
          messge: "Autentificare reusita!",
          type: "success",
        });
        window.location.href = "/";
      } else {
        setMessage({
          messge: "Utilizator sau parola incorecta",
          type: "error",
        });
      }
    });
  }
  return (
    <div className="flex min-h-screen bg-x-blue-700 w-full">

      <div className="bg-white p-7 z-10 h-full flex min-h-screen items-center w-full sm:w-[400px]">
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="space-y-4 w-full"
          >
            <div className="space-y-3">

              <div className="flex items-center gap-3 justify-center">
                <Avatar /> <h1 className="text-2xl font-bold">Profesorul AI (Beta)</h1>
              </div>
              <div className="text-center text-lg font-bold">Autentificare</div>
            </div>
            {message && message.messge && (
              <Alert
                variant={message.type == "error" ? "destructive" : "success"}
              >
                {message.type == "error" ? (
                  <AlertTriangle className="w-5 h-5" />
                ) : (
                  <CheckCircle className="w-5 h-5" />
                )}
                <AlertTitle>
                  {message.type == "error" ? "Eroare" : "Succes"}
                </AlertTitle>
                <AlertDescription>{message.messge}</AlertDescription>
              </Alert>
            )}
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input
                      type="email"
                      autoComplete="email"
                      placeholder=""
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Parola</FormLabel>
                  <FormControl>
                    <Input
                      autoComplete="current-password"
                      type="password"
                      placeholder=""
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <Button disabled={isLoading} className="w-full" type="submit">
              {!isLoading ? (
                "Autentificare"
              ) : (
                <CircleDashed className="animate-spin" />
              )}
            </Button>
            <div>
              <Link to="/register" className="text-x-blue-700 hover:underline">
                Nu ai cont? <b>Inregistreaza-te</b>
              </Link>
            </div>
            <div>
              <Link
                to="/reset-password"
                className="text-x-blue-700 hover:underline"
              >
                Ti-ai uitat parola?
              </Link>
            </div>
            <div className="mt-10">
              <div className="w-full text-center mt-10">
                <a href="https://profesorul.ai" className="text-x-blue-700">profesorul.ai</a> este un proiect realizat de
              </div>
              <div className="flex justify-center w-full">
                <div className="flex w-[200px]">
                  <LogoNewEdu />
                </div>
              </div>

            </div>
          </form>
        </Form>
      </div>
      <div className="flex-1 hidden md:block">
        <div className="max-h-screen h-screen items-end flex justify-end px-[100px]">
          <>
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 500 500"
              style={{ enableBackground: "new 0 0 500 500" }}
              xmlSpace="preserve"
            >
              <g id="Background_Complete">
                <g>
                  <g>
                    <g>
                      <g>
                        <path
                          style={{ fill: "#F5F5F5" }}
                          d="M150.087,130.282c0.12-0.02,1.769,8.346,1.769,8.346l74.811-15.35l-16.364-75.703l11.957,70.458
						L150.087,130.282z"
                        />
                      </g>
                    </g>
                  </g>
                  <g>
                    <g>
                      <g>
                        <g>
                          <g>
                            <rect
                              x="140.728"
                              y="49.173"
                              transform="matrix(0.9859 -0.1673 0.1673 0.9859 -12.0451 31.1078)"
                              style={{ fill: "#FFFFFF" }}
                              width="75.731"
                              height="75.731"
                            />
                          </g>
                        </g>
                      </g>
                      <g>
                        <g>
                          <g>
                            <g>
                              <path
                                style={{ fill: "#E0E0E0" }}
                                d="M222.26,118.034c0,0-0.026-0.121-0.068-0.353c-0.042-0.238-0.103-0.581-0.183-1.031
									c-0.159-0.913-0.393-2.257-0.697-4.008c-0.604-3.509-1.489-8.645-2.614-15.179c-2.234-13.06-5.424-31.703-9.25-54.068
									l0.166,0.118c-21.7,3.689-47.319,8.045-74.652,12.692l-0.007,0.001l0.137-0.193c4.632,27.342,8.972,52.965,12.648,74.667
									l-0.166-0.118c22.377-3.767,41.029-6.906,54.094-9.105c6.539-1.093,11.678-1.952,15.19-2.539
									c1.753-0.29,3.098-0.513,4.013-0.664c0.452-0.073,0.796-0.129,1.034-0.167C222.137,118.05,222.26,118.034,222.26,118.034
									s-0.11,0.024-0.337,0.065c-0.234,0.042-0.572,0.102-1.016,0.18c-0.907,0.158-2.241,0.39-3.98,0.693
									c-3.502,0.603-8.626,1.486-15.145,2.608c-13.081,2.238-31.756,5.433-54.161,9.266l-0.142,0.024l-0.024-0.143
									c-3.689-21.7-8.045-47.32-12.693-74.659l-0.028-0.165l0.165-0.028l0.007-0.001c27.336-4.631,52.957-8.971,74.66-12.647
									l0.143-0.024l0.024,0.142c3.773,22.412,6.917,41.095,9.121,54.183c1.091,6.526,1.948,11.655,2.534,15.16
									c0.288,1.741,0.509,3.078,0.66,3.986c0.072,0.445,0.127,0.784,0.165,1.019C222.246,117.922,222.26,118.034,222.26,118.034z"
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                    <g>
                      <g>
                        <g>
                          <path
                            style={{ fill: "#E0E0E0" }}
                            d="M183.805,69.352c-0.287,0.045-0.55-0.024-0.792-0.206c-0.241-0.182-0.385-0.416-0.43-0.703
							c-0.112-0.158-0.184-0.331-0.213-0.517c-0.124-0.978-0.33-2.384-0.618-4.218c-0.287-1.834-0.476-3.081-0.565-3.742
							c-0.038-0.244-0.113-0.668-0.222-1.276c-0.11-0.606-0.199-1.172-0.266-1.697c-0.068-0.525-0.102-1.022-0.102-1.492
							c-0.003-0.205,0.055-0.398,0.174-0.578c0.118-0.18,0.273-0.314,0.465-0.404c0.159-0.2,0.374-0.329,0.644-0.386
							c1.055-0.195,2.567-0.454,4.534-0.777c1.968-0.324,3.31-0.541,4.026-0.653c0.3-0.048,0.571,0.016,0.812,0.191
							c0.24,0.175,0.384,0.413,0.431,0.715c0.048,0.301-0.017,0.571-0.192,0.811c-0.175,0.241-0.413,0.384-0.714,0.432
							c-0.76,0.119-2.037,0.326-3.832,0.623c-1.796,0.297-3.18,0.529-4.152,0.695c-0.011,0.208,0.064,0.757,0.226,1.649
							c0.161,0.892,0.27,1.51,0.324,1.854l0.138,0.881c0.695-0.153,1.44-0.293,2.236-0.417c0.795-0.125,1.699-0.259,2.71-0.403
							c1.012-0.145,1.762-0.254,2.249-0.331c0.301-0.048,0.571,0.017,0.812,0.192c0.24,0.175,0.384,0.413,0.431,0.714
							c0.047,0.301-0.017,0.572-0.192,0.812c-0.175,0.24-0.413,0.384-0.714,0.431c-0.502,0.078-1.258,0.19-2.27,0.334
							c-1.012,0.143-1.913,0.277-2.7,0.401c-0.788,0.123-1.537,0.264-2.246,0.418c0.319,2.034,0.541,3.49,0.663,4.366
							c3.504-0.492,6.207-0.901,8.107-1.229c0.301-0.047,0.574,0.012,0.82,0.18c0.247,0.167,0.393,0.401,0.44,0.701
							c0.047,0.301-0.013,0.574-0.179,0.82c-0.167,0.246-0.401,0.393-0.702,0.441C191.018,68.316,187.971,68.773,183.805,69.352z"
                          />
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            style={{ fill: "#E0E0E0" }}
                            d="M198.72,66.617c-0.844-0.235-1.516-0.592-2.017-1.071c-0.502-0.479-0.88-0.934-1.139-1.363
							c-0.259-0.43-0.444-0.866-0.558-1.311c-0.114-0.444-0.184-1.031-0.21-1.761c0.006-0.618,0.124-1.244,0.354-1.883
							c0.23-0.638,0.577-1.231,1.042-1.782c0.464-0.55,1.055-1.01,1.776-1.379c0.72-0.37,1.517-0.572,2.392-0.607
							c0.846-0.03,1.591,0.084,2.233,0.343c0.643,0.259,1.214,0.627,1.715,1.107c0.43,0.402,0.703,0.712,0.817,0.929
							c0.115,0.217,0.22,0.42,0.316,0.611c0.096,0.19,0.177,0.354,0.242,0.49c0.066,0.137,0.128,0.277,0.187,0.422
							c0.059,0.145,0.112,0.317,0.158,0.515c0.057,0.271,0.046,0.529-0.032,0.776c-0.079,0.247-0.223,0.453-0.432,0.618
							c-0.175,0.101-0.37,0.168-0.585,0.202l-3.664,0.641c-2.576,0.418-3.861,0.649-3.854,0.693l-0.065,0.01
							c0.301,0.702,0.781,1.213,1.44,1.535c0.66,0.322,1.511,0.438,2.554,0.348c0.563-0.058,1.024-0.161,1.383-0.305
							c0.398-0.18,0.682-0.331,0.854-0.453c0.172-0.123,0.382-0.284,0.629-0.484c0.257-0.143,0.531-0.172,0.823-0.086
							c0.292,0.087,0.51,0.258,0.653,0.515c0.143,0.256,0.174,0.523,0.093,0.8s-0.24,0.507-0.475,0.691
							c-0.208,0.165-0.428,0.314-0.656,0.445c-0.229,0.132-0.57,0.298-1.022,0.502c-0.453,0.203-1.146,0.37-2.079,0.502
							C200.522,66.92,199.564,66.851,198.72,66.617z M203.558,59.561c-0.196-0.41-0.468-0.785-0.815-1.128
							c-0.676-0.568-1.445-0.836-2.306-0.805c-0.906,0.025-1.657,0.337-2.253,0.938c-0.596,0.6-0.954,1.312-1.073,2.138
							C197.935,60.53,200.084,60.149,203.558,59.561z"
                          />
                        </g>
                      </g>
                    </g>
                    <g>
                      <g>
                        <g>
                          <g>
                            <g>
                              <path
                                style={{ fill: "#E0E0E0" }}
                                d="M195.966,105.025c0,0,1.708,8.411,2.052,9.195c0,0-1.597,2.166-1.425,2.768
									c0.172,0.602,1.456,1.603,1.456,1.603l3.275-0.556l3.42-2.22l0.099-5.49l-1.973-5.138l-2.586-2.005L195.966,105.025z"
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                      <g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <path
                                  style={{ fill: "#EBEBEB" }}
                                  d="M144.747,94.674c-0.003,0.276-0.435,3.704,0.888,5.232c1.323,1.528,4.231,3.913,5.369,4.346
										c1.138,0.433,4.849,0.178,7.115-1.959c0,0,0.652-3.532-0.224-4.27c-0.876-0.738-3.012-2.629-3.061-1.807
										c-0.048,0.822,0.31,1.825,0.31,1.825s-1.366,0.065-1.204,0.653c0.162,0.588,1.266,1.562,1.266,1.562
										s-1.566,0.364-2.068,0.351c-0.503-0.013-2.545-0.98-2.62-2.532c-0.076-1.552,2.398-5.414,3.834-6.91
										c1.436-1.495,6.294-5.324,6.294-5.324l6.462-3.225l-4.259-9.604c0,0-7.469,2.457-11.856,7.582
										C146.607,85.722,144.779,91.974,144.747,94.674z"
                                />
                              </g>
                            </g>
                          </g>
                        </g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <path
                                  style={{ fill: "#EBEBEB" }}
                                  d="M177.838,104.124c0,0,3.486,1.45,5.008,2.017c1.522,0.568,7.406,1.643,9.19,1.081
										c1.784-0.562,7.607-2.522,7.607-2.522l2.12-17.289l3.067-3.835c0,0-4.261-3.961-6.857-4.372
										c-2.597-0.411-10.108,1.527-10.108,1.527s-9.375,3.552-9.571,3.926c-0.196,0.373-1.854,15.168-1.854,15.168
										L177.838,104.124z"
                                />
                              </g>
                            </g>
                          </g>
                        </g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <path
                                  style={{ fill: "#EBEBEB" }}
                                  d="M167.954,95.016c0,0,0.315,5.166-0.24,6.032c-0.555,0.866-2.447,7.081-2.068,8.491
										c0.38,1.409,1.612,2.884,3.735,5.471c2.123,2.587,3.331,3.505,3.331,3.505l3.805-5.698l-1.93-6.409l1.024-7.612
										L167.954,95.016z"
                                />
                              </g>
                            </g>
                          </g>
                        </g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <path
                                  style={{ fill: "#EBEBEB" }}
                                  d="M162.851,73.014c0,0,2.671-2.09,3.771-2.081c2.329,0.02,7.411,2.894,7.411,2.894
										l14.99,6.775L177.67,95.949l-7.905,3.839l-4.196-7.869l-7.255-5.504L162.851,73.014z"
                                />
                              </g>
                            </g>
                          </g>
                        </g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <path
                                  style={{ fill: "#EBEBEB" }}
                                  d="M169.333,97.768c-0.115,0.148,0.586,14.203,0.586,14.203l0.286,8.302
										c0,0-1.382,1.778-1.29,2.324c0.093,0.546,0.639,0.874,0.639,0.874s3.137-0.647,4.512-0.81
										c1.376-0.163,3.714-0.586,3.671-0.839c-0.062-0.366,0.249-0.765,0.249-0.765l-1.072-0.941c0,0,0.263-3.413,1.233-5.963
										c0.971-2.551,1.035-7.544,1.035-7.544l1.5-6.872c0,0-0.342-5.324-0.387-5.176c-0.045,0.148-3.671,0.267-3.671,0.267
										L169.333,97.768z"
                                />
                              </g>
                            </g>
                          </g>
                        </g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <path
                                  style={{ fill: "#EBEBEB" }}
                                  d="M172.671,122.971c-0.055-0.671,0.19-1.361,0.655-1.847c0.185-0.193,0.431-0.364,0.698-0.339
										c0.261,0.024,0.467,0.226,0.635,0.428c0.31,0.375,0.569,0.793,0.765,1.238"
                                />
                              </g>
                            </g>
                          </g>
                        </g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <path
                                  style={{ fill: "#EBEBEB" }}
                                  d="M169.652,123.41c0,0,0.411-1.8,0.858-1.972c0.447-0.172,1.855,1.491,1.855,1.491
										L169.652,123.41z"
                                />
                              </g>
                            </g>
                          </g>
                        </g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <path
                                    style={{ fill: "#EBEBEB" }}
                                    d="M171.701,74.223c0,0,4.902-1.017,6.638-0.85s5.988,2.121,6.365,2.98
											c0.376,0.859,0.528,9.091-0.121,9.617c-0.649,0.525-1.089,0.575-2.243,1.291c-1.154,0.716-1.41,0.944-1.978,0.671
											c-0.568-0.273-0.23,0.362-1.222,1.038c-0.993,0.676-1.965,0.656-2.247,1.166c-0.283,0.509-1.72,4.265-2.223,4.767
											c-0.503,0.501-1.903,0.824-2.655-0.617c-0.752-1.441-2.958-11.447-2.98-11.305"
                                  />
                                </g>
                              </g>
                            </g>
                          </g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <path
                                      style={{ fill: "#E0E0E0" }}
                                      d="M169.035,82.98c0.011-0.01,0.06,0.174,0.146,0.513c0.084,0.346,0.207,0.849,0.365,1.494
												c0.315,1.298,0.759,3.179,1.356,5.491c0.151,0.578,0.311,1.182,0.489,1.808c0.184,0.623,0.36,1.284,0.637,1.919
												c0.157,0.304,0.367,0.596,0.657,0.799c0.289,0.198,0.653,0.3,1.015,0.261c0.181-0.014,0.361-0.061,0.528-0.136
												c0.166-0.076,0.329-0.175,0.444-0.315c0.223-0.294,0.387-0.658,0.558-1.009c0.336-0.712,0.649-1.458,0.97-2.22
												c0.161-0.381,0.32-0.767,0.49-1.154c0.044-0.097,0.086-0.194,0.136-0.291c0.051-0.098,0.127-0.194,0.219-0.258
												c0.181-0.138,0.389-0.21,0.59-0.287c0.406-0.145,0.813-0.284,1.188-0.499c0.377-0.208,0.724-0.484,0.942-0.858
												c0.056-0.094,0.1-0.192,0.164-0.294c0.033-0.048,0.081-0.111,0.159-0.126c0.037-0.014,0.07-0.004,0.106-0.005
												l0.091,0.024c0.114,0.046,0.204,0.093,0.308,0.121c0.101,0.029,0.206,0.034,0.309,0.015
												c0.207-0.039,0.405-0.15,0.598-0.263c0.194-0.114,0.385-0.236,0.579-0.357c0.385-0.243,0.778-0.476,1.181-0.68
												c0.402-0.206,0.81-0.386,1.159-0.651l0.128-0.101c0.013-0.018,0.026-0.034,0.041-0.051
												c0.012-0.02,0.022-0.045,0.033-0.068c0.042-0.093,0.071-0.199,0.094-0.304c0.048-0.211,0.079-0.427,0.105-0.643
												c0.051-0.432,0.079-0.864,0.101-1.292c0.078-1.714,0.058-3.373-0.015-4.948c-0.02-0.394-0.044-0.782-0.078-1.164
												c-0.018-0.191-0.036-0.381-0.063-0.567c-0.029-0.183-0.05-0.379-0.121-0.533c-0.081-0.15-0.222-0.277-0.36-0.392
												c-0.141-0.115-0.288-0.221-0.439-0.319c-0.301-0.199-0.611-0.375-0.919-0.54c-1.239-0.651-2.455-1.15-3.597-1.483
												c-0.286-0.08-0.569-0.149-0.847-0.189c-0.278-0.037-0.554-0.039-0.822-0.033c-0.536,0.017-1.041,0.064-1.511,0.118
												c-0.941,0.108-1.748,0.24-2.407,0.348c-0.658,0.11-1.169,0.203-1.514,0.267c-0.17,0.031-0.301,0.055-0.393,0.072
												c-0.089,0.016-0.135,0.021-0.135,0.021s0.044-0.013,0.132-0.033c0.092-0.02,0.222-0.047,0.391-0.083
												c0.344-0.071,0.853-0.171,1.511-0.288c0.658-0.115,1.464-0.253,2.407-0.367c0.471-0.058,0.977-0.108,1.517-0.128
												c0.269-0.007,0.549-0.007,0.834,0.03c0.284,0.039,0.57,0.108,0.859,0.187c1.152,0.331,2.371,0.826,3.62,1.477
												c0.311,0.165,0.623,0.342,0.929,0.542c0.153,0.099,0.303,0.206,0.448,0.324c0.143,0.12,0.289,0.244,0.387,0.424
												c0.085,0.187,0.101,0.375,0.132,0.564c0.027,0.189,0.047,0.38,0.065,0.572c0.035,0.384,0.06,0.774,0.081,1.168
												c0.076,1.579,0.098,3.239,0.021,4.959c-0.021,0.43-0.05,0.864-0.101,1.3c-0.025,0.218-0.057,0.437-0.107,0.655
												c-0.025,0.109-0.053,0.218-0.102,0.326c-0.013,0.027-0.024,0.054-0.04,0.081l-0.064,0.077l-0.133,0.104
												c-0.361,0.274-0.777,0.458-1.175,0.662c-0.4,0.203-0.788,0.432-1.173,0.675c-0.193,0.121-0.385,0.243-0.581,0.358
												c-0.197,0.113-0.399,0.231-0.636,0.276c-0.241,0.055-0.488-0.057-0.674-0.141c-0.104-0.037-0.168-0.028-0.223,0.063
												c-0.055,0.086-0.103,0.191-0.161,0.288c-0.231,0.397-0.601,0.688-0.985,0.899c-0.385,0.221-0.803,0.363-1.205,0.506
												c-0.199,0.074-0.401,0.147-0.56,0.27c-0.167,0.112-0.23,0.315-0.322,0.506c-0.17,0.385-0.329,0.771-0.49,1.151
												c-0.322,0.761-0.636,1.508-0.975,2.223c-0.176,0.356-0.332,0.712-0.575,1.031c-0.13,0.157-0.304,0.261-0.481,0.341
												c-0.178,0.08-0.369,0.129-0.561,0.144c-0.383,0.041-0.774-0.069-1.08-0.281c-0.307-0.216-0.525-0.521-0.687-0.839
												c-0.283-0.653-0.454-1.31-0.637-1.936c-0.176-0.628-0.334-1.234-0.482-1.812c-0.588-2.315-1.02-4.2-1.321-5.501
												c-0.148-0.648-0.264-1.152-0.344-1.499c-0.039-0.168-0.069-0.298-0.09-0.39C169.048,83.022,169.037,82.973,169.035,82.98
												z"
                                    />
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <path
                                    style={{ fill: "#E0E0E0" }}
                                    d="M182.681,82.44c-0.004,0-0.007-0.057-0.009-0.167c-0.001-0.12-0.003-0.279-0.005-0.478
											c-0.007-0.414-0.006-1.016-0.103-1.748c-0.1-0.725-0.316-1.605-0.883-2.398c-0.28-0.393-0.65-0.758-1.112-1.007
											c-0.461-0.25-1.003-0.378-1.56-0.417c-0.559-0.04-1.11-0.001-1.639,0.053c-0.529,0.055-1.036,0.135-1.515,0.233
											c-0.958,0.196-1.802,0.458-2.492,0.724c-0.69,0.266-1.237,0.517-1.597,0.717c-0.179,0.102-0.309,0.197-0.394,0.264
											c-0.085,0.068-0.127,0.107-0.13,0.104c-0.001-0.001,0.007-0.013,0.026-0.034c0.019-0.021,0.048-0.051,0.089-0.088
											c0.081-0.074,0.208-0.176,0.386-0.285c0.177-0.11,0.404-0.227,0.671-0.353c0.266-0.128,0.574-0.265,0.919-0.402
											c0.69-0.277,1.537-0.549,2.5-0.752c0.482-0.101,0.992-0.184,1.525-0.241c0.532-0.056,1.088-0.096,1.659-0.056
											c0.569,0.04,1.128,0.172,1.606,0.433c0.48,0.26,0.86,0.64,1.145,1.044c0.577,0.817,0.787,1.716,0.877,2.449
											c0.088,0.741,0.074,1.344,0.067,1.759c-0.007,0.199-0.012,0.357-0.016,0.478C182.689,82.382,182.685,82.44,182.681,82.44z
											"
                                  />
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <path
                                    style={{ fill: "#E0E0E0" }}
                                    d="M167.69,86.039c0.001,0.002-0.015,0.019-0.05,0.049c-0.041,0.035-0.092,0.079-0.155,0.133
											c-0.147,0.121-0.347,0.286-0.598,0.493c-0.529,0.428-1.271,1.029-2.164,1.751l-0.005,0.004l-0.006,0.003
											c-0.432,0.188-0.907,0.394-1.407,0.612l-0.027,0.012l-0.025-0.018c-0.754-0.533-1.58-1.116-2.444-1.726
											c-0.352-0.25-0.698-0.495-1.028-0.729l-0.018-0.013l-0.004-0.021c-0.054-0.251-0.087-0.498-0.101-0.745
											c-0.012-0.246-0.009-0.492,0.051-0.729c0.032-0.117,0.071-0.235,0.146-0.336c0.03-0.054,0.085-0.092,0.126-0.137
											l0.128-0.106c0.167-0.149,0.326-0.298,0.48-0.445c0.617-0.59,1.155-1.129,1.607-1.576
											c0.445-0.442,0.804-0.799,1.062-1.055c0.119-0.117,0.215-0.21,0.289-0.282c0.067-0.064,0.102-0.096,0.105-0.093
											c0.003,0.002-0.028,0.04-0.09,0.108c-0.07,0.075-0.161,0.173-0.275,0.296c-0.241,0.256-0.594,0.624-1.038,1.079
											c-0.445,0.455-0.977,1-1.591,1.595c-0.153,0.149-0.313,0.3-0.481,0.452l-0.132,0.111c-0.034,0.04-0.079,0.067-0.104,0.115
											c-0.064,0.084-0.099,0.192-0.128,0.3c-0.055,0.219-0.058,0.458-0.045,0.697c0.014,0.239,0.048,0.485,0.1,0.726
											l-0.023-0.033c0.33,0.233,0.676,0.478,1.028,0.727c0.862,0.612,1.687,1.198,2.44,1.733l-0.052-0.006
											c0.501-0.214,0.978-0.417,1.411-0.602l-0.011,0.007c0.903-0.709,1.653-1.298,2.189-1.719
											c0.258-0.198,0.463-0.356,0.614-0.472c0.066-0.049,0.12-0.089,0.164-0.121C167.668,86.05,167.688,86.037,167.69,86.039z"
                                  />
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <path
                                    style={{ fill: "#FFFFFF" }}
                                    d="M160.377,86.256c0,0-5.234,3.043-6.711,3.144c-1.477,0.101-6.284-0.939-6.284-0.939
											s2.753,2.576,4.788,3.131c2.036,0.554,3.744,0.937,5.812,0.362c2.068-0.575,5.425-2.937,5.425-2.937
											S163.393,85.98,160.377,86.256z"
                                  />
                                </g>
                              </g>
                            </g>
                          </g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <path
                                      style={{ fill: "#E0E0E0" }}
                                      d="M160.377,86.256c-0.001-0.002,0.026-0.004,0.079-0.008
												c0.059-0.003,0.138-0.007,0.238-0.012c0.106-0.006,0.237-0.014,0.391,0.009c0.077,0.008,0.16,0.017,0.249,0.026
												c0.089,0.01,0.179,0.045,0.277,0.068c0.387,0.104,0.853,0.35,1.207,0.82c0.176,0.231,0.328,0.509,0.431,0.825
												c0.105,0.314,0.182,0.659,0.196,1.032l0.001,0.02l-0.017,0.012c-0.673,0.47-1.442,0.97-2.302,1.479
												c-0.864,0.499-1.811,1.036-2.906,1.406c-0.549,0.176-1.135,0.292-1.741,0.34c-0.606,0.042-1.232,0.017-1.859-0.074
												c-0.628-0.09-1.258-0.233-1.891-0.398c-0.317-0.081-0.635-0.166-0.954-0.276c-0.155-0.063-0.311-0.126-0.464-0.195
												c-0.15-0.076-0.305-0.145-0.452-0.228c-1.334-0.739-2.489-1.671-3.518-2.599l-0.154-0.139l0.204,0.043
												c0.969,0.207,1.911,0.391,2.816,0.55c0.905,0.158,1.775,0.297,2.602,0.37c0.412,0.031,0.819,0.064,1.192-0.031
												c0.187-0.054,0.372-0.1,0.548-0.169c0.178-0.061,0.352-0.127,0.52-0.198c0.675-0.276,1.286-0.568,1.836-0.84
												c1.1-0.547,1.962-1.016,2.554-1.337c0.291-0.16,0.518-0.284,0.678-0.371c0.074-0.04,0.132-0.071,0.176-0.095
												C160.356,86.265,160.377,86.255,160.377,86.256c0.001,0.001-0.019,0.015-0.056,0.038
												c-0.043,0.026-0.099,0.06-0.171,0.104c-0.157,0.091-0.381,0.221-0.669,0.388c-0.587,0.332-1.443,0.811-2.541,1.367
												c-0.549,0.277-1.159,0.573-1.836,0.855c-0.168,0.073-0.343,0.14-0.523,0.202c-0.177,0.07-0.366,0.119-0.556,0.174
												c-0.393,0.102-0.806,0.068-1.221,0.039c-0.833-0.07-1.705-0.206-2.612-0.362c-0.908-0.157-1.85-0.34-2.822-0.545
												l0.05-0.095c1.023,0.92,2.177,1.849,3.496,2.579c0.145,0.082,0.298,0.15,0.445,0.225c0.151,0.069,0.304,0.13,0.456,0.192
												c0.308,0.107,0.627,0.192,0.943,0.273c0.631,0.165,1.257,0.308,1.878,0.397c0.621,0.091,1.237,0.117,1.835,0.077
												c0.597-0.046,1.176-0.159,1.717-0.331c1.08-0.361,2.028-0.892,2.891-1.386c0.86-0.503,1.631-0.999,2.305-1.462
												l-0.016,0.031c-0.035-0.719-0.255-1.368-0.599-1.82c-0.34-0.461-0.791-0.707-1.17-0.816
												c-0.096-0.025-0.185-0.06-0.272-0.072c-0.087-0.011-0.169-0.021-0.246-0.031c-0.152-0.027-0.281-0.022-0.385-0.02
												c-0.101,0-0.179,0.001-0.238,0.001C160.406,86.259,160.378,86.258,160.377,86.256z"
                                    />
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <path
                                    style={{ fill: "#E0E0E0" }}
                                    d="M149.86,86.852c-0.001,0.014-0.149,0.014-0.378-0.044
											c-0.229-0.055-0.531-0.168-0.861-0.296c-0.33-0.127-0.628-0.239-0.849-0.295c-0.221-0.06-0.363-0.066-0.364-0.084
											c-0.001-0.013,0.147-0.037,0.381,0.005c0.234,0.038,0.543,0.14,0.874,0.268c0.331,0.128,0.625,0.251,0.843,0.325
											C149.722,86.807,149.862,86.835,149.86,86.852z"
                                  />
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <path
                                    style={{ fill: "#E0E0E0" }}
                                    d="M148.309,95.744c0.005,0.012-0.183,0.106-0.508,0.198
											c-0.324,0.093-0.786,0.179-1.302,0.202c-0.516,0.021-0.986-0.03-1.313-0.122c-0.329-0.087-0.51-0.201-0.504-0.211
											c0.008-0.018,0.198,0.067,0.523,0.132c0.324,0.069,0.783,0.106,1.29,0.086c0.507-0.022,0.962-0.094,1.287-0.166
											C148.106,95.793,148.303,95.727,148.309,95.744z"
                                  />
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <path
                                    style={{ fill: "#E0E0E0" }}
                                    d="M148.133,92.894c-0.013,0.033-0.646-0.219-1.468-0.361
											c-0.821-0.147-1.502-0.127-1.503-0.162c-0.001-0.015,0.169-0.038,0.446-0.042c0.277-0.004,0.66,0.018,1.077,0.091
											c0.417,0.074,0.785,0.184,1.043,0.282C147.987,92.8,148.139,92.881,148.133,92.894z"
                                  />
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <path
                                    style={{ fill: "#E0E0E0" }}
                                    d="M148.724,97.984c0.009,0.008-0.134,0.173-0.427,0.355
											c-0.291,0.187-0.728,0.383-1.231,0.532c-0.505,0.144-0.979,0.213-1.324,0.212c-0.346,0.003-0.555-0.059-0.551-0.071
											c0.003-0.019,0.212,0.013,0.549-0.011c0.337-0.019,0.799-0.099,1.295-0.241c0.494-0.146,0.927-0.327,1.222-0.492
											C148.552,98.109,148.711,97.97,148.724,97.984z"
                                  />
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <path
                                    style={{ fill: "#E0E0E0" }}
                                    d="M150.086,100.863c0.009,0.012-0.098,0.107-0.296,0.22
											c-0.197,0.115-0.482,0.247-0.806,0.368c-0.325,0.119-0.627,0.205-0.851,0.246c-0.224,0.043-0.367,0.041-0.368,0.026
											c-0.001-0.017,0.134-0.043,0.349-0.105c0.215-0.06,0.509-0.157,0.83-0.274c0.32-0.12,0.606-0.238,0.808-0.332
											C149.956,100.918,150.076,100.849,150.086,100.863z"
                                  />
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <path
                                  style={{ fill: "#EBEBEB" }}
                                  d="M196.47,91.461c-0.112,0.165-1.132,5.32-0.467,7.172c0.665,1.852,5.859,12.198,5.859,12.198
										l0.556,3.274c0,0-1.58,2.935-1.342,3.385c0.238,0.451,0.608,0.484,0.608,0.484l8.833-1.499l0.058-0.774l-1.026-1.333
										l-0.412-0.772c0,0-0.752-4.433-0.73-4.718c0.022-0.284-0.184-12.249-0.184-12.249l-0.234-8.407
										c0,0-1.586-3.143-2.772-4.345c-1.186-1.202-7.241-2.56-7.241-2.56L196.47,91.461z"
                                />
                              </g>
                            </g>
                          </g>
                        </g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <path
                                  style={{ fill: "#E0E0E0" }}
                                  d="M211.524,102.339c0.062,0.09-0.387,0.98,0.173,2.188c0.56,1.207,3.073,2.755,3.233,2.767
										c0.16,0.012-0.335-0.812-0.335-0.812s1.647,0.631,2.407,0.461c0.761-0.17,0.192-0.031,0.192-0.031l0.46-0.078
										c0,0-1.774-0.688-2.244-1.595c-0.47-0.907-1.783-2.545-2.388-2.792c-0.605-0.247-0.924-0.205-0.924-0.205L211.524,102.339z
										"
                                />
                              </g>
                            </g>
                          </g>
                        </g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <path
                                  style={{ fill: "#EBEBEB" }}
                                  d="M207.99,88.222c0,0,1.641,1.725,2.148,4.714c0.507,2.989,0.755,4.448,1.457,6.827
										c0.703,2.379,1.799,4.882,1.799,4.882s-1.916-2.046-2.423-3.276c-0.508-1.23-1.307-2.812-1.477-4.277
										c-0.17-1.465-0.524-2.674-1.788-4.402C206.443,90.962,207.99,88.222,207.99,88.222z"
                                />
                              </g>
                            </g>
                          </g>
                        </g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <path
                                    style={{ fill: "#E0E0E0" }}
                                    d="M148.511,90.322c-0.016,0.027-0.55-0.258-1.268-0.453
											c-0.714-0.208-1.319-0.241-1.318-0.273c-0.003-0.032,0.621-0.05,1.349,0.162
											C148.007,89.957,148.531,90.296,148.511,90.322z"
                                  />
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <path
                                    style={{ fill: "#E0E0E0" }}
                                    d="M217.624,106.839c-0.028,0.005-0.16,0.029-0.46,0.082l-0.002-0.009
											c0.033-0.008,0.068-0.017,0.104-0.026c0.018-0.005,0.037-0.01,0.056-0.015l0.029-0.008
											c0.004-0.001,0.012-0.003,0.012-0.003c0.004,0.017-0.007,0.006-0.01,0.011c-0.081,0.018-0.17,0.037-0.266,0.058
											c-0.19,0.051-0.422,0.069-0.679,0.042c-0.515-0.053-1.136-0.214-1.848-0.472l0.015-0.018
											c0.113,0.193,0.231,0.395,0.327,0.623c0.018,0.059,0.056,0.113,0.033,0.191c-0.016,0.023-0.044,0.017-0.056,0.01
											c-0.016-0.006-0.033-0.01-0.048-0.017l-0.086-0.043c-0.114-0.061-0.227-0.128-0.341-0.198
											c-0.455-0.284-0.933-0.617-1.416-1.014c-0.242-0.198-0.484-0.412-0.715-0.654c-0.23-0.241-0.461-0.505-0.618-0.829
											c-0.153-0.322-0.254-0.681-0.285-1.058c-0.015-0.188-0.009-0.381,0.014-0.574c0.011-0.096,0.028-0.193,0.047-0.289
											l0.03-0.144c0.009-0.044,0.021-0.105,0.015-0.13l-0.007-0.029l0.021-0.004h0l-0.014,0.032
											c-0.19-0.282-0.378-0.578-0.526-0.906c-0.141-0.328-0.285-0.664-0.433-1.007c-0.297-0.686-0.605-1.406-0.834-2.188
											c-0.26-0.779-0.249-1.643-0.483-2.483c-0.215-0.846-0.612-1.67-1.123-2.449l0.052-0.016
											c0.035,1.406,0.094,2.89,0.114,4.449c0.027,1.558,0.055,3.19,0.084,4.892c0.026,1.702,0.053,3.473,0.081,5.309
											c0.002,0.229,0.005,0.46,0.007,0.692c0.002,0.115-0.005,0.239,0.005,0.345c0.012,0.115,0.026,0.23,0.043,0.345
											c0.063,0.462,0.137,0.928,0.211,1.397c0.155,0.938,0.312,1.891,0.472,2.859l-0.004-0.012
											c0.123,0.23,0.267,0.501,0.412,0.772l-0.003-0.005c0.308,0.4,0.665,0.864,1.026,1.333l0.009,0.012l-0.001,0.015
											c-0.017,0.231-0.038,0.502-0.058,0.774l-0.002,0.031l-0.031,0.005c-2.751,0.468-5.735,0.975-8.832,1.502l-0.007,0.001
											l-0.004-0.001c-0.188-0.03-0.347-0.131-0.473-0.266c-0.062-0.068-0.117-0.143-0.161-0.225
											c-0.055-0.097-0.039-0.2-0.028-0.29c0.063-0.368,0.207-0.712,0.349-1.062c0.295-0.696,0.643-1.395,1.01-2.09l-0.005,0.028
											c-0.169-0.995-0.362-2.128-0.556-3.274l0.004,0.013c-0.9-1.816-1.901-3.835-2.922-5.895l0.057,0.024
											c-1.502,0.498-3.028,1.003-4.577,1.517c-0.775,0.256-1.556,0.515-2.342,0.775c-0.205,0.048-0.411,0.099-0.62,0.113
											c-0.208,0.03-0.418,0.031-0.627,0.039c-0.419,0.009-0.839-0.007-1.259-0.034c-1.679-0.121-3.366-0.387-5.052-0.754
											c-0.421-0.094-0.842-0.196-1.26-0.319c-0.421-0.124-0.816-0.303-1.224-0.459c-0.811-0.322-1.626-0.647-2.445-0.973
											l0.07-0.037c-0.118,0.541-0.252,1.156-0.387,1.772l0.001-0.01c-0.022,1.082-0.09,2.182-0.199,3.298
											c-0.116,1.113-0.267,2.246-0.558,3.365c-0.134,0.564-0.371,1.1-0.537,1.652c-0.173,0.553-0.32,1.119-0.443,1.692
											c-0.244,1.146-0.424,2.32-0.53,3.504l-0.018-0.045c0.329,0.289,0.7,0.614,1.072,0.941l0.037,0.032l-0.03,0.042
											c-0.129,0.179-0.239,0.4-0.244,0.623c-0.001,0.028,0.001,0.055,0.005,0.082l0.002,0.025c0,0.017-0.001,0.036-0.008,0.047
											c-0.012,0.028-0.027,0.041-0.041,0.056c-0.058,0.048-0.115,0.073-0.174,0.099c-0.116,0.049-0.235,0.085-0.354,0.12
											c-0.478,0.134-0.972,0.23-1.474,0.321c-0.25,0.045-0.505,0.087-0.758,0.126c-0.253,0.048-0.507,0.096-0.762,0.144
											c-0.51,0.097-1.025,0.194-1.54,0.291l-0.063,0.012l-0.002-0.061c-0.001-0.03-0.002-0.064-0.003-0.098l0.064,0.052
											c-0.963,0.184-2.035,0.389-3.112,0.595l-0.023,0.004l-0.017-0.011c-0.257-0.168-0.482-0.4-0.607-0.703
											c-0.027-0.077-0.054-0.153-0.059-0.24c-0.008-0.092,0.014-0.172,0.032-0.253c0.051-0.155,0.113-0.305,0.189-0.447
											c0.301-0.572,0.682-1.109,1.08-1.636l-0.012,0.036c-0.048-1.391-0.101-2.925-0.154-4.468l0.014,0.035
											c-0.546-0.643-1.084-1.297-1.622-1.959c-0.538-0.662-1.078-1.331-1.583-2.034c-0.496-0.705-0.98-1.451-1.214-2.311
											c-0.052-0.224-0.053-0.448-0.044-0.668c0.009-0.221,0.031-0.44,0.06-0.659c0.057-0.437,0.137-0.871,0.228-1.303
											c0.182-0.864,0.405-1.722,0.651-2.578c0.248-0.854,0.516-1.707,0.835-2.547c0.082-0.209,0.166-0.418,0.268-0.622
											c0.023-0.051,0.057-0.102,0.084-0.151c0.021-0.048,0.051-0.09,0.066-0.142c0.038-0.101,0.063-0.208,0.087-0.316
											c0.083-0.435,0.115-0.884,0.137-1.333c0.039-0.898,0.028-1.801,0.001-2.706l0.007,0.025
											c-0.771-1.447-1.608-3.016-2.449-4.592l0.016,0.019c-0.959-0.728-2-1.518-3.044-2.31l0.066,0.002
											c-0.595,0.387-1.225,0.773-1.882,1.144c-0.659,0.366-1.341,0.724-2.063,1.002c-0.789,0.307-1.629,0.462-2.469,0.49
											c-0.842,0.029-1.679-0.094-2.493-0.27l0.057-0.02c-0.673,0.854-1.281,1.757-1.826,2.691
											c-0.271,0.468-0.526,0.945-0.751,1.434c-0.222,0.489-0.425,0.992-0.518,1.514c-0.114,0.52,0.047,1.057,0.349,1.484
											c0.151,0.216,0.338,0.405,0.534,0.581c0.203,0.17,0.42,0.323,0.648,0.457c0.23,0.131,0.47,0.246,0.718,0.327
											c0.128,0.038,0.243,0.071,0.374,0.058c0.13-0.008,0.262-0.023,0.393-0.042c0.525-0.078,1.047-0.189,1.565-0.306
											l-0.025,0.098c-0.224-0.202-0.436-0.411-0.639-0.633c-0.201-0.222-0.395-0.453-0.545-0.721
											c-0.036-0.068-0.069-0.138-0.092-0.215c-0.014-0.036-0.015-0.084-0.021-0.125c-0.003-0.043,0.013-0.087,0.021-0.13
											c0.072-0.167,0.225-0.249,0.363-0.311c0.287-0.117,0.585-0.159,0.885-0.181l-0.051,0.076
											c-0.137-0.409-0.238-0.826-0.29-1.253c-0.023-0.216-0.04-0.423-0.014-0.653c0.01-0.059,0.044-0.132,0.112-0.166
											c0.064-0.036,0.132-0.035,0.189-0.027c0.117,0.017,0.219,0.06,0.317,0.106c0.388,0.194,0.728,0.45,1.065,0.706
											c0.335,0.259,0.66,0.528,0.982,0.8l0.481,0.408c0.171,0.154,0.266,0.361,0.328,0.565c0.12,0.414,0.144,0.84,0.148,1.26
											c0.001,0.842-0.095,1.674-0.234,2.497l-0.003,0.019l-0.014,0.012c-0.422,0.382-0.881,0.719-1.374,0.997
											c-0.497,0.27-1.015,0.498-1.55,0.673c-1.071,0.338-2.192,0.527-3.31,0.468c-0.279-0.022-0.559-0.048-0.831-0.135
											c-0.272-0.085-0.518-0.224-0.755-0.367c-0.475-0.288-0.922-0.612-1.36-0.946c-0.874-0.669-1.703-1.387-2.488-2.143
											c-0.194-0.191-0.387-0.384-0.573-0.583c-0.185-0.199-0.373-0.398-0.527-0.63c-0.304-0.463-0.47-0.994-0.58-1.524
											c-0.212-1.066-0.186-2.156-0.129-3.215c0.007-1.072,0.203-2.128,0.453-3.153c0.257-1.025,0.576-2.029,0.958-3.001
											c0.382-0.972,0.797-1.926,1.278-2.839c0.226-0.465,0.493-0.905,0.738-1.355l0.4-0.656
											c0.133-0.219,0.266-0.437,0.412-0.646c1.129-1.694,2.395-3.287,3.898-4.596c1.497-1.311,3.135-2.409,4.818-3.357
											c1.694-0.932,3.424-1.74,5.198-2.36l-0.015,0.008c0.855-0.649,1.728-1.258,2.669-1.735c0.118-0.06,0.24-0.109,0.36-0.164
											c0.126-0.041,0.245-0.1,0.376-0.127c0.065-0.014,0.128-0.037,0.195-0.045l0.202-0.02l0.393,0.029
											c0.519,0.069,1.022,0.197,1.511,0.354c0.98,0.312,1.913,0.718,2.824,1.138c0.91,0.424,1.794,0.878,2.655,1.353
											l-0.03-0.005c1.225-0.2,2.434-0.388,3.639-0.438c0.301-0.009,0.604-0.009,0.903,0.035
											c0.297,0.046,0.587,0.117,0.872,0.197c0.57,0.162,1.122,0.365,1.662,0.582c0.539,0.22,1.066,0.457,1.577,0.715
											c0.511,0.259,1.01,0.534,1.474,0.86c0.227,0.168,0.461,0.335,0.618,0.589c0.073,0.135,0.087,0.276,0.115,0.414
											c0.023,0.138,0.041,0.276,0.056,0.414c0.061,0.552,0.093,1.101,0.12,1.646l-0.024-0.036
											c1.379,0.624,2.617,1.184,3.83,1.733l-0.026-0.003c1.613-0.383,3.186-0.715,4.721-0.973
											c0.767-0.129,1.526-0.236,2.277-0.307c0.75-0.063,1.497-0.116,2.225-0.005c0.726,0.134,1.387,0.443,2.005,0.781
											c0.618,0.342,1.203,0.717,1.759,1.111c1.111,0.792,2.132,1.626,3.073,2.483l0.022,0.02l-0.018,0.023l-0.001,0.001
											l-0.008-0.047c0.115,0.073,0.226,0.151,0.327,0.241c0.102,0.091,0.183,0.194,0.273,0.288
											c0.17,0.198,0.327,0.4,0.475,0.602c0.293,0.406,0.554,0.811,0.798,1.209c0.487,0.796,0.908,1.563,1.297,2.319
											l-0.005-0.007c0.546,0.619,0.954,1.309,1.288,2c0.329,0.695,0.574,1.401,0.736,2.1c0.156,0.702,0.25,1.386,0.368,2.042
											c0.114,0.657,0.225,1.288,0.345,1.894c0.475,2.424,1.107,4.414,1.709,6.026l-0.012-0.01
											c0.151,0.029,0.295,0.071,0.434,0.121c0.139,0.047,0.273,0.114,0.388,0.199c0.232,0.169,0.425,0.365,0.606,0.557
											c0.361,0.386,0.664,0.77,0.928,1.132c0.263,0.363,0.481,0.71,0.656,1.035c0.191,0.314,0.452,0.53,0.684,0.704
											c0.474,0.342,0.867,0.531,1.123,0.656C217.493,106.787,217.624,106.839,217.624,106.839s-0.132-0.047-0.393-0.167
											c-0.258-0.123-0.652-0.31-1.129-0.65c-0.234-0.174-0.496-0.389-0.692-0.708c-0.177-0.325-0.395-0.669-0.658-1.031
											c-0.264-0.361-0.568-0.743-0.929-1.127c-0.181-0.19-0.375-0.386-0.604-0.552c-0.114-0.084-0.241-0.147-0.381-0.194
											c-0.138-0.05-0.281-0.091-0.43-0.119l-0.009-0.002l-0.003-0.009c-0.607-1.611-1.244-3.603-1.723-6.028
											c-0.121-0.606-0.234-1.238-0.348-1.895c-0.12-0.655-0.215-1.342-0.371-2.038c-0.163-0.696-0.407-1.398-0.735-2.089
											c-0.333-0.687-0.742-1.374-1.281-1.984l-0.003-0.004l-0.002-0.003c-0.389-0.753-0.81-1.519-1.297-2.313
											c-0.244-0.396-0.505-0.8-0.797-1.204c-0.147-0.201-0.303-0.401-0.472-0.597c-0.089-0.092-0.172-0.197-0.268-0.282
											c-0.097-0.086-0.206-0.162-0.32-0.234l-0.03-0.019l0.022-0.028l0.001-0.001l0.004,0.043
											c-0.939-0.853-1.96-1.686-3.069-2.474c-0.555-0.393-1.138-0.766-1.753-1.105c-0.614-0.336-1.272-0.641-1.984-0.771
											c-0.715-0.108-1.46-0.056-2.206,0.008c-0.748,0.071-1.505,0.178-2.271,0.308c-1.532,0.259-3.103,0.592-4.715,0.975
											l-0.013,0.003l-0.013-0.006c-1.213-0.548-2.451-1.107-3.831-1.73l-0.023-0.01l-0.001-0.026
											c-0.027-0.544-0.059-1.092-0.12-1.641c-0.015-0.137-0.033-0.274-0.056-0.41c-0.026-0.133-0.044-0.277-0.106-0.387
											c-0.137-0.225-0.37-0.397-0.592-0.56c-0.457-0.321-0.954-0.595-1.463-0.852c-0.509-0.257-1.034-0.493-1.571-0.711
											c-0.538-0.216-1.087-0.418-1.653-0.578c-0.283-0.08-0.57-0.15-0.861-0.194c-0.291-0.042-0.588-0.042-0.887-0.033
											c-1.195,0.051-2.404,0.239-3.626,0.44l-0.016,0.003l-0.014-0.008c-0.859-0.473-1.742-0.926-2.65-1.348
											c-0.909-0.419-1.84-0.823-2.813-1.133c-0.486-0.156-0.985-0.282-1.493-0.35c-0.517-0.107-1.005,0.089-1.472,0.318
											c-0.929,0.471-1.802,1.08-2.651,1.726l-0.007,0.005l-0.008,0.003c-1.764,0.617-3.493,1.425-5.18,2.355
											c-1.678,0.946-3.309,2.041-4.799,3.346c-1.495,1.302-2.753,2.888-3.879,4.577c-0.145,0.208-0.277,0.426-0.41,0.644
											l-0.399,0.654c-0.244,0.449-0.511,0.888-0.735,1.351c-0.479,0.91-0.892,1.861-1.273,2.829
											c-0.38,0.969-0.698,1.968-0.953,2.988c-0.249,1.021-0.443,2.065-0.449,3.129c-0.057,1.065-0.081,2.139,0.128,3.191
											c0.108,0.521,0.272,1.04,0.564,1.485c0.146,0.22,0.332,0.416,0.515,0.615c0.185,0.197,0.377,0.388,0.57,0.579
											c0.782,0.752,1.609,1.468,2.479,2.134c0.436,0.332,0.881,0.654,1.35,0.939c0.234,0.141,0.475,0.276,0.731,0.356
											c0.256,0.082,0.532,0.109,0.804,0.13c1.099,0.058,2.211-0.129,3.268-0.464c0.529-0.173,1.039-0.398,1.529-0.664
											c0.486-0.274,0.938-0.606,1.352-0.981l-0.017,0.031c0.138-0.815,0.233-1.646,0.232-2.476
											c-0.004-0.414-0.029-0.834-0.144-1.228c-0.058-0.195-0.149-0.383-0.292-0.512l-0.481-0.408
											c-0.321-0.271-0.645-0.54-0.978-0.797c-0.334-0.254-0.674-0.508-1.045-0.693c-0.092-0.044-0.189-0.082-0.282-0.095
											c-0.097-0.014-0.163,0.014-0.175,0.103c-0.021,0.191-0.007,0.412,0.016,0.616c0.05,0.416,0.15,0.83,0.284,1.228
											l0.024,0.071l-0.075,0.006c-0.287,0.021-0.584,0.064-0.846,0.173c-0.128,0.055-0.254,0.133-0.301,0.246
											c-0.041,0.111,0.02,0.249,0.087,0.375c0.141,0.251,0.33,0.48,0.529,0.698c0.199,0.218,0.411,0.427,0.63,0.624l0.083,0.074
											l-0.107,0.024c-0.521,0.117-1.043,0.228-1.575,0.308c-0.133,0.019-0.267,0.035-0.404,0.043
											c-0.138,0.015-0.287-0.024-0.414-0.063c-0.261-0.085-0.506-0.202-0.741-0.337c-0.234-0.138-0.456-0.294-0.665-0.47
											c-0.203-0.181-0.396-0.377-0.553-0.602c-0.316-0.444-0.489-1.023-0.368-1.569c0.096-0.541,0.303-1.049,0.527-1.543
											c0.227-0.493,0.484-0.973,0.756-1.444c0.548-0.939,1.158-1.846,1.835-2.705l0.022-0.028l0.035,0.008
											c0.809,0.175,1.635,0.295,2.465,0.267c0.827-0.027,1.657-0.18,2.431-0.482c1.421-0.567,2.733-1.373,3.923-2.135
											l0.034-0.022l0.032,0.024c1.044,0.792,2.084,1.582,3.044,2.31l0.01,0.008l0.006,0.011c0.84,1.576,1.677,3.146,2.449,4.592
											l0.006,0.012l0,0.013c0.026,0.907,0.038,1.812-0.001,2.715c-0.022,0.451-0.054,0.903-0.139,1.35
											c-0.026,0.112-0.051,0.223-0.092,0.333c-0.016,0.055-0.051,0.108-0.076,0.162c-0.026,0.05-0.055,0.092-0.079,0.143
											c-0.099,0.197-0.182,0.404-0.264,0.611c-0.317,0.834-0.585,1.686-0.832,2.537c-0.245,0.853-0.467,1.709-0.649,2.569
											c-0.09,0.43-0.17,0.861-0.226,1.294c-0.029,0.216-0.05,0.433-0.059,0.649c-0.01,0.215-0.007,0.434,0.04,0.636
											c0.227,0.833,0.703,1.573,1.196,2.273c0.502,0.699,1.041,1.367,1.578,2.028c0.538,0.661,1.077,1.316,1.62,1.957
											l0.013,0.015l0.001,0.02c0.053,1.543,0.106,3.077,0.154,4.468l0.001,0.02l-0.013,0.016
											c-0.394,0.521-0.775,1.059-1.069,1.618c-0.135,0.283-0.295,0.572-0.158,0.849c0.11,0.272,0.327,0.496,0.561,0.649
											l-0.04-0.007c1.078-0.206,2.152-0.411,3.116-0.595l0.063-0.012l0.002,0.064c0.001,0.032,0.002,0.064,0.002,0.093
											l-0.065-0.05c0.516-0.097,1.031-0.194,1.541-0.291c0.255-0.048,0.51-0.096,0.763-0.144
											c0.256-0.039,0.507-0.081,0.757-0.125c0.499-0.09,0.992-0.187,1.463-0.318c0.117-0.034,0.233-0.07,0.342-0.115
											c0.053-0.023,0.108-0.049,0.143-0.079c0.006-0.007,0.014-0.015,0.013-0.016c0.002-0.002,0.008,0.003,0.011,0.002
											c-0.02,0.004-0.007-0.013-0.012-0.019c-0.004-0.033-0.007-0.066-0.006-0.098c0.009-0.263,0.126-0.49,0.268-0.688
											l0.007,0.074c-0.372-0.326-0.743-0.652-1.072-0.941l-0.021-0.018l0.002-0.027c0.107-1.191,0.287-2.366,0.533-3.518
											c0.123-0.576,0.271-1.144,0.446-1.701c0.17-0.561,0.404-1.09,0.536-1.647c0.289-1.109,0.44-2.238,0.556-3.348
											c0.11-1.112,0.177-2.211,0.2-3.288l0-0.005l0.001-0.005c0.135-0.617,0.269-1.231,0.387-1.772l0.013-0.059l0.057,0.023
											c0.819,0.326,1.633,0.65,2.443,0.973c0.406,0.156,0.805,0.337,1.217,0.457c0.414,0.122,0.833,0.225,1.253,0.318
											c1.68,0.366,3.365,0.633,5.036,0.754c0.418,0.026,0.835,0.043,1.25,0.034c0.207-0.008,0.415-0.009,0.618-0.039
											c0.207-0.013,0.404-0.063,0.602-0.109c0.786-0.26,1.567-0.517,2.341-0.773c1.549-0.512,3.076-1.017,4.577-1.514
											l0.039-0.013l0.018,0.037c1.02,2.06,2.02,4.079,2.919,5.896l0.003,0.006l0.001,0.007c0.194,1.146,0.386,2.278,0.555,3.274
											l0.002,0.015l-0.007,0.014c-0.367,0.693-0.714,1.39-1.008,2.082c-0.141,0.346-0.285,0.693-0.344,1.04
											c-0.012,0.085-0.02,0.175,0.018,0.237c0.041,0.076,0.091,0.145,0.148,0.207c0.112,0.123,0.266,0.215,0.417,0.239l-0.011,0
											c3.098-0.525,6.082-1.03,8.833-1.496l-0.033,0.036c0.02-0.272,0.041-0.543,0.058-0.774l0.008,0.027
											c-0.36-0.469-0.717-0.933-1.025-1.333l-0.002-0.003l-0.001-0.003c-0.145-0.271-0.289-0.542-0.411-0.772l-0.003-0.006
											l-0.001-0.006c-0.159-0.969-0.316-1.922-0.47-2.86c-0.074-0.47-0.147-0.935-0.211-1.399
											c-0.016-0.116-0.031-0.232-0.042-0.349c-0.01-0.126-0.003-0.235-0.005-0.351c-0.002-0.232-0.004-0.462-0.006-0.692
											c-0.026-1.836-0.052-3.607-0.076-5.309c-0.027-1.702-0.053-3.333-0.078-4.892c-0.018-1.558-0.076-3.042-0.109-4.448
											l-0.002-0.099l0.054,0.083c0.513,0.783,0.912,1.613,1.127,2.466c0.235,0.85,0.222,1.71,0.479,2.483
											c0.227,0.779,0.533,1.498,0.829,2.184c0.147,0.344,0.291,0.679,0.431,1.007c0.145,0.324,0.332,0.619,0.521,0.9
											l0.018,0.027l-0.032,0.005l0,0l0.014-0.033c0.021,0.072-0.003,0.11-0.009,0.162l-0.03,0.144
											c-0.019,0.095-0.036,0.191-0.047,0.286c-0.023,0.19-0.029,0.38-0.015,0.566c0.031,0.371,0.129,0.727,0.28,1.045
											c0.152,0.318,0.381,0.581,0.609,0.821c0.229,0.241,0.47,0.455,0.711,0.653c0.481,0.396,0.957,0.73,1.41,1.015
											c0.114,0.07,0.226,0.138,0.339,0.198c0.052,0.025,0.125,0.07,0.155,0.059c0.013-0.043-0.016-0.113-0.036-0.166
											c-0.094-0.225-0.212-0.429-0.324-0.621l-0.017-0.029l0.032,0.012c0.708,0.259,1.329,0.424,1.841,0.478
											c0.256,0.029,0.484,0.012,0.675-0.038c0.096-0.02,0.185-0.039,0.267-0.056c0.007,0.003,0.006-0.011,0.02,0.007
											c-0.009,0.005-0.011,0.004-0.017,0.006l-0.029,0.008c-0.019,0.005-0.038,0.01-0.056,0.014
											c-0.037,0.009-0.072,0.017-0.104,0.025l-0.002-0.009C217.463,106.865,217.595,106.844,217.624,106.839z"
                                  />
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                      <g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <path
                                  style={{ fill: "#E0E0E0" }}
                                  d="M170.205,120.273c-0.002,0-0.004-0.026-0.007-0.076c-0.003-0.056-0.006-0.13-0.011-0.223
										c-0.007-0.203-0.017-0.49-0.029-0.856c-0.021-0.751-0.05-1.825-0.086-3.15c-0.062-2.666-0.146-6.338-0.24-10.394
										c-0.042-2.055-0.083-4.012-0.119-5.786l0.006,0.024c-1.224-2.314-2.266-4.283-3.005-5.68
										c-0.362-0.692-0.65-1.243-0.851-1.627c-0.095-0.185-0.168-0.33-0.222-0.434c-0.023-0.046-0.04-0.083-0.055-0.113
										c-0.012-0.025-0.018-0.039-0.016-0.04c0.001-0.001,0.009,0.011,0.024,0.036c0.017,0.029,0.037,0.064,0.063,0.109
										c0.057,0.103,0.136,0.245,0.237,0.426c0.207,0.381,0.504,0.926,0.877,1.613c0.748,1.392,1.803,3.354,3.042,5.661
										l0.006,0.011l0,0.013c0.04,1.774,0.083,3.73,0.129,5.785c0.08,4.056,0.153,7.728,0.206,10.394
										c0.021,1.325,0.037,2.4,0.049,3.151c0.003,0.367,0.006,0.654,0.008,0.857c0,0.093-0.001,0.167-0.001,0.223
										C170.208,120.247,170.207,120.273,170.205,120.273z"
                                />
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                      <g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <path
                                  style={{ fill: "#E0E0E0" }}
                                  d="M181.336,99.334c0.007-0.001,0.035,0.09,0.067,0.26c0.034,0.169,0.066,0.418,0.074,0.728
										c0.017,0.616-0.091,1.492-0.51,2.349c-0.206,0.427-0.451,0.805-0.671,1.147c-0.222,0.341-0.418,0.648-0.568,0.915
										c-0.304,0.532-0.413,0.898-0.433,0.891c-0.007-0.002,0.014-0.095,0.068-0.26c0.054-0.164,0.151-0.396,0.294-0.67
										c0.142-0.274,0.334-0.589,0.55-0.933c0.215-0.345,0.455-0.721,0.656-1.14c0.409-0.838,0.528-1.691,0.532-2.298
										C181.4,99.711,181.314,99.337,181.336,99.334z"
                                />
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                      <g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <path
                                  style={{ fill: "#E0E0E0" }}
                                  d="M196.439,88.449c0.002,0,0.001,0.018-0.002,0.052c-0.004,0.041-0.009,0.09-0.015,0.151
										c-0.016,0.142-0.039,0.334-0.067,0.578c-0.059,0.502-0.144,1.227-0.237,2.125c-0.092,0.898-0.195,1.968-0.268,3.158
										c-0.036,0.595-0.064,1.221-0.067,1.869c0.002,0.324,0.004,0.653,0.027,0.986c0.024,0.331,0.056,0.672,0.149,0.994
										c1.208,2.454,2.262,4.734,3.01,6.372c0.368,0.816,0.668,1.479,0.88,1.949c0.098,0.224,0.176,0.402,0.233,0.533
										c0.023,0.056,0.042,0.102,0.058,0.14c0.012,0.032,0.018,0.049,0.016,0.049s-0.011-0.014-0.027-0.044
										c-0.019-0.037-0.041-0.081-0.068-0.135c-0.062-0.129-0.146-0.303-0.253-0.524c-0.22-0.466-0.531-1.124-0.913-1.934
										c-0.77-1.637-1.826-3.885-3.047-6.373c-0.098-0.34-0.129-0.684-0.153-1.02c-0.024-0.337-0.025-0.669-0.026-0.994
										c0.005-0.651,0.035-1.278,0.074-1.875c0.079-1.193,0.19-2.263,0.293-3.16c0.104-0.898,0.201-1.622,0.274-2.122
										c0.037-0.242,0.066-0.434,0.088-0.575c0.011-0.06,0.019-0.109,0.027-0.149C196.432,88.465,196.437,88.448,196.439,88.449z"
                                />
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                      <g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <path
                                  style={{ fill: "#E0E0E0" }}
                                  d="M207.575,88.661c0.008-0.002,0.033,0.067,0.073,0.193c0.041,0.126,0.091,0.31,0.148,0.54
										c0.113,0.459,0.239,1.102,0.319,1.82c0.079,0.718,0.097,1.373,0.087,1.846c-0.005,0.236-0.014,0.427-0.026,0.559
										c-0.011,0.132-0.021,0.204-0.029,0.204c-0.017-0.001-0.015-0.293-0.026-0.763c-0.011-0.47-0.041-1.12-0.12-1.833
										c-0.079-0.713-0.191-1.353-0.284-1.815C207.624,88.95,207.558,88.666,207.575,88.661z"
                                />
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                      <g>
                        <g>
                          <g>
                            <g>
                              <path
                                style={{ fill: "#E0E0E0" }}
                                d="M166.758,79.702c-0.108,0.378-0.502,0.597-0.88,0.489c-0.378-0.108-0.597-0.502-0.49-0.88
									c0.108-0.378,0.502-0.597,0.88-0.489C166.647,78.93,166.866,79.324,166.758,79.702z"
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                      <g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <path
                                  style={{ fill: "#E0E0E0" }}
                                  d="M167.317,78.241c-0.046,0.048-0.393-0.29-0.94-0.376c-0.546-0.092-0.982,0.118-1.01,0.058
										c-0.015-0.026,0.076-0.116,0.263-0.197c0.185-0.08,0.473-0.139,0.785-0.088c0.312,0.051,0.566,0.198,0.716,0.333
										C167.282,78.107,167.339,78.221,167.317,78.241z"
                                />
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                      <g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <path
                                  style={{ fill: "#E0E0E0" }}
                                  d="M152.874,85.224c-0.015,0.012-0.167-0.176-0.442-0.45c-0.274-0.274-0.68-0.626-1.181-0.939
										c-0.502-0.312-0.996-0.522-1.363-0.647c-0.367-0.126-0.602-0.18-0.598-0.199c0.001-0.008,0.062-0.002,0.17,0.016
										c0.109,0.016,0.264,0.052,0.452,0.105c0.378,0.107,0.888,0.309,1.399,0.627c0.51,0.319,0.916,0.688,1.178,0.98
										c0.132,0.146,0.232,0.27,0.294,0.36C152.848,85.167,152.88,85.219,152.874,85.224z"
                                />
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g>
                    <g>
                      <g>
                        <g>
                          <path
                            style={{ fill: "#E0E0E0" }}
                            d="M180.136,48.739c0,2.424-1.965,4.389-4.389,4.389c-2.424,0-4.389-1.965-4.389-4.389
							c0-2.424,1.965-4.389,4.389-4.389C178.171,44.35,180.136,46.315,180.136,48.739z"
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
                <g>
                  <g>
                    <g>
                      <g>
                        <polygon
                          style={{ fill: "#F5F5F5" }}
                          points="53.447,175.641 53.447,181.381 121.822,183.625 124.579,108.419 120.484,179.502 					
						"
                        />
                      </g>
                    </g>
                  </g>
                  <g>
                    <g>
                      <g>
                        <g>
                          <g>
                            <rect
                              x="50.175"
                              y="105.026"
                              transform="matrix(0.0575 -0.9983 0.9983 0.0575 -59.5862 219.3871)"
                              style={{ fill: "#FFFFFF" }}
                              width="72.453"
                              height="72.453"
                            />
                          </g>
                        </g>
                      </g>
                      <g>
                        <g>
                          <g>
                            <g>
                              <path
                                style={{ fill: "#E0E0E0" }}
                                d="M120.485,179.502c0,0,0.001-0.119,0.012-0.344c0.012-0.231,0.028-0.564,0.05-1.001
									c0.047-0.886,0.117-2.19,0.207-3.887c0.187-3.403,0.461-8.381,0.81-14.714c0.711-12.656,1.727-30.723,2.944-52.395
									l0.136,0.152c-21.024-1.205-45.845-2.627-72.327-4.144l-0.007,0l0.176-0.157c-1.534,26.487-2.972,51.308-4.189,72.332
									l-0.136-0.152c21.671,1.279,39.734,2.345,52.388,3.092c6.331,0.381,11.307,0.68,14.709,0.885
									c1.696,0.105,2.999,0.186,3.885,0.241c0.437,0.029,0.769,0.051,1,0.066C120.366,179.49,120.485,179.502,120.485,179.502
									s-0.108-0.001-0.328-0.011c-0.228-0.011-0.555-0.027-0.985-0.049c-0.88-0.047-2.174-0.116-3.859-0.205
									c-3.395-0.187-8.361-0.46-14.681-0.808c-12.678-0.712-30.776-1.73-52.488-2.95l-0.144-0.008l0.008-0.144
									c1.205-21.024,2.627-45.846,4.144-72.334l0.01-0.167l0.167,0.01l0.007,0c26.481,1.534,51.301,2.971,72.325,4.189
									l0.145,0.008l-0.009,0.144c-1.281,21.707-2.349,39.802-3.097,52.478c-0.38,6.317-0.679,11.283-0.883,14.677
									c-0.104,1.685-0.185,2.978-0.239,3.857c-0.028,0.43-0.05,0.757-0.065,0.985
									C120.496,179.395,120.485,179.502,120.485,179.502z"
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                    <g>
                      <g>
                        <g>
                          <g>
                            <path
                              style={{ fill: "#EBEBEB" }}
                              d="M76.953,126.393c-1.722-2.519-4.518-4.346-7.594-4.961c-0.288-0.058-0.608-0.1-0.858,0.045
								c-0.356,0.207-0.398,0.68-0.382,1.079c0.117,2.89,1.213,5.741,3.084,8.02c1.871,2.28,4.507,3.977,7.416,4.775l0.033-0.326
								C79.301,132.092,78.675,128.912,76.953,126.393z"
                            />
                          </g>
                        </g>
                      </g>
                      <g>
                        <g>
                          <g>
                            <g>
                              <path
                                style={{ fill: "#E0E0E0" }}
                                d="M86.792,123.412c0.034,0.055-0.869,0.583-2.158,1.651c-0.648,0.53-1.37,1.218-2.117,2.032
									c-0.733,0.826-1.484,1.783-2.171,2.857c-0.675,1.082-1.218,2.171-1.648,3.188c-0.413,1.024-0.726,1.972-0.926,2.784
									c-0.412,1.623-0.5,2.665-0.564,2.658c-0.023-0.002-0.013-0.262,0.024-0.734c0.015-0.236,0.043-0.524,0.103-0.855
									c0.056-0.331,0.103-0.713,0.208-1.123c0.173-0.829,0.466-1.799,0.87-2.845c0.421-1.039,0.965-2.151,1.651-3.251
									c0.698-1.092,1.469-2.06,2.226-2.886c0.771-0.814,1.521-1.495,2.196-2.007c0.325-0.271,0.65-0.477,0.925-0.67
									c0.273-0.196,0.522-0.345,0.729-0.46C86.549,123.513,86.78,123.393,86.792,123.412z"
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                      <g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <path
                                  style={{ fill: "#F5F5F5" }}
                                  d="M58.056,157.707c3.129,7.157,7.927,12.133,14.193,12.722
										c1.253,0.114,2.248-0.62,3.451-0.618c0.611,0.075,1.301,0.531,1.968,0.839c0.357,0.154,0.712,0.281,1.034,0.287
										c6.508,0.04,12.288-4.007,16.434-11.093c5.778-9.926,4.808-21.002-2.16-24.751c-0.992-0.54-1.933-0.954-2.867-1.264
										c-1.142-0.359-2.277-0.573-3.537-0.605c-2.207-0.086-4.765,0.342-8.237,1.292c-0.113,0.035-0.242,0.068-0.356,0.101
										c-5.676-2.792-10.324-3.074-14.95-1.25C55.677,136.291,53.453,147.183,58.056,157.707z"
                                />
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                      <g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <path
                                  style={{ fill: "#FAFAFA" }}
                                  d="M58.778,147.357c-0.044,0.015-0.368-0.76-0.476-2.09c-0.114-1.323,0.091-3.203,0.937-5.077
										c0.854-1.871,2.149-3.294,3.236-4.113c1.088-0.828,1.899-1.126,1.918-1.085c0.045,0.074-0.695,0.474-1.688,1.34
										c-0.995,0.859-2.189,2.253-3.01,4.049c-0.812,1.8-1.063,3.589-1.042,4.874C58.667,146.545,58.865,147.339,58.778,147.357z"
                                />
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                      <g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <path
                                  style={{ fill: "#FAFAFA" }}
                                  d="M59.814,152.294c-0.135,0.022-0.338-0.474-0.452-1.108
										c-0.115-0.633-0.099-1.165,0.036-1.186c0.135-0.022,0.337,0.474,0.452,1.108C59.965,151.741,59.948,152.272,59.814,152.294
										z"
                                />
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                      <g>
                        <g>
                          <g>
                            <g>
                              <path
                                style={{ fill: "#E0E0E0" }}
                                d="M72.674,125.69c0.078-0.049,1.382,1.88,2.913,4.309c1.531,2.43,2.709,4.439,2.63,4.488
									c-0.078,0.049-1.382-1.88-2.913-4.31C73.773,127.748,72.596,125.739,72.674,125.69z"
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                      <g style={{ opacity: "0.3" }}>
                        <g>
                          <g>
                            <g>
                              <path
                                style={{ fill: "#FFFFFF" }}
                                d="M96.2,141.798c-0.796-2.899-4.048-6.76-6.581-6.64c-1.037-0.031-1.986,0.442-2.403,1.431
									c-0.418,0.989-0.238,2.142,0.186,3.128c0.424,0.986,1.071,1.857,1.624,2.777c1.176,1.957,1.928,4.167,2.192,6.435
									c0.073,0.632,0.112,1.28,0.354,1.869c0.242,0.589,0.738,1.121,1.37,1.199c0.908,0.112,1.648-0.705,2.111-1.493
									C96.572,147.91,96.996,144.697,96.2,141.798z"
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                    <g>
                      <g>
                        <g>
                          <path
                            style={{ fill: "#E0E0E0" }}
                            d="M92.14,129.6c-0.344,0.119-0.681,0.099-1.009-0.058c-0.329-0.157-0.553-0.408-0.671-0.753
							c-0.119-0.344-0.1-0.676,0.057-0.996c0.393-0.826,0.921-2.085,1.58-3.778c0.66-1.693,1.129-2.834,1.405-3.424
							c0.14-0.338,0.702-1.535,1.687-3.59c0.93-2.024,1.656-3.669,2.18-4.938c0.141-0.338,0.375-0.58,0.703-0.725
							c0.329-0.145,0.663-0.148,1.001-0.007c0.152,0.061,0.286,0.146,0.401,0.257c0.338,0.141,0.565,0.388,0.684,0.741l5.527,16.287
							c0.118,0.354,0.098,0.694-0.059,1.022c-0.158,0.329-0.408,0.552-0.752,0.671c-0.345,0.118-0.682,0.099-1.01-0.058
							c-0.328-0.157-0.552-0.404-0.671-0.74l-1.652-5.132c-0.709-0.006-1.108-0.012-1.194-0.017
							c-2.334-0.048-4.166-0.11-5.494-0.187c-0.907,2.233-1.562,3.814-1.961,4.743C92.735,129.254,92.484,129.482,92.14,129.6z
							 M100.628,121.754l-2.027-5.829l-2.637,5.664C97.414,121.656,98.968,121.711,100.628,121.754z"
                          />
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            style={{ fill: "#E0E0E0" }}
                            d="M112.089,130.802c-0.787-0.011-1.511-0.143-2.171-0.398c-0.66-0.254-1.236-0.621-1.728-1.099
							c-0.854-0.793-1.378-1.75-1.574-2.869c-0.195-1.119-0.147-2.112,0.146-2.978c0.292-0.866,0.708-1.703,1.248-2.512
							c0.539-0.808,1.115-1.485,1.726-2.029c0.611-0.545,1.308-0.993,2.09-1.347c0.781-0.353,1.587-0.501,2.414-0.445
							c0.828,0.056,1.685,0.335,2.575,0.837c0.134,0.077,0.279,0.189,0.435,0.336c0.156,0.147,0.292,0.276,0.407,0.387l0.317,0.356
							c0.098,0.11,0.146,0.174,0.145,0.19c0.262,0.258,0.391,0.577,0.386,0.957c0.165,1.048,0.249,2.143,0.252,3.286
							c0.003,1.143-0.036,2.214-0.12,3.213c-0.084,1-0.132,2.061-0.145,3.185c-0.003,0.346-0.133,0.641-0.389,0.887
							c-0.257,0.244-0.567,0.365-0.93,0.362c-0.363-0.004-0.667-0.133-0.912-0.39c-0.244-0.257-0.366-0.557-0.363-0.904l0.023-0.388
							c-0.548,0.505-1.115,0.862-1.697,1.07C113.588,130.715,112.876,130.813,112.089,130.802z M113.824,127.942
							c0.16-0.06,0.359-0.153,0.601-0.277c0.24-0.124,0.496-0.283,0.766-0.475c0.271-0.192,0.519-0.437,0.744-0.736
							c0.036-0.033,0.081-0.064,0.135-0.096c0.135-2.329,0.095-4.21-0.116-5.641c-0.048-0.072-0.1-0.14-0.157-0.204
							c-0.058-0.063-0.096-0.113-0.121-0.15c-0.024-0.036-0.057-0.068-0.098-0.097c-0.041-0.028-0.088-0.061-0.137-0.098
							c-0.803-0.496-1.673-0.559-2.612-0.19c-0.938,0.37-1.786,1.113-2.542,2.229c-0.758,1.134-1.158,2.137-1.199,3.008
							c-0.042,0.872,0.249,1.612,0.872,2.219c0.378,0.351,0.857,0.586,1.439,0.706c0.581,0.121,1.179,0.111,1.793-0.026
							C113.454,128.059,113.666,128.002,113.824,127.942z"
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                  <g>
                    <g>
                      <g>
                        <g>
                          <path
                            style={{ fill: "#E0E0E0" }}
                            d="M92.793,106.501c0,2.424-1.965,4.389-4.389,4.389c-2.424,0-4.389-1.965-4.389-4.389
							c0-2.424,1.965-4.389,4.389-4.389C90.828,102.111,92.793,104.076,92.793,106.501z"
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
                <g>
                  <g>
                    <g>
                      <g>
                        <polygon
                          style={{ fill: "#F5F5F5" }}
                          points="372.502,152.678 371.291,158.955 441.089,172.982 452.389,103.687 438.398,167.196 
											"
                        />
                      </g>
                    </g>
                  </g>
                  <g>
                    <g>
                      <g>
                        <g>
                          <g>
                            <rect
                              x="376.871"
                              y="90.638"
                              transform="matrix(0.2151 -0.9766 0.9766 0.2151 200.5756 500.7099)"
                              style={{ fill: "#FFFFFF" }}
                              width="69.86"
                              height="69.86"
                            />
                          </g>
                        </g>
                      </g>
                      <g>
                        <g>
                          <g>
                            <g>
                              <path
                                style={{ fill: "#E0E0E0" }}
                                d="M438.398,167.196c0,0,0.02-0.113,0.064-0.326c0.046-0.218,0.113-0.533,0.2-0.945
									c0.181-0.837,0.446-2.067,0.792-3.669c0.699-3.211,1.721-7.908,3.021-13.884c2.613-11.94,6.343-28.984,10.817-49.43
									l0.106,0.166c-19.831-4.363-43.243-9.513-68.223-15.007l-0.007-0.002l0.192-0.122c-5.512,24.981-10.677,48.391-15.051,68.22
									l-0.106-0.166c20.435,4.532,37.469,8.31,49.401,10.956c5.969,1.331,10.661,2.377,13.867,3.092
									c1.599,0.359,2.827,0.635,3.662,0.823c0.411,0.094,0.724,0.166,0.942,0.216
									C438.288,167.166,438.398,167.196,438.398,167.196s-0.103-0.017-0.311-0.061c-0.215-0.046-0.524-0.111-0.931-0.197
									c-0.831-0.179-2.052-0.443-3.642-0.786c-3.203-0.697-7.89-1.717-13.852-3.014c-11.96-2.617-29.034-6.354-49.518-10.837
									l-0.136-0.03l0.03-0.136c4.362-19.831,9.513-43.244,15.009-68.229l0.035-0.157l0.157,0.035l0.007,0.001
									c24.976,5.51,48.385,10.675,68.213,15.05l0.136,0.03l-0.03,0.136c-4.539,20.469-8.324,37.532-10.975,49.486
									c-1.328,5.956-2.372,10.638-3.086,13.838c-0.357,1.588-0.631,2.807-0.817,3.636c-0.093,0.405-0.163,0.713-0.212,0.928
									C438.426,167.095,438.398,167.196,438.398,167.196z"
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                    <g>
                      <g>
                        <g>
                          <path
                            style={{ fill: "#E0E0E0" }}
                            d="M417.981,113.755c-0.626-0.488-1.129-1.03-1.51-1.625c-0.381-0.596-0.687-1.243-0.916-1.942
							c-0.23-0.699-0.348-1.403-0.353-2.113c-0.007-0.709,0.041-1.428,0.142-2.157c0.101-0.729,0.265-1.417,0.494-2.067
							c0.284-0.824,0.656-1.604,1.117-2.339c0.46-0.734,1.013-1.402,1.657-2.002c0.645-0.6,1.342-1.078,2.095-1.433
							c0.751-0.355,1.583-0.551,2.497-0.589c0.913-0.037,1.804,0.022,2.673,0.179c0.394,0.07,0.798,0.176,1.21,0.318
							c0.775,0.273,1.39,0.546,1.842,0.815c1.04,0.622,1.845,1.4,2.415,2.337c0.571,0.936,0.915,1.908,1.034,2.915
							c0.119,1.008,0.067,2.037-0.155,3.088c-0.223,1.051-0.572,2.054-1.046,3.007c-0.475,0.953-1.074,1.836-1.799,2.65
							c-0.726,0.813-1.584,1.455-2.575,1.928c-0.991,0.473-2.068,0.731-3.229,0.773c-1.161,0.043-2.302-0.144-3.421-0.562
							C419.331,114.637,418.607,114.244,417.981,113.755z M425.499,112.507c1.52-0.622,2.682-1.798,3.491-3.531
							c0.488-1.053,0.775-2.086,0.862-3.099c0.087-1.013-0.065-1.951-0.455-2.814c-0.39-0.862-0.941-1.534-1.655-2.015
							c-0.343-0.229-0.618-0.392-0.827-0.489c-0.208-0.097-0.468-0.18-0.777-0.248c-0.244-0.054-0.511-0.121-0.801-0.202
							c-0.305-0.084-0.717-0.149-1.236-0.195c-0.953-0.09-2.031,0.274-3.235,1.092c-1.203,0.818-2.078,2.037-2.622,3.658
							c-0.385,1.127-0.562,2.222-0.532,3.286c0.03,1.065,0.342,1.987,0.938,2.766c0.584,0.76,1.389,1.364,2.417,1.812
							C422.519,113.139,423.997,113.131,425.499,112.507z"
                          />
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            style={{ fill: "#E0E0E0" }}
                            d="M434.209,116.422c-0.792-1.284-1.128-2.782-1.006-4.496c0.107-1.341,0.534-2.544,1.281-3.609
							c0.915-1.368,2.086-2.27,3.514-2.707c0.292-0.089,0.642-0.123,1.046-0.102c0.016,0.004,0.132,0.02,0.347,0.051
							c0.085,0.002,0.154,0,0.206-0.005c0.255,0.005,0.494,0.083,0.716,0.234c0.065,0.015,0.219,0.053,0.461,0.114
							c0.243,0.062,0.403,0.106,0.483,0.132c0.079,0.026,0.218,0.074,0.416,0.143c0.198,0.069,0.358,0.134,0.482,0.196l0.426,0.273
							c0.18,0.108,0.349,0.231,0.508,0.368c0.156,0.137,0.327,0.294,0.51,0.471c0.9,0.95,1.411,2.115,1.533,3.499
							c0.121,1.383-0.002,2.452-0.374,3.207c-0.34,0.846-0.78,1.607-1.322,2.281c-0.541,0.674-1.205,1.232-1.993,1.673
							c-0.789,0.441-1.578,0.676-2.37,0.706C436.812,118.897,435.189,118.088,434.209,116.422z M440.911,115.454
							c0.634-0.517,1.106-1.109,1.414-1.775c0.309-0.665,0.422-1.447,0.338-2.344c-0.085-0.897-0.379-1.597-0.882-2.1
							c-0.151-0.17-0.277-0.296-0.378-0.378c-0.101-0.082-0.225-0.16-0.37-0.235c-0.145-0.075-0.263-0.122-0.352-0.142
							c-0.09-0.02-0.28-0.07-0.569-0.151c-0.289-0.08-0.546-0.154-0.77-0.22c-0.049-0.011-0.104-0.031-0.166-0.062
							c-0.183-0.023-0.282-0.036-0.298-0.04l-0.182,0.011c-0.803,0.232-1.489,0.734-2.059,1.504
							c-0.571,0.77-0.875,1.629-0.914,2.576c-0.065,1.146,0.149,2.072,0.643,2.778c0.531,0.766,1.367,1.189,2.51,1.27
							C439.597,116.201,440.276,115.971,440.911,115.454z"
                          />
                        </g>
                      </g>
                    </g>
                    <g>
                      <g>
                        <g>
                          <g>
                            <path
                              style={{ fill: "#E0E0E0" }}
                              d="M404.392,97.578c-0.839,0.103-1.698,0.038-2.513-0.189c2.393,5.467,2.403,12.056,1.661,17.977
								l1.144-0.117C405.591,110.38,405.425,103.051,404.392,97.578z"
                            />
                          </g>
                        </g>
                      </g>
                      <g>
                        <g>
                          <g>
                            <path
                              style={{ fill: "#F5F5F5" }}
                              d="M420.641,120.269c6.684,8.865,3.696,22.39-5.169,29.074
								c-8.865,6.684-21.47,4.917-28.154-3.948c-6.684-8.865-4.917-21.47,3.948-28.154
								C400.131,110.557,413.956,111.405,420.641,120.269z"
                            />
                          </g>
                        </g>
                      </g>
                      <g style={{ opacity: "0.3" }}>
                        <g>
                          <g>
                            <path
                              style={{ fill: "#E0E0E0" }}
                              d="M385.108,124.878c0,0,6.018,0.116,10.935-3.979c4.917-4.095,6.478-7.864,6.478-7.864
								s-5.172-0.513-9.086,2.729c-3.091,2.56-6.482,6.36-7.806,8.08C385.276,124.302,385.135,124.817,385.108,124.878z"
                            />
                          </g>
                        </g>
                      </g>
                      <g>
                        <g>
                          <g>
                            <path
                              style={{ fill: "#EBEBEB" }}
                              d="M402.693,105.509c-2.504,0.107-5.027,0.217-7.464,0.801c-2.437,0.584-4.813,1.679-6.538,3.498
								c-1.822,1.923-2.787,4.496-3.475,7.054c-0.689,2.558-1.154,5.193-2.19,7.631c4.448-0.69,8.477-2.06,12.223-4.554
								c1.459-0.971,3.199-2.449,4.245-3.855c1.337-1.797,2.119-3.15,2.552-4.849C402.514,109.4,402.734,107.481,402.693,105.509"
                            />
                          </g>
                        </g>
                      </g>
                      <g>
                        <g>
                          <g>
                            <g>
                              <path
                                style={{ fill: "#E0E0E0" }}
                                d="M404.066,104.505c0.015,0.017-0.184,0.221-0.554,0.575c-0.42,0.396-0.949,0.894-1.579,1.487
									c-1.337,1.252-3.176,2.988-5.171,4.942c-1.995,1.956-3.768,3.76-5.046,5.071c-0.606,0.618-1.114,1.137-1.518,1.549
									c-0.361,0.363-0.569,0.557-0.586,0.542c-0.016-0.015,0.16-0.238,0.493-0.627c0.333-0.39,0.826-0.944,1.445-1.621
									c1.238-1.353,2.988-3.185,4.987-5.145c1.999-1.958,3.866-3.671,5.243-4.881c0.688-0.605,1.252-1.087,1.649-1.412
									C403.826,104.66,404.052,104.488,404.066,104.505z"
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                      <g style={{ opacity: "0.3" }}>
                        <g>
                          <g>
                            <g>
                              <path
                                style={{ fill: "#EBEBEB" }}
                                d="M390,135.514c0.497,0,0.498-0.773,0-0.773C389.503,134.741,389.502,135.514,390,135.514
									L390,135.514z"
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                      <g style={{ opacity: "0.3" }}>
                        <g>
                          <g>
                            <g>
                              <path
                                style={{ fill: "#EBEBEB" }}
                                d="M389.026,132.17c0.497,0,0.498-0.773,0-0.773C388.529,131.397,388.528,132.17,389.026,132.17
									L389.026,132.17z"
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                      <g style={{ opacity: "0.3" }}>
                        <g>
                          <g>
                            <g>
                              <path
                                style={{ fill: "#EBEBEB" }}
                                d="M386.991,135.533c0.497,0,0.498-0.773,0-0.773
									C386.493,134.76,386.493,135.533,386.991,135.533L386.991,135.533z"
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                      <g style={{ opacity: "0.3" }}>
                        <g>
                          <g>
                            <g>
                              <path
                                style={{ fill: "#EBEBEB" }}
                                d="M386.473,130.649c0.497,0,0.498-0.773,0-0.773
									C385.976,129.876,385.975,130.649,386.473,130.649L386.473,130.649z"
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                      <g style={{ opacity: "0.3" }}>
                        <g>
                          <g>
                            <g>
                              <path
                                style={{ fill: "#EBEBEB" }}
                                d="M389.901,127.361c0.497,0,0.498-0.773,0-0.773
									C389.404,126.588,389.403,127.361,389.901,127.361L389.901,127.361z"
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                      <g style={{ opacity: "0.3" }}>
                        <g>
                          <g>
                            <g>
                              <path
                                style={{ fill: "#EBEBEB" }}
                                d="M392.097,129.995c0.497,0,0.498-0.773,0-0.773
									C391.6,129.222,391.599,129.995,392.097,129.995L392.097,129.995z"
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                      <g style={{ opacity: "0.3" }}>
                        <g>
                          <g>
                            <g>
                              <path
                                style={{ fill: "#EBEBEB" }}
                                d="M387.767,140.292c0.497,0,0.498-0.773,0-0.773
									C387.269,139.519,387.268,140.292,387.767,140.292L387.767,140.292z"
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                      <g style={{ opacity: "0.3" }}>
                        <g>
                          <g>
                            <g>
                              <path
                                style={{ fill: "#EBEBEB" }}
                                d="M387.327,126.559c0.497,0,0.498-0.773,0-0.773
									C386.83,125.786,386.829,126.559,387.327,126.559L387.327,126.559z"
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                      <g style={{ opacity: "0.3" }}>
                        <g>
                          <g>
                            <g>
                              <path
                                style={{ fill: "#FFFFFF" }}
                                d="M421.724,129.966c-0.065,0.003-0.022-1.162-0.417-2.974c-0.384-1.8-1.315-4.255-3.12-6.43
									c-1.806-2.176-4.047-3.544-5.746-4.253c-1.708-0.723-2.861-0.895-2.846-0.958c0.003-0.019,0.292,0.009,0.806,0.111
									c0.514,0.101,1.25,0.297,2.126,0.637c1.746,0.665,4.059,2.031,5.907,4.258c1.847,2.226,2.764,4.751,3.096,6.59
									c0.172,0.924,0.23,1.684,0.234,2.208C421.769,129.677,421.743,129.966,421.724,129.966z"
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                      <g style={{ opacity: "0.3" }}>
                        <g>
                          <g>
                            <g>
                              <path
                                style={{ fill: "#FFFFFF" }}
                                d="M421.17,135.68c-0.076-0.055,0.264-0.606,0.443-1.362c0.19-0.754,0.143-1.399,0.236-1.413
									c0.081-0.023,0.283,0.661,0.076,1.49C421.726,135.224,421.231,135.737,421.17,135.68z"
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g>
                    <g>
                      <g>
                        <g>
                          <path
                            style={{ fill: "#E0E0E0" }}
                            d="M423.495,90.564c0,2.337-1.895,4.232-4.232,4.232c-2.337,0-4.232-1.895-4.232-4.232
							c0-2.338,1.895-4.232,4.232-4.232C421.6,86.331,423.495,88.226,423.495,90.564z"
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
                <g>
                  <g>
                    <ellipse
                      style={{ fill: "#EBEBEB" }}
                      cx="247.733"
                      cy="422.384"
                      rx="218.968"
                      ry="41.94"
                    />
                  </g>
                  <g>
                    <g>
                      <path
                        style={{ fill: "#FFFFFF" }}
                        d="M443.124,423.01c-0.048,0.009,0.054-0.746-0.423-2.072c-0.462-1.327-1.718-3.085-3.882-4.774
					c-4.322-3.418-11.884-6.663-21.886-9.57c-20.062-5.81-50.037-10.507-87.232-13.462c-37.206-2.911-81.71-4.172-131.027-2.278
					c-24.649,1.002-50.518,2.768-77.141,6.244c-13.3,1.778-26.808,3.939-40.287,7.155c-6.729,1.626-13.467,3.505-19.999,6.062
					c-3.249,1.305-6.463,2.771-9.407,4.687c-1.462,0.962-2.859,2.051-3.996,3.369c-0.583,0.647-1.049,1.381-1.434,2.148
					c-0.341,0.786-0.559,1.62-0.575,2.472c0.014,0.852,0.22,1.688,0.557,2.477c0.388,0.765,0.841,1.507,1.428,2.153
					c1.133,1.321,2.529,2.412,3.99,3.377c2.942,1.922,6.157,3.389,9.407,4.695c6.533,2.561,13.272,4.441,20.003,6.069
					c13.483,3.218,26.994,5.38,40.298,7.159c26.63,3.478,52.503,5.244,77.156,6.246c49.324,1.895,93.831,0.633,131.037-2.278
					c37.195-2.955,67.168-7.653,87.227-13.462c10.001-2.907,17.561-6.152,21.883-9.57c2.164-1.689,3.419-3.447,3.881-4.774
					C443.178,423.756,443.076,423.001,443.124,423.01c0,0-0.005,0.18-0.027,0.536c-0.004,0.18-0.025,0.401-0.094,0.66
					c-0.056,0.261-0.112,0.567-0.252,0.893c-0.212,0.677-0.667,1.443-1.286,2.285c-0.637,0.833-1.513,1.685-2.58,2.553
					c-4.322,3.461-11.887,6.742-21.891,9.682c-20.066,5.876-50.047,10.631-87.255,13.635c-37.218,2.96-81.738,4.26-131.085,2.391
					c-24.665-0.989-50.551-2.746-77.204-6.22c-13.316-1.777-26.84-3.938-40.352-7.161c-6.746-1.631-13.503-3.514-20.074-6.089
					c-3.27-1.314-6.51-2.791-9.502-4.744c-1.487-0.982-2.916-2.096-4.099-3.473c-0.61-0.671-1.093-1.457-1.506-2.276
					c-0.364-0.847-0.591-1.76-0.604-2.694c0.016-0.935,0.255-1.845,0.623-2.688c0.409-0.821,0.907-1.596,1.512-2.27
					c1.186-1.373,2.616-2.484,4.104-3.464c2.994-1.948,6.233-3.423,9.502-4.736c6.57-2.571,13.325-4.453,20.069-6.083
					c13.508-3.22,27.029-5.38,40.342-7.156c26.647-3.472,52.528-5.228,77.189-6.218c49.339-1.869,93.857-0.569,131.075,2.39
					c37.207,3.003,67.191,7.758,87.26,13.634c10.006,2.941,17.572,6.221,21.895,9.683c1.068,0.869,1.944,1.72,2.581,2.554
					c0.619,0.841,1.074,1.607,1.286,2.285c0.14,0.326,0.196,0.633,0.252,0.893c0.069,0.259,0.09,0.48,0.094,0.66
					C443.12,422.83,443.124,423.01,443.124,423.01z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        style={{ fill: "#FFFFFF" }}
                        d="M416.087,422.384c-0.044,0.012,0.057-0.644-0.451-1.729c-0.501-1.082-1.759-2.415-3.721-3.659
					c-3.928-2.523-10.478-4.849-19.06-6.961c-17.202-4.215-42.676-7.637-74.255-9.781c-31.584-2.118-69.32-3.031-111.157-1.647
					c-20.913,0.73-42.861,2.025-65.492,4.559c-11.309,1.292-22.798,2.876-34.318,5.206c-5.751,1.188-11.524,2.543-17.171,4.413
					c-2.816,0.942-5.616,2.006-8.239,3.415c-1.305,0.709-2.571,1.508-3.663,2.496c-1.075,0.974-1.989,2.247-2.013,3.672
					c0.011,1.426,0.918,2.703,1.989,3.682c1.09,0.991,2.355,1.792,3.66,2.503c2.622,1.413,5.422,2.478,8.239,3.421
					c5.649,1.872,11.422,3.229,17.175,4.417c11.523,2.333,23.015,3.916,34.327,5.21c22.636,2.535,44.589,3.829,65.505,4.56
					c41.843,1.384,79.581,0.471,111.165-1.648c31.579-2.144,57.051-5.567,74.251-9.782c8.581-2.112,15.129-4.438,19.057-6.961
					c1.962-1.243,3.22-2.576,3.72-3.658C416.144,423.028,416.043,422.373,416.087,422.384c0,0-0.004,0.152-0.026,0.455
					c-0.027,0.299-0.121,0.753-0.376,1.296c-0.49,1.102-1.749,2.46-3.711,3.723c-3.93,2.564-10.482,4.924-19.065,7.069
					c-17.205,4.28-42.685,7.76-74.274,9.952c-31.595,2.167-69.344,3.119-111.207,1.76c-20.926-0.718-42.89-2.003-65.546-4.533
					c-11.322-1.291-22.826-2.873-34.373-5.209c-5.766-1.19-11.554-2.549-17.236-4.431c-2.835-0.95-5.655-2.02-8.323-3.457
					c-1.328-0.724-2.624-1.54-3.765-2.578c-1.113-1.021-2.139-2.406-2.156-4.067c0.032-1.659,1.065-3.037,2.181-4.053
					c1.143-1.035,2.439-1.849,3.768-2.571c2.668-1.433,5.488-2.503,8.322-3.451c5.68-1.881,11.467-3.238,17.232-4.427
					c11.544-2.334,23.045-3.916,34.364-5.206c22.651-2.529,44.61-3.814,65.533-4.531c41.856-1.358,79.603-0.407,111.198,1.759
					c31.589,2.192,57.071,5.672,74.279,9.952c8.585,2.145,15.138,4.506,19.068,7.07c1.963,1.263,3.222,2.621,3.712,3.724
					c0.256,0.543,0.35,0.997,0.376,1.296C416.083,422.232,416.087,422.384,416.087,422.384z"
                      />
                    </g>
                  </g>
                </g>
                <g>
                  <g>
                    <g>
                      <g>
                        <g>
                          <rect
                            x="43.234"
                            y="271.328"
                            style={{ fill: "#EBEBEB" }}
                            width="46.825"
                            height="46.495"
                          />
                        </g>
                      </g>
                    </g>
                    <g>
                      <g>
                        <g>
                          <rect
                            x="63.681"
                            y="271.328"
                            style={{ fill: "#F5F5F5" }}
                            width="46.825"
                            height="46.495"
                          />
                        </g>
                      </g>
                    </g>
                    <g>
                      <g>
                        <g>
                          <path
                            style={{ fill: "#FFFFFF" }}
                            d="M77.26,295.183c0-5.98,4.591-10.26,10.77-10.26c3.43,0,6.292,1.247,8.163,3.515l-2.948,2.721
							c-1.332-1.531-3.004-2.324-4.988-2.324c-3.713,0-6.349,2.608-6.349,6.349c0,3.742,2.636,6.349,6.349,6.349
							c1.983,0,3.656-0.793,4.988-2.352l2.948,2.721c-1.87,2.296-4.733,3.543-8.191,3.543
							C81.851,305.443,77.26,301.163,77.26,295.183z"
                          />
                        </g>
                      </g>
                    </g>
                    <g>
                      <g>
                        <g>
                          <g>
                            <path
                              style={{ fill: "#E0E0E0" }}
                              d="M63.472,302.634c0.068,0.091-4.407,3.565-9.995,7.759c-5.59,4.195-10.175,7.52-10.244,7.429
								c-0.068-0.091,4.406-3.565,9.996-7.759C58.817,305.87,63.404,302.543,63.472,302.634z"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                    <g>
                      <g>
                        <g>
                          <g>
                            <path
                              style={{ fill: "#E0E0E0" }}
                              d="M63.472,289.249c0.068,0.091-4.407,3.565-9.995,7.759c-5.59,4.195-10.175,7.52-10.244,7.429
								c-0.068-0.091,4.406-3.565,9.996-7.759C58.817,292.484,63.404,289.158,63.472,289.249z"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                    <g>
                      <g>
                        <g>
                          <g>
                            <path
                              style={{ fill: "#E0E0E0" }}
                              d="M63.472,275.863c0.068,0.091-4.407,3.565-9.995,7.759c-5.59,4.195-10.175,7.52-10.244,7.429
								c-0.068-0.091,4.406-3.565,9.996-7.759C58.817,279.099,63.404,275.772,63.472,275.863z"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                    <g>
                      <g>
                        <g>
                          <g>
                            <path
                              style={{ fill: "#E0E0E0" }}
                              d="M51.68,271.328c0.068,0.091-1.767,1.584-4.099,3.334c-2.333,1.751-4.279,3.095-4.347,3.004
								c-0.068-0.091,1.766-1.584,4.099-3.334C49.665,272.582,51.611,271.236,51.68,271.328z"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g>
                    <g>
                      <g>
                        <g>
                          <rect
                            x="69.791"
                            y="224.373"
                            style={{ fill: "#EBEBEB" }}
                            width="47.286"
                            height="46.953"
                          />
                        </g>
                      </g>
                    </g>
                    <g>
                      <g>
                        <g>
                          <rect
                            x="90.44"
                            y="224.373"
                            style={{ fill: "#F5F5F5" }}
                            width="47.286"
                            height="46.953"
                          />
                        </g>
                      </g>
                    </g>
                    <g>
                      <g>
                        <g>
                          <path
                            style={{ fill: "#FFFFFF" }}
                            d="M118.607,254.187h-9.302l-1.774,4.293h-4.752l8.93-20.034h4.579l8.959,20.034h-4.866
							L118.607,254.187z M117.147,250.667l-3.177-7.671l-3.177,7.671H117.147z"
                          />
                        </g>
                      </g>
                    </g>
                    <g>
                      <g>
                        <g>
                          <g>
                            <path
                              style={{ fill: "#E0E0E0" }}
                              d="M90.229,255.988c0.068,0.091-4.452,3.599-10.094,7.833
								c-5.645,4.236-10.275,7.595-10.343,7.504c-0.068-0.091,4.451-3.598,10.095-7.834
								C85.53,259.257,90.161,255.897,90.229,255.988z"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                    <g>
                      <g>
                        <g>
                          <g>
                            <path
                              style={{ fill: "#E0E0E0" }}
                              d="M90.229,242.471c0.068,0.091-4.452,3.599-10.094,7.834
								c-5.645,4.236-10.275,7.595-10.343,7.504c-0.068-0.091,4.451-3.598,10.095-7.834C85.53,245.739,90.161,242.38,90.229,242.471
								z"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                    <g>
                      <g>
                        <g>
                          <g>
                            <path
                              style={{ fill: "#E0E0E0" }}
                              d="M90.229,228.954c0.068,0.091-4.452,3.599-10.094,7.834
								c-5.645,4.236-10.275,7.595-10.343,7.504c-0.068-0.091,4.451-3.598,10.095-7.834
								C85.53,232.222,90.161,228.862,90.229,228.954z"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                    <g>
                      <g>
                        <g>
                          <g>
                            <path
                              style={{ fill: "#E0E0E0" }}
                              d="M78.32,224.373c0.069,0.091-1.785,1.598-4.141,3.365c-2.356,1.768-4.32,3.127-4.389,3.035
								c-0.068-0.091,1.785-1.598,4.141-3.366C76.287,225.641,78.252,224.282,78.32,224.373z"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g>
                    <g>
                      <g>
                        <g>
                          <rect
                            x="50.288"
                            y="317.824"
                            style={{ fill: "#EBEBEB" }}
                            width="46.825"
                            height="46.495"
                          />
                        </g>
                      </g>
                    </g>
                    <g>
                      <g>
                        <g>
                          <rect
                            x="70.735"
                            y="317.824"
                            style={{ fill: "#F5F5F5" }}
                            width="46.825"
                            height="46.495"
                          />
                        </g>
                      </g>
                    </g>
                    <g>
                      <g>
                        <g>
                          <path
                            style={{ fill: "#FFFFFF" }}
                            d="M103.757,346.186c0,3.429-2.721,5.413-7.936,5.413h-10.26v-19.84h9.693
							c4.96,0,7.511,2.07,7.511,5.158c0,1.984-1.021,3.515-2.637,4.365C102.341,341.991,103.757,343.691,103.757,346.186z
							 M90.125,335.217v4.677h4.563c2.239,0,3.458-0.794,3.458-2.352c0-1.559-1.219-2.324-3.458-2.324H90.125z M99.138,345.704
							c0-1.672-1.276-2.465-3.657-2.465h-5.356v4.904h5.356C97.862,348.142,99.138,347.404,99.138,345.704z"
                          />
                        </g>
                      </g>
                    </g>
                    <g>
                      <g>
                        <g>
                          <g>
                            <path
                              style={{ fill: "#E0E0E0" }}
                              d="M70.526,349.131c0.068,0.091-4.407,3.565-9.995,7.759c-5.59,4.195-10.175,7.52-10.244,7.429
								c-0.068-0.091,4.406-3.565,9.996-7.76C65.871,352.366,70.458,349.04,70.526,349.131z"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                    <g>
                      <g>
                        <g>
                          <g>
                            <path
                              style={{ fill: "#E0E0E0" }}
                              d="M70.526,335.746c0.068,0.091-4.407,3.565-9.995,7.759c-5.59,4.195-10.175,7.52-10.244,7.429
								c-0.068-0.091,4.406-3.565,9.996-7.76C65.871,338.981,70.458,335.655,70.526,335.746z"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                    <g>
                      <g>
                        <g>
                          <g>
                            <path
                              style={{ fill: "#E0E0E0" }}
                              d="M70.526,322.36c0.068,0.091-4.407,3.565-9.995,7.759c-5.59,4.195-10.175,7.52-10.244,7.429
								c-0.068-0.091,4.406-3.565,9.996-7.759C65.871,325.595,70.458,322.269,70.526,322.36z"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                    <g>
                      <g>
                        <g>
                          <g>
                            <path
                              style={{ fill: "#E0E0E0" }}
                              d="M58.734,317.824c0.068,0.091-1.767,1.584-4.099,3.334c-2.333,1.751-4.279,3.095-4.347,3.004
								c-0.068-0.091,1.766-1.584,4.099-3.335C56.719,319.078,58.666,317.733,58.734,317.824z"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
              <g id="Background_Simple" style={{ display: "none" }}>
                <g style={{ display: "inline" }}>
                  <path
                    style={{ fill: "#F5F5F5" }}
                    d="M421.111,96.955l-1.645-3.683c-13.869-31.048-43.325-52.422-76.955-54.515
			c-4.323-0.269-8.667-0.226-12.994,0.145c-31.153,2.672-60.439,22.287-75.081,50.288c-8.611,16.468-12.915,36.136-26.853,48.312
			c-18.14,15.847-45.191,13.009-68.818,9.281c-23.628-3.728-50.705-6.498-68.777,9.429c-11.318,9.975-16.476,25.538-17.571,40.669
			c-2.898,40.058,20.347,79.13,53.381,101.257c33.034,22.127,74.352,28.941,113.925,26.023
			c55.436-4.088,110.62-27.54,147.347-69.873C423.797,211.956,439.552,149.963,421.111,96.955z"
                  />
                </g>
              </g>
              <g id="Floor">
                <g>
                  <g>
                    <path
                      style={{ fill: "#263238" }}
                      d="M464.907,363.702c0,0.144-98.533,0.26-220.051,0.26c-121.56,0-220.072-0.117-220.072-0.26
				s98.511-0.26,220.072-0.26C366.375,363.441,464.907,363.558,464.907,363.702z"
                    />
                  </g>
                </g>
              </g>
              <g id="Speech_Bubble_2">
                <g>
                  <g>
                    <g>
                      <g>
                        <circle
                          style={{ fill: "#FFFFFF" }}
                          cx="323.054"
                          cy="89.886"
                          r="27.852"
                        />
                      </g>
                      <g>
                        <g>
                          <path
                            style={{ fill: "#263238" }}
                            d="M295.203,89.886c0,0-0.01-0.153-0.004-0.451c0.01-0.322,0.024-0.762,0.041-1.327
							c0.011-0.294,0.023-0.621,0.037-0.979c0.031-0.359,0.1-0.747,0.156-1.171c0.119-0.846,0.233-1.828,0.543-2.897
							c0.468-2.171,1.457-4.742,3.039-7.472c1.594-2.719,3.909-5.565,7.077-7.988c3.158-2.4,7.156-4.447,11.783-5.285
							c4.595-0.809,9.79-0.682,14.749,1.186c1.261,0.401,2.448,1.021,3.667,1.604c0.594,0.321,1.164,0.694,1.752,1.042
							c0.599,0.333,1.147,0.747,1.697,1.166c1.143,0.783,2.144,1.761,3.191,2.705c0.947,1.044,1.941,2.068,2.758,3.254
							c1.676,2.321,3.138,4.902,4.025,7.741c0.918,2.822,1.413,5.82,1.453,8.869c-0.039,3.05-0.533,6.049-1.451,8.872
							c-0.886,2.84-2.349,5.422-4.025,7.744c-0.817,1.187-1.811,2.211-2.758,3.255c-1.047,0.945-2.048,1.923-3.192,2.706
							c-0.55,0.419-1.099,0.833-1.698,1.166c-0.588,0.348-1.158,0.721-1.752,1.042c-1.219,0.583-2.406,1.202-3.668,1.604
							c-4.959,1.868-10.155,1.995-14.751,1.186c-4.627-0.839-8.626-2.887-11.783-5.286c-3.168-2.422-5.482-5.269-7.076-7.988
							c-1.581-2.731-2.57-5.301-3.038-7.472c-0.31-1.069-0.423-2.051-0.542-2.897c-0.056-0.423-0.126-0.812-0.156-1.171
							c-0.013-0.358-0.026-0.684-0.037-0.978c-0.017-0.564-0.031-1.005-0.041-1.327C295.192,90.038,295.203,89.886,295.203,89.886
							s0.025,0.153,0.049,0.451c0.022,0.321,0.053,0.759,0.093,1.321c0.019,0.293,0.039,0.617,0.062,0.972
							c0.039,0.356,0.116,0.742,0.18,1.162c0.135,0.839,0.263,1.812,0.586,2.869c0.494,2.147,1.504,4.684,3.092,7.372
							c1.601,2.677,3.909,5.472,7.049,7.844c3.13,2.35,7.08,4.348,11.639,5.155c4.528,0.778,9.64,0.637,14.508-1.213
							c1.239-0.397,2.403-1.01,3.6-1.584c0.583-0.316,1.142-0.685,1.719-1.028c0.588-0.328,1.126-0.736,1.666-1.149
							c1.122-0.771,2.103-1.735,3.131-2.663c0.928-1.028,1.904-2.034,2.704-3.2c1.642-2.282,3.077-4.817,3.944-7.605
							c0.899-2.771,1.382-5.714,1.422-8.709c-0.04-2.994-0.524-5.936-1.424-8.705c-0.868-2.787-2.302-5.322-3.944-7.602
							c-0.8-1.166-1.776-2.172-2.704-3.2c-1.028-0.928-2.008-1.892-3.13-2.662c-0.539-0.412-1.077-0.82-1.665-1.148
							c-0.577-0.342-1.136-0.711-1.719-1.027c-1.197-0.574-2.361-1.187-3.6-1.584c-4.868-1.849-9.978-1.99-14.506-1.213
							c-4.559,0.806-8.509,2.804-11.639,5.154c-3.141,2.372-5.449,5.167-7.05,7.844c-1.589,2.688-2.598,5.225-3.093,7.373
							c-0.323,1.058-0.451,2.031-0.586,2.869c-0.064,0.42-0.141,0.806-0.18,1.162c-0.023,0.356-0.043,0.68-0.062,0.973
							c-0.039,0.562-0.07,1.001-0.093,1.321C295.228,89.733,295.203,89.886,295.203,89.886z"
                          />
                        </g>
                      </g>
                    </g>
                    <g>
                      <g>
                        <circle
                          style={{ fill: "#FFFFFF" }}
                          cx="298.712"
                          cy="115.553"
                          r="3.509"
                        />
                      </g>
                      <g>
                        <g>
                          <path
                            style={{ fill: "#263238" }}
                            d="M295.203,115.553c-0.025,0.002-0.092-0.314,0.011-0.882c0.102-0.555,0.436-1.39,1.263-2.054
							c0.798-0.664,2.164-1.065,3.512-0.586c1.336,0.436,2.508,1.855,2.492,3.521c0.016,1.666-1.156,3.086-2.492,3.522
							c-1.349,0.48-2.714,0.079-3.512-0.586c-0.827-0.664-1.16-1.499-1.263-2.054C295.111,115.867,295.178,115.55,295.203,115.553
							c0.047-0.001,0.051,0.312,0.208,0.832c0.155,0.51,0.517,1.25,1.289,1.811c0.747,0.563,1.96,0.869,3.125,0.428
							c1.156-0.406,2.148-1.637,2.135-3.071c0.013-1.433-0.979-2.664-2.135-3.07c-1.165-0.441-2.378-0.136-3.125,0.427
							c-0.772,0.561-1.135,1.3-1.29,1.811C295.254,115.24,295.249,115.554,295.203,115.553z"
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                  <g>
                    <g>
                      <circle
                        style={{ fill: "#263238" }}
                        cx="312.175"
                        cy="90.57"
                        r="3.395"
                      />
                    </g>
                    <g>
                      <circle
                        style={{ fill: "#263238" }}
                        cx="323.99"
                        cy="90.57"
                        r="3.395"
                      />
                    </g>
                    <g>
                      <circle
                        style={{ fill: "#263238" }}
                        cx="335.805"
                        cy="90.57"
                        r="3.395"
                      />
                    </g>
                  </g>
                </g>
              </g>
              <g id="Speech_Bubble_1">
                <g>
                  <g>
                    <g>
                      <circle
                        style={{ fill: "#FFFFFF" }}
                        cx="77.107"
                        cy="290.391"
                        r="27.852"
                      />
                    </g>
                    <g>
                      <g>
                        <path
                          style={{ fill: "#263238" }}
                          d="M104.959,290.391c0,0-0.026-0.153-0.049-0.451c-0.023-0.321-0.053-0.759-0.093-1.321
						c-0.019-0.292-0.039-0.617-0.062-0.973c-0.039-0.356-0.116-0.742-0.18-1.162c-0.135-0.839-0.263-1.812-0.586-2.87
						c-0.494-2.148-1.504-4.684-3.092-7.373c-1.602-2.677-3.91-5.472-7.05-7.844c-3.131-2.349-7.08-4.348-11.639-5.154
						c-4.528-0.778-9.639-0.637-14.506,1.213c-1.239,0.397-2.403,1.009-3.6,1.584c-0.583,0.316-1.142,0.685-1.719,1.027
						c-0.588,0.328-1.126,0.736-1.665,1.148c-1.122,0.77-2.103,1.734-3.13,2.662c-0.928,1.028-1.904,2.033-2.704,3.199
						c-1.642,2.281-3.076,4.815-3.944,7.602c-0.899,2.77-1.384,5.712-1.424,8.706c0.04,2.995,0.523,5.938,1.422,8.709
						c0.867,2.788,2.302,5.323,3.944,7.605c0.8,1.167,1.776,2.173,2.704,3.201c1.028,0.928,2.008,1.892,3.131,2.663
						c0.54,0.412,1.078,0.821,1.666,1.149c0.577,0.343,1.136,0.711,1.719,1.028c1.197,0.574,2.362,1.187,3.601,1.584
						c4.868,1.85,9.98,1.99,14.508,1.213c4.559-0.807,8.509-2.805,11.639-5.155c3.14-2.372,5.448-5.168,7.049-7.844
						c1.588-2.688,2.598-5.225,3.092-7.372c0.323-1.057,0.451-2.03,0.586-2.869c0.064-0.42,0.141-0.806,0.18-1.162
						c0.023-0.356,0.043-0.68,0.062-0.972c0.039-0.562,0.07-1,0.093-1.321C104.934,290.544,104.959,290.391,104.959,290.391
						s0.01,0.153,0.004,0.451c-0.01,0.322-0.024,0.762-0.041,1.327c-0.011,0.294-0.023,0.62-0.037,0.978
						c-0.03,0.359-0.1,0.747-0.156,1.171c-0.119,0.846-0.233,1.828-0.542,2.897c-0.468,2.171-1.457,4.741-3.038,7.472
						c-1.594,2.719-3.909,5.565-7.076,7.988c-3.157,2.4-7.156,4.448-11.783,5.286c-4.595,0.81-9.791,0.682-14.75-1.186
						c-1.261-0.401-2.448-1.021-3.668-1.604c-0.594-0.321-1.164-0.694-1.752-1.042c-0.599-0.333-1.147-0.747-1.698-1.166
						c-1.143-0.783-2.145-1.762-3.192-2.706c-0.948-1.044-1.941-2.068-2.758-3.255c-1.676-2.322-3.138-4.904-4.025-7.744
						c-0.918-2.823-1.411-5.822-1.451-8.872c0.04-3.049,0.534-6.047,1.453-8.869c0.887-2.839,2.348-5.42,4.025-7.741
						c0.817-1.186,1.811-2.21,2.758-3.254c1.047-0.944,2.048-1.922,3.191-2.705c0.55-0.419,1.099-0.832,1.698-1.165
						c0.588-0.348,1.158-0.721,1.752-1.042c1.219-0.583,2.406-1.202,3.667-1.604c4.958-1.868,10.154-1.996,14.749-1.186
						c4.627,0.838,8.626,2.886,11.783,5.285c3.168,2.422,5.483,5.269,7.077,7.988c1.582,2.731,2.571,5.302,3.039,7.472
						c0.31,1.069,0.423,2.051,0.543,2.897c0.056,0.423,0.126,0.812,0.156,1.171c0.014,0.358,0.026,0.684,0.037,0.979
						c0.018,0.565,0.031,1.005,0.041,1.327C104.969,290.238,104.959,290.391,104.959,290.391z"
                        />
                      </g>
                    </g>
                  </g>
                  <g>
                    <g>
                      <g>
                        <g>
                          <g>
                            <g>
                              <path
                                style={{ fill: "#FF725E" }}
                                d="M63.977,292.036c-0.634-2.86,0.101-5.927,2.092-8.075c1.002-1.081,2.264-1.934,3.685-2.304
									c1.702-0.443,3.582-0.152,5.07,0.784c0.636,0.4,1.197,0.916,1.657,1.509c0.248,0.321,0.725,0.306,0.962-0.024
									c0.381-0.53,0.84-1.004,1.363-1.395c1.408-1.053,3.258-1.494,4.99-1.189c1.447,0.254,2.774,1.004,3.859,2
									c2.158,1.982,3.137,4.979,2.735,7.881c-0.156,1.125-0.496,2.223-1.028,3.195c-1.074,1.961-2.871,3.581-4.737,4.811
									c-2.607,1.718-5.904,3.8-6.945,4.456c-0.203,0.128-0.454,0.123-0.653-0.011c-0.968-0.653-3.947-2.633-6.66-4.122
									c-1.959-1.076-3.881-2.546-5.109-4.414C64.65,294.212,64.223,293.145,63.977,292.036z"
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g>
                    <g>
                      <circle
                        style={{ fill: "#FFFFFF" }}
                        cx="101.45"
                        cy="316.058"
                        r="3.509"
                      />
                    </g>
                    <g>
                      <g>
                        <path
                          style={{ fill: "#263238" }}
                          d="M104.959,316.058c-0.047,0.001-0.051-0.312-0.208-0.832c-0.155-0.51-0.517-1.25-1.29-1.811
						c-0.747-0.563-1.96-0.869-3.125-0.427c-1.156,0.406-2.148,1.636-2.135,3.07c-0.013,1.434,0.979,2.665,2.135,3.071
						c1.165,0.441,2.379,0.136,3.125-0.427c0.772-0.561,1.135-1.3,1.289-1.811C104.908,316.37,104.913,316.057,104.959,316.058
						c0.024-0.002,0.091,0.314-0.011,0.881c-0.102,0.555-0.436,1.39-1.263,2.054c-0.798,0.664-2.164,1.066-3.512,0.586
						c-1.336-0.436-2.509-1.856-2.492-3.522c-0.016-1.666,1.156-3.084,2.492-3.521c1.348-0.48,2.714-0.079,3.512,0.586
						c0.827,0.664,1.161,1.499,1.263,2.054C105.051,315.744,104.984,316.06,104.959,316.058z"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
              <g id="Character_3">
                <g>
                  <g>
                    <g>
                      <polygon
                        style={{ fill: "#455A64" }}
                        points="393.048,374.369 406.858,366.521 414.775,384.21 396.924,391.523 389.043,380.192 				
					"
                      />
                    </g>
                    <g>
                      <g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <g>
                                          <g>
                                            <g>
                                              <g>
                                                <g>
                                                  <g>
                                                    <g>
                                                      <g>
                                                        <g>
                                                          <g>
                                                            <g>
                                                              <g>
                                                                <g>
                                                                  <g>
                                                                    <g>
                                                                      <path
                                                                        style={{
                                                                          fill: "#FF725E"
                                                                        }}
                                                                        d="M399.519,391.822l-1.396,0.422c0,0-0.379,21.157-5.369,22.895
																													l-14.188-36.253l14.241-4.818C392.039,380.597,394.152,386.663,399.519,391.822z"
                                                                      />
                                                                    </g>
                                                                  </g>
                                                                </g>
                                                              </g>
                                                            </g>
                                                          </g>
                                                        </g>
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <g>
                                          <g>
                                            <g>
                                              <g>
                                                <g>
                                                  <g>
                                                    <g>
                                                      <g>
                                                        <g>
                                                          <g>
                                                            <g>
                                                              <g>
                                                                <g>
                                                                  <g>
                                                                    <g>
                                                                      <path
                                                                        style={{
                                                                          fill: "#E0E0E0"
                                                                        }}
                                                                        d="M378.567,378.886l3.425-1.159l12.594,35.601c0,0-0.811,1.526-1.831,1.81
																													L378.567,378.886z"
                                                                      />
                                                                    </g>
                                                                  </g>
                                                                </g>
                                                              </g>
                                                            </g>
                                                          </g>
                                                        </g>
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <g>
                                          <g>
                                            <g>
                                              <g>
                                                <g>
                                                  <g>
                                                    <g>
                                                      <g>
                                                        <g>
                                                          <g>
                                                            <path
                                                              style={{ fill: "#FFFFFF" }}
                                                              d="M392.905,393.682c-0.024-0.046,0.251-0.242,0.77-0.414
																								c0.515-0.173,1.281-0.29,2.092-0.191c0.81,0.102,1.475,0.397,1.877,0.685
																								c0.406,0.288,0.57,0.538,0.525,0.576c-0.098,0.098-1.009-0.666-2.507-0.837
																								C394.173,393.299,392.95,393.798,392.905,393.682z"
                                                            />
                                                          </g>
                                                        </g>
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <g>
                                          <g>
                                            <g>
                                              <g>
                                                <g>
                                                  <g>
                                                    <g>
                                                      <g>
                                                        <g>
                                                          <g>
                                                            <path
                                                              style={{ fill: "#FFFFFF" }}
                                                              d="M392.629,391.579c-0.054-0.095,1.256-0.643,2.879-0.429
																								c1.628,0.199,2.593,1.035,2.496,1.111c-0.088,0.107-1.108-0.509-2.602-0.687
																								C393.915,391.373,392.66,391.701,392.629,391.579z"
                                                            />
                                                          </g>
                                                        </g>
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <g>
                                          <g>
                                            <g>
                                              <g>
                                                <g>
                                                  <g>
                                                    <g>
                                                      <g>
                                                        <g>
                                                          <g>
                                                            <path
                                                              style={{ fill: "#FFFFFF" }}
                                                              d="M393.011,396.887c-0.083-0.077,0.838-0.806,2.236-0.994
																								c1.397-0.2,2.414,0.261,2.345,0.359c-0.053,0.121-1.052-0.12-2.311,0.064
																								C394.021,396.48,393.082,396.99,393.011,396.887z"
                                                            />
                                                          </g>
                                                        </g>
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <g>
                                          <g>
                                            <g>
                                              <g>
                                                <g>
                                                  <g>
                                                    <g>
                                                      <g>
                                                        <g>
                                                          <g>
                                                            <path
                                                              style={{ fill: "#FFFFFF" }}
                                                              d="M397.801,394.56c-0.046-0.021,0.294-0.561,1.175-1.129
																								c0.435-0.283,0.993-0.561,1.626-0.818c0.317-0.129,0.651-0.253,0.998-0.377
																								c0.17-0.059,0.35-0.135,0.582-0.175c0.227-0.039,0.518-0.016,0.721,0.163
																								c0.228,0.185,0.193,0.535,0.054,0.718c-0.128,0.196-0.295,0.331-0.461,0.444
																								c-0.314,0.213-0.639,0.389-0.959,0.529c-0.643,0.282-1.262,0.428-1.781,0.489
																								c-1.046,0.116-1.654-0.067-1.637-0.111c0.014-0.071,0.633-0.003,1.607-0.186
																								c0.484-0.095,1.053-0.26,1.636-0.539c0.292-0.138,0.585-0.307,0.868-0.505
																								c0.278-0.18,0.45-0.451,0.321-0.522c-0.136-0.132-0.459-0.048-0.812,0.086
																								c-0.337,0.118-0.666,0.234-0.99,0.348c-0.624,0.231-1.168,0.469-1.602,0.706
																								C398.275,394.15,397.86,394.603,397.801,394.56z"
                                                            />
                                                          </g>
                                                        </g>
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <g>
                                          <g>
                                            <g>
                                              <g>
                                                <g>
                                                  <g>
                                                    <g>
                                                      <g>
                                                        <g>
                                                          <g>
                                                            <path
                                                              style={{ fill: "#FFFFFF" }}
                                                              d="M398.024,394.765c-0.02,0.015-0.093-0.028-0.225-0.136l-0.102-0.084
																								l0.164-0.026c0.329-0.052,1.008-0.099,1.864,0.048c0.427,0.073,0.896,0.195,1.371,0.383
																								c0.237,0.095,0.475,0.205,0.712,0.332c0.119,0.066,0.255,0.145,0.366,0.278
																								c0.112,0.136,0.178,0.324,0.114,0.536c-0.083,0.294-0.45,0.433-0.655,0.428
																								c-0.228,0.012-0.402-0.033-0.568-0.061c-0.338-0.066-0.653-0.159-0.941-0.267
																								c-0.577-0.216-1.043-0.5-1.391-0.773c-0.699-0.554-0.942-1.036-0.895-1.063
																								c0.064-0.045,0.403,0.359,1.119,0.819c0.357,0.229,0.813,0.467,1.36,0.648
																								c0.273,0.091,0.568,0.169,0.881,0.225c0.321,0.079,0.647,0.053,0.631-0.042
																								c0.104-0.234-0.565-0.506-0.952-0.69c-0.439-0.189-0.871-0.324-1.268-0.417
																								c-0.794-0.19-1.438-0.218-1.756-0.253l0.061-0.11C398.011,394.677,398.045,394.749,398.024,394.765z"
                                                            />
                                                          </g>
                                                        </g>
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g>
                    <path
                      style={{ fill: "#263238" }}
                      d="M453.831,328.273c0.829,0,10.777,7.624,7.737,20.15c-1.343,5.534-10.931,14.051-21.217,21.837
				c-12.999,9.838-27.113,18.507-27.113,18.507l-14.718-21.782l20.394-15.673l-2.756-5.511L453.831,328.273z"
                    />
                  </g>
                  <g>
                    <g>
                      <polygon
                        style={{ fill: "#455A64" }}
                        points="436.719,362.241 449.568,370.947 442.867,387.408 425.461,381.933 425.461,379.827 				
					"
                      />
                    </g>
                    <g>
                      <g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <g>
                                          <g>
                                            <g>
                                              <g>
                                                <g>
                                                  <g>
                                                    <g>
                                                      <g>
                                                        <g>
                                                          <g>
                                                            <g>
                                                              <g>
                                                                <g>
                                                                  <g>
                                                                    <g>
                                                                      <path
                                                                        style={{
                                                                          fill: "#FF725E"
                                                                        }}
                                                                        d="M447.884,368.755l1.233,0.913c0,0,18.091-12.971,22.693-9.852
																													l-21.68,34.746l-13.011-8.99C443.11,382.119,446.901,376.526,447.884,368.755z"
                                                                      />
                                                                    </g>
                                                                  </g>
                                                                </g>
                                                              </g>
                                                            </g>
                                                          </g>
                                                        </g>
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <g>
                                          <g>
                                            <g>
                                              <g>
                                                <g>
                                                  <g>
                                                    <g>
                                                      <g>
                                                        <g>
                                                          <g>
                                                            <g>
                                                              <g>
                                                                <g>
                                                                  <g>
                                                                    <g>
                                                                      <path
                                                                        style={{
                                                                          fill: "#E0E0E0"
                                                                        }}
                                                                        d="M450.13,394.563L447,392.4l22.131-32.992c0,0,1.797-0.274,2.678,0.408
																													L450.13,394.563z"
                                                                      />
                                                                    </g>
                                                                  </g>
                                                                </g>
                                                              </g>
                                                            </g>
                                                          </g>
                                                        </g>
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <g>
                                          <g>
                                            <g>
                                              <g>
                                                <g>
                                                  <g>
                                                    <g>
                                                      <g>
                                                        <g>
                                                          <g>
                                                            <path
                                                              style={{ fill: "#FFFFFF" }}
                                                              d="M453.608,373.169c-0.024,0.049-0.362-0.06-0.833-0.389
																								c-0.47-0.326-1.05-0.899-1.475-1.646c-0.423-0.748-0.591-1.494-0.601-2.014
																								c-0.012-0.523,0.096-0.819,0.156-0.805c0.145,0.021,0.072,1.27,0.869,2.641
																								C452.488,372.339,453.678,373.058,453.608,373.169z"
                                                            />
                                                          </g>
                                                        </g>
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <g>
                                          <g>
                                            <g>
                                              <g>
                                                <g>
                                                  <g>
                                                    <g>
                                                      <g>
                                                        <g>
                                                          <g>
                                                            <path
                                                              style={{ fill: "#FFFFFF" }}
                                                              d="M452.006,374.722c-0.046,0.105-1.331-0.656-2.171-2.16
																								c-0.855-1.499-0.755-2.838-0.631-2.804c0.146,0.007,0.266,1.254,1.055,2.627
																								C451.025,373.767,452.09,374.62,452.006,374.722z"
                                                            />
                                                          </g>
                                                        </g>
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <g>
                                          <g>
                                            <g>
                                              <g>
                                                <g>
                                                  <g>
                                                    <g>
                                                      <g>
                                                        <g>
                                                          <g>
                                                            <path
                                                              style={{ fill: "#FFFFFF" }}
                                                              d="M456.246,371.066c-0.013,0.119-1.206-0.201-2.243-1.262
																								c-1.046-1.053-1.296-2.201-1.171-2.204c0.135-0.031,0.56,0.964,1.506,1.91
																								C455.269,370.469,456.288,370.941,456.246,371.066z"
                                                            />
                                                          </g>
                                                        </g>
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <g>
                                          <g>
                                            <g>
                                              <g>
                                                <g>
                                                  <g>
                                                    <g>
                                                      <g>
                                                        <g>
                                                          <g>
                                                            <path
                                                              style={{ fill: "#FFFFFF" }}
                                                              d="M451.273,368.485c0.011,0.052-0.658,0.104-1.691-0.283
																								c-0.512-0.189-1.097-0.486-1.712-0.858c-0.307-0.186-0.623-0.391-0.945-0.605
																								c-0.157-0.107-0.334-0.211-0.514-0.382c-0.175-0.167-0.339-0.427-0.315-0.711
																								c0.012-0.309,0.33-0.498,0.572-0.496c0.246-0.015,0.465,0.041,0.664,0.111
																								c0.377,0.131,0.729,0.294,1.049,0.477c0.642,0.365,1.154,0.796,1.531,1.195
																								c0.754,0.81,0.983,1.438,0.935,1.452c-0.069,0.033-0.4-0.533-1.167-1.239
																								c-0.384-0.349-0.881-0.725-1.482-1.042c-0.3-0.159-0.626-0.301-0.972-0.415
																								c-0.326-0.122-0.663-0.097-0.643,0.057c-0.026,0.198,0.248,0.417,0.583,0.632
																								c0.311,0.21,0.616,0.415,0.916,0.616c0.587,0.381,1.129,0.691,1.602,0.908
																								C450.63,368.343,451.273,368.409,451.273,368.485z"
                                                            />
                                                          </g>
                                                        </g>
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <g>
                                          <g>
                                            <g>
                                              <g>
                                                <g>
                                                  <g>
                                                    <g>
                                                      <g>
                                                        <g>
                                                          <g>
                                                            <path
                                                              style={{ fill: "#FFFFFF" }}
                                                              d="M451.306,368.168c0.026,0.007,0.035,0.096,0.026,0.275l-0.007,0.139
																								l-0.125-0.122c-0.25-0.245-0.717-0.788-1.131-1.603c-0.207-0.406-0.398-0.878-0.539-1.397
																								c-0.069-0.26-0.125-0.53-0.167-0.81c-0.019-0.142-0.038-0.307,0.004-0.484
																								c0.044-0.18,0.162-0.354,0.38-0.433c0.3-0.115,0.648,0.108,0.772,0.284c0.154,0.185,0.225,0.36,0.306,0.518
																								c0.156,0.327,0.276,0.651,0.366,0.962c0.18,0.623,0.234,1.194,0.222,1.66
																								c-0.028,0.938-0.283,1.446-0.334,1.423c-0.078-0.026,0.05-0.565-0.011-1.459
																								c-0.031-0.445-0.116-0.979-0.307-1.555c-0.095-0.288-0.214-0.585-0.364-0.885
																								c-0.135-0.32-0.362-0.579-0.432-0.507c-0.263,0.059-0.072,0.795,0.016,1.236
																								c0.116,0.489,0.274,0.938,0.444,1.332c0.339,0.79,0.719,1.35,0.89,1.641l-0.131,0.017
																								C451.24,368.235,451.28,368.161,451.306,368.168z"
                                                            />
                                                          </g>
                                                        </g>
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g>
                    <g>
                      <g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <path
                                          style={{ fill: "#263238" }}
                                          d="M381.457,227.522c-0.586-7.823-4.284-15.37-10.106-20.629l0.673-0.306
														c-5.724-3.195-13.832-0.931-17.072,4.768c-1.33,2.339-1.873,5.028-2.751,7.572c-0.877,2.544-2.233,5.11-4.581,6.424
														c-1.251,0.701-2.692,0.991-3.981,1.618c-1.29,0.627-2.501,1.77-2.541,3.203c-0.052,1.836,1.788,3.155,3.544,3.695
														c1.755,0.54,3.704,0.698,5.158,1.82c-2.677,1.468-3.738,5.277-2.205,7.918c1.533,2.64,5.366,3.608,7.969,2.011
														c-1.582,2.809-0.42,6.655,2.159,8.589c2.579,1.934,6.192,2.117,9.225,1.024c3.033-1.093,5.546-3.31,7.59-5.803
														C379.513,243.359,382.043,235.345,381.457,227.522z"
                                        />
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                      <g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <path
                                          style={{ fill: "#263238" }}
                                          d="M424.732,218.539c0.003-2.214-1.755-4.122-3.791-4.991
														c-2.036-0.869-4.316-0.913-6.529-0.944c1.231-2.683,2.484-5.457,2.572-8.407c0.088-2.95-1.292-6.151-4.012-7.298
														c-2.343-0.988-5.056-0.257-7.347,0.843c-2.292,1.101-4.446,2.574-6.923,3.15c-6.441,1.497-12.706-3.402-19.317-3.479
														c-6.788-0.078-13.862,6.228-14.31,13.002c9.806,6.302,19.703,12.676,27.878,20.985
														c4.42,4.493,9.045,9.897,15.34,10.208c3.914,0.193,7.857-1.902,9.889-5.252c2.031-3.35,2.066-7.815,0.086-11.197
														C421.259,223.831,424.728,221.812,424.732,218.539z"
                                        />
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                      <g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <path
                                          style={{ fill: "#FFBF9D" }}
                                          d="M410.996,231.137c1.157-8.733-6.44-19.18-15.215-19.965l-28.922-0.192l3.941,5
														l-9.477,56.283l0,0c0,0-6.153,20.195,9.697,22.219c14.24,1.819,16.392-13.179,16.393-13.185l1.706-10.92
														c0,0,11.44,1.781,16.558-12.149C408.222,251.3,409.71,240.84,410.996,231.137z"
                                        />
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                      <g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <g>
                                          <g>
                                            <g>
                                              <g>
                                                <g>
                                                  <g>
                                                    <g>
                                                      <g>
                                                        <g>
                                                          <g>
                                                            <g>
                                                              <g>
                                                                <g>
                                                                  <g>
                                                                    <path
                                                                      style={{
                                                                        fill: "#263238"
                                                                      }}
                                                                      d="M404.847,243.859c-0.158,0.981-1.118,1.656-2.145,1.51
																												c-1.025-0.146-1.731-1.057-1.573-2.037c0.158-0.98,1.118-1.657,2.143-1.511
																												C404.299,241.965,405.005,242.878,404.847,243.859z"
                                                                    />
                                                                  </g>
                                                                </g>
                                                              </g>
                                                            </g>
                                                          </g>
                                                        </g>
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                      <g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <g>
                                          <g>
                                            <g>
                                              <g>
                                                <g>
                                                  <g>
                                                    <g>
                                                      <g>
                                                        <g>
                                                          <g>
                                                            <g>
                                                              <g>
                                                                <g>
                                                                  <g>
                                                                    <g>
                                                                      <path
                                                                        style={{
                                                                          fill: "#263238"
                                                                        }}
                                                                        d="M406.951,238.286c-0.276,0.196-1.469-1.113-3.455-1.5
																													c-1.978-0.414-3.635,0.32-3.803,0.034c-0.084-0.128,0.234-0.516,0.959-0.853
																													c0.715-0.336,1.867-0.574,3.103-0.325c1.234,0.251,2.185,0.911,2.69,1.494
																													C406.964,237.725,407.085,238.201,406.951,238.286z"
                                                                      />
                                                                    </g>
                                                                  </g>
                                                                </g>
                                                              </g>
                                                            </g>
                                                          </g>
                                                        </g>
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                      <g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <g>
                                          <g>
                                            <g>
                                              <g>
                                                <g>
                                                  <g>
                                                    <g>
                                                      <g>
                                                        <g>
                                                          <g>
                                                            <g>
                                                              <g>
                                                                <g>
                                                                  <g>
                                                                    <path
                                                                      style={{
                                                                        fill: "#263238"
                                                                      }}
                                                                      d="M388.494,241.312c-0.158,0.98-1.118,1.655-2.145,1.51
																												c-1.025-0.146-1.731-1.056-1.573-2.037c0.157-0.981,1.118-1.657,2.143-1.512
																												C387.947,239.419,388.652,240.331,388.494,241.312z"
                                                                    />
                                                                  </g>
                                                                </g>
                                                              </g>
                                                            </g>
                                                          </g>
                                                        </g>
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                      <g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <g>
                                          <g>
                                            <g>
                                              <g>
                                                <g>
                                                  <g>
                                                    <g>
                                                      <g>
                                                        <g>
                                                          <g>
                                                            <g>
                                                              <g>
                                                                <g>
                                                                  <g>
                                                                    <g>
                                                                      <path
                                                                        style={{
                                                                          fill: "#263238"
                                                                        }}
                                                                        d="M388.63,234.997c-0.277,0.195-1.472-1.114-3.455-1.5
																													c-1.978-0.414-3.635,0.32-3.803,0.034c-0.084-0.128,0.232-0.517,0.96-0.853
																													c0.715-0.337,1.867-0.574,3.103-0.325c1.235,0.251,2.185,0.911,2.691,1.494
																													C388.641,234.435,388.763,234.912,388.63,234.997z"
                                                                      />
                                                                    </g>
                                                                  </g>
                                                                </g>
                                                              </g>
                                                            </g>
                                                          </g>
                                                        </g>
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                      <g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <g>
                                          <g>
                                            <g>
                                              <g>
                                                <g>
                                                  <g>
                                                    <g>
                                                      <g>
                                                        <g>
                                                          <g>
                                                            <g>
                                                              <g>
                                                                <g>
                                                                  <g>
                                                                    <path
                                                                      style={{
                                                                        fill: "#263238"
                                                                      }}
                                                                      d="M391.759,250.585c0.01-0.097,1.282-0.042,3.328,0.121
																												c0.516,0.052,1.014,0.058,1.159-0.218c0.183-0.287,0.066-0.789-0.078-1.335
																												c-0.263-1.128-0.539-2.31-0.829-3.549c-0.319-1.295-1.716-8.527-1.614-9.178
																												c0.237-0.027,1.343,4.039,2.471,9.082c0.266,1.241,0.521,2.425,0.762,3.557
																												c0.092,0.524,0.309,1.139-0.082,1.733c-0.202,0.294-0.623,0.456-0.963,0.468
																												c-0.342,0.022-0.629-0.027-0.882-0.063C392.998,250.912,391.747,250.683,391.759,250.585z"
                                                                    />
                                                                  </g>
                                                                </g>
                                                              </g>
                                                            </g>
                                                          </g>
                                                        </g>
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                      <g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <g>
                                          <g>
                                            <g>
                                              <g>
                                                <g>
                                                  <g>
                                                    <g>
                                                      <g>
                                                        <g>
                                                          <g>
                                                            <g>
                                                              <g>
                                                                <g>
                                                                  <path
                                                                    style={{
                                                                      fill: "#FF9A6C"
                                                                    }}
                                                                    d="M389.119,270.376c0,0-9.722-1.182-18.17-8.831
																											c0,0,2.94,11.01,17.597,12.502L389.119,270.376z"
                                                                  />
                                                                </g>
                                                              </g>
                                                            </g>
                                                          </g>
                                                        </g>
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                      <g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <g>
                                          <g>
                                            <g>
                                              <g>
                                                <g>
                                                  <g>
                                                    <g>
                                                      <g>
                                                        <g>
                                                          <g>
                                                            <path
                                                              style={{ fill: "#263238" }}
                                                              d="M390.66,227.259c-0.349,0.567-1.967-0.225-4.063-0.49
																								c-2.085-0.321-3.928-0.015-4.032-0.646c-0.04-0.299,0.411-0.797,1.239-1.203
																								c0.821-0.406,2.02-0.67,3.274-0.496c1.253,0.178,2.279,0.754,2.892,1.362
																								C390.59,226.394,390.817,226.987,390.66,227.259z"
                                                            />
                                                          </g>
                                                        </g>
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                      <g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <g>
                                          <g>
                                            <g>
                                              <g>
                                                <g>
                                                  <g>
                                                    <g>
                                                      <g>
                                                        <g>
                                                          <g>
                                                            <path
                                                              style={{ fill: "#263238" }}
                                                              d="M408.392,230.405c-0.437,0.495-1.821-0.243-3.565-0.482
																								c-1.734-0.309-3.27-0.031-3.542-0.637c-0.115-0.293,0.183-0.805,0.892-1.237
																								c0.699-0.432,1.815-0.718,3.01-0.531c1.195,0.189,2.176,0.805,2.716,1.432
																								C408.453,229.58,408.588,230.161,408.392,230.405z"
                                                            />
                                                          </g>
                                                        </g>
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                      <g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <path
                                          style={{ fill: "#263238" }}
                                          d="M411.362,222.738c-1.621-5.428-5.878-9.837-10.922-12.415
														c-5.044-2.578-10.818-3.464-16.482-3.394c-5.665,0.07-11.274,1.053-16.853,2.033c-1.352,0.238-2.834,0.551-3.668,1.642
														c-0.566,0.741-0.721,1.707-0.858,2.629c-1.154,7.771-2.308,15.543-3.463,23.314l1.11,5.147
														c2.486,0.534,4.964-0.857,6.975-2.412c7.022-5.43,11.821-13.653,13.096-22.437c0.544,1.108,0.52,2.395,0.684,3.619
														c0.165,1.223,0.633,2.549,1.737,3.102c1.843,0.924,3.931-0.948,4.641-2.883c0.71-1.935,0.817-4.175,2.159-5.74
														c1.387,3.265,3.527,6.208,6.206,8.534c1.262,1.096,3.029,2.098,4.52,1.342c1.056-0.535,1.555-1.82,1.506-3.002
														c-0.05-1.182-0.544-2.295-1.031-3.374c1.831,3.064,4.575,5.575,7.79,7.126c0.707,0.341,1.556,0.634,2.248,0.265
														C411.766,225.298,411.689,223.833,411.362,222.738z"
                                        />
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                      <g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <path
                                          style={{ fill: "#FFBF9D" }}
                                          d="M368.523,235.932c-0.201-0.137-8.34-4.29-9.728,4.555
														c-1.388,8.845,7.778,8.414,7.834,8.163C366.685,248.398,368.523,235.932,368.523,235.932z"
                                        />
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                      <g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <path
                                          style={{ fill: "#FF9A6C" }}
                                          d="M364.453,244.874c-0.035-0.034-0.171,0.084-0.45,0.162
														c-0.272,0.076-0.721,0.088-1.155-0.157c-0.881-0.49-1.435-2.097-1.228-3.698c0.104-0.809,0.393-1.549,0.778-2.139
														c0.371-0.605,0.861-0.991,1.345-1.001c0.485-0.035,0.774,0.299,0.843,0.565c0.08,0.266-0.004,0.434,0.04,0.456
														c0.022,0.026,0.213-0.118,0.2-0.495c-0.009-0.181-0.08-0.406-0.261-0.608c-0.186-0.206-0.484-0.343-0.817-0.359
														c-0.689-0.06-1.402,0.471-1.825,1.119c-0.461,0.645-0.804,1.473-0.921,2.381c-0.223,1.791,0.428,3.602,1.648,4.173
														c0.597,0.26,1.145,0.154,1.446-0.013C364.404,245.085,364.481,244.895,364.453,244.874z"
                                        />
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                      <g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <g>
                                          <path
                                            style={{
                                              fill: "none",
                                              stroke: "#839198",
                                              strokeWidth: "0.05",
                                              strokeMiterlimit: 10
                                            }}
                                            d="M364.905,207.486"
                                          />
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                      <g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <g>
                                          <path
                                            style={{ fill: "#455A64" }}
                                            d="M378.699,215.424c0.013,0.009-0.083,0.174-0.281,0.479
															c-0.196,0.306-0.503,0.747-0.924,1.295c-0.838,1.097-2.146,2.629-4.019,4.253c-0.937,0.81-2.015,1.64-3.235,2.438
															c-0.618,0.391-1.249,0.791-1.947,1.157c-0.694,0.351-1.502,0.683-2.397,0.588c-0.898-0.077-1.709-0.73-2.093-1.547
															c-0.403-0.816-0.526-1.75-0.43-2.67c0.25-1.869,1.378-3.421,1.909-5.169c0.07-0.23,0.127-0.463,0.172-0.678
															l0.378,0.229c-1.48,1.097-2.924,2.167-4.318,3.2c-0.697,0.515-1.381,1.021-2.052,1.517
															c-0.667,0.484-1.395,0.98-2.276,1.082c-0.438,0.043-0.908-0.071-1.254-0.346c-0.357-0.268-0.569-0.661-0.69-1.042
															c-0.237-0.772-0.192-1.549-0.106-2.249c0.146-1.421,0.507-2.71,0.928-3.841c0.859-2.269,2.047-3.863,2.937-4.859
															c0.448-0.501,0.827-0.859,1.093-1.09c0.268-0.23,0.413-0.344,0.423-0.332c0.03,0.033-0.529,0.519-1.379,1.539
															c-0.838,1.021-1.964,2.618-2.766,4.855c-0.392,1.116-0.726,2.38-0.852,3.767c-0.079,0.691-0.102,1.411,0.112,2.082
															c0.187,0.674,0.793,1.179,1.513,1.086c0.715-0.084,1.404-0.529,2.062-1.021c0.665-0.497,1.345-1.004,2.037-1.521
															c1.386-1.032,2.822-2.101,4.294-3.197l0.504-0.375l-0.125,0.605c-0.048,0.232-0.109,0.481-0.184,0.725
															c-0.571,1.841-1.681,3.39-1.9,5.085c-0.092,0.838,0.022,1.698,0.374,2.415c0.342,0.721,0.993,1.243,1.741,1.318
															c0.745,0.089,1.491-0.193,2.159-0.526c0.673-0.344,1.313-0.74,1.923-1.116c1.213-0.774,2.29-1.578,3.23-2.363
															c1.88-1.574,3.215-3.058,4.092-4.113c0.44-0.527,0.769-0.949,0.99-1.236
															C378.562,215.561,378.686,215.415,378.699,215.424z"
                                          />
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                    <g>
                      <g>
                        <path
                          style={{ fill: "#FF9A6C" }}
                          d="M389.858,253.261c-1.13-0.989-2.334-1.893-3.598-2.702c-0.407-0.26-0.862-0.521-1.341-0.463
						c-0.444,0.054-0.811,0.375-1.08,0.733c-0.868,1.152-0.947,2.841-0.19,4.069c0.757,1.228,2.301,1.916,3.721,1.658
						c1.419-0.258,2.622-1.446,2.898-2.862L389.858,253.261z"
                        />
                      </g>
                      <g>
                        <g>
                          <path
                            style={{ fill: "#263238" }}
                            d="M387.099,256.573c0.01,0.025-0.202,0.155-0.64,0.21c-0.432,0.054-1.106-0.011-1.799-0.418
							c-0.682-0.388-1.389-1.183-1.572-2.252c-0.195-1.052,0.077-2.277,0.81-3.261c0.364-0.477,0.833-0.942,1.484-1.072
							c0.669-0.121,1.233,0.215,1.639,0.507c0.802,0.63,1.451,1.245,2.025,1.746c1.13,1.012,1.801,1.662,1.752,1.715
							c-0.049,0.053-0.807-0.5-1.988-1.433c-0.594-0.456-1.281-1.051-2.064-1.635c-0.39-0.268-0.837-0.491-1.268-0.399
							c-0.435,0.085-0.841,0.453-1.163,0.883c-0.647,0.868-0.9,1.951-0.754,2.868c0.136,0.931,0.703,1.626,1.295,2.021
							c0.595,0.402,1.192,0.517,1.594,0.527C386.857,256.591,387.087,256.531,387.099,256.573z"
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                  <g>
                    <path
                      style={{ fill: "#FF725E" }}
                      d="M401.139,279.978c0,0,5.772,4.629,9.512,13.269c3.739,8.64,7.196,24.463,7.196,24.463
				l-66.796,43.545l-10.533,3.924c0,0-3.726-2.535-4.014-10.558c-0.288-8.023-1.879-34.883-1.879-36.611
				c0-1.729-0.653-22.861,1.879-31.305c2.25-7.505,4.314-10.262,11.111-14.273c4.452-2.627,14.72-6.03,14.72-6.03
				s10.208,7.107,18.718,9.737c6.822,2.108,7.698-3.226,7.698-3.226L401.139,279.978z"
                    />
                  </g>
                  <g>
                    <path
                      style={{ fill: "#263238" }}
                      d="M340.518,365.18c0,0,24.367-26.796,39.012-30.027s28.216,10.242,31.905,12.023
				c4.655,2.247,31.115,17.668,31.115,17.668l-12.218,20.978l-39.473-12.168c0,0-9.636,16.079-21.048,16.171
				c-1.684,0.013,0.405-0.014-1.551-0.091c-13.626-0.534-23.294-9.055-27.057-22.162L340.518,365.18z"
                    />
                  </g>
                  <g>
                    <polygon
                      style={{ fill: "#FFBF9D" }}
                      points="373.4,319.564 389.038,314.93 391.069,328.273 374.16,335.796 			"
                    />
                  </g>
                  <g>
                    <g>
                      <path
                        style={{ fill: "#263238" }}
                        d="M361.867,286.995c0,0,0.026,0.192,0.048,0.567c0.02,0.397,0.048,0.952,0.084,1.667
					c0.051,1.466,0.102,3.63,0.082,6.414c-0.027,5.568-0.291,13.617-0.987,23.525l-0.194-0.208
					c3.807-0.038,8.015-0.079,12.475-0.123l0.21-0.002l0.01,0.211c0.249,5.158,0.521,10.804,0.806,16.738l0.005,0.106l-0.072,0.073
					c-1.963,1.979-4.117,3.837-6.459,5.564c-2.344,1.714-4.868,3.32-7.694,4.457c-2.836,1.107-5.961,1.583-9.092,1.4
					c-3.108-0.19-6.353-1.068-8.872-3.176c-1.315-1.106-2.353-2.56-2.882-4.184c-0.256-0.802-0.487-1.608-0.673-2.417
					c-0.157-0.814-0.353-1.62-0.475-2.432c-0.553-3.242-0.85-6.462-1.104-9.624c-0.494-6.328-0.679-12.446-0.777-18.272
					c-0.036-2.914-0.049-5.755-0.011-8.517c0.015-2.765,0.27-5.464,0.761-8.017c0.994-5.092,2.68-9.767,5.345-13.351
					c1.341-1.768,3.026-3.206,4.958-3.88c1.975-0.605,3.957-0.188,5.583,0.445c3.322,1.298,5.517,3.649,6.787,5.748
					c1.285,2.129,1.76,4.038,1.969,5.313C361.9,286.308,361.867,286.995,361.867,286.995c-0.039,0.003-0.038-0.683-0.271-1.955
					c-0.24-1.26-0.744-3.141-2.038-5.225c-1.279-2.055-3.46-4.339-6.719-5.583c-1.599-0.609-3.516-0.987-5.379-0.401
					c-1.827,0.652-3.462,2.048-4.763,3.786c-2.593,3.523-4.25,8.163-5.214,13.208c-1.014,5.068-0.709,10.633-0.695,16.447
					c0.112,5.818,0.307,11.929,0.806,18.239c0.256,3.153,0.556,6.364,1.106,9.577c0.122,0.806,0.316,1.599,0.471,2.403
					c0.183,0.795,0.41,1.584,0.657,2.359c0.503,1.544,1.478,2.906,2.728,3.96c2.391,2.005,5.535,2.871,8.567,3.055
					c3.053,0.181,6.114-0.284,8.874-1.359c2.756-1.105,5.265-2.692,7.584-4.384c2.318-1.703,4.47-3.553,6.405-5.496l-0.067,0.179
					c-0.269-5.935-0.526-11.582-0.76-16.74l0.22,0.208c-4.46,0.026-8.668,0.05-12.476,0.072l-0.21,0.001l0.016-0.209
					c0.743-9.894,1.064-17.937,1.157-23.498c0.053-2.781,0.036-4.943,0.021-6.409c-0.012-0.716-0.022-1.27-0.029-1.668
					C361.854,287.188,361.867,286.995,361.867,286.995z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        style={{ fill: "#263238" }}
                        d="M401.27,309.403c-0.07-0.003-0.097-0.964-0.056-2.514c0.041-1.55,0.157-3.691,0.369-6.048
					c0.212-2.358,0.479-4.485,0.715-6.018c0.236-1.533,0.433-2.473,0.503-2.464c0.073,0.01,0.004,0.967-0.137,2.508
					c-0.161,1.728-0.352,3.767-0.562,6.02c-0.195,2.254-0.371,4.293-0.52,6.022C401.447,308.452,401.343,309.406,401.27,309.403z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        style={{ fill: "#263238" }}
                        d="M354.432,326.87c-0.123-0.034,0.661-2.332,2.591-4.562c1.913-2.245,4.066-3.368,4.118-3.252
					c0.09,0.133-1.875,1.418-3.723,3.591C355.55,324.802,354.577,326.939,354.432,326.87z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        style={{ fill: "#455A64" }}
                        d="M412.252,347.175c0.007-0.012,0.21,0.087,0.597,0.29c0.415,0.222,0.978,0.524,1.694,0.908
					c1.505,0.827,3.648,2.004,6.339,3.483c5.447,3.033,13.106,7.299,22.103,12.309l0.213,0.118l-0.123,0.211
					c-3.491,5.986-7.332,12.572-11.366,19.489c-0.389,0.665-0.776,1.327-1.15,1.968l-0.104,0.177l-0.196-0.06
					c-15.737-4.785-29.753-9.046-39.293-11.947l-0.013-0.004l-0.01-0.005c-4.11-1.955-6.761-4.535-8.348-6.401
					c-1.585-1.896-2.188-3.103-2.188-3.103c0.007-0.004,0.059,0.061,0.155,0.193c0.11,0.156,0.248,0.351,0.42,0.593
					c0.379,0.515,0.94,1.278,1.754,2.194c1.618,1.814,4.279,4.326,8.351,6.212l-0.022-0.008c9.552,2.859,23.587,7.059,39.344,11.775
					l-0.3,0.118c0.374-0.641,0.761-1.303,1.15-1.968c4.047-6.909,7.9-13.488,11.402-19.468l0.089,0.33
					c-8.959-5.077-16.586-9.4-22.01-12.474c-2.663-1.529-4.783-2.747-6.272-3.602c-0.7-0.412-1.251-0.736-1.657-0.974
					C412.436,347.309,412.245,347.187,412.252,347.175z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <polygon
                        style={{ fill: "#E0E0E0" }}
                        points="462.878,329.545 463.667,322.222 468.485,318.056 481.572,310.018 491.512,305.951 
					491.997,306.617 463.667,330.508 				"
                      />
                    </g>
                    <g>
                      <path
                        style={{ fill: "#FFBF9D" }}
                        d="M442.933,316.164c0,0,11.872-8.493,21.372-9.235c9.5-0.742,17.292,1.762,17.898,3.431
					c0.606,1.669-1.558,2.319-3.55,2.411c-1.991,0.093-6.482-0.927-6.482-0.927l-5.119,6.585l-10.389,4.637l-12.12-1.298
					L442.933,316.164z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ fill: "#F5F5F5" }}
                        d="M419.183,309.788c0,0,6.19,2.201,9.305,2.837c3.115,0.636,16.568,0.979,24.025,4.484
					c3.968,1.865,8.616,9.415,8.616,9.415l1.492,1.669l-3.865,2.94l-25.514-2.542l-21.806-10.17L419.183,309.788z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ fill: "#F5F5F5" }}
                        d="M491.512,305.382l-1.13-0.98l-38.471,17.277l8.512,9.138l3.588-1.314c0,0-0.379-1.899,0-4.557
					c0.379-2.658,7.003-7.537,8.808-8.45C475.617,315.081,490.46,307.681,491.512,305.382z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ fill: "#455A64" }}
                        d="M491.626,304.917l-27.198,23.089c-0.079,0.067-0.548,0.393-0.969,0.683
					c-0.455,0.313-0.994,0.48-1.547,0.48l0,0c-0.451,0-0.817-0.366-0.817-0.817v-1.156c0-0.346-0.218-0.655-0.544-0.77
					l-43.139-15.317c-0.195-0.069-0.409-0.062-0.598,0.02l-60.989,26.403c-0.68,0.295-0.649,1.27,0.049,1.52l43.063,15.416
					c1.817,0.973,3.718-0.017,3.718-0.017l60.541-23.434c0.089-0.033,0.172-0.081,0.245-0.143l29.241-24.709
					c0.357-0.302,0.389-0.841,0.07-1.182l0,0C492.454,304.663,491.958,304.634,491.626,304.917z"
                      />
                    </g>
                    <g>
                      <g>
                        <path
                          style={{ fill: "#FFBF9D" }}
                          d="M464.787,308.841c0.173,0.449,1.818,2.397,1.818,2.397s5.984-0.742,7.148,0.835
						c1.163,1.577,0,2.968,0,2.968s-2.906,2.411-6.888,2.226c-3.982-0.185-7.186,3.617-10.475,0.835
						c-3.29-2.782-3.809-6.399-3.809-6.399"
                        />
                      </g>
                      <g>
                        <g>
                          <path
                            style={{ fill: "#FF9A6C" }}
                            d="M452.58,311.702c0.039-0.007,0.141,0.538,0.507,1.462c0.365,0.917,0.998,2.232,2.172,3.552
							c0.59,0.642,1.288,1.359,2.174,1.804c0.44,0.239,0.954,0.33,1.479,0.367c0.531,0.001,1.081-0.077,1.637-0.234
							c1.115-0.303,2.244-0.835,3.497-1.22c0.626-0.193,1.286-0.342,1.973-0.406c0.707-0.05,1.364,0.019,2.021-0.047
							c2.101-0.162,4.067-1.021,5.554-2.131l-0.032,0.032c0.288-0.379,0.474-0.891,0.465-1.361
							c-0.003-0.477-0.185-0.932-0.461-1.313c-0.245-0.334-0.718-0.505-1.145-0.634c-0.438-0.133-0.894-0.188-1.329-0.24
							c-1.756-0.164-3.294-0.057-4.472,0.041l-0.078,0.007l-0.044-0.054c-1.219-1.498-1.796-2.441-1.713-2.485
							c0,0,0.646,0.864,1.923,2.308l-0.122-0.048c1.194-0.15,2.728-0.306,4.544-0.175c0.453,0.045,0.925,0.091,1.407,0.227
							c0.47,0.143,1.005,0.29,1.397,0.781c0.325,0.431,0.561,0.986,0.571,1.58c0.007,0.598-0.184,1.174-0.564,1.684l-0.013,0.017
							l-0.019,0.014c-1.587,1.205-3.6,2.089-5.829,2.269c-0.703,0.068-1.401-0.001-2.02,0.044c-0.638,0.058-1.262,0.195-1.867,0.378
							c-1.212,0.363-2.347,0.888-3.522,1.195c-0.585,0.158-1.186,0.239-1.777,0.228c-0.586-0.046-1.168-0.164-1.652-0.437
							c-0.988-0.524-1.666-1.272-2.263-1.952c-1.176-1.396-1.771-2.77-2.084-3.724C452.583,312.271,452.547,311.705,452.58,311.702z
							"
                          />
                        </g>
                      </g>
                    </g>
                    <g>
                      <g>
                        <path
                          style={{ fill: "#263238" }}
                          d="M417.847,311.276c-0.013-0.012,0.092-0.155,0.306-0.418c0.256-0.304,0.571-0.68,0.955-1.137
						l0.04-0.047l0.066,0.019c1.867,0.539,4.999,1.514,8.989,2.546c1.988,0.518,4.241,0.85,6.641,1.257
						c2.407,0.383,4.961,0.79,7.611,1.212c1.763,0.274,3.483,0.541,5.147,0.8c1.669,0.306,3.279,0.659,4.733,1.251
						c2.956,1.069,5.213,3.006,6.553,4.946c1.362,1.946,2.02,3.754,2.526,4.953c0.225,0.561,0.411,1.022,0.561,1.396
						c0.127,0.32,0.187,0.492,0.17,0.499c-0.016,0.008-0.107-0.149-0.266-0.456c-0.162-0.305-0.377-0.766-0.652-1.36
						c-0.561-1.175-1.272-2.949-2.637-4.817c-1.348-1.863-3.536-3.694-6.426-4.713c-2.845-1.133-6.267-1.387-9.788-1.985
						c-2.65-0.43-5.205-0.845-7.613-1.236c-2.396-0.422-4.66-0.778-6.667-1.33c-3.997-1.092-7.1-2.143-8.943-2.773l0.105-0.028
						c-0.413,0.431-0.752,0.785-1.027,1.072C417.991,311.168,417.86,311.288,417.847,311.276z"
                        />
                      </g>
                    </g>
                    <g style={{ opacity: "0.3" }}>
                      <polygon
                        style={{ fill: "#FFFFFF" }}
                        points="416.526,341.569 447,330.816 414.775,319.057 386.835,331.131 				"
                      />
                    </g>
                    <g>
                      <g>
                        <path
                          style={{ fill: "#FFBF9D" }}
                          d="M400.213,310.117c0,0,3.627-3.297,5.056-2.944c1.429,0.353,4.786,3.343,5.276,5.181
						c0.49,1.837-0.799,3.211-2.103,2.96c-1.304-0.252-4.052-2.842-4.052-2.842l-2.968,1.766c0,0,4.726,5.534,4.726,7.653
						c0,2.119-1.099,3.65-3.188,3.061c0,0,0.314,1.658-1.543,2.675c-3.397,1.86-6.48-0.791-6.48-0.791s-0.66,1.865-3.297,1.345
						c-2.638-0.521-4.616-2.745-4.616-2.745s-1.649-1.073-1.759-3.428c-0.11-2.355,0-5.298,0-5.298l9.233-4.356
						C394.497,312.354,396.585,309.764,400.213,310.117z"
                        />
                      </g>
                      <g>
                        <g>
                          <path
                            style={{ fill: "#FF9A6C" }}
                            d="M389.161,322.42c0.066-0.121,1.547,0.589,3.115,1.85c1.574,1.256,2.589,2.546,2.486,2.637
							c-0.11,0.102-1.273-1.003-2.812-2.23C390.418,323.444,389.085,322.549,389.161,322.42z"
                          />
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            style={{ fill: "#FF9A6C" }}
                            d="M397.816,317.146c0.08-0.104,1.792,1.224,3.264,3.422c1.486,2.19,2.075,4.275,1.948,4.31
							c-0.146,0.056-0.936-1.894-2.38-4.019C399.221,318.725,397.709,317.261,397.816,317.146z"
                          />
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            style={{ fill: "#FF9A6C" }}
                            d="M401.417,327.628c-0.081,0.106-2.354-1.572-4.694-4.136c-2.351-2.556-3.824-4.966-3.711-5.037
							c0.127-0.088,1.774,2.163,4.095,4.685C399.417,325.671,401.516,327.509,401.417,327.628z"
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
              <g id="Character_2">
                <g>
                  <g>
                    <rect
                      x="207.557"
                      y="286.298"
                      style={{ fill: "#455A64" }}
                      width="115.156"
                      height="27.98"
                    />
                  </g>
                  <g>
                    <rect
                      x="204.259"
                      y="314.279"
                      style={{ fill: "#455A64" }}
                      width="115.156"
                      height="27.393"
                    />
                  </g>
                  <g>
                    <rect
                      x="180.27"
                      y="341.671"
                      style={{ fill: "#455A64" }}
                      width="134.328"
                      height="22.03"
                    />
                  </g>
                  <g>
                    <rect
                      x="299.885"
                      y="291.076"
                      style={{ fill: "#FFFFFF" }}
                      width="22.827"
                      height="17.217"
                    />
                  </g>
                  <g>
                    <path
                      style={{ fill: "#FFFFFF" }}
                      d="M229.794,307.271c0-0.701,0.069-1.176,0.208-1.423c0.139-0.247,0.432-0.371,0.879-0.371
				c0.478,0,0.717,0.086,0.717,0.258c0.107-0.66,0.929-3.013,2.464-7.06c1.535-4.046,2.387-6.085,2.557-6.116
				c0.493-0.079,1.095-0.118,1.804-0.118c0.37,0,0.902,0.039,1.597,0.117c0.169,0.079,1.021,2.134,2.556,6.164
				c1.535,4.031,2.356,6.368,2.464,7.013c0-0.172,0.239-0.258,0.717-0.258c0.447,0,0.741,0.124,0.879,0.371
				c0.139,0.247,0.208,0.722,0.208,1.423c0,0.779-0.085,1.215-0.254,1.307c-0.201,0.184-1.404,0.275-3.609,0.275
				c-1.589,0-2.383-0.084-2.383-0.252c-0.339-0.198-0.509-0.879-0.509-2.041c0-0.167,0.007-0.298,0.023-0.389
				c0.015-0.092,0.077-0.184,0.185-0.275c0.108-0.092,0.262-0.138,0.463-0.138c0.447,0,0.671,0.031,0.671,0.094
				c0.062,0,0.093-0.078,0.093-0.235c0-0.395-0.208-1.293-0.625-2.695c-0.632,0.063-1.473,0.094-2.521,0.094
				c-1.126,0-2.005-0.031-2.638-0.094c-0.417,1.418-0.625,2.324-0.625,2.719c0,0.124,0.031,0.194,0.092,0.21
				c0.093-0.062,0.293-0.092,0.602-0.092c0.232,0,0.405,0.054,0.521,0.16c0.116,0.107,0.181,0.214,0.197,0.321
				c0.015,0.106,0.023,0.282,0.023,0.527c0,1.009-0.17,1.62-0.509,1.834c0,0.168-0.795,0.252-2.383,0.252
				c-2.206,0-3.409-0.092-3.609-0.275C229.879,308.486,229.794,308.05,229.794,307.271z M236.573,300.155
				c0.586-0.047,1.149-0.07,1.689-0.07c0.632,0,1.226,0.031,1.781,0.094c-0.694-2.079-1.272-3.688-1.735-4.83
				C237.876,296.426,237.297,298.029,236.573,300.155z"
                    />
                    <path
                      style={{ fill: "#FFFFFF" }}
                      d="M248.14,307.305c0-0.313,0.011-0.555,0.034-0.727c0.023-0.172,0.125-0.348,0.308-0.527
				c0.182-0.18,0.456-0.27,0.82-0.27c0.045,0,0.145,0.016,0.296,0.047c-0.124-1.454-0.186-2.837-0.186-4.15
				c0-2.421,0.101-4.437,0.301-6.045c-0.333,0-0.75-0.04-1.25-0.121c-0.092-0.128-0.138-0.585-0.138-1.371
				c0-0.785,0.023-1.229,0.069-1.333c0.46-0.37,1.39-0.555,2.788-0.555c0.419,0,0.956,0.029,1.608,0.089
				c0.061,0.192,0.092,0.487,0.092,0.887c0.43-0.251,0.99-0.473,1.681-0.664c0.69-0.192,1.313-0.288,1.865-0.288
				c1.192,0,2.188,0.48,2.985,1.442c0.798,0.961,1.197,2.05,1.197,3.265c0,1.483-0.567,2.751-1.702,3.804
				c1.381,0.906,2.072,2.204,2.072,3.892c0,1.189-0.378,2.22-1.134,3.096c-0.755,0.875-1.673,1.313-2.751,1.313
				c-2.319,0-3.824-0.32-4.515-0.961c0,0.406-0.109,0.652-0.326,0.738c-0.218,0.086-0.724,0.129-1.516,0.129
				c-0.89,0-1.703-0.094-2.439-0.281C248.194,308.197,248.14,307.728,248.14,307.305z M252.745,299.011
				c0,0.097,0.422,0.228,1.266,0.397c0.845,0.169,1.405,0.254,1.681,0.254c0.491,0,0.909-0.23,1.255-0.689
				c0.346-0.458,0.519-1.017,0.519-1.677c0-0.659-0.123-1.262-0.368-1.809c-0.246-0.547-0.63-0.82-1.151-0.82
				c-0.476,0-1.106,0.108-1.888,0.325c-0.783,0.218-1.182,0.431-1.197,0.64c-0.016,0.161-0.027,0.378-0.034,0.651
				c-0.008,0.273-0.02,0.672-0.035,1.194C252.775,298.001,252.759,298.512,252.745,299.011z M252.745,306.063
				c0,0.094,0.422,0.223,1.266,0.387c0.845,0.164,1.405,0.246,1.681,0.246c0.491,0,0.909-0.223,1.255-0.668
				c0.346-0.445,0.519-0.988,0.519-1.63c0-0.641-0.123-1.227-0.368-1.758c-0.246-0.531-0.63-0.798-1.151-0.798
				c-0.476,0-1.106,0.105-1.888,0.317c-0.783,0.211-1.182,0.418-1.197,0.621c-0.016,0.156-0.027,0.367-0.034,0.633
				c-0.008,0.266-0.02,0.652-0.035,1.16C252.775,305.082,252.759,305.578,252.745,306.063z"
                    />
                    <path
                      style={{ fill: "#FFFFFF" }}
                      d="M263.086,300.787c0-2.392,0.605-4.408,1.816-6.049c1.211-1.642,2.818-2.462,4.824-2.462
				c1.465,0,2.545,0.341,3.238,1.023c0.031-0.466,0.193-0.753,0.486-0.861c0.385-0.171,0.855-0.256,1.411-0.256
				c0.725,0,1.296,0.155,1.712,0.466c0.016,0.17,0.05,0.438,0.105,0.802c0.054,0.364,0.096,0.694,0.127,0.989
				c0.031,0.296,0.046,0.567,0.046,0.815c0,0.978-0.277,1.718-0.832,2.222c-0.324,0.363-0.857,0.544-1.597,0.544
				c-0.771,0-1.35-0.181-1.735-0.544c-0.016-0.031-0.023-0.102-0.023-0.212c0-0.19,0.023-0.316,0.069-0.379
				c-0.262-0.583-0.686-1.032-1.272-1.347c-0.586-0.315-1.118-0.473-1.596-0.473c-0.895,0-1.724,0.585-2.487,1.754
				c-0.764,1.169-1.146,2.507-1.146,4.014c0,1.423,0.359,2.575,1.076,3.458c0.717,0.884,1.546,1.325,2.487,1.325
				c0.478,0,1.01-0.109,1.596-0.328c0.586-0.219,1.018-0.523,1.296-0.915c-0.046-0.281-0.069-0.438-0.069-0.469
				c0-0.031,0.004-0.063,0.012-0.094c0.008-0.031,0.012-0.055,0.012-0.07c0.57-0.266,1.071-0.398,1.504-0.398
				c0.432,0,1.063,0.156,1.896,0.469c0.556,0.501,0.833,1.228,0.833,2.181c0,0.25-0.02,0.527-0.058,0.832
				c-0.039,0.305-0.085,0.638-0.139,0.997c-0.055,0.359-0.089,0.633-0.105,0.82c-0.478,0.391-1.095,0.586-1.851,0.586
				c-0.556,0-0.979-0.125-1.272-0.375c-0.324-0.125-0.486-0.43-0.486-0.914c-1.018,0.703-2.051,1.055-3.1,1.055
				c-0.818,0-1.62-0.172-2.406-0.516c-0.787-0.344-1.512-0.844-2.175-1.501c-0.663-0.656-1.195-1.52-1.597-2.591
				C263.286,303.316,263.086,302.116,263.086,300.787z"
                    />
                  </g>
                  <g style={{ opacity: "0.3" }}>
                    <rect x="295.192" y="286.298" width="27.514" height="27.98" />
                  </g>
                  <g>
                    <g>
                      <path
                        style={{ fill: "#263238" }}
                        d="M322.707,295.083c0,0.144-5.112,0.26-11.418,0.26c-6.307,0-11.419-0.117-11.419-0.26
					c0-0.144,5.111-0.26,11.419-0.26C317.594,294.823,322.707,294.939,322.707,295.083z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        style={{ fill: "#263238" }}
                        d="M322.707,299.684c0,0.144-5.112,0.26-11.418,0.26c-6.307,0-11.419-0.117-11.419-0.26
					s5.111-0.26,11.419-0.26C317.594,299.424,322.707,299.54,322.707,299.684z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        style={{ fill: "#263238" }}
                        d="M322.707,304.286c0,0.144-5.112,0.26-11.418,0.26c-6.307,0-11.419-0.117-11.419-0.26
					s5.111-0.26,11.419-0.26C317.594,304.025,322.707,304.142,322.707,304.286z"
                      />
                    </g>
                  </g>
                  <g>
                    <rect
                      x="291.771"
                      y="345.433"
                      style={{ fill: "#FFFFFF" }}
                      width="22.827"
                      height="13.556"
                    />
                  </g>
                  <g style={{ opacity: "0.3" }}>
                    <rect x="287.078" y="341.671" width="27.514" height="22.03" />
                  </g>
                  <g>
                    <g>
                      <path
                        style={{ fill: "#263238" }}
                        d="M314.592,348.588c0,0.144-5.113,0.26-11.418,0.26c-6.307,0-11.419-0.117-11.419-0.26
					c0-0.144,5.111-0.26,11.419-0.26C309.479,348.328,314.592,348.444,314.592,348.588z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        style={{ fill: "#263238" }}
                        d="M314.592,352.211c0,0.144-5.113,0.26-11.418,0.26c-6.307,0-11.419-0.117-11.419-0.26
					s5.111-0.26,11.419-0.26C309.479,351.951,314.592,352.067,314.592,352.211z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        style={{ fill: "#263238" }}
                        d="M314.592,355.834c0,0.144-5.113,0.26-11.418,0.26c-6.307,0-11.419-0.117-11.419-0.26
					c0-0.144,5.111-0.26,11.419-0.26C309.479,355.574,314.592,355.69,314.592,355.834z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        style={{ fill: "#263238" }}
                        d="M323.162,314.148c0,0.144-25.916,0.26-57.877,0.26c-31.972,0-57.882-0.117-57.882-0.26
					s25.91-0.26,57.882-0.26C297.247,313.888,323.162,314.005,323.162,314.148z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        style={{ fill: "#263238" }}
                        d="M319.415,341.671c0,0.144-25.921,0.26-57.889,0.26c-31.979,0-57.895-0.116-57.895-0.26
					c0-0.144,25.916-0.26,57.895-0.26C293.494,341.411,319.415,341.528,319.415,341.671z"
                      />
                    </g>
                  </g>
                  <g>
                    <rect
                      x="204.259"
                      y="319.555"
                      style={{ fill: "#FAFAFA" }}
                      width="110.327"
                      height="15.682"
                    />
                  </g>
                  <g style={{ opacity: "0.3" }}>
                    <rect x="288.369" y="314.286" width="31.046" height="27.385" />
                  </g>
                  <g>
                    <g>
                      <path
                        style={{ fill: "#263238" }}
                        d="M314.022,327.396c0,0.144-24.611,0.26-54.962,0.26c-30.362,0-54.967-0.117-54.967-0.26
					s24.605-0.26,54.967-0.26C289.412,327.136,314.022,327.252,314.022,327.396z"
                      />
                    </g>
                  </g>
                  <g>
                    <polygon
                      style={{ fill: "#FF725E" }}
                      points="273.967,322.988 273.967,330.305 276.76,328.362 279.553,330.305 279.553,322.988 			"
                    />
                  </g>
                  <g>
                    <g>
                      <path
                        style={{ fill: "#263238" }}
                        d="M314.022,322.988c0,0.144-24.611,0.26-54.962,0.26c-30.362,0-54.967-0.117-54.967-0.26
					c0-0.144,24.605-0.26,54.967-0.26C289.412,322.728,314.022,322.844,314.022,322.988z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        style={{ fill: "#263238" }}
                        d="M314.022,331.804c0,0.144-24.611,0.26-54.962,0.26c-30.362,0-54.967-0.117-54.967-0.26
					s24.605-0.26,54.967-0.26C289.412,331.544,314.022,331.66,314.022,331.804z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        style={{ fill: "#263238" }}
                        d="M273.473,357.05c0,0-0.014-0.198-0.021-0.567c-0.006-0.389-0.015-0.929-0.026-1.628
					c-0.014-1.442-0.035-3.537-0.061-6.249l0.108,0.108c-14.286,0.09-46.236,0.152-83.32,0.152h-0.004
					c0.52-0.52,0.144-0.143,0.26-0.258v0.002v0.009v0.017v0.034v0.068v0.136v0.271v0.541l0,1.079c0,0.717,0,1.431,0,2.138
					c0,1.414-0.001,2.802-0.001,4.148l-0.259-0.259c23.351,0.044,44.14,0.083,59.118,0.111c7.485,0.033,13.517,0.059,17.704,0.077
					c2.071,0.016,3.685,0.028,4.807,0.036c0.535,0.008,0.951,0.014,1.256,0.018C273.318,357.038,273.473,357.05,273.473,357.05
					c0.006,0.006-0.131,0.012-0.404,0.018c-0.297,0.004-0.702,0.01-1.222,0.018c-1.108,0.008-2.702,0.021-4.748,0.036
					c-4.172,0.018-10.184,0.044-17.644,0.077c-15.026,0.028-35.881,0.067-59.306,0.111l-0.259,0.001l0-0.26
					c0-1.346-0.001-2.734-0.001-4.148c0-0.707,0-1.42,0-2.138l0-1.079v-0.541v-0.271v-0.136v-0.068v-0.034v-0.017v-0.009v-0.002
					c0.116-0.117-0.26,0.258,0.261-0.262h0.004c37.083,0,69.034,0.062,83.32,0.152l0.109,0.001l-0.001,0.108
					c-0.027,2.739-0.047,4.855-0.062,6.311c-0.011,0.689-0.019,1.221-0.025,1.604C273.486,356.879,273.473,357.05,273.473,357.05z"
                      />
                    </g>
                  </g>
                </g>
                <g>
                  <g>
                    <g>
                      <polygon
                        style={{ fill: "#455A64" }}
                        points="219.323,327.446 218.906,322.183 239.459,321.379 238.269,327.867 231.264,331.364 
					224.317,331.713 				"
                      />
                    </g>
                    <g>
                      <polygon
                        style={{ fill: "#455A64" }}
                        points="199.265,327.446 198.848,322.183 219.401,321.379 218.211,327.867 211.207,331.364 
					204.26,331.713 				"
                      />
                    </g>
                    <g>
                      <g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <g>
                                          <g>
                                            <g>
                                              <g>
                                                <g>
                                                  <g>
                                                    <g>
                                                      <g>
                                                        <g>
                                                          <g>
                                                            <g>
                                                              <g>
                                                                <g>
                                                                  <g>
                                                                    <g>
                                                                      <path
                                                                        style={{
                                                                          fill: "#FF725E"
                                                                        }}
                                                                        d="M198.999,325.281l0.004,1.41c0,0-20.799,8.495-20.59,14.05l40.955,0.014
																													l-0.734-15.798C212.531,328.211,206.11,328.563,198.999,325.281z"
                                                                      />
                                                                    </g>
                                                                  </g>
                                                                </g>
                                                              </g>
                                                            </g>
                                                          </g>
                                                        </g>
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <g>
                                          <g>
                                            <g>
                                              <g>
                                                <g>
                                                  <g>
                                                    <g>
                                                      <g>
                                                        <g>
                                                          <g>
                                                            <g>
                                                              <g>
                                                                <g>
                                                                  <g>
                                                                    <g>
                                                                      <path
                                                                        style={{
                                                                          fill: "#E0E0E0"
                                                                        }}
                                                                        d="M219.368,340.756l-0.177-3.8l-39.706,1.298c0,0-1.184,1.379-1.072,2.487
																													L219.368,340.756z"
                                                                      />
                                                                    </g>
                                                                  </g>
                                                                </g>
                                                              </g>
                                                            </g>
                                                          </g>
                                                        </g>
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <g>
                                          <g>
                                            <g>
                                              <g>
                                                <g>
                                                  <g>
                                                    <g>
                                                      <g>
                                                        <g>
                                                          <g>
                                                            <path
                                                              style={{ fill: "#FFFFFF" }}
                                                              d="M199.38,332.375c0.054,0.006,0.141-0.338,0.111-0.913
																								c-0.027-0.571-0.206-1.367-0.615-2.123c-0.411-0.755-0.954-1.293-1.39-1.576
																								c-0.437-0.287-0.746-0.352-0.765-0.294c-0.059,0.134,1.039,0.734,1.78,2.136
																								C199.269,330.986,199.248,332.375,199.38,332.375z"
                                                            />
                                                          </g>
                                                        </g>
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <g>
                                          <g>
                                            <g>
                                              <g>
                                                <g>
                                                  <g>
                                                    <g>
                                                      <g>
                                                        <g>
                                                          <g>
                                                            <path
                                                              style={{ fill: "#FFFFFF" }}
                                                              d="M201.546,331.839c0.114,0.017,0.149-1.477-0.682-2.986
																								c-0.818-1.519-2.007-2.144-2.044-2.02c-0.071,0.127,0.923,0.89,1.669,2.286
																								C201.255,330.501,201.414,331.856,201.546,331.839z"
                                                            />
                                                          </g>
                                                        </g>
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <g>
                                          <g>
                                            <g>
                                              <g>
                                                <g>
                                                  <g>
                                                    <g>
                                                      <g>
                                                        <g>
                                                          <g>
                                                            <path
                                                              style={{ fill: "#FFFFFF" }}
                                                              d="M196.199,333.499c0.107,0.052,0.468-1.13,0.117-2.571
																								c-0.34-1.445-1.18-2.265-1.25-2.16c-0.098,0.098,0.521,0.985,0.822,2.289
																								C196.21,332.354,196.071,333.469,196.199,333.499z"
                                                            />
                                                          </g>
                                                        </g>
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <g>
                                          <g>
                                            <g>
                                              <g>
                                                <g>
                                                  <g>
                                                    <g>
                                                      <g>
                                                        <g>
                                                          <g>
                                                            <path
                                                              style={{ fill: "#FFFFFF" }}
                                                              d="M196.644,327.914c0.038,0.037,0.437-0.503,0.656-1.584
																								c0.111-0.535,0.169-1.188,0.179-1.906c0.005-0.359-0.001-0.735-0.012-1.122
																								c-0.008-0.19-0.001-0.395-0.052-0.638c-0.048-0.237-0.183-0.513-0.436-0.644
																								c-0.268-0.153-0.598,0.016-0.723,0.222c-0.143,0.201-0.211,0.416-0.258,0.622
																								c-0.089,0.389-0.136,0.775-0.151,1.143c-0.03,0.738,0.064,1.401,0.203,1.932
																								c0.287,1.069,0.699,1.595,0.736,1.562c0.064-0.041-0.24-0.622-0.433-1.646
																								c-0.093-0.51-0.148-1.131-0.099-1.81c0.024-0.339,0.076-0.691,0.163-1.044
																								c0.069-0.341,0.269-0.614,0.389-0.515c0.182,0.082,0.223,0.431,0.227,0.829
																								c0.013,0.375,0.025,0.742,0.038,1.103c0.013,0.7-0.012,1.324-0.078,1.84
																								C196.864,327.293,196.58,327.873,196.644,327.914z"
                                                            />
                                                          </g>
                                                        </g>
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <g>
                                          <g>
                                            <g>
                                              <g>
                                                <g>
                                                  <g>
                                                    <g>
                                                      <g>
                                                        <g>
                                                          <g>
                                                            <path
                                                              style={{ fill: "#FFFFFF" }}
                                                              d="M196.357,327.774c-0.007,0.026,0.063,0.081,0.219,0.168l0.122,0.068
																								l-0.038-0.171c-0.075-0.342-0.289-1.025-0.761-1.808c-0.235-0.391-0.534-0.803-0.9-1.197
																								c-0.184-0.196-0.383-0.387-0.598-0.571c-0.11-0.091-0.24-0.195-0.412-0.252
																								c-0.176-0.058-0.386-0.051-0.569,0.093c-0.257,0.194-0.252,0.607-0.168,0.806
																								c0.075,0.228,0.186,0.381,0.277,0.534c0.195,0.305,0.406,0.579,0.622,0.82
																								c0.433,0.483,0.889,0.831,1.29,1.067c0.81,0.473,1.376,0.526,1.384,0.47c0.02-0.08-0.506-0.257-1.232-0.782
																								c-0.361-0.263-0.769-0.618-1.156-1.084c-0.194-0.233-0.383-0.492-0.558-0.777
																								c-0.2-0.284-0.299-0.613-0.201-0.634c0.19-0.192,0.712,0.36,1.04,0.668c0.353,0.358,0.651,0.73,0.895,1.082
																								c0.49,0.706,0.765,1.325,0.92,1.624l0.084-0.102C196.449,327.753,196.365,327.748,196.357,327.774z"
                                                            />
                                                          </g>
                                                        </g>
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                    <g>
                      <g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <g>
                                          <g>
                                            <g>
                                              <g>
                                                <g>
                                                  <g>
                                                    <g>
                                                      <g>
                                                        <g>
                                                          <g>
                                                            <g>
                                                              <g>
                                                                <g>
                                                                  <g>
                                                                    <g>
                                                                      <path
                                                                        style={{
                                                                          fill: "#FF725E"
                                                                        }}
                                                                        d="M220.466,325.373l0.121,1.529c0,0-20.584,8.475-20.376,14.03
																													l40.955,0.014l-0.734-15.798C234.33,328.402,227.577,328.655,220.466,325.373z"
                                                                      />
                                                                    </g>
                                                                  </g>
                                                                </g>
                                                              </g>
                                                            </g>
                                                          </g>
                                                        </g>
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <g>
                                          <g>
                                            <g>
                                              <g>
                                                <g>
                                                  <g>
                                                    <g>
                                                      <g>
                                                        <g>
                                                          <g>
                                                            <g>
                                                              <g>
                                                                <g>
                                                                  <g>
                                                                    <g>
                                                                      <path
                                                                        style={{
                                                                          fill: "#E0E0E0"
                                                                        }}
                                                                        d="M241.167,340.947l-0.177-3.8l-39.706,1.298c0,0-1.184,1.379-1.072,2.488
																													L241.167,340.947z"
                                                                      />
                                                                    </g>
                                                                  </g>
                                                                </g>
                                                              </g>
                                                            </g>
                                                          </g>
                                                        </g>
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <g>
                                          <g>
                                            <g>
                                              <g>
                                                <g>
                                                  <g>
                                                    <g>
                                                      <g>
                                                        <g>
                                                          <g>
                                                            <path
                                                              style={{ fill: "#FFFFFF" }}
                                                              d="M221.178,332.566c0.054,0.006,0.141-0.338,0.111-0.913
																								c-0.027-0.571-0.206-1.367-0.615-2.123c-0.411-0.755-0.954-1.293-1.39-1.576
																								c-0.437-0.287-0.746-0.352-0.765-0.294c-0.059,0.134,1.04,0.734,1.78,2.136
																								C221.067,331.177,221.047,332.566,221.178,332.566z"
                                                            />
                                                          </g>
                                                        </g>
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <g>
                                          <g>
                                            <g>
                                              <g>
                                                <g>
                                                  <g>
                                                    <g>
                                                      <g>
                                                        <g>
                                                          <g>
                                                            <path
                                                              style={{ fill: "#FFFFFF" }}
                                                              d="M223.344,332.03c0.114,0.017,0.148-1.477-0.683-2.986
																								c-0.818-1.519-2.007-2.144-2.044-2.02c-0.071,0.127,0.923,0.89,1.669,2.286
																								C223.053,330.692,223.213,332.047,223.344,332.03z"
                                                            />
                                                          </g>
                                                        </g>
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <g>
                                          <g>
                                            <g>
                                              <g>
                                                <g>
                                                  <g>
                                                    <g>
                                                      <g>
                                                        <g>
                                                          <g>
                                                            <path
                                                              style={{ fill: "#FFFFFF" }}
                                                              d="M217.997,333.69c0.107,0.052,0.469-1.13,0.117-2.571
																								c-0.34-1.445-1.18-2.265-1.25-2.16c-0.098,0.098,0.521,0.985,0.822,2.289
																								C218.008,332.545,217.869,333.66,217.997,333.69z"
                                                            />
                                                          </g>
                                                        </g>
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <g>
                                          <g>
                                            <g>
                                              <g>
                                                <g>
                                                  <g>
                                                    <g>
                                                      <g>
                                                        <g>
                                                          <g>
                                                            <path
                                                              style={{ fill: "#FFFFFF" }}
                                                              d="M218.442,328.105c0.038,0.037,0.437-0.503,0.656-1.584
																								c0.111-0.535,0.169-1.188,0.179-1.906c0.005-0.359-0.002-0.735-0.012-1.122
																								c-0.008-0.19-0.001-0.395-0.052-0.638c-0.048-0.237-0.183-0.513-0.436-0.644
																								c-0.268-0.153-0.597,0.016-0.723,0.222c-0.143,0.2-0.211,0.416-0.258,0.622
																								c-0.089,0.389-0.136,0.775-0.151,1.143c-0.03,0.738,0.064,1.401,0.203,1.932
																								c0.287,1.069,0.699,1.595,0.736,1.561c0.064-0.041-0.24-0.622-0.433-1.646
																								c-0.093-0.51-0.148-1.131-0.099-1.81c0.024-0.339,0.076-0.691,0.163-1.044
																								c0.069-0.341,0.269-0.614,0.389-0.515c0.182,0.082,0.222,0.431,0.227,0.829
																								c0.013,0.375,0.025,0.742,0.038,1.103c0.013,0.7-0.012,1.324-0.078,1.84
																								C218.662,327.484,218.378,328.064,218.442,328.105z"
                                                            />
                                                          </g>
                                                        </g>
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <g>
                                          <g>
                                            <g>
                                              <g>
                                                <g>
                                                  <g>
                                                    <g>
                                                      <g>
                                                        <g>
                                                          <g>
                                                            <path
                                                              style={{ fill: "#FFFFFF" }}
                                                              d="M218.156,327.965c-0.007,0.026,0.063,0.081,0.219,0.168l0.122,0.068
																								l-0.037-0.171c-0.075-0.342-0.289-1.025-0.761-1.808c-0.235-0.391-0.534-0.803-0.9-1.197
																								c-0.184-0.196-0.383-0.387-0.598-0.571c-0.11-0.091-0.24-0.194-0.413-0.252
																								c-0.176-0.058-0.386-0.051-0.569,0.093c-0.257,0.194-0.252,0.607-0.168,0.806
																								c0.075,0.228,0.186,0.381,0.277,0.534c0.195,0.306,0.406,0.579,0.622,0.82
																								c0.433,0.483,0.889,0.831,1.29,1.067c0.81,0.473,1.376,0.526,1.384,0.47c0.02-0.08-0.506-0.257-1.231-0.782
																								c-0.361-0.263-0.769-0.617-1.156-1.084c-0.194-0.233-0.383-0.492-0.558-0.777
																								c-0.2-0.284-0.299-0.613-0.201-0.634c0.19-0.192,0.712,0.36,1.04,0.668c0.353,0.358,0.651,0.73,0.895,1.082
																								c0.49,0.706,0.765,1.325,0.92,1.624l0.084-0.102C218.247,327.944,218.163,327.939,218.156,327.965z"
                                                            />
                                                          </g>
                                                        </g>
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                    <g>
                      <g>
                        <path
                          style={{ fill: "#263238" }}
                          d="M219.264,326.711c0.015,0.032-0.29,0.214-0.857,0.514c-0.568,0.298-1.397,0.713-2.428,1.212
						c-2.058,1.005-4.934,2.32-7.992,3.982c-3.051,1.665-5.733,3.376-7.193,5.107c-1.49,1.716-1.723,3.187-1.826,3.146
						c-0.021-0.003-0.005-0.368,0.203-0.984c0.2-0.617,0.613-1.482,1.34-2.397c1.448-1.861,4.159-3.654,7.226-5.328
						c3.084-1.675,5.992-2.94,8.088-3.859c0.967-0.411,1.798-0.764,2.496-1.061C218.914,326.798,219.249,326.678,219.264,326.711z"
                        />
                      </g>
                    </g>
                  </g>
                  <g>
                    <g>
                      <g>
                        <path
                          style={{ fill: "#263238" }}
                          d="M307.214,167.582c-1.448-5.59-2.897-11.179-4.345-16.769
						c-2.197-8.477-5.056-19.985-9.951-27.082c-7.215-10.462-23.989-13.044-35.669-9.218c-10.623,3.479-15.834,7.663-18.635,19.068
						c-2.801,11.405,0.099,29.523,2.679,40.986c0.368,1.635,0.821,3.371,2.02,4.471c1.266,1.162,3.063,1.343,4.733,1.471
						c8.109,0.621,16.219,1.243,24.328,1.864l13.412-5.172c6.207,0.781,12.602-0.193,18.349-2.797
						c1.341-0.607,2.757-1.424,3.286-2.864C307.883,170.286,307.55,168.879,307.214,167.582z"
                        />
                      </g>
                      <g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <path
                                          style={{ fill: "#FFBF9D" }}
                                          d="M242.395,138.457c0.051-8.774,8.744-17.957,17.283-17.447l17.039,2.476
														c6.217,0.903,10.921,6.297,11.105,12.733l-2.196,10.111l2.152,41.359l1.429,0.443c0,0-1.32,18.915-16.825,18.591
														c-13.929-0.292-11.568-17.349-11.569-17.355l-0.422-10.877c0,0-13.803-0.101-16.867-14.575
														C242,156.717,242.338,148.206,242.395,138.457z"
                                        />
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <path
                                          style={{ fill: "#263238" }}
                                          d="M246.667,152.361c0.021,0.99,0.854,1.795,1.861,1.802
														c1.005,0.007,1.804-0.788,1.783-1.777c-0.021-0.989-0.854-1.796-1.858-1.803
														C247.446,150.576,246.647,151.372,246.667,152.361z"
                                        />
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <path
                                          style={{ fill: "#263238" }}
                                          d="M245.313,146.435c0.222,0.206,1.357-0.833,3.098-0.956
														c1.737-0.149,3.104,0.695,3.272,0.458c0.083-0.105-0.159-0.489-0.756-0.873c-0.588-0.382-1.561-0.724-2.645-0.64
														c-1.084,0.086-1.956,0.571-2.439,1.037C245.348,145.93,245.205,146.343,245.313,146.435z"
                                        />
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <path
                                          style={{ fill: "#263238" }}
                                          d="M262.796,151.711c0.021,0.989,0.854,1.795,1.861,1.802
														c1.005,0.006,1.804-0.788,1.783-1.777c-0.021-0.989-0.854-1.796-1.858-1.803
														C263.576,149.926,262.776,150.722,262.796,151.711z"
                                        />
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <path
                                          style={{ fill: "#263238" }}
                                          d="M263.216,145.067c0.222,0.206,1.357-0.833,3.098-0.956
														c1.737-0.149,3.104,0.695,3.272,0.458c0.083-0.105-0.159-0.49-0.755-0.873c-0.588-0.382-1.561-0.724-2.646-0.64
														c-1.083,0.086-1.956,0.571-2.439,1.037C263.251,144.562,263.108,144.975,263.216,145.067z"
                                        />
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <path
                                          style={{ fill: "#263238" }}
                                          d="M258.279,159.139c0.003-0.088-1.227-0.208-3.214-0.333
														c-0.503-0.022-0.982-0.082-1.085-0.349c-0.138-0.281,0.043-0.716,0.253-1.185c0.405-0.976,0.828-1.998,1.274-3.069
														c0.48-1.117,2.793-7.41,2.783-8.006c-0.224-0.056-1.833,3.439-3.594,7.806c-0.422,1.077-0.826,2.103-1.21,3.085
														c-0.159,0.457-0.449,0.979-0.154,1.563c0.155,0.291,0.538,0.491,0.863,0.547c0.326,0.065,0.608,0.059,0.856,0.061
														C257.045,159.267,258.278,159.228,258.279,159.139z"
                                        />
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <path
                                          style={{ fill: "#FF9A6C" }}
                                          d="M260.288,178.521c0,0,9.587,0.169,18.726-6.133c0,0-4.188,10.629-18.474,9.954
														L260.288,178.521z"
                                        />
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <path
                                          style={{ fill: "#263238" }}
                                          d="M262.433,140.099c0.26,0.61,1.921,0.067,3.971,0.111
														c2.047-0.011,3.777,0.56,3.961-0.047c0.079-0.289-0.289-0.845-1.03-1.367c-0.735-0.521-1.853-0.956-3.081-0.968
														c-1.228-0.008-2.291,0.409-2.962,0.919C262.616,139.257,262.318,139.808,262.433,140.099z"
                                        />
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <path
                                          style={{ fill: "#263238" }}
                                          d="M244.398,140.197c0.354,0.551,1.783,0.027,3.49,0.047
														c1.708-0.05,3.147,0.448,3.489-0.109c0.15-0.271-0.069-0.82-0.693-1.35c-0.614-0.528-1.649-0.973-2.823-0.964
														c-1.174,0.012-2.198,0.475-2.801,1.014C244.45,139.376,244.242,139.929,244.398,140.197z"
                                        />
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                            <g>
                              <path
                                style={{ fill: "#263238" }}
                                d="M289.206,141.582c-0.043-3.539-0.047-4.824-0.868-8.27c-0.821-3.445-3.32-7.039-6.072-9.198
									c-2.197-1.723-5.613-3.911-8.316-4.461c-4.246-0.864-6.448-1.858-14.348,0.041c-3.341,0.803-3.896,0.312-6.895,1.991
									l-2.084,1.268c2.329,6.036,22.654,18.499,29.637,20.484c0.458,2.243,0.671,3.87,1.376,6.046
									c0.305,0.942,0.761,1.897,1.572,2.44c1.021,0.683,2.42,0.539,3.439-0.127c1.019-0.666,1.25-1.442,1.69-2.598
									C289.266,146.769,289.238,144.202,289.206,141.582z"
                              />
                            </g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <path
                                          style={{ fill: "#FFBF9D" }}
                                          d="M285.926,147.075c0,0,8.286-3.753,8.442,5.166c0.156,8.919-8.597,7.153-8.617,6.897
														C285.732,158.883,285.926,147.075,285.926,147.075z"
                                        />
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <path
                                          style={{ fill: "#FF9A6C" }}
                                          d="M288.346,155.737c0.038-0.029,0.153,0.108,0.411,0.226
														c0.251,0.115,0.682,0.192,1.131,0.014c0.912-0.353,1.658-1.856,1.673-3.463c0.008-0.812-0.171-1.584-0.463-2.221
														c-0.276-0.651-0.695-1.103-1.159-1.183c-0.462-0.106-0.784,0.181-0.886,0.433c-0.112,0.251-0.054,0.428-0.099,0.444
														c-0.024,0.022-0.189-0.148-0.126-0.517c0.033-0.177,0.131-0.389,0.332-0.562c0.206-0.176,0.511-0.267,0.833-0.234
														c0.671,0.042,1.285,0.67,1.605,1.37c0.357,0.702,0.577,1.57,0.568,2.481c-0.025,1.798-0.891,3.487-2.14,3.87
														c-0.608,0.169-1.121-0.016-1.388-0.224C288.366,155.952,288.316,155.753,288.346,155.737z"
                                        />
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <g>
                                          <path
                                            style={{ fill: "#263238" }}
                                            d="M259.603,163.375c-0.014,0.077,0.409,0.254,1.137,0.223
															c0.716-0.026,1.744-0.32,2.589-1.083c0.844-0.765,1.239-1.758,1.335-2.468c0.103-0.721-0.03-1.16-0.108-1.153
															c-0.097,0.002-0.132,0.428-0.334,1.05c-0.196,0.618-0.604,1.442-1.324,2.095c-0.721,0.651-1.581,0.975-2.216,1.108
															C260.042,163.287,259.615,163.279,259.603,163.375z"
                                          />
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                      <g>
                        <path
                          style={{ fill: "#263238" }}
                          d="M276.204,139.687c5.668,11.977,7.104,25.906,3.998,38.787c1.393-0.6,2.957-0.607,4.456-0.835
						c1.499-0.228,3.091-0.78,3.89-2.069c0.698-1.126,0.632-2.547,0.53-3.867c-0.816-10.543-2.466-21.022-4.929-31.306"
                        />
                      </g>
                      <g>
                        <g>
                          <path
                            style={{ fill: "#455A64" }}
                            d="M284.659,177.639c-0.018-0.003-0.009-0.26,0.024-0.747c0.043-0.537,0.099-1.245,0.17-2.128
							c0.174-1.902,0.418-4.555,0.717-7.81c0.196-3.282-0.178-7.224-0.698-11.562c-0.539-4.341-1.277-9.099-2.164-14.071
							c-0.099-0.543-0.198-1.083-0.29-1.584l0.191,0.203c-4.752-1.233-9.028-3.168-12.657-5.298
							c-3.641-2.123-6.682-4.38-9.149-6.357c-2.466-1.98-4.376-3.673-5.675-4.86c-0.653-0.59-1.145-1.063-1.481-1.381
							c-0.333-0.321-0.503-0.495-0.491-0.508c0.012-0.013,0.204,0.136,0.559,0.432c0.391,0.331,0.903,0.766,1.543,1.309
							c1.338,1.14,3.281,2.788,5.768,4.725c2.487,1.934,5.536,4.149,9.163,6.231c3.616,2.09,7.852,3.988,12.549,5.204l0.162,0.042
							l0.03,0.162c0.092,0.503,0.191,1.045,0.291,1.589c0.888,4.982,1.614,9.749,2.128,14.106c0.495,4.357,0.836,8.31,0.589,11.651
							c-0.368,3.253-0.668,5.904-0.883,7.805c-0.111,0.879-0.2,1.583-0.267,2.117C284.72,177.389,284.676,177.641,284.659,177.639z"
                          />
                        </g>
                      </g>
                      <g>
                        <path
                          style={{ fill: "#FF725E" }}
                          d="M286.999,138.822c-0.262-0.429-0.774-0.654-1.275-0.695c-0.501-0.041-1,0.076-1.489,0.191
						c-2.28,0.539-4.604,1.096-6.603,2.318c-1.232,0.562-0.736,1.715-0.511,2.049c0.225,0.335,0.641,0.501,1.043,0.524
						c0.403,0.022,0.801-0.079,1.191-0.179c1.71-0.44,3.419-0.88,5.129-1.32c0.724-0.186,1.478-0.387,2.04-0.88
						C287.086,140.337,287.389,139.46,286.999,138.822z"
                        />
                      </g>
                    </g>
                    <g style={{ opacity: "0.3" }}>
                      <g>
                        <path
                          style={{ fill: "#FF9A6C" }}
                          d="M270.107,154.869c-0.403-0.473-1.123-0.609-1.737-0.706c-0.491,0.039-0.987,0.078-1.454,0.234
						c-0.467,0.155-0.909,0.44-1.156,0.867c-0.246,0.426-0.257,1.006,0.054,1.387c0.247,0.303,0.643,0.438,1.026,0.518
						c0.638,0.132,1.3,0.149,1.944,0.049c0.586-0.091,1.206-0.316,1.509-0.825C270.61,155.858,270.51,155.342,270.107,154.869z"
                        />
                      </g>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        style={{ fill: "#263238" }}
                        d="M305.746,250.122c-0.002,1.047-25.453,4.181-38.598-0.594
					c-12.754-4.633-22.553-7.935-22.553-7.935l-11.307,10.489l14.995,0.396l4.685,2.024c-9.19-0.542-19.045-1.07-22.799-1.08
					c-12.839-0.035-18.881,10.461-18.881,19.027c0,8.566,3.986,52.372,3.986,52.372h24.198c0,0,2.492-9.489,2.887-20.695
					c0.405-11.504,0.269-15.925,0.269-15.925l28.324,0.528l4.437-0.005c5.03-0.22,13.129-0.631,16.035-1.101
					c7.353-1.19,12.548-7.256,15.178-14.853C309.23,265.172,305.746,250.122,305.746,250.122z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ fill: "#263238" }}
                        d="M198.968,324.822c0,0-3.658-44.314-3.658-52.88c0-8.566,6.041-19.062,18.881-19.027
					c7.774,0.021,41.72,2.259,41.72,2.259l3.525,33.282l-36.27-0.592c0,0,0.405,5.022,0,16.525c-0.395,11.207,0,20.433,0,20.433
					H198.968z"
                      />
                    </g>
                    <g>
                      <g>
                        <path
                          style={{ fill: "#455A64" }}
                          d="M218.295,257.388c0.033,0.058-0.935,0.366-2.264,1.524c-1.315,1.14-2.904,3.272-3.673,6.326
						c-0.82,3.05-0.718,6.846-0.535,11.027c0.157,4.188,0.398,8.789,0.731,13.613c0.672,9.652,1.531,18.374,2.035,24.698
						c0.259,3.161,0.449,5.721,0.563,7.492c0.048,0.844,0.087,1.522,0.116,2.04c0.021,0.467,0.023,0.712,0.005,0.714
						c-0.018,0.001-0.055-0.242-0.11-0.706c-0.054-0.516-0.125-1.191-0.214-2.031c-0.178-1.824-0.425-4.363-0.728-7.475
						c-0.598-6.313-1.516-15.03-2.188-24.695c-0.333-4.83-0.56-9.439-0.691-13.632c-0.156-4.175-0.225-8.03,0.659-11.138
						c0.835-3.118,2.513-5.27,3.897-6.386c0.693-0.57,1.304-0.912,1.727-1.114C218.055,257.451,218.29,257.374,218.295,257.388z"
                        />
                      </g>
                    </g>
                    <g>
                      <g>
                        <path
                          style={{ fill: "#455A64" }}
                          d="M239.054,280.258c0.099-0.086,1.587,1.656,2.677,4.233c1.106,2.571,1.334,4.85,1.203,4.862
						c-0.154,0.029-0.604-2.155-1.682-4.658C240.192,282.185,238.926,280.349,239.054,280.258z"
                        />
                      </g>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        style={{ fill: "#FF725E" }}
                        d="M248.128,187.24c0,0-4.877,0.872-6.777,8.366c-0.933,3.682-4.368,28.234-4.368,28.234
					l-33.957,6.945l5.62,10.72c0,0,36.751-5.213,46.478-8.344C264.851,230.031,248.128,187.24,248.128,187.24z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ fill: "#FF725E" }}
                        d="M305.802,191.371c-3.229-4.175-17.981-9.836-17.981-9.836
					c-15.612,7.438-22.713,6.485-27.534,1.804c0,0-9.899,1.86-13.966,5.055l2.429,50.84c-0.175,0.118-10.818,6.29-10.818,6.29
					l-7.275,6.174l76.833,10.665c2.456-1.689,2.166-4.684,0.187-7.805c0,0-2.66-15.726-2.738-24.109
					C304.821,217.62,310.954,198.032,305.802,191.371z"
                      />
                    </g>
                    <g style={{ opacity: "0.3" }}>
                      <g>
                        <path
                          d="M265.019,252.18c11.558,2.553,23.89,1.486,34.838-3.015c2.464-1.013,4.596-1.916,6.438-3.841l-0.767-6.435
						c0,0-37.526,2.09-37.429,2.648c0.097,0.558-6.27,3.187-6.27,3.187l-3.177,4.663L265.019,252.18z"
                        />
                      </g>
                    </g>
                    <g>
                      <g>
                        <polygon
                          style={{ fill: "#FFFFFF" }}
                          points="180.035,199.495 175.698,201.901 186.735,218.441 185.815,221.76 190.439,225.654 
						193.454,221.368 264.12,255.501 265.277,251.849 222.567,206.958 191.26,215.895 					"
                        />
                      </g>
                      <g>
                        <g>
                          <path
                            style={{ fill: "#263238" }}
                            d="M180.035,199.495c0.001-0.006,0.067,0.081,0.195,0.258c0.137,0.194,0.325,0.459,0.568,0.802
							c0.511,0.732,1.251,1.792,2.209,3.165c1.947,2.811,4.78,6.904,8.373,12.093l-0.158-0.057
							c8.166-2.351,18.851-5.427,31.286-9.007l0.126-0.036l0.091,0.095c12.229,12.835,26.913,28.247,42.742,44.861l0.106,0.111
							l-0.046,0.147c-0.357,1.127-0.742,2.343-1.129,3.564l-0.028,0.087l-0.091,0.287l-0.271-0.131
							c-27.324-13.227-52.231-25.283-70.637-34.193l0.242-0.063c-1.075,1.521-2.065,2.923-3.024,4.279l-0.115,0.163l-0.152-0.128
							c-1.656-1.401-3.17-2.682-4.615-3.905l-0.083-0.07l0.029-0.103c0.343-1.225,0.64-2.286,0.929-3.316l0.021,0.13
							c-5.043-7.629-8.753-13.242-10.964-16.589l-0.043-0.065l0.068-0.037c1.492-0.807,2.585-1.398,3.322-1.796
							c0.345-0.183,0.605-0.321,0.794-0.422C179.953,199.53,180.036,199.489,180.035,199.495c0,0-0.088,0.059-0.262,0.162
							c-0.189,0.109-0.448,0.259-0.792,0.458c-0.721,0.41-1.789,1.018-3.248,1.848l0.025-0.102c2.241,3.327,6,8.907,11.11,16.492
							l0.04,0.06l-0.019,0.071c-0.283,1.032-0.575,2.094-0.911,3.321l-0.054-0.173c1.451,1.216,2.971,2.489,4.634,3.882
							l-0.266,0.035c0.953-1.361,1.937-2.767,3.005-4.292l0.094-0.135l0.148,0.072c18.422,8.879,43.349,20.893,70.695,34.073
							l-0.361,0.156l0.028-0.087c0.387-1.221,0.772-2.437,1.129-3.564l0.06,0.258c-15.805-16.636-30.468-32.069-42.678-44.921
							l0.216,0.059c-12.451,3.525-23.15,6.553-31.326,8.868l-0.101,0.028l-0.058-0.086c-3.518-5.2-6.292-9.3-8.198-12.116
							c-0.94-1.401-1.666-2.482-2.168-3.229c-0.238-0.359-0.421-0.636-0.556-0.839C180.096,199.602,180.035,199.495,180.035,199.495
							z"
                          />
                        </g>
                      </g>
                    </g>
                    <g>
                      <path
                        style={{ fill: "#455A64" }}
                        d="M220.955,211.885l-30.182,7.913c0,0-1.23,2.82-2.534,2.731c0,0-1.389,0.379-1.691-1.037
					c-0.288-1.355,0.854-2.683,0.854-2.683l-12.472-18.907l-1.17,0.661l12.248,18.566c0,0-1.438,2.176,0.499,4.508
					c1.937,2.332,32.467,32.279,32.467,32.279c2.194,0.886,4.215,0.991,5.986-0.036l41.093-0.248L220.955,211.885z"
                      />
                    </g>
                    <g>
                      <g>
                        <path
                          style={{ fill: "#FF725E" }}
                          d="M289.948,208.186l-2.464,20.732l-31.923,6.615l9.058,13.579c10.537,0,26.679-0.874,35.054-3.324
						c6.629-1.939,7.817-7.779,8.864-14.606c1.643-10.715,1.826-20.117,1.971-21.406c0.569-5.073-1.26-13.596-2.859-15.901
						c-3.657-5.27-5.44-5.882-10.358-8.28"
                        />
                      </g>
                      <g>
                        <g>
                          <path
                            style={{ fill: "#263238" }}
                            d="M297.291,185.596c0,0,0.145,0.051,0.417,0.173c0.292,0.135,0.691,0.319,1.202,0.554
							c1.038,0.514,2.648,1.174,4.432,2.517c0.886,0.675,1.773,1.55,2.632,2.593c0.431,0.52,0.861,1.078,1.291,1.67
							c0.436,0.577,0.872,1.238,1.172,1.975c1.185,2.941,1.92,6.484,2.276,10.436c0.063,0.991,0.118,2.009,0.073,3.053
							c-0.062,1.052-0.157,2.08-0.193,3.165c-0.103,2.155-0.229,4.398-0.403,6.717c-0.353,4.638-0.875,9.586-1.737,14.751
							c-0.447,2.57-0.977,5.264-2.306,7.731c-0.664,1.223-1.564,2.357-2.697,3.236c-1.128,0.887-2.464,1.498-3.848,1.904
							c-5.531,1.505-11.421,2.117-17.432,2.619c-6.005,0.463-11.883,0.673-17.552,0.678l-0.137,0l-0.076-0.114
							c-3.145-4.725-6.173-9.273-9.045-13.588l-0.198-0.297l0.349-0.071c12.531-2.568,23.447-4.805,31.937-6.545l-0.132,0.144
							c0.806-6.501,1.447-11.675,1.89-15.25c0.228-1.754,0.404-3.115,0.527-4.064c0.063-0.443,0.111-0.789,0.148-1.046
							c0.036-0.235,0.058-0.354,0.066-0.353c0.008,0.001,0.001,0.122-0.019,0.359c-0.025,0.258-0.059,0.606-0.102,1.052
							c-0.103,0.951-0.25,2.315-0.44,4.074c-0.407,3.58-0.996,8.759-1.737,15.268l-0.014,0.12l-0.119,0.025
							c-8.482,1.777-19.388,4.063-31.908,6.686l0.151-0.368c2.88,4.31,5.916,8.852,9.07,13.571l-0.212-0.113
							c5.654-0.012,11.523-0.225,17.512-0.687c5.99-0.499,11.874-1.111,17.331-2.59c1.34-0.392,2.608-0.973,3.679-1.811
							c1.074-0.831,1.93-1.904,2.571-3.078c1.283-2.367,1.82-5.019,2.269-7.573c0.871-5.141,1.408-10.077,1.779-14.703
							c0.183-2.314,0.319-4.551,0.432-6.705c0.041-1.071,0.144-2.149,0.209-3.16c0.05-1.023,0.002-2.03-0.055-3.01
							c-0.327-3.911-1.038-7.454-2.176-10.349c-0.289-0.724-0.686-1.338-1.123-1.931c-0.422-0.593-0.842-1.15-1.264-1.671
							c-0.839-1.043-1.702-1.914-2.565-2.591c-1.737-1.347-3.331-2.039-4.354-2.582c-0.502-0.256-0.893-0.455-1.18-0.601
							C297.42,185.679,297.291,185.596,297.291,185.596z"
                          />
                        </g>
                      </g>
                    </g>
                    <g>
                      <g>
                        <path
                          style={{ fill: "#FFBF9D" }}
                          d="M240.895,244.177c-1.375,0.085-2.158-1.594-1.299-2.672c0.436-0.547,1.069-1.22,1.989-2.018
						c2.929-2.543,13.976-3.837,13.976-3.837l7.21,11.386c0,0-0.841,2.773-6.225,5.423c-2.429,1.196-8.699,4.632-9.135,4.507
						c-0.436-0.125-1.263-0.979-1.263-0.979s-1.142,0.924-2.206,0.1c-1.064-0.825-1.671-1.827-0.786-2.419
						c0.885-0.592,9.831-9.584,9.659-9.575c-0.172,0.009-11.135,9.282-11.652,8.988c-0.517-0.294-3.803-0.862-2.024-3.294
						c1.778-2.432,9.822-9.488,9.822-9.488s-6.78,3.777-8.026,3.875C240.919,244.176,240.907,244.176,240.895,244.177z"
                        />
                      </g>
                    </g>
                    <g>
                      <g>
                        <path
                          style={{ fill: "#263238" }}
                          d="M247.787,199.459c0.144-0.001,0.337,7.985,0.431,17.835c0.094,9.854,0.054,17.84-0.09,17.842
						c-0.144,0.001-0.337-7.983-0.431-17.837C247.603,207.449,247.643,199.461,247.787,199.459z"
                        />
                      </g>
                    </g>
                    <g>
                      <g>
                        <path
                          style={{ fill: "#263238" }}
                          d="M302.374,190.446c0.014,0.094-0.945,0.223-2.384,0.755c-1.438,0.523-3.327,1.546-5.015,3.163
						c-1.68,1.627-2.772,3.477-3.349,4.894c-0.585,1.418-0.749,2.372-0.843,2.354c-0.035-0.006-0.02-0.247,0.041-0.682
						c0.05-0.437,0.203-1.059,0.458-1.804c0.503-1.491,1.588-3.451,3.331-5.138c1.75-1.679,3.749-2.69,5.257-3.137
						c0.754-0.227,1.381-0.357,1.819-0.391C302.128,190.417,302.369,190.41,302.374,190.446z"
                        />
                      </g>
                    </g>
                    <g>
                      <path
                        style={{ fill: "#FFBF9D" }}
                        d="M245.98,230.36c-0.4-0.104-0.925-0.147-1.129,0.213c-0.194,0.343,0.075,0.756,0.329,1.056
					c1.768,2.084,3.535,4.168,5.303,6.252l5.078-2.231C252.968,232.956,249.599,231.299,245.98,230.36z"
                      />
                    </g>
                    <g>
                      <g>
                        <path
                          style={{ fill: "#263238" }}
                          d="M294.759,233.941c-0.134,0.094-1.274-1.849-3.525-3.32c-2.23-1.506-4.462-1.804-4.427-1.964
						c-0.021-0.128,2.36-0.054,4.715,1.53C293.894,231.745,294.885,233.911,294.759,233.941z"
                        />
                      </g>
                    </g>
                    <g>
                      <g>
                        <path
                          style={{ fill: "#263238" }}
                          d="M222.767,206.957c0.134,0.051-0.201,1.253-0.75,2.684c-0.549,1.431-1.102,2.549-1.236,2.498
						c-0.134-0.051,0.202-1.253,0.75-2.684C222.08,208.024,222.633,206.906,222.767,206.957z"
                        />
                      </g>
                    </g>
                    <g>
                      <g>
                        <path
                          style={{ fill: "#263238" }}
                          d="M224.96,255.702c-0.013,0.012-0.145-0.101-0.385-0.329c-0.279-0.273-0.619-0.608-1.032-1.012
						c-0.935-0.952-2.193-2.233-3.723-3.79c-3.127-3.216-7.421-7.684-12.141-12.64c-4.721-4.958-8.973-9.466-12.032-12.748
						c-1.481-1.604-2.698-2.924-3.603-3.904c-0.384-0.432-0.701-0.788-0.961-1.08c-0.215-0.251-0.322-0.389-0.309-0.401
						c0.013-0.013,0.145,0.101,0.385,0.328c0.279,0.273,0.619,0.608,1.031,1.012c0.935,0.952,2.193,2.233,3.723,3.79
						c3.127,3.216,7.422,7.685,12.143,12.643c4.719,4.957,8.972,9.465,12.031,12.746c1.48,1.604,2.698,2.924,3.603,3.904
						c0.384,0.432,0.701,0.788,0.96,1.08C224.866,255.552,224.973,255.69,224.96,255.702z"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
              <g id="Character_1">
                <g>
                  <g>
                    <g>
                      <path
                        style={{ fill: "#EBEBEB" }}
                        d="M272.045,379.542c-0.152,0.361-4.802,13.306-4.802,13.306l19.243,6.495l4.728-15.498
					L272.045,379.542z"
                      />
                    </g>
                    <g>
                      <g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <g>
                                          <g>
                                            <g>
                                              <g>
                                                <g>
                                                  <g>
                                                    <g>
                                                      <g>
                                                        <g>
                                                          <g>
                                                            <g>
                                                              <g>
                                                                <g>
                                                                  <g>
                                                                    <g>
                                                                      <path
                                                                        style={{
                                                                          fill: "#FF725E"
                                                                        }}
                                                                        d="M290.132,389.317l0.239-1.603c0,0,23.206-3.828,24.312-9.596
																													l-42.155-9.758l-3.002,16.437C276.582,382.901,283.593,384.247,290.132,389.317z"
                                                                      />
                                                                    </g>
                                                                  </g>
                                                                </g>
                                                              </g>
                                                            </g>
                                                          </g>
                                                        </g>
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <g>
                                          <g>
                                            <g>
                                              <g>
                                                <g>
                                                  <g>
                                                    <g>
                                                      <g>
                                                        <g>
                                                          <g>
                                                            <g>
                                                              <g>
                                                                <g>
                                                                  <g>
                                                                    <g>
                                                                      <path
                                                                        style={{
                                                                          fill: "#E0E0E0"
                                                                        }}
                                                                        d="M272.529,368.361l-0.722,3.954l41.182,8.11c0,0,1.547-1.138,1.695-2.306
																													L272.529,368.361z"
                                                                      />
                                                                    </g>
                                                                  </g>
                                                                </g>
                                                              </g>
                                                            </g>
                                                          </g>
                                                        </g>
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <g>
                                          <g>
                                            <g>
                                              <g>
                                                <g>
                                                  <g>
                                                    <g>
                                                      <g>
                                                        <g>
                                                          <g>
                                                            <path
                                                              style={{ fill: "#FFFFFF" }}
                                                              d="M291.111,381.743c-0.054-0.019-0.226,0.315-0.332,0.913
																								c-0.108,0.594-0.113,1.456,0.128,2.331c0.243,0.875,0.675,1.558,1.056,1.953
																								c0.382,0.4,0.684,0.54,0.718,0.485c0.092-0.124-0.896-1.002-1.324-2.622
																								C290.895,383.2,291.246,381.775,291.111,381.743z"
                                                            />
                                                          </g>
                                                        </g>
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <g>
                                          <g>
                                            <g>
                                              <g>
                                                <g>
                                                  <g>
                                                    <g>
                                                      <g>
                                                        <g>
                                                          <g>
                                                            <path
                                                              style={{ fill: "#FFFFFF" }}
                                                              d="M288.754,381.779c-0.113-0.044-0.504,1.485-0.008,3.236
																								c0.481,1.758,1.556,2.684,1.624,2.566c0.104-0.114-0.738-1.136-1.175-2.75
																								C288.735,383.227,288.893,381.793,288.754,381.779z"
                                                            />
                                                          </g>
                                                        </g>
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <g>
                                          <g>
                                            <g>
                                              <g>
                                                <g>
                                                  <g>
                                                    <g>
                                                      <g>
                                                        <g>
                                                          <g>
                                                            <path
                                                              style={{ fill: "#FFFFFF" }}
                                                              d="M294.653,381.343c-0.098-0.079-0.751,1.051-0.732,2.619
																								c0.006,1.568,0.676,2.613,0.773,2.521c0.124-0.078-0.302-1.138-0.302-2.552
																								C294.369,382.519,294.778,381.405,294.653,381.343z"
                                                            />
                                                          </g>
                                                        </g>
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <g>
                                          <g>
                                            <g>
                                              <g>
                                                <g>
                                                  <g>
                                                    <g>
                                                      <g>
                                                        <g>
                                                          <g>
                                                            <path
                                                              style={{ fill: "#FFFFFF" }}
                                                              d="M292.866,386.986c-0.031-0.047-0.57,0.414-1.052,1.475
																								c-0.241,0.524-0.457,1.183-0.637,1.92c-0.09,0.369-0.173,0.757-0.254,1.158
																								c-0.037,0.197-0.092,0.407-0.098,0.669c-0.007,0.256,0.066,0.572,0.296,0.767
																								c0.24,0.221,0.619,0.126,0.798-0.057c0.195-0.172,0.316-0.378,0.413-0.579
																								c0.184-0.379,0.325-0.765,0.427-1.14c0.207-0.752,0.267-1.457,0.251-2.037
																								c-0.041-1.168-0.34-1.808-0.387-1.783c-0.076,0.027,0.099,0.697,0.054,1.797
																								c-0.026,0.548-0.116,1.2-0.329,1.886c-0.105,0.343-0.243,0.693-0.416,1.036
																								c-0.153,0.335-0.423,0.568-0.523,0.438c-0.168-0.128-0.126-0.497-0.037-0.907
																								c0.076-0.389,0.15-0.77,0.223-1.145c0.153-0.723,0.327-1.36,0.518-1.876
																								C292.492,387.573,292.923,387.044,292.866,386.986z"
                                                            />
                                                          </g>
                                                        </g>
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <g>
                                          <g>
                                            <g>
                                              <g>
                                                <g>
                                                  <g>
                                                    <g>
                                                      <g>
                                                        <g>
                                                          <g>
                                                            <path
                                                              style={{ fill: "#FFFFFF" }}
                                                              d="M293.128,387.199c0.014-0.025-0.045-0.098-0.186-0.225l-0.109-0.099
																								l-0.002,0.184c-0.004,0.37,0.053,1.124,0.353,2.043c0.149,0.458,0.359,0.954,0.642,1.446
																								c0.143,0.246,0.302,0.49,0.48,0.73c0.092,0.12,0.201,0.257,0.365,0.358c0.168,0.102,0.385,0.144,0.608,0.039
																								c0.31-0.138,0.404-0.564,0.365-0.789c-0.023-0.253-0.101-0.436-0.159-0.615
																								c-0.128-0.361-0.28-0.692-0.446-0.992c-0.331-0.6-0.717-1.066-1.074-1.405
																								c-0.721-0.68-1.292-0.869-1.313-0.813c-0.039,0.077,0.46,0.385,1.082,1.098
																								c0.309,0.356,0.645,0.819,0.932,1.391c0.144,0.286,0.277,0.598,0.389,0.933
																								c0.138,0.34,0.162,0.703,0.056,0.701c-0.241,0.153-0.648-0.54-0.912-0.935
																								c-0.278-0.452-0.496-0.906-0.663-1.327c-0.337-0.843-0.472-1.546-0.561-1.891l-0.111,0.085
																								C293.029,387.198,293.114,387.224,293.128,387.199z"
                                                            />
                                                          </g>
                                                        </g>
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        style={{ fill: "#AE7461" }}
                        d="M92.694,405.061c-0.128-2.531,1.799-12.05,3.173-15.808c1.374-3.758,13.9-5.904,16.238-2.91
					c2.338,2.994,1.369,4.621,1.369,4.621l-1.147,0.675c0,0,0.844,3.548-1.652,3.17c0,0-1.751,3.535-3.25,3.197l-3.113,4.851
					l0.54,19.166c0,0-15.244,2.586-15.335,2.278C89.425,423.993,92.694,405.061,92.694,405.061z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ fill: "#FF725E" }}
                        d="M131.154,393.661c-0.47,0.092-6.579,2.857-10.809,9.123
					c-4.229,6.266-13.534,20.274-13.534,20.274l-2.538-15.205l-12.089,3.975l-3.325,1.093c0,0,2.068,24.881,2.538,27.922
					c0.47,3.041,5.639,6.93,10.997,7.741c5.357,0.811,13.534-7.557,13.534-7.557l17.2-20.734L131.154,393.661z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        style={{ fill: "#263238" }}
                        d="M276.476,444.007c0.41-1.064,14.237-51.754,14.237-51.754l-16.305-2.232l-7.396,44.545
					L276.476,444.007z"
                      />
                    </g>
                    <g>
                      <g>
                        <path
                          style={{ fill: "#263238" }}
                          d="M148.722,329.21c-3.03,2.701-4.049,7.42-2.403,11.131c1.646,3.71,5.829,6.122,9.865,5.688
						c3.355-0.361,6.714-3.268,6.179-6.6c0.764,3.032,3.103,5.714,6.125,6.518c3.022,0.805,6.585-0.532,8.03-3.305
						c1.445-2.773,0.283-6.703-2.581-7.959c3.486-0.022,6.987-1.645,9.04-4.462c2.053-2.817,2.493-6.803,0.866-9.886
						c-1.627-3.083-5.352-4.989-8.76-4.259c1.076-4.389-1.698-9.372-5.996-10.77c-4.298-1.397-9.472,1.002-11.183,5.185
						c-2.6-3.174-7.508-4.175-11.143-2.272c-3.635,1.902-5.61,6.506-4.483,10.451c-2.877,1.777-4.172,5.736-2.901,8.869
						s4.958,5.072,8.259,4.344"
                        />
                      </g>
                      <g>
                        <path
                          style={{ fill: "#263238" }}
                          d="M160.404,339.448c0.843-5.097-0.178-6.19-2.995-8.729c-2.972-2.679-6.894-2.551-9.129-1.349
						c-0.811-3.863-1.036-6.641-4.718-8.304c-3.891-1.757-5.54-1.176-9.672-0.138c-3.229-1.755-7.221-2.285-10.86-1.442
						c-3.638,0.843-6.879,3.048-8.816,6c-4.728,0.236-9.134,3.334-10.616,7.464c-0.695,1.936-0.819,4.092-2.056,5.784
						c-0.896,1.225-1.699,2.46-1.998,3.924c-0.552,2.703-0.182,3.933,1.062,6.676c0.861,1.898,2.579,4.583,1.572,6.406
						c-3.91,7.076,3.156,14.137,5.379,14.539l39.297,10.653c2.571,2.111,6.572,2.603,9.657,1.186
						c3.084-1.417,5.057-4.652,4.759-7.807c4.922-3.329,6.682-10.048,3.948-15.069C169.544,354.827,168.57,345.498,160.404,339.448z
						"
                        />
                      </g>
                      <g>
                        <path
                          style={{ fill: "#AE7461" }}
                          d="M107.305,349.643c0.056-9.531,9.498-19.505,18.773-18.951l18.508,2.689
						c6.753,0.981,11.863,6.84,12.062,13.831l-2.385,10.983l2.338,44.925l1.552,0.481c0,0-1.434,20.546-18.275,20.194
						c-15.13-0.317-12.566-18.845-12.566-18.851l-0.459-11.815c0,0-14.993-0.11-18.321-15.832
						C106.876,369.478,107.243,360.233,107.305,349.643z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ fill: "#263238" }}
                          d="M112.351,364.953c0.023,1.075,0.928,1.95,2.021,1.957c1.092,0.007,1.96-0.856,1.937-1.931
						c-0.023-1.075-0.927-1.951-2.018-1.959C113.197,363.014,112.328,363.879,112.351,364.953z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ fill: "#263238" }}
                          d="M110.475,358.309c0.241,0.224,1.474-0.904,3.365-1.038c1.887-0.161,3.372,0.755,3.554,0.498
						c0.09-0.115-0.173-0.531-0.821-0.948c-0.639-0.415-1.695-0.787-2.873-0.696c-1.177,0.093-2.125,0.62-2.65,1.126
						C110.513,357.761,110.358,358.209,110.475,358.309z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ fill: "#263238" }}
                          d="M129.465,364.04c0.023,1.075,0.928,1.95,2.021,1.957c1.092,0.007,1.96-0.856,1.937-1.931
						c-0.023-1.074-0.927-1.951-2.019-1.958C130.312,362.101,129.443,362.966,129.465,364.04z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ fill: "#263238" }}
                          d="M129.922,356.823c0.241,0.224,1.474-0.905,3.365-1.038c1.887-0.161,3.372,0.755,3.554,0.498
						c0.09-0.115-0.173-0.532-0.821-0.948c-0.639-0.415-1.695-0.787-2.874-0.695c-1.177,0.093-2.124,0.62-2.649,1.126
						C129.96,356.275,129.804,356.723,129.922,356.823z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ fill: "#263238" }}
                          d="M124.558,372.109c0.004-0.096-1.332-0.226-3.491-0.362c-0.547-0.024-1.067-0.09-1.179-0.38
						c-0.15-0.306,0.046-0.777,0.275-1.288c0.439-1.06,0.9-2.17,1.383-3.333c0.522-1.214,3.034-8.048,3.023-8.697
						c-0.243-0.06-1.991,3.736-3.903,8.479c-0.458,1.17-0.897,2.284-1.314,3.35c-0.172,0.496-0.488,1.064-0.167,1.698
						c0.169,0.316,0.584,0.534,0.937,0.595c0.354,0.07,0.66,0.064,0.93,0.066C123.218,372.248,124.557,372.205,124.558,372.109z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ fill: "#6F4439" }}
                          d="M126.74,393.162c0,0,10.414,0.183,20.341-6.662c0,0-4.549,11.545-20.066,10.812L126.74,393.162z
						"
                        />
                      </g>
                      <g>
                        <path
                          style={{ fill: "#263238" }}
                          d="M129.071,351.427c0.283,0.663,2.086,0.072,4.313,0.121c2.224-0.012,4.103,0.608,4.303-0.051
						c0.086-0.314-0.314-0.918-1.119-1.485c-0.799-0.566-2.012-1.038-3.347-1.051c-1.334-0.009-2.489,0.444-3.217,0.998
						C129.269,350.512,128.946,351.111,129.071,351.427z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ fill: "#263238" }}
                          d="M109.48,351.534c0.384,0.599,1.937,0.029,3.792,0.051c1.855-0.055,3.418,0.487,3.79-0.119
						c0.163-0.295-0.075-0.891-0.752-1.466c-0.667-0.574-1.791-1.057-3.066-1.047c-1.275,0.013-2.388,0.516-3.042,1.101
						C109.537,350.642,109.311,351.242,109.48,351.534z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ fill: "#AE7461" }}
                          d="M153.813,359.015c0,0,9.777-4.086,9.947,5.601c0.169,9.688-9.338,7.77-9.36,7.492
						C154.379,371.83,153.813,359.015,153.813,359.015z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ fill: "#6F4439" }}
                          d="M157.218,368.413c0.041-0.031,0.167,0.117,0.447,0.245c0.273,0.125,0.741,0.209,1.229,0.015
						c0.991-0.384,1.801-2.016,1.817-3.762c0.009-0.882-0.186-1.72-0.503-2.413c-0.3-0.707-0.755-1.198-1.259-1.285
						c-0.502-0.115-0.852,0.197-0.962,0.47c-0.122,0.272-0.059,0.465-0.108,0.482c-0.026,0.024-0.206-0.16-0.137-0.561
						c0.036-0.193,0.142-0.422,0.36-0.61c0.224-0.191,0.555-0.29,0.905-0.254c0.729,0.045,1.396,0.727,1.743,1.488
						c0.388,0.763,0.626,1.705,0.617,2.695c-0.027,1.953-0.968,3.788-2.325,4.204c-0.661,0.183-1.218-0.017-1.508-0.243
						C157.24,368.647,157.186,368.431,157.218,368.413z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ fill: "#263238" }}
                          d="M125.997,376.71c-0.016,0.084,0.444,0.275,1.235,0.242c0.778-0.028,1.894-0.347,2.812-1.176
						c0.917-0.831,1.345-1.91,1.451-2.681c0.112-0.783-0.032-1.26-0.118-1.252c-0.106,0.003-0.143,0.465-0.363,1.141
						c-0.213,0.672-0.656,1.566-1.438,2.275c-0.784,0.707-1.718,1.059-2.407,1.204C126.473,376.614,126.01,376.605,125.997,376.71z"
                        />
                      </g>
                      <g style={{ opacity: "0.4" }}>
                        <g>
                          <path
                            style={{ fill: "#6F4439" }}
                            d="M138.156,367.079c-0.438-0.514-1.22-0.661-1.887-0.767c-0.533,0.042-1.072,0.085-1.58,0.254
							c-0.508,0.169-0.988,0.478-1.255,0.941c-0.268,0.463-0.28,1.093,0.058,1.507c0.268,0.329,0.699,0.476,1.114,0.562
							c0.693,0.144,1.412,0.162,2.112,0.054c0.636-0.099,1.31-0.343,1.639-0.896C138.702,368.154,138.593,367.593,138.156,367.079z"
                          />
                        </g>
                      </g>
                      <g style={{ opacity: "0.4" }}>
                        <g>
                          <path
                            style={{ fill: "#6F4439" }}
                            d="M114.874,368.99c-0.438-0.514-1.22-0.661-1.887-0.767c-0.533,0.042-1.072,0.085-1.58,0.254
							c-0.508,0.169-0.988,0.478-1.256,0.941c-0.268,0.463-0.28,1.093,0.058,1.507c0.268,0.329,0.699,0.476,1.115,0.562
							c0.693,0.144,1.412,0.162,2.112,0.054c0.636-0.099,1.31-0.343,1.639-0.896C115.42,370.065,115.311,369.504,114.874,368.99z"
                          />
                        </g>
                      </g>
                      <g>
                        <path
                          style={{ fill: "#263238" }}
                          d="M159.361,345.951c-0.199-2.603-1.467-5.043-3.195-7c-1.728-1.957-2.915-6.072-5.158-7.407
						c-4.821-2.87-11.128-2.412-16.7-3.071c-5.572-0.658-14.363-3.039-19.205-0.205l-11.423,15.356
						c0.766,2.382,4.625,3.333,6.021,2.154c1.396-1.179,2.009-3.207,1.5-4.962c2.98,1.118,8.397-1.046,9.162-4.136
						c3.206,3.611,7.571,4.426,11.443,1.541c0.159,2.575,1.744,5.016,4.031,6.21c2.287,1.194,5.196,1.099,7.4-0.242
						c-1.071,2.027-1.69,4.43-1.02,6.623c0.669,2.193,2.931,3.982,5.18,3.538c-1.09,1.427-1.963,3.072-2.198,4.853
						c-0.235,1.781,0.238,3.71,1.514,4.975c1.275,1.265,3.404,1.697,4.945,0.775c1.245-0.745,1.904-2.152,2.493-3.478
						c0.952-2.143,1.904-4.287,2.856-6.43C158.29,352.152,159.602,349.107,159.361,345.951z"
                        />
                      </g>
                      <g>
                        <g>
                          <path
                            style={{ fill: "#455A64" }}
                            d="M164.698,344.144c-0.047,0.018-0.216-0.594-0.815-1.54c-0.596-0.924-1.702-2.219-3.55-2.988
							l-0.138-0.058l0.031-0.149c0.415-1.974,0.109-4.598-1.456-6.841c-1.507-2.256-4.484-3.8-7.638-3.591
							c-0.971,0.063-1.915,0.283-2.751,0.632l-0.373,0.155l0.015-0.404c0.101-2.768-1.181-5.308-2.901-6.89
							c-1.728-1.63-3.89-2.234-5.643-2.283c-1.776-0.057-3.178,0.382-4.075,0.771c-0.903,0.397-1.349,0.723-1.375,0.684
							c-0.01-0.014,0.095-0.102,0.307-0.259c0.209-0.16,0.539-0.37,0.989-0.595c0.892-0.451,2.319-0.957,4.161-0.946
							c1.814,0.003,4.103,0.593,5.946,2.289c1.829,1.645,3.204,4.302,3.107,7.25l-0.359-0.248c0.89-0.374,1.892-0.609,2.921-0.676
							c3.331-0.225,6.492,1.435,8.063,3.839c1.626,2.381,1.9,5.136,1.418,7.191l-0.107-0.207c1.923,0.855,3.02,2.24,3.579,3.22
							c0.284,0.499,0.444,0.915,0.54,1.198C164.682,343.986,164.714,344.139,164.698,344.144z"
                          />
                        </g>
                      </g>
                    </g>
                    <g>
                      <g>
                        <path
                          style={{ fill: "#EBEBEB" }}
                          d="M254.397,375.895c0,0.392,0.748,14.126,0.748,14.126l20.255-1.495l-1.668-16.117
						L254.397,375.895z"
                        />
                      </g>
                      <g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <g>
                                          <g>
                                            <g>
                                              <g>
                                                <g>
                                                  <g>
                                                    <g>
                                                      <g>
                                                        <g>
                                                          <g>
                                                            <g>
                                                              <g>
                                                                <g>
                                                                  <g>
                                                                    <g>
                                                                      <g>
                                                                        <path
                                                                          style={{
                                                                            fill: "#FF725E"
                                                                          }}
                                                                          d="M274.862,377.871l-0.403-1.57c0,0,19.893-12.546,18.671-18.291
																														l-42.633,7.395l3.623,16.312C259.884,377.225,266.866,375.74,274.862,377.871z"
                                                                        />
                                                                      </g>
                                                                    </g>
                                                                  </g>
                                                                </g>
                                                              </g>
                                                            </g>
                                                          </g>
                                                        </g>
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <g>
                                          <g>
                                            <g>
                                              <g>
                                                <g>
                                                  <g>
                                                    <g>
                                                      <g>
                                                        <g>
                                                          <g>
                                                            <g>
                                                              <g>
                                                                <g>
                                                                  <g>
                                                                    <g>
                                                                      <g>
                                                                        <path
                                                                          style={{
                                                                            fill: "#E0E0E0"
                                                                          }}
                                                                          d="M250.497,365.404l0.871,3.923l41.096-8.534c0,0,0.983-1.65,0.666-2.783
																														L250.497,365.404z"
                                                                        />
                                                                      </g>
                                                                    </g>
                                                                  </g>
                                                                </g>
                                                              </g>
                                                            </g>
                                                          </g>
                                                        </g>
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <g>
                                          <g>
                                            <g>
                                              <g>
                                                <g>
                                                  <g>
                                                    <g>
                                                      <g>
                                                        <g>
                                                          <g>
                                                            <g>
                                                              <path
                                                                style={{ fill: "#FFFFFF" }}
                                                                d="M272.82,370.512c-0.057,0.004-0.086,0.378,0.049,0.97
																									c0.132,0.59,0.462,1.385,1.024,2.099c0.564,0.711,1.227,1.173,1.732,1.389
																									c0.507,0.22,0.84,0.232,0.85,0.168c0.037-0.15-1.215-0.576-2.239-1.901
																									C273.187,371.938,272.957,370.488,272.82,370.512z"
                                                              />
                                                            </g>
                                                          </g>
                                                        </g>
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <g>
                                          <g>
                                            <g>
                                              <g>
                                                <g>
                                                  <g>
                                                    <g>
                                                      <g>
                                                        <g>
                                                          <g>
                                                            <g>
                                                              <path
                                                                style={{ fill: "#FFFFFF" }}
                                                                d="M270.662,371.461c-0.121,0.003,0.113,1.564,1.251,2.984
																									c1.127,1.433,2.477,1.868,2.493,1.733c0.051-0.145-1.122-0.759-2.151-2.077
																									C271.207,372.802,270.796,371.42,270.662,371.461z"
                                                              />
                                                            </g>
                                                          </g>
                                                        </g>
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <g>
                                          <g>
                                            <g>
                                              <g>
                                                <g>
                                                  <g>
                                                    <g>
                                                      <g>
                                                        <g>
                                                          <g>
                                                            <g>
                                                              <path
                                                                style={{ fill: "#FFFFFF" }}
                                                                d="M275.928,368.767c-0.121-0.035-0.283,1.261,0.343,2.697
																									c0.615,1.443,1.638,2.145,1.692,2.023c0.084-0.12-0.721-0.931-1.27-2.234
																									C276.123,369.96,276.067,368.775,275.928,368.767z"
                                                              />
                                                            </g>
                                                          </g>
                                                        </g>
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <g>
                                          <g>
                                            <g>
                                              <g>
                                                <g>
                                                  <g>
                                                    <g>
                                                      <g>
                                                        <g>
                                                          <g>
                                                            <g>
                                                              <path
                                                                style={{ fill: "#FFFFFF" }}
                                                                d="M276.475,374.66c-0.046-0.031-0.364,0.603-0.396,1.768
																									c-0.018,0.577,0.039,1.267,0.159,2.017c0.06,0.375,0.135,0.765,0.216,1.166
																									c0.042,0.196,0.073,0.411,0.169,0.655c0.093,0.238,0.283,0.501,0.57,0.591
																									c0.307,0.111,0.619-0.125,0.713-0.362c0.113-0.235,0.145-0.471,0.156-0.694
																									c0.022-0.421,0.002-0.831-0.05-1.217c-0.102-0.773-0.32-1.447-0.561-1.974
																									c-0.492-1.06-1.017-1.534-1.049-1.492c-0.059,0.054,0.362,0.604,0.748,1.635
																									c0.189,0.514,0.359,1.151,0.43,1.866c0.036,0.357,0.046,0.733,0.02,1.116
																									c-0.011,0.368-0.169,0.688-0.312,0.606c-0.204-0.053-0.31-0.409-0.386-0.822
																									c-0.081-0.388-0.161-0.768-0.239-1.141c-0.14-0.726-0.227-1.381-0.252-1.93
																									C276.358,375.346,276.549,374.691,276.475,374.66z"
                                                              />
                                                            </g>
                                                          </g>
                                                        </g>
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <g>
                                          <g>
                                            <g>
                                              <g>
                                                <g>
                                                  <g>
                                                    <g>
                                                      <g>
                                                        <g>
                                                          <g>
                                                            <g>
                                                              <path
                                                                style={{ fill: "#FFFFFF" }}
                                                                d="M276.798,374.754c0.003-0.028-0.08-0.073-0.258-0.135l-0.139-0.049
																									l0.07,0.171c0.14,0.342,0.486,1.015,1.119,1.745c0.315,0.364,0.701,0.739,1.154,1.083
																									c0.227,0.171,0.469,0.334,0.726,0.486c0.131,0.075,0.285,0.159,0.475,0.188
																									c0.194,0.028,0.411-0.017,0.575-0.2c0.232-0.248,0.152-0.677,0.029-0.869
																									c-0.12-0.224-0.263-0.363-0.385-0.505c-0.258-0.283-0.527-0.529-0.796-0.74
																									c-0.538-0.424-1.076-0.704-1.536-0.877c-0.929-0.346-1.528-0.298-1.526-0.239
																									c-0.006,0.086,0.573,0.176,1.423,0.592c0.423,0.208,0.912,0.504,1.4,0.919
																									c0.244,0.207,0.487,0.443,0.721,0.708c0.26,0.26,0.423,0.584,0.324,0.624
																									c-0.163,0.234-0.807-0.246-1.204-0.507c-0.432-0.308-0.809-0.642-1.127-0.965
																									c-0.638-0.646-1.036-1.241-1.252-1.524l-0.069,0.122C276.707,374.792,276.795,374.783,276.798,374.754z"
                                                              />
                                                            </g>
                                                          </g>
                                                        </g>
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                    <g>
                      <path
                        style={{ fill: "#263238" }}
                        d="M188.193,413.963c0,0,11.834-4.65,21.823-4.65c9.989,0,24.084,5.974,24.084,5.974l19.435,4.626
					l0.655-35.06l22.901-0.624c0,0,2.255,42.069,1.157,52.265c-1.765,16.387-11.492,16.387-25.062,16.387
					c-13.57,0-68.246-0.193-68.246-0.193L188.193,413.963z"
                      />
                    </g>
                    <g>
                      <g>
                        <path
                          style={{ fill: "#455A64" }}
                          d="M276.792,440.996c-0.018-0.001-0.024-0.195-0.019-0.562c0.012-0.425,0.028-0.957,0.046-1.607
						c0.056-1.467,0.133-3.466,0.227-5.904c0.188-4.984,0.393-11.871,0.45-19.48c0.054-7.611-0.051-14.501-0.167-19.488
						c-0.058-2.439-0.106-4.438-0.141-5.906c-0.009-0.65-0.017-1.181-0.023-1.607c0-0.368,0.009-0.561,0.027-0.562
						c0.018-0.001,0.044,0.191,0.077,0.557c0.032,0.424,0.072,0.954,0.121,1.603c0.096,1.393,0.203,3.409,0.307,5.903
						c0.21,4.987,0.373,11.884,0.32,19.504c-0.057,7.617-0.32,14.51-0.603,19.493c-0.141,2.492-0.276,4.507-0.392,5.898
						c-0.058,0.648-0.106,1.177-0.144,1.601C276.839,440.806,276.81,440.998,276.792,440.996z"
                        />
                      </g>
                    </g>
                    <g>
                      <g>
                        <path
                          style={{ fill: "#455A64" }}
                          d="M256.072,427.419c-0.149,0.03-0.565-1.632-1.35-3.575c-0.775-1.948-1.622-3.436-1.494-3.518
						c0.11-0.078,1.179,1.321,1.977,3.324C256.012,425.649,256.206,427.4,256.072,427.419z"
                        />
                      </g>
                    </g>
                    <g>
                      <g>
                        <path
                          style={{ fill: "#AE7461" }}
                          d="M94.139,439.769c0,0-12.198-5.009-14.909-6.261c-2.711-1.252-8.132,1.67-8.326,3.13
						s0.968,2.296,0.968,2.296s-0.774,3.131,0.678,4.278c0,0-1.162,3.548,0.774,4.8c0,0-1.162,2.504,1.065,4.8
						s13.65,2.087,16.264,1.878c2.614-0.209,8.422,0.032,8.422,0.121s2.517-14.417,2.517-14.417L94.139,439.769z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ fill: "#FF725E" }}
                          d="M123.589,429.902c13.194,15.872,26.261,24.226,42.498,27.101
						c9.164,1.623,35.206,2.174,35.206,2.174c5.546-18.016,5.657-34.389-0.841-48.763l-26.556,0c0,0-15.225-17.118-20.809-18.161
						c0,0-27.45,8.067-28.097,11.424C122.88,414.618,123.589,429.902,123.589,429.902z"
                        />
                      </g>
                      <g>
                        <g>
                          <path
                            style={{ fill: "#FF725E" }}
                            d="M151.686,402.346c-7.167-9.092-21.852-5.334-23.662,6.1c-0.021,0.133-0.041,0.268-0.061,0.403
							c-1.924,13.565-2.929,30.242-2.929,30.242l-30.077-0.073l-3.006,18.157c0,0,40.881,5.255,48.578,0.451
							c7.697-4.804,7.255-16.943,9.426-27.864c1.911-9.616,3.574-16.108,3.849-22.326
							C153.878,405.771,153.005,404.019,151.686,402.346z"
                          />
                        </g>
                        <g>
                          <g>
                            <path
                              style={{ fill: "#263238" }}
                              d="M151.686,402.346c0,0,0.535,0.54,1.259,1.782c0.345,0.624,0.75,1.441,0.898,2.497
								c0.085,0.53,0.027,1.101-0.001,1.699c-0.036,0.602-0.081,1.243-0.151,1.919c-0.256,2.71-0.823,5.994-1.584,9.779
								c-0.734,3.788-1.732,8.079-2.516,12.865c-0.383,2.397-0.7,4.923-1.083,7.552c-0.391,2.626-0.833,5.369-1.652,8.132
								c-0.822,2.734-2.035,5.6-4.32,7.75c-0.523,0.581-1.207,0.982-1.831,1.462c-0.69,0.411-1.439,0.712-2.204,0.928
								c-1.532,0.44-3.121,0.675-4.729,0.835c-6.443,0.603-13.225,0.33-20.224-0.078c-7.002-0.448-14.236-1.127-21.629-2.035
								l-0.268-0.033l0.044-0.268c0.067-0.404,0.14-0.843,0.212-1.282c0.953-5.748,1.888-11.39,2.797-16.874l0.036-0.216
								l0.219,0.001c10.678,0.034,20.798,0.066,30.076,0.096l-0.235,0.22c0.604-9.133,1.38-17.529,2.283-24.977
								c0.23-1.862,0.469-3.662,0.719-5.402c0.172-0.866,0.367-1.724,0.614-2.541c0.162-0.397,0.323-0.79,0.482-1.179
								c0.083-0.193,0.151-0.392,0.246-0.577l0.32-0.533c1.651-2.862,4.187-4.835,6.751-5.779c2.568-0.979,5.092-1.09,7.192-0.767
								c2.102,0.391,3.772,1.164,5.005,1.962c1.204,0.837,2.054,1.592,2.526,2.189c0.241,0.275,0.428,0.488,0.568,0.647
								C151.63,402.266,151.691,402.342,151.686,402.346c-0.005,0.004-0.076-0.064-0.211-0.202
								c-0.146-0.152-0.341-0.356-0.593-0.619c-0.488-0.577-1.348-1.308-2.55-2.116c-1.231-0.769-2.888-1.509-4.958-1.873
								c-2.07-0.296-4.548-0.164-7.056,0.814c-2.506,0.944-4.966,2.889-6.559,5.685c-1.722,2.78-1.765,6.424-2.289,10.111
								c-0.87,7.441-1.619,15.837-2.201,24.958l-0.014,0.221l-0.221,0c-9.278-0.016-19.398-0.033-30.076-0.051l0.254-0.215
								c-0.907,5.484-1.84,11.127-2.791,16.875c-0.073,0.439-0.145,0.878-0.212,1.282l-0.224-0.301
								c7.377,0.906,14.607,1.59,21.595,2.044c6.984,0.415,13.758,0.696,20.144,0.11c3.147-0.343,6.414-0.784,8.498-3.08
								c2.203-2.058,3.403-4.847,4.223-7.543c0.817-2.722,1.269-5.449,1.669-8.067c0.393-2.622,0.721-5.149,1.117-7.552
								c0.811-4.806,1.833-9.081,2.594-12.865c0.788-3.776,1.382-7.046,1.668-9.742c0.078-0.673,0.13-1.311,0.174-1.91
								c0.036-0.602,0.101-1.159,0.026-1.669c-0.127-1.026-0.509-1.84-0.836-2.466c-0.337-0.626-0.646-1.076-0.855-1.375
								C151.79,402.504,151.686,402.346,151.686,402.346z"
                            />
                          </g>
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            style={{ fill: "#263238" }}
                            d="M177.993,434.566c-0.076,0.004-0.193-1.203-0.394-3.148c-0.2-1.945-0.502-4.629-0.937-7.578
							c-0.437-2.95-0.924-5.606-1.295-7.526c-0.37-1.92-0.607-3.109-0.533-3.127c0.066-0.016,0.429,1.142,0.893,3.049
							c0.464,1.906,1.011,4.564,1.45,7.528c0.437,2.963,0.682,5.666,0.789,7.625C178.074,433.348,178.061,434.562,177.993,434.566z"
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        style={{ fill: "#455A64" }}
                        d="M13.89,377.149c0,0,39.815-11.616,39.815-10.955c0,0.069,0.835,0.419,0.869,1.097
					c0.296,5.835,0.904,31.624,0.904,31.624l-41.474-6.932l-2.185-13.229L13.89,377.149z"
                      />
                    </g>
                    <g>
                      <polygon
                        style={{ fill: "#EBEBEB" }}
                        points="59.802,452.508 66.031,446.829 62.915,381.119 13.3,378.134 10.752,381.606 
					18.452,442.092 				"
                      />
                    </g>
                    <g>
                      <polygon
                        style={{ fill: "#455A64" }}
                        points="13.3,383.703 54.766,387.182 58.83,454.114 18.452,444.319 				"
                      />
                    </g>
                    <g>
                      <polygon
                        style={{ fill: "#E0E0E0" }}
                        points="13.3,378.134 57.586,369.98 59.596,372.16 59.8,380.823 26.193,379.292 				"
                      />
                    </g>
                    <g>
                      <path
                        style={{ fill: "#263238" }}
                        d="M14.013,442.303l-5.865-60.477c0,0,0.332-3.93,5.742-4.677c0,0-2.987,2.668-0.59,6.554
					l5.152,60.617C18.452,444.319,16.174,443.906,14.013,442.303z"
                      />
                    </g>
                    <g>
                      <g>
                        <path
                          style={{ fill: "#263238" }}
                          d="M12.45,379.292c0-0.018,0.177-0.032,0.514-0.041c0.396-0.005,0.882-0.01,1.472-0.017
						c1.357,0.01,3.186,0.023,5.412,0.04c4.569,0.06,10.882,0.192,17.851,0.426c6.972,0.235,13.279,0.54,17.841,0.824
						c2.281,0.141,4.126,0.271,5.4,0.378c0.587,0.054,1.071,0.099,1.466,0.135c0.335,0.037,0.51,0.065,0.509,0.082
						c-0.001,0.018-0.179,0.025-0.515,0.022c-0.396-0.01-0.882-0.023-1.471-0.038c-1.355-0.053-3.183-0.125-5.407-0.213
						c-4.637-0.174-10.908-0.41-17.84-0.671c-6.931-0.225-13.203-0.428-17.842-0.579c-2.224-0.084-4.053-0.154-5.408-0.205
						c-0.589-0.032-1.074-0.059-1.47-0.081C12.626,379.331,12.45,379.31,12.45,379.292z"
                        />
                      </g>
                    </g>
                    <g>
                      <g>
                        <path
                          style={{ fill: "#263238" }}
                          d="M17.465,379.292c-0.024-0.142,9.36-1.841,20.956-3.795c11.601-1.955,21.021-3.424,21.045-3.282
						c0.024,0.142-9.358,1.841-20.958,3.795C26.911,377.964,17.489,379.434,17.465,379.292z"
                        />
                      </g>
                    </g>
                    <g>
                      <g>
                        <path
                          style={{ fill: "#263238" }}
                          d="M62.802,381.141c0.101,0.138-1.829,1.511-4.198,3.141c-2.382,1.625-3.92,3.377-4.03,3.293
						c-0.051-0.039,0.256-0.553,0.899-1.269c0.639-0.718,1.638-1.605,2.829-2.448C60.704,382.205,62.732,381.048,62.802,381.141z"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </svg>

          </>
        </div>
      </div>
    </div>
  );
}
