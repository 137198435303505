import { api_test, api_get_users } from "@/lib/chat";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import * as React from "react";
import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { MoreHorizontal, ArrowLeft, ArrowRight, Search } from "lucide-react";

import { CircleDashed } from "lucide-react";

import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import AdminSidebar from "@/components/Sidebar/AdminSidebar";
import { User, PaginationInfo } from "@/types/types";
import { api_delete_user, api_search_users, api_get_options, api_set_option } from "@/lib/chat";

import { Input } from "@/components/ui/input";




function AdminUsers() {
  const [loading, setLoading] = useState(false);

  const columns: ColumnDef<User>[] = [
    {
      accessorKey: "name",
      header: ({ column }) => {
        return (
          <div
            className="inline-flex items-center"
          // onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            Nume
            {/* <ArrowUpDown className="ml-2 h-4 w-4" /> */}
          </div>
        );
      },

      cell: ({ row }) => <div className="">{row.getValue("name")}</div>,
    },
    {
      accessorKey: "email",
      header: ({ column }) => {
        return (
          <div
            className="inline-flex items-center"
          // onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            Email
            {/* <ArrowUpDown className="ml-2 h-4 w-4" /> */}
          </div>
        );
      },

      cell: ({ row }) => <div className="">{row.getValue("email")}</div>,
    },

    {
      accessorKey: "birthday",
      header: ({ column }) => {
        return (
          <div
            className="inline-flex items-center"
          // onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            Varsta
            {/* <ArrowUpDown className="ml-2 h-4 w-4" /> */}
          </div>
        );
      },
      cell: ({ row }) => {
        if (!row.getValue("birthday")) {
          return <div></div>;
        }
        const birthday = new Date(row.getValue("birthday"));
        const today = new Date();
        let age = today.getFullYear() - birthday.getFullYear();
        const m = today.getMonth() - birthday.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthday.getDate())) {
          age--;
        }
        return <div className="lowercase">{age}</div>;
      },
    },
    {
      accessorKey: "class",
      header: ({ column }) => {
        return (
          <div
            className="inline-flex items-center"
          // onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            Clasa
            {/* <ArrowUpDown className="ml-2 h-4 w-4" /> */}
          </div>
        );
      },
    },
    {
      accessorKey: "created_at",
      header: ({ column }) => {
        return (
          <div
            className="inline-flex items-center "
          // onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            Data inscrierii
            {/* <ArrowUpDown className="ml-2 h-4 w-4" /> */}
          </div>
        );
      },
      cell: ({ row }) => {
        return (
          <div>
            {new Intl.DateTimeFormat("ro-RO", {
              year: "numeric",
              month: "long",
              day: "numeric",
            }).format(new Date(row.getValue("created_at")))}
          </div>
        );
      },
    },
    {
      id: "actions",
      enableHiding: false,
      cell: ({ row }) => {
        const user = row.original;

        function deleteUser(user: string) {
          let text = `Sigur vrei sa stergi userul ${user.name} ?`;
          if (confirm(text) == true) {
            setLoading(true);
            api_delete_user(user.user_id, (data, error) => {
              if (data) {
                alert("User sters cu succes!");
                window.location.reload();
              } else {
                alert("Eroare la stergere user!");
                setLoading(false);
              }
            });
          } else {
            text = "You canceled!";
          }
        }
        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="h-8 w-8 p-0">
                <span className="sr-only">Open menu</span>
                <MoreHorizontal className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuLabel>Actions</DropdownMenuLabel>
              <DropdownMenuItem
                className="cursor-pointer"
                onClick={() => navigator.clipboard.writeText(user.user_id)}
              >
                Copy user ID
              </DropdownMenuItem>
              <DropdownMenuSeparator />
              <DropdownMenuItem>
                <div
                  className="text-destructive cursor-pointer"
                  onClick={() => deleteUser(user)}
                >
                  Delete User
                </div>
              </DropdownMenuItem>
              <DropdownMenuItem className="cursor-pointer">
                <Link to={`/admin/users/${user.user_id}`}>View User</Link>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        );
      },
    },
  ];

  const navigate = useNavigate();
  const [usersData, setUsersData] = useState<PaginationInfo<User>>({
    items: [],
    total_items: 0,
    current_page: 1,
    items_per_page: 0,
    total_pages: 0,
  });
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [resultsFromSearch, setResultsFromSearch] = useState(false);

  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>({});
  const [rowSelection, setRowSelection] = React.useState({});

  const [segment, setSegment] = useState("all");
  const [options, setOptions] = useState({});

  const [segmentOptions, setSegmentOptions] = useState([]);

  useEffect(() => {

    api_get_options((data, error) => {
      setOptions(data);
    });
  }, []);

  useEffect(() => {
    setSegmentOptions([
      { label: "Toți", value: "all", tooltip: "Toți utilizatorii." },
      { label: "Neactivați", value: "never_active", tooltip: `Utilizatorii care au trimis mai putin de ${options.ACTIVE_USER_THRESHOLD} mesaje.` },
      { label: "Abandon", value: "abandon", tooltip: `Utilizatorii care au trimis cel putin ${options.ACTIVE_USER_THRESHOLD} mesaje. Dar care nu au mai trimis mesaje in ultimele ${options.ABANDON_USER_DAYS} zile.` },
      { label: "Activi", value: "active", tooltip: `Utilizatorii care au trimis cel putin ${options.ACTIVE_USER_THRESHOLD} mesaje. Care au trimis cel putin un mesaj in ultimele ${options.ABANDON_USER_DAYS} zile.` },
    ]);
  }, [options]);




  const table = useReactTable({
    data: usersData.items,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  });

  api_test((data, error) => {
    if (data && data.status === "success") {
      return;
    } else {
      navigate("/login");
    }
  });

  function getUsers(page, segment) {
    api_get_users(page, segment, (data, error) => {
      if (data && data.total_items) {
        setUsersData(data);
      } else {
        console.log("error", error);
      }
    });
  }

  useEffect(() => {
    getUsers(page, segment);
  }, [page, segment]);

  useEffect(() => {
    api_get_options((data, error) => {
      const perPage = parseInt(data.ADMIN_USERS_PER_PAGE);
      table.setPageSize(perPage);
    });
  }, []);

  useEffect(() => {
    if (window.location.pathname === "/admin") {
      navigate("/admin/users");
    }
  }, [])

  function handleSearch(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    if (searchValue === "") {
      setResultsFromSearch(false);
      getUsers(page, segment);
      return;
    }
    setResultsFromSearch(true);
    setSegment("all");

    api_search_users(searchValue, (data: any, error: any) => {
      if (data && data.total_items) {
        setUsersData(data);
      } else {
        console.log("error", error);
      }
    });
  }

  return (
    <div className="flex">
      <AdminSidebar />
      <div className="mt-7 flex-1">
        <div className="container">
          <div className="flex justify-between">
            <h1 className="text-4xl font-bold">Users</h1>
            <div className="flex gap-2 items-center">
              <Input
                placeholder="Cauta"
                value={searchValue}
                onChange={(event) => setSearchValue(event.target.value)}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    handleSearch(event);
                  }
                }}
                className="max-w-sm"
              />
              <Button onClick={handleSearch}><Search /></Button>
            </div>
          </div>
          <div className="w-full">
            <div className="flex items-center py-4">

              {/* <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="outline" className="ml-auto">
                    Coloane <ChevronDown className="ml-2 h-4 w-4" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end">
                  {table
                    .getAllColumns()
                    .filter((column) => column.getCanHide())
                    .map((column) => {
                      return (
                        <DropdownMenuCheckboxItem
                          key={column.id}
                          className="capitalize"
                          checked={column.getIsVisible()}
                          onCheckedChange={(value) =>
                            column.toggleVisibility(!!value)
                          }
                        >
                          {column.id}
                        </DropdownMenuCheckboxItem>
                      );
                    })}
                </DropdownMenuContent>
              </DropdownMenu> */}
            </div>
            <div className="flex justify-between">
              <div className="flex items-center gap-2">
                <Input
                  type="number"
                  value={table.getState().pagination.pageSize}
                  onChange={(event) => {
                    const newValue = parseInt(event.target.value);
                    table.setPageSize(newValue);
                    api_set_option('ADMIN_USERS_PER_PAGE', newValue, (data, error) => {
                      console.log(data);
                    });
                  }}
                  className="w-[60px]"
                ></Input>
                <div>useri per pagină</div>
              </div>
              <div className="flex items-center gap-2">
                {segmentOptions.map((option) => (
                  <Button variant="link"
                    className={segment == option.value ? 'text-x-blue-700' : ''}
                    onClick={(e) => setSegment(option.value)}
                    title={option.tooltip}
                  >{option.label}</Button>
                ))}


              </div>
              <div className="flex items-center space-x-2 py-4">
                {/* <div className="flex-1 text-sm text-muted-foreground">
                {table.getFilteredSelectedRowModel().rows.length} of{" "}
                {table.getFilteredRowModel().rows.length} row(s) selected.
              </div> */}
                <div className={`space-x-2 flex items-center gap-2 ${resultsFromSearch ? 'hidden' : ''}`}>
                  <Button
                    variant="outline"
                    size="sm"
                    onClick={() => table.previousPage()}
                    disabled={!table.getCanPreviousPage()}
                  >
                    <ArrowLeft />
                  </Button>
                  <div>{table.getState().pagination.pageIndex + 1}</div>
                  <Button
                    variant="outline"
                    size="sm"
                    onClick={() => table.nextPage()}
                    disabled={!table.getCanNextPage()}
                  >
                    <ArrowRight />
                  </Button>
                </div>
              </div>
            </div>
            <div className={`${loading ? "" : "hidden"}`}>
              <div className="flex items-center justify-center h-24">
                <CircleDashed className="animate-spin h-6 w-6 mx-auto" />
              </div>

            </div>
            <div className={`rounded-md border ${loading ? "hidden" : ""}`}>
              <Table>
                <TableHeader>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <TableRow key={headerGroup.id}>
                      {headerGroup.headers.map((header) => {
                        return (
                          <TableHead key={header.id}>
                            {header.isPlaceholder
                              ? null
                              : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                          </TableHead>
                        );
                      })}
                    </TableRow>
                  ))}
                </TableHeader>
                <TableBody>
                  {table.getRowModel().rows?.length ? (
                    table.getRowModel().rows.map((row) => (
                      <TableRow
                        key={row.id}
                        data-state={row.getIsSelected() && "selected"}
                      >
                        {row.getVisibleCells().map((cell) => (
                          <TableCell key={cell.id}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell
                        colSpan={columns.length}
                        className="h-24 text-center"
                      >
                        No results.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminUsers;
