import { Message } from "@/types/types";
import { useStore } from "@/store/store";
import { useState, useEffect } from "react";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import LatexMarkdown from "../ui/LatexMarkdown";
import { ThumbsDown, Volume2, CircleDashed } from 'lucide-react';
import { Button } from "@/components/ui/button";


import { api_speak_message, api_set_feedback_for_message } from "@/lib/chat";
import { set } from "date-fns";

function ChatMessage({ messageData }: { messageData: Message }) {
  const { type, message, images } = messageData;
  const filteredMessage = message.replace("SUDO ", '');

  const { threads, currentThreadIndex, settings, setSettings, userProfile } = useStore();
  const [speaking, setSpeaking] = useState(false);
  const [downvote, setDownvote] = useState(messageData.downvoted);

  const [isLastMessage, setIsLastMessage] = useState(false);
  const [messageNotSent, setMessageNotSent] = useState(false);

  useEffect(() => {
    if (threads[currentThreadIndex].messages.length > 0) {
      const lastMessage =
        threads[currentThreadIndex].messages[
        threads[currentThreadIndex].messages.length - 1
        ];
      if (lastMessage.id === messageData.id) {
        setIsLastMessage(true);
      } else {
        setIsLastMessage(false);
      }
    } else {
      setIsLastMessage(false);
    }
  }, [threads, currentThreadIndex, settings]);

  useEffect(() => {
    setMessageNotSent(isLastMessage && threads[currentThreadIndex].isError);
  }, [isLastMessage, threads, currentThreadIndex]);

  function classForMessageType(message: Message) {
    if (messageData.type === "human") {
      return "border-blue-100 bg-indigo-200 ml-auto md:max-w-[70%]";
    } else if (messageData.type === "tool") {
      if (messageData.name == "math_draw") {
        return "border-x-gray-200 bg-white md:max-w-[100%]";
      }
      if (userProfile?.is_admin && userProfile?.debug_enabled === true) {
        return "border-x-gray-200 bg-green-200 md:max-w-[70%]";
      } else {
        return "hidden";
      }
    } else {
      if (messageData.message.trim() === "") {
        return "hidden";
      }
      return "border-x-gray-200 bg-white md:max-w-[70%]";
    }
  }

  function classForMessagePart(message: Message) {
    if (message.type === "tool" && message.name == "math_draw") {
      return "hidden";
    }
    return "";
  }

  return (
    <div className="flex w-full relative group flex-wrap">
      <div
        className={`border rounded-3xl flex flex-col max-w-[calc(100%-20px)] gap-4 p-3 px-5 w-auto ${classForMessageType(messageData)} ${messageNotSent && "opacity-50"}`}
      >
        {type == "tool" && messageData.name != "math_draw" && (
          <div>TOOL: {messageData.name}</div>
        )}
        {type == "tool" && messageData.name == "math_draw" && (
          <div dangerouslySetInnerHTML={{ __html: messageData.message }}></div>
        )}

        {message && message.trim() !== "" && (
          <div className={classForMessagePart(messageData)}>
            <LatexMarkdown content={filteredMessage} />
          </div>
        )}
        {images && images.length > 0 && (
          <div className="flex flex-wrap gap-2 w-full max-w-[200px] ml-auto">
            {images.map((image, index) => (
              <div
                key={(image.id, +"image_" + index)}
                className={`relative flex-shrink-0 h-auto rounded-md w-full`}
              >
                {image.blob && (
                  <>
                    <Zoom>
                      <img
                        className="rounded-md w-full min-w-[100px]"
                        src={"data:image/png;base64," + image.blob}
                      />
                    </Zoom>
                  </>
                )}
                {image.url && !image.blob && (
                  <>
                    <Zoom>
                      <img
                        className="rounded-md min-w-[100px]"
                        src={image.url}
                      />
                    </Zoom>
                  </>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
      <div className={`${type == "ai" && message.length > 0 ? "" : "hidden"} opacity-0 group-hover:opacity-100 flex items-center w-full`}>
        <Button variant="iconish"
          size="icon"
          disabled={speaking || settings.isLoading || settings.isAudioMode || settings.isTalking || settings.isRecording}
          onClick={() => {
            setSpeaking(true);
            setSettings({
              isLoading: true
            });
            api_speak_message(message, () => {
              setSettings({
                isLoading: false,
                isTalking: true,
                bubbleText: "",
              });
            }, () => {
              console.log("message spoken");
              setSpeaking(false);
              setSettings({
                isTalking: false
              });
            });
          }}>
          <Volume2 className={`h-[28px] w-[28px] ${speaking ? "hidden" : ""}`} color="#aaaaaa" />
          <CircleDashed className={`animate-spin h-6 w-6 mx-auto ${!speaking ? "hidden" : ""}`} />
        </Button>
        <Button variant="iconish"
          size="icon"
          onClick={() => {
            const newState = !downvote;
            messageData.downvoted = newState;
            setDownvote(newState);
            api_set_feedback_for_message(messageData.id, newState, () => { });
          }}>
          <ThumbsDown className="h-[24px] mt-1"
            color="#aaaaaa"
            fill={downvote ? "#ccc" : "transparent"}></ThumbsDown>
        </Button>
      </div>
    </div>
  );
}

export default ChatMessage;
