import { AvatarRobot } from "../Robo/Avatar";
import { useStore } from "@/store/store";
import { Loading } from "../Robo/Loading";
import { TalkingFace } from "../Robo/TalkingFace";
import { AvatarError } from "../Robo/AvatarError";

import { useEffect, useState } from "react";
import { MenuIcon } from "lucide-react";
import { Button } from "../ui/button";
import ChoseProfessor from "../Main/ChoseProfessor";

function Navbar() {
  const { settings, setSidebarActive, sidebarActive, threads, currentThreadIndex } = useStore();
  const { isLoading, isTalking } = settings;

  const [isError, setIsError] = useState(false);

  useEffect(() => {
    setIsError(threads && threads[currentThreadIndex] && threads[currentThreadIndex].isError);
  }, [settings, threads, currentThreadIndex]);


  return (
    <div className="bg-white z-20 sticky top-0 w-full  px-4 py-7 md:px-7 h-[125px]">
      <div className="max-w-[670px] w-full mx-auto  flex items-center justify-between">
        <div className={isError ? "hidden" : ""}>
          {/* <pre>{JSON.stringify(settings, null, 2)}</pre> */}
          {isLoading ? <Loading /> : ""}
          {isTalking ? <TalkingFace /> : ""}
          {isTalking && (
            <div className="relative flex gap-[2px] -top-[22px] left-[36px] w-[18px] items-center h-[10px]">
              {Array.from({ length: 4 }, (_, i) => (
                <div
                  key={i + "_bar_play"}
                  id={`bar-play-${i + 0}`}
                  className=" h-[6px] flex-1 rounded-full"
                  style={{
                    backgroundColor: "#00ffff",
                  }}
                ></div>
              ))}
            </div>
          )}
          {!(isLoading || isTalking) ? <AvatarRobot /> : ""}
        </div>
        <div className={isError ? "" : "hidden"}>
          <AvatarError />
        </div>

        <div className="w-fit">
          <ChoseProfessor />
        </div>

        <Button
          variant="icon"
          className={`p-3 border-transparent ${
            sidebarActive && "invisible"
          } block md:hidden`}
          onClick={() => setSidebarActive(true)}
        >
          <MenuIcon />
        </Button>
      </div>
    </div>
  );
}

export default Navbar;
