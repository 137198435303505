import AdminSidebar from "@/components/Sidebar/AdminSidebar";
import {
  api_get_user,
  api_get_user_activity,
  api_test,
} from "@/lib/chat";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { User, PaginationInfo } from "@/types/types";
import ReactPaginate from "react-paginate";

import ChatMessage from "@/components/ChatMessage";
import { ChevronLeft, ChevronRight } from "lucide-react";

function AdminUser() {
  const [user, setUser] = useState<User[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [usersData, setUsersData] = useState({
    items: [],
    total_items: 0,
    current_page: 1,
    items_per_page: 0,
    total_pages: 0,
  });
  const { user_id } = useParams();

  useEffect(() => {
    api_get_user_activity(user_id, currentPage, (data, error) => {
      if (data && data.total_items) {
        console.log(data);
        setUsersData(data);
      } else {
        console.log("error", error);
        setUsersData({
          items: [],
          total_items: 0,
          current_page: 1,
          items_per_page: 0,
          total_pages: 0,
        });
      }
    });
  }, [user_id, currentPage]);

  useEffect(() => {
    api_get_user(user_id, (data, error) => {
      if (data && data.id) {
        setUser(data);
      }
    });
  }, []);

  const handlePageChange = (page) => {
    const selected = page.selected + 1;
    setCurrentPage(selected);
  };

  const extractBirthday = (date: string) => {
    const birthday = new Date(date);
    const today = new Date();
    let age = today.getFullYear() - birthday.getFullYear();
    const m = today.getMonth() - birthday.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthday.getDate())) {
      age--;
    }
    return age;
  };

  return (
    <div className="flex">
      <AdminSidebar />
      <div className="mt-7 flex-1">
        <div className="container">
          <div>
            <h1 className="text-4xl font-bold">{user && user.name}</h1>
            <div className="space-y-1 mt-2 text-md">
              {user && user.email && (
                <p className="text-md text-gray-800">Email: {user.email}</p>
              )}

              {user && user.class && (
                <p className="text-md text-gray-800">Clasa: {user.class}</p>
              )}

              {user && user.birthday && (
                <p className="text-md text-gray-800">
                  Varsta: {extractBirthday(user.birthday)}
                </p>
              )}
            </div>
          </div>
          <div className="w-full">
            <div className="flex-1 relative flex-grow overflow-auto scrollbar scrollbar-thumb-x-gray-200 scrollbar-w-[10px] scrollbar-thumb-rounded-lg">
              <div className=" flex flex-1 px-0 md:px-7 flex-col h-full">
                <div className="mx-auto max-w-[670px] w-full flex-1 flex flex-wrap rounded-lg border border-gray-300 border-b-0 p-7">
                  <div className="w-full relative px-4 md:px-0 space-y-3 pb-14 pt-10">
                    {usersData &&
                      usersData.items &&
                      usersData.items.map((message) => {
                        return (
                          <ChatMessage key={message.id} message={message} />
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="sticky bg-white bottom-0">
            <div className="mx-auto max-w-[670px] w-full navigation">
              {usersData && (
                <ReactPaginate
                  breakLabel="..."
                  nextLabel={
                    <div>
                      Next
                      <ChevronRight width={15} />
                    </div>
                  }
                  onClick={handlePageChange}
                  onPageChange={handlePageChange}
                  pageRangeDisplayed={5}
                  pageCount={usersData.total_pages}
                  previousLabel={
                    <div>
                      <ChevronLeft width={15} />
                      Previous
                    </div>
                  }
                  renderOnZeroPageCount={null}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminUser;
