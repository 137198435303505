import { useStore } from "@/store/store";
import { Link } from "react-router-dom";
import { Button } from "@/components/ui/button";
import UserAvatar from "./User/UserAvatar";
import { useState, useRef, useEffect } from "react";
import { X, PenLine, Trash, MoreHorizontal } from "lucide-react";
import { api_delete_thread, api_update_thread, clearNewTaskHintTimeout } from "@/lib/chat";
import { useNavigate, useParams } from "react-router-dom";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Input } from "../ui/input";

function Sidebar() {
  const {
    userProfile,
    setDialogInactiveAccount,
    threads,
    setCurrentThread,
    setThreads,
    currentThread,
    sidebarActive,
    setSidebarActive,
    addEmptyThread,
    updateThread,
  } = useStore();
  const navigate = useNavigate();
  const [renameThread, setRenameThread] = useState(false);
  const editableRef = useRef(null);
  useEffect(() => {
    console.log("....");
    if (editableRef.current) {
      const div = editableRef.current;
      const t = threads.find((t) => t.id == renameThread);
      div.innerHTML = t.name;

      setTimeout(() => {
        // select the whole range of the div
        const range = document.createRange();
        range.selectNodeContents(div);
        const sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
        // I want to set the cursor at the end of the text
        div.focus();
      }, 500);
    }
  }, [renameThread]);
  const { threadId: slug } = useParams();

  useEffect(() => {
    setRenameThread(false);
  }, [currentThread]);

  function handleRenameThread(thread, newTitle) {
    api_update_thread(
      {
        id: thread.id,
        name: newTitle,
      },
      (data, error) => {
        if (data) {
          updateThread(thread.id, { name: newTitle });
          setRenameThread(false);
        }
      }
    );
  }

  return (
    <>
      <aside
        className={`flex flex-col fixed md:sticky top-0 z-30 w-full max-w-[calc(100%-80px)] md:max-w-full md:w-64 h-full bg-x-dark-950 text-white max-h-screen overflow-hidden p-3 md:p-3 md:translate-x-0 transition-all ${sidebarActive ? "translate-x-0" : "-translate-x-full"
          }`}
      >
        <div className="pb-3 w-full">
          <Link
            to="/"
            onClick={(e) => {
              if (!userProfile?.account_licensed) {
                e.preventDefault();
                setDialogInactiveAccount(true);
                return;
              }
              clearNewTaskHintTimeout();

              gtag('event', 'new_conversation', {
                'event_category': 'Engagement',
                'event_label': 'Conversation Started',
                'value': 1
              });
              addEmptyThread();
              setSidebarActive(false);
            }}
          >
            <Button
              className="w-full justify-start bg-x-gray-950/80 gap-3"
              variant="ghost"
            >
              <PenLine className="h-5" />
              Conversație nouă
            </Button>
          </Link>
        </div>
        <div className="-mr-1 pr-1 mt-3 flex-grow scrollbar scrollbar-thumb-x-gray-900 scrollbar-w-[10px] scrollbar-rounded-md overflow-auto ">
          {threads
            .reduce(
              (acc, curr, index, src) => {
                if (curr.id === "label" && !acc.found) {
                  acc.found = true; // Mark that we've updated an object
                  acc.result.push({ ...curr, conversation_id: "margin" });
                } else {
                  acc.result.push(curr);
                }
                return acc;
              },
              { result: [], found: false }
            )
            .result.map((thread) => {
              if (thread.id == "label") {
                return (
                  <div
                    key={thread.name}
                    className={`${thread.conversation_id != "margin" && "mt-10"
                      }  pl-5  pb-3 sticky z-10 -top-1 bg-x-dark-950 text-x-gray-400 text-xs`}
                  >
                    {thread.name}
                  </div>
                );
              }

              return (
                <div
                  key={thread.id}
                  className={
                    "group relative " +
                    (thread.name == "" || thread.wasSentinel ? "hidden" : "")
                  }
                >
                  <Link
                    className="block"
                    to={`/c/${thread.id}`}
                    onClick={() => {
                      setCurrentThread(thread.id);
                      setSidebarActive(false);
                      clearNewTaskHintTimeout();
                    }}
                  >
                    <Button
                      className={
                        "relative w-full justify-start h-10  group-hover:bg-x-gray-900/50 " +
                        (thread.id == slug &&
                          "bg-x-gray-900 group-hover:bg-x-gray-900 ")
                      }
                      variant="ghost"
                      type="button"
                    >
                      {renameThread == thread.id ? (
                        <form
                          onSubmit={(e) => {
                            e.preventDefault();
                            const formData = new FormData(e.target);
                            const newTitle = formData.get("newTitle");

                            if (newTitle == "") {
                              return;
                            }

                            handleRenameThread(thread, newTitle);
                          }}
                        >
                          <input
                            className="bg-transparent font-normal overflow-hidden break-words"
                            name="newTitle"
                            autoFocus={true}
                            onBlur={(e) => {
                              const newTitle = e.target.value;

                              if (newTitle == "") {
                                return;
                              }

                              handleRenameThread(thread, newTitle);
                            }}
                            onKeyDown={(e) => {
                              // Example: Prevent the default action for the space key
                              // Note: This is generally NOT recommended for input fields
                              if (e.key === " ") {
                                e.preventDefault();
                                e.target.value = e.target.value + " ";
                                // This would prevent spaces from being added to the input, which is usually undesirable
                              }

                              // Custom handling for other keys can be added here
                            }}
                          />

                        </form>
                      ) : (
                        <div className="overflow-hidden whitespace-nowrap font-normal overflow-ellipsis">
                          {thread.name != "" ? thread.name : thread.id}
                        </div>
                      )}
                    </Button>
                  </Link>
                  {renameThread !== thread.id &&
                    thread.owner_id !== "sentinel" && (
                      <div className="cursor-pointer text-white/70 opacity-0 group-hover:opacity-100 absolute right-1 top-1 bg-x-gray-950 hover:bg-x-gray-900 transition flex items-center justify-center w-8 h-8 rounded-sm hover:shadow-[0_0_0_4px_rgba(255,255,255,0.07)]">
                        <DropdownMenu>
                          <DropdownMenuTrigger>
                            <MoreHorizontal />
                          </DropdownMenuTrigger>
                          <DropdownMenuContent>
                            <DropdownMenuItem
                              className="text-red-500 hover:!text-red-500 gap-2"
                              onClick={() => {
                                if (currentThread == thread.id) {
                                  addEmptyThread();
                                  navigate("/");
                                }
                                api_delete_thread(thread.id, (data, error) => {
                                  if (data) {
                                    setThreads(
                                      threads.filter((t) => t.id != thread.id)
                                    );
                                  }
                                });
                              }}
                            >
                              <Trash width={15} />
                              Delete
                            </DropdownMenuItem>
                            <DropdownMenuItem
                              // Rename thread name on click
                              onClick={() => {
                                setRenameThread(thread.id);
                              }}
                              className="gap-2"
                            >
                              <PenLine width={15} />
                              Rename
                            </DropdownMenuItem>
                          </DropdownMenuContent>
                        </DropdownMenu>
                      </div>
                    )}
                </div>
              );
            })}
        </div>
        <div className="mt-auto flex-1 flex items-end flex-col-reverse">
          <UserAvatar />
        </div>
      </aside>
      <div
        onClick={() => setSidebarActive(false)}
        className={`fixed bg-black/50 top-0 bottom-0 right-0 left-0 z-[25] block md:hidden  opacity-0 ${sidebarActive ? "visible opacity-100" : "invisible"
          } transition-all`}
      >
        <Button
          variant={"icon"}
          className="text-white border-2 p-3 absolute top-9 right-4"
        >
          <X />
        </Button>
      </div>
    </>
  );
}

export default Sidebar;
