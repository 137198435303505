import { AlertCircle, Camera, Mic, MicOff, Send, Trash, X } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { useStore } from "@/store/store";
import { useRef, useEffect } from "react";
import ImageCrop from "./ImageCrop";
import { useNavigate } from "react-router-dom";
import {
  api_create_thread,
  handleNewMessages,
  stopRecording,
  startRecording,
} from "@/lib/chat";
import { Message, Thread, ImageType } from "@/types/types";
import { v4 as uuid } from "uuid";
import { useState, useCallback } from "react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import Webcam from "react-webcam";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
} from "@/components/ui/dialog";
import { Alert, AlertDescription, AlertTitle } from "../ui/alert";
import { isAndroid } from "react-device-detect";

function ChatTextarea() {
  const imageInputRef = useRef<HTMLInputElement>(null);
  const imageInputRefCapture = useRef<HTMLInputElement>(null);
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const webcamRef = useRef(null);
  const streamRef = useRef(null);
  const [openCamera, setOpenCamera] = useState(false);
  const [imgSrc, setImgSrc] = useState(null);
  const [errorCamera, setErrorCamera] = useState(false);

  const verifyCameraAccess = async () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: true })
      .then(function (stream) {
        if (
          stream.getVideoTracks().length > 0 &&
          stream.getAudioTracks().length > 0
        ) {
          setErrorCamera(false);
        } else {
          setErrorCamera(true);
        }
      })
      .catch(function (error) {
        setErrorCamera(true);
      });
  };

  const [usedHint, setUsedHint] = useState("");

  const [isFocused, setIsFocused] = useState(false);
  const {
    userProfile,
    setDialogInactiveAccount,
    settings,
    setSettings,
    setPrompt,
    prompt,
    threads,
    updateThread,
    currentThread,
    currentThreadIndex,
    currentProfesor,
    addImageToPrompt
  } = useStore();
  const navigate = useNavigate();
  const { setSelectedImage } = settings;
  const barColors = ["#3B82F6", "#F43F5E", "#EAB308", "#22C55E"];

  const handleImageUpload = () => {
    if (!userProfile?.account_licensed) {
      setDialogInactiveAccount(true);
      return;
    }

    gtag('event', 'camera_click', {
      'event_category': 'Interaction',
      'event_label': 'Button Press',
      'value': 1
    });
    if (imageInputRef.current) {
      imageInputRef.current.click(); // Open file input dialog
    }
    if (settings.isAudioMode) {
      setSettings({
        ...settings,
        isRecording: false,
        discardNextAudioPrompt: true,
      });
    }
  };

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImgSrc(imageSrc);
    setOpenCamera(false);
    setSelectedImage(imageSrc);

    // Oprirea stream-ului video de la webcam
    console.log(webcamRef.current);
    if (
      webcamRef.current &&
      webcamRef.current.video &&
      webcamRef.current.video.srcObject
    ) {
      const tracks = webcamRef.current.video.srcObject.getTracks();
      tracks.forEach((track) => track.stop());
    }
  }, [webcamRef]);

  const handleImageUploadCapture = () => {
    if (imageInputRef.current) {
      imageInputRefCapture.current.click(); // Open file input dialog
    }
    if (settings.isAudioMode) {
      setSettings({
        ...settings,
        isRecording: false,
        discardNextAudioPrompt: true,
      });
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    if (file) {
      const file = e.target.files[0];
      console.log(file);
      const reader = new FileReader();
      reader.onload = function (e) {
        setSelectedImage(e.target.result as string);
      };
      reader.readAsDataURL(file);
    }
    e.target.value = "";
  };

  useEffect(() => {
    if (usedHint && usedHint.length > 0) {
      setSettings({ ...settings, showNewTaskHint: false });
      gtag('event', 'sent_text_message', {
        'event_category': 'Messaging',
        'event_label': 'Sent Text',
        'value': 1
      });
      gtag('event', 'use_hint', {
        'event_category': 'Messaging',
        'event_label': 'Use Hint',
        'value': 1
      });
      const newMessage: Message = {
        type: "human",
        message: `SUDO ${usedHint}`,
        id: uuid(),
      };
      const currentThread = threads[currentThreadIndex];

      const newThread = {
        ...currentThread,
        messages: [...currentThread.messages, newMessage],
      };
      updateThread(currentThread.id, newThread);

      handleNewMessages(
        newThread,
        updateThread,
        setSettings,
        settings
      );
      setUsedHint("");
    }
  }, [usedHint]);


  useEffect(() => {
    const getMedia = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        streamRef.current = stream;
        console.log("Stream started");
      } catch (error) {
        console.error("Error accessing camera:", error);
      }
    };

    if (openCamera) {
      getMedia();
    } else {
      if (streamRef.current) {
        const tracks = streamRef.current.getTracks();
        console.log("Stopping stream");
        tracks.forEach((track) => {
          console.log(`Stopping track: ${track.label}`);
          track.stop();
        });
        streamRef.current = null;
        console.log("Stream stopped");
      }
    }

    return () => {
      if (streamRef.current) {
        console.log("Cleanup: Stopping stream");
        streamRef.current.getTracks().forEach((track) => {
          console.log(`Cleanup: Stopping track: ${track.label}`);
          track.stop();
        });
        streamRef.current = null;
      }
    };
  }, [openCamera]);

  function handlePromptSubmit() {
    if (settings.isTalking) {
      return;
    }

    if (!prompt.message) {
      if (!prompt.images || prompt.images.length == 0) {
        alert("Trebuie sa trimiti un mesaj sau o poza.");
        return;
      }
    }

    if (threads[currentThreadIndex].owner_id === "sentinel") {
      const bot_id = currentProfesor?.id || "";

      api_create_thread(bot_id, currentThread, (data, error) => {
        if (data) {
          const newThread = {
            ...data,
            messages: [],
            bot_id,
            wasSentinel: true,
            sendCurrentMessage,
          };
          updateThread(currentThread || "", newThread);
          // use react router to switch from / to /c/:threadId
          navigate(`/c/${newThread.id}`);

          sendCurrentMessage(threads[currentThreadIndex]);
        } else {
          console.error("Error creating thread:", error);
          updateThread(currentThread, {
            isError: true,
          });
        }
      });
    } else {
      if (currentThreadIndex !== -1) {
        sendCurrentMessage(threads[currentThreadIndex]);
      }
    }
  }

  // useEffect(() => {
  //   if (threads && threads[currentThreadIndex] && threads[currentThreadIndex].sendCurrentMessage) {
  //     sendCurrentMessage(threads[currentThreadIndex]);
  //   }
  // }, [threads]);

  function handleAudioMode() {
    console.log("handleAudioMode");
    if (settings.isAudioMode) {
      if (settings.isRecording) {
        setSettings({
          ...settings,
          isAudioMode: false,
          isRecording: false,
          discardNextAudioPrompt: true,
        });
      } else {
        setSettings({
          ...settings,
          isAudioMode: false,
          isRecording: false,
        });
      }
      return;
    }

    console.log("isAudioMode");
    setSettings({
      ...settings,
      isAudioMode: true,
      isRecording: !settings.isLoading && !settings.isTalking,
    });
  }

  useEffect(() => {
    if (settings.isRecording) {
      startRecording(setSettings, settings, () => {
        if (settings.isAudioMode) {
          setSettings({ ...settings, isRecording: false });
          return;
        }
      });
    } else {
      stopRecording(
        setSettings,
        updateThread,
        threads[currentThreadIndex],
        settings,
        setPrompt,
        currentProfesor,
        currentThreadIndex,
        threads,
        navigate
      );
      setSettings({ ...settings, isRecording: false });
    }
  }, [settings.isRecording]);

  useEffect(() => {
    if (prompt.autoSend) {
      handlePromptSubmit();
    }
    if (inputRef && inputRef.current) {
      inputRef.current.style.height = "52px"; // Set height to auto
      inputRef.current.style.height = `${inputRef.current.scrollHeight + 2}px`;
    }
  }, [prompt, inputRef]);

  function sendCurrentMessage(currentThread: Thread) {
    const newMessage: Message = {
      type: "human",
      message: prompt.message || "",
      images: prompt.images,
      id: uuid(),
    };

    if (newMessage?.images?.length == 0) {
      if ("images" in newMessage) {
        delete newMessage.images;
      }
    }
    const newThread: Thread = {
      ...currentThread,
      messages: [...currentThread.messages, newMessage],
      sendCurrentMessage: false,
    };
    updateThread(currentThread.id, newThread);

    // Reset the prompt state
    setPrompt({ message: "", images: [], type: "human" });
    //setSettings({ ...settings, isLoading: true });

    handleNewMessages(newThread, updateThread, setSettings, settings);
  }

  function handlePromptChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    setPrompt({ ...prompt, message: e.target.value });
  }

  function handleKeyDown(e: React.KeyboardEvent<HTMLTextAreaElement>) {
    if (
      e.key === "Enter" &&
      !e.shiftKey && // Check if the Shift key is not pressed
      prompt?.message.trim().length !== 0 &&
      !settings.isLoading
    ) {
      e.preventDefault();

      if (!userProfile?.account_licensed) {
        setTimeout(() => {
          setDialogInactiveAccount(true);
        }, 250);

        return;
      }

      gtag('event', 'sent_text_message', {
        'event_category': 'Messaging',
        'event_label': 'Sent Text',
        'value': 1
      });
      handlePromptSubmit();
    }
  }

  const handlePaste = (event) => {
    var items = (event.clipboardData || event.originalEvent.clipboardData).items;
    for (let index in items) {
      var item = items[index];
      if (item.kind === 'file') {
        var blob = item.getAsFile();
        var reader = new FileReader();
        reader.onload = function (event) {
          const base64Image = event.target.result.split(";base64,").pop();
          const newImage: ImageType = {
            blob: base64Image,
            id: uuid(),
          };
          addImageToPrompt(newImage);
        };
        reader.readAsDataURL(blob);

      }
    }
  };


  const hints = [
    "Dă-mi un indiciu.",
    "Spune-mi cum se face pas cu pas.",
    "Unde greșesc?"
  ];

  return (
    <>
      <div className={`flex w-full justify-center py-3 gap-3 
      ${!settings.showNewTaskHint || settings.isLoading || settings.isTalking || settings.isAudioMode || settings.isRecording || settings.isTalking ? 'hidden' : ''}
      ${threads && threads[currentThreadIndex] && threads[currentThreadIndex].isError ? 'hidden' : ''}`}>
        {hints.map((hint) => (
          <Button variant="bubble"
            className="text-black bg-blue-100 border-gray-300 px-5 py-2 rounded-full"
            onClick={(e) => {
              setUsedHint(hint);
            }}>
            {hint}
          </Button>
        ))}
      </div>
      <div
        className={`border-0 !border-t  md:border border-gray-300 md:border-gray-200 rounded-md ${isFocused ? "md:ring-2 md:ring-x-blue-700" : ""
          } ${threads && threads[currentThreadIndex] && threads[currentThreadIndex].isError ? 'hidden' : ''}`}
      >
        <ImageCrop />
        {prompt.images && prompt.images.length > 0 && (
          <div className="flex gap-3 p-2 bg-white rounded-md">
            {prompt.images.map((image) => {
              return (
                <div
                  key={image.id}
                  className="relative flex-shrink-0 w-12 h-12 rounded-md"
                >
                  {image.blob && (
                    <>
                      <img
                        className="object-cover w-full h-full rounded-md"
                        src={"data:image/png;base64," + image.blob}
                      />
                      <Button
                        onClick={() => {
                          setPrompt({
                            ...prompt,
                            images: prompt.images.filter(
                              (i) => i.id !== image.id
                            ),
                          });
                        }}
                        variant={"icon"}
                        className="absolute justify-center text-white -top-2 cursor-pointer -right-2 bg-black rounded-full p-1 flex items-center h-6 w-6"
                      >
                        <X width={13} strokeWidth="4" />
                      </Button>
                    </>
                  )}
                  {!image.blob && image.url && (
                    <>
                      <img
                        className="object-cover w-full h-full"
                        src={image.url}
                      />
                      <div className="absolute top-0">
                        <X />
                      </div>
                    </>
                  )}
                </div>
              );
            })}
          </div>
        )}

        <input
          type="file"
          accept="image/*"
          style={{ display: "none" }}
          capture="environment"
          ref={imageInputRefCapture}
          onChange={handleFileChange}
        />
        <input
          type="file"
          accept="image/*"
          style={{ display: "none" }}
          ref={imageInputRef}
          onChange={handleFileChange}
        />

        <Dialog open={openCamera} onOpenChange={() => setOpenCamera(false)}>
          <DialogContent>
            <DialogHeader>
              <DialogDescription>
                {!errorCamera ? (
                  <div>
                    <div className="my-3 rounded-md overflow-hidden">
                      <Webcam
                        height={600}
                        width={600}
                        ref={webcamRef}
                        audio={false}
                        screenshotFormat="image/png"
                      />
                    </div>
                    <div className="text-center">
                      <Button
                        onClick={() => {
                          capture();
                          setOpenCamera(false);
                        }}
                      >
                        Capturează foto
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div className="mt-4">
                    <Alert variant="destructive" className="bg-red-50">
                      <AlertCircle className="h-4 w-4" />
                      <AlertTitle>Eroare</AlertTitle>
                      <AlertDescription>
                        Nu ai acces la camera. Verifică permisiunile browser-ului
                        și încearcă din nou.
                      </AlertDescription>
                    </Alert>
                  </div>
                )}
              </DialogDescription>
            </DialogHeader>
          </DialogContent>
        </Dialog>

        <div className="flex relative  w-full flex-wrap gap-2">
          <div className="flex gap-2 absolute left-2 bottom-2">
            {isAndroid ? (
              <DropdownMenu>
                <DropdownMenuTrigger>
                  <Button
                    type="button"
                    className="w-9 h-9 p-0 text-x-blue-950 border-x-gray-200 hover:border-x-blue-400"
                    variant="icon"
                  >
                    <Camera width={22} />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                  <DropdownMenuItem
                    className="block sm:hidden"
                    onClick={handleImageUploadCapture}
                  >
                    Fa o poza
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    className="hidden sm:block"
                    onClick={() => {
                      verifyCameraAccess();
                      setOpenCamera(true);
                    }}
                  >
                    Fa o poza
                  </DropdownMenuItem>
                  <DropdownMenuItem onClick={handleImageUpload}>
                    Incarca o poza
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            ) : (
              <Button
                className="w-9 h-9 p-0 text-x-blue-950 border-x-gray-200 hover:border-x-blue-400"
                variant={"icon"}
                onClick={handleImageUpload}
              >
                <Camera width={22} />
              </Button>
            )}

            <Button
              onClick={() => {
                if (!userProfile?.account_licensed) {
                  setDialogInactiveAccount(true);
                  return;
                }

                if (settings.isLoading && !settings.isAudioMode) {
                  return;
                }
                gtag('event', 'mic_on_click', {
                  'event_category': 'Interaction',
                  'event_label': 'Button Press',
                  'value': 1
                });
                handleAudioMode();
              }}
              className={
                (settings.isAudioMode ? "hidden " : "") +
                "w-9 h-9 p-0 text-x-blue-950 border-x-gray-200 hover:border-x-blue-400"
              }
              variant="icon"
              aria-label="Attach audio"
              disabled={settings.isLoading && !settings.isAudioMode}
            >
              <Mic strokeWidth={2} size={20} />
            </Button>
          </div>
          {settings.isAudioMode ? (
            <div
              className={`m-0 w-full max-w-sm mx-auto resize-none border-0 
                        bg-transparent py-[10px] pr-[105px] focus:ring-0 focus-visible:ring-0 
                        dark:bg-transparent md:py-3.5 md:pr-12 placeholder-black/50
                        dark:placeholder-white/50  
                        md:pl-[105px] rounded-md  pl-[59px] flex justify-center 
                        items-center gap-2 ${!settings.isRecording && "opacity-50 "
                }`}
              style={{
                maxHeight: 200,
                height: 52,
                overflowY: "hidden",
              }}
            >
              {Array.from({ length: 4 }, (_, i) => (
                <div
                  key={i + "_bar_rec"}
                  id={`bar-recording-${i + 0}`}
                  className="bg-x-dark-950 h-5 flex-1 rounded-full"
                  style={{
                    backgroundColor: barColors[i % barColors.length],
                  }}
                ></div>
              ))}
            </div>
          ) : (
            <Textarea
              ref={inputRef}
              onKeyDown={handleKeyDown}
              onChange={handlePromptChange}
              onPaste={handlePaste}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              value={prompt?.message}
              placeholder="Mesaj..."
              className="pl-24 pr-10 pt-[17px] focus-visible:ring-transparent focus-visible:ring-offset-0  border-0 flex-1 resize-none min-h-0 rounded-none md:rounded-md scrollbar scrollbar-w-[5px] scrollbar-thumb-rounded-md"
              style={{
                maxHeight: 200,
                height: 54,
                overflowY: "auto",
              }}
            // value={prompt?.message}
            ></Textarea>
          )}
          <div className="absolute right-2 bottom-2 flex gap-2">
            <Button
              className={
                (settings.isAudioMode ? "hidden" : "") +
                " w-9 h-9 p-0 bg-white text-x-blue-950 border-x-gray-200 hover:border-x-blue-400"
              }
              variant="icon"
              disabled={settings.isLoading}
              onClick={() => {
                if (userProfile?.account_licensed) {
                  gtag('event', 'sent_text_message', {
                    'event_category': 'Messaging',
                    'event_label': 'Sent Text',
                    'value': 1
                  });
                  handlePromptSubmit();
                } else {
                  setDialogInactiveAccount(true);
                }
              }}
            >
              <Send width={22} />
            </Button>
            <Button
              onClick={() => {
                if (settings.isLoading && !settings.isAudioMode) {
                  return;
                }
                gtag('event', 'mic_off_click', {
                  'event_category': 'Interaction',
                  'event_label': 'Button Press',
                  'value': 1
                });
                handleAudioMode();
              }}
              className={
                (settings.isAudioMode ? "" : "hidden ") +
                "w-9 h-9 p-0 text-x-blue-950 border-x-gray-200 hover:border-x-blue-400"
              }
              variant="icon"
              aria-label="Attach audio"
              disabled={settings.isLoading && !settings.isAudioMode}
            >
              <MicOff strokeWidth={2} size={20} />
            </Button>
            <Button
              className={
                (settings.isAudioMode ? "" : "hidden") +
                " w-9 h-9 p-0 bg-white text-x-blue-950 border-x-gray-200 hover:border-x-blue-400"
              }
              variant="icon"
              disabled={settings.isLoading}
              onClick={() => {
                setSettings({ ...settings, isRecording: false });
              }}
            >
              <Send width={22} />
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChatTextarea;
