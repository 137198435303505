import AdminSidebar from "@/components/Sidebar/AdminSidebar";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";

import { useEffect, useState } from "react";

import { api_get_options, api_set_option } from "@/lib/chat";

import { CircleDashed } from "lucide-react";
import { set } from "date-fns";

function AdminSettings() {
  const [limit, setLimit] = useState(50);
  const [hoursLimit, setHoursLimit] = useState(3);
  const [loading, setLoading] = useState(true);
  const [numberOfMessages, setNumberOfMessages] = useState(5);
  const [abandonUserDays, setAbandonUserDays] = useState(14);

  const [options, setOptions] = useState({});

  useEffect(() => {
    setLoading(true);
    api_get_options((data, error) => {
      setOptions(data);
      setLimit(data.LIMIT_NUMBER_OF_MESSAGES);
      setHoursLimit(data.LIMIT_NUMBER_OF_HOURS);
      setNumberOfMessages(data.ACTIVE_USER_THRESHOLD);
      setAbandonUserDays(data.ABANDON_USER_DAYS);
      setLoading(false);
    });
  }, []);

  function handleUpdateLimit(event) {
    const newLimit = parseInt(event.target.value);
    setLimit(newLimit);
    api_set_option("LIMIT_NUMBER_OF_MESSAGES", newLimit, (data, error) => {
      console.log(data);
    });
  }

  function handleUpdateHoursLimit(event) {
    const newHoursLimit = parseInt(event.target.value);
    setHoursLimit(newHoursLimit);
    api_set_option("LIMIT_NUMBER_OF_HOURS", newHoursLimit, (data, error) => {
      console.log(data);
    });
  }

  function handleUpdateNumberOfMessages(event) {
    const newNumberOfMessages = parseInt(event.target.value);
    setNumberOfMessages(newNumberOfMessages);
    api_set_option("ACTIVE_USER_THRESHOLD", newNumberOfMessages, (data, error) => {
      console.log(data);
    });
  }

  function handleUpdateAbandonUserDays(event) {
    const newAbandonUserDays = parseInt(event.target.value);
    setAbandonUserDays(newAbandonUserDays);
    api_set_option("ABANDON_USER_DAYS", newAbandonUserDays, (data, error) => {
      console.log(data);
    });
  }

  return (
    <div className="flex">
      <AdminSidebar />
      <div className="mt-7 flex-1">
        <div className={loading ? "w-full" : "hidden"}>
          <CircleDashed className="animate-spin h-6 w-6 mx-auto" />
        </div>
        <div className={"container " + (!loading ? "" : "hidden")} >
          <div className="flex justify-between items-center">
            <h1 className="text-4xl font-bold">Setări</h1>
          </div>
          <div className="mt-3 flex gap-2 items-center">
            <div>Limită</div>
            <div>
              <Input className="w-[60px]"
                value={limit}
                type="number"
                min={1}
                max={1000}
                onChange={handleUpdateLimit} />  </div>
            <div>mesaje la</div>
            <div>
              <Input className="w-[50px]"
                value={hoursLimit}
                type="number"
                min={1}
                max={24}
                onChange={handleUpdateHoursLimit}
              />
            </div>
            <div>{hoursLimit != 1 ? 'ore' : 'oră'}</div>
          </div>
          <div className="mt-3 flex gap-2 items-center">
            <div>Se cosideră un utilizator activ dacă a trimis minim de</div>
            <div>
              <Input className="w-[60px]"
                value={numberOfMessages}
                type="number"
                min={1}
                max={100}
                onChange={handleUpdateNumberOfMessages} />  </div>
            <div>mesaje către profesorul virtual în utimele</div>
            <div>
              <Input className="w-[60px]"
                value={abandonUserDays}
                type="number"
                min={1}
                max={100}
                onChange={handleUpdateAbandonUserDays} />  </div>
            <div>zile.</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminSettings;
