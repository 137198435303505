import { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { v4 as uuid } from "uuid";
import AdminSidebar from "@/components/Sidebar/AdminSidebar";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";
import {
  api_get_profesors,
  api_update_profesor,
  api_get_subjects,
  api_create_profesor,
  api_delete_profesor,
} from "@/lib/chat";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

const formSchema = z.object({
  id: z.string().uuid(),
  class: z.string(),
  subject: z.string(),
});

function Professors() {
  const [professors, setProfessors] = useState([]);
  const [newProfessorName, setNewProfessorName] = useState("");
  const [subject, setSubject] = useState("");

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      id: "",
      class: "",
      subject: "",
    },
  });

  useEffect(() => {
    api_get_profesors((data, error) => {
      console.log(data);
      if (data && data.status === "success") {
        setProfessors(data.profesors);
      } else {
        console.log("Fetch error:", error);
      }
    });
    api_get_subjects((data, error) => {
      if (data) {
        console.log(data);
        setSubject(data);
      } else {
        console.log("Fetch error:", error);
      }
    });
  }, []);

  const handleDeleteProfessor = (id) => {
    api_delete_profesor(id, (data, error) => {
      if (data) {
        api_get_profesors((data, error) => {
          if (data && data.status === "success") {
            setProfessors(data.profesors);
          } else {
            console.log("Fetch error:", error);
          }
        });
      } else {
        console.log("error", error);
      }
    });
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    // Assuming 'professors' is the array you are working with.
    const items = Array.from(professors);

    // Perform the reordering.
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    // Update the 'order' property based on the new index of each item.
    const updatedItems = items.map((item, index) => ({
      ...item,
      order: index + 1, // Assuming you want the order to start at 1.
    }));

    setProfessors(updatedItems);
  };

  useEffect(() => {
    professors.map((professor) => {
      if (professors.length > 0) {
        api_update_profesor(professor, (data, error) => {
          if (data) {
          } else {
            console.log("error", error);
          }
        });
      }
    });
  }, [professors]);

  function onSubmit(values: z.infer<typeof formSchema>) {
    const { id, class: clasa, subject } = values;

    api_create_profesor(id, subject, clasa, (data, error) => {
      if (data) {
        form.setValue("id", "");
        form.setValue("class", "");
        form.setValue("subject", "");
        form.reset();
        api_get_profesors((data, error) => {
          if (data && data.status === "success") {
            setProfessors(data.profesors);
          } else {
            console.log("Fetch error:", error);
          }
        });
      } else {
        console.log("error", error);
      }
    });
    // setProfessors((prevProfessors) => [
    //   ...prevProfessors,
    //   { id, class: clasa, subject },
    // ]);

    // if valid form reset form values
  }

  const clasele = [1, 2, 3, 4, 5, 6, 7, 8];
  return (
    <div className="flex">
      <AdminSidebar />
      <div className="mt-7 flex-1">
        <div className="container">
          <div>
            <h1 className="text-4xl font-bold">Professors</h1>
            <div className="space-y-7 mt-7">
              <Form {...form}>
                <form
                  onSubmit={form.handleSubmit(onSubmit)}
                  className="space-y-4 w-full"
                >
                  <div className="flex gap-3 w-full">
                    <FormField
                      control={form.control}
                      name="id"
                      render={({ field }) => (
                        <FormItem className="flex-1">
                          <FormLabel>Bot ID</FormLabel>
                          <FormControl>
                            <Input type="text" autoComplete="text" {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="class"
                      render={({ field }) => (
                        <FormItem className="flex-1">
                          <FormLabel>Clasa</FormLabel>
                          <FormControl>
                            <Select
                              value={field.value}
                              onValueChange={field.onChange}
                              defaultValue={field.value}
                            >
                              <SelectTrigger>
                                <SelectValue placeholder="Selecteaza clasa" />
                              </SelectTrigger>
                              <SelectContent>
                                {clasele.map((clasa) => (
                                  <SelectItem
                                    key={clasa}
                                    value={clasa.toString()}
                                  >
                                    {clasa}
                                  </SelectItem>
                                ))}
                              </SelectContent>
                            </Select>
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="subject"
                      render={({ field }) => (
                        <FormItem className="flex-1">
                          <FormLabel>Materia</FormLabel>
                          <FormControl>
                            <Select
                              value={field.value}
                              onValueChange={field.onChange}
                              defaultValue={field.value}
                            >
                              <SelectTrigger>
                                <SelectValue placeholder="Selecteaza materia" />
                              </SelectTrigger>
                              <SelectContent>
                                {subject &&
                                  subject.map((sub) => (
                                    <SelectItem key={sub.id} value={sub.id}>
                                      {sub.name}
                                    </SelectItem>
                                  ))}
                              </SelectContent>
                            </Select>
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  <Button>Adauga profesor</Button>
                </form>
              </Form>
            </div>
          </div>
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="droppable-professors">
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 mt-16">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-200 rounded-lg dark:bg-gray-700 dark:text-gray-400">
                      <tr>
                        <th scope="col" className="px-6 py-3">
                          Nume
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Bot ID
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Clasa
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Materie
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Actiuni
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {professors.map((professor, index) => (
                        <Draggable
                          key={professor.id}
                          draggableId={professor.id}
                          index={index}
                        >
                          {(provided) => (
                            <tr
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                            >
                              <td className="px-6 py-4">{professor.name}</td>
                              <td className="px-6 py-4">{professor.id}</td>
                              <td className="px-6 py-4">{professor.class}</td>
                              <td className="px-6 py-4">{professor.subject}</td>
                              <td className="px-6 py-4">
                                <Button
                                  variant="link"
                                  className="text-red-500 dark:text-red-400"
                                  onClick={() =>
                                    handleDeleteProfessor(professor.id)
                                  }
                                >
                                  Delete
                                </Button>
                              </td>
                            </tr>
                          )}
                        </Draggable>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </div>
    </div>
  );
}

export default Professors;
