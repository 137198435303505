import { create } from "zustand";
import { AppState, AppPromptState } from "@/types/types";
import { api_get_messages_from_thread } from "@/lib/chat";
import { v4 as uuid } from "uuid";

export const useStore = create<AppState>((set, get) => ({
  profesors: [],
  setProfesors: (profesors) => set({ profesors }),
  currentProfesorIndex: 0,
  setCurrentProfesor: (profesor) => {
    const currentProfesorIndex = get().profesors.findIndex(
      (p) => p.id == profesor.id
    );
    set({
      currentProfesorIndex,
      currentProfesor: profesor,
    });
  },
  threads: [],
  addEmptyThread: (profesorId = null) => {
    const { threads } = get();
    if (threads.length > 0 && threads[0].owner_id == "sentinel") {
      if (profesorId) {
        const thread = threads[0];
        const currentProfesor = get().profesors.find((p) => p.id == profesorId);
        const currentProfesorIndex = get().profesors.findIndex(
          (p) => p.id == profesorId
        );
        set({
          currentThread: threads[0].id,
          currentThreadIndex: 0,
          currentProfesor,
          currentProfesorIndex,
        });
      } else {
        set({ currentThread: threads[0].id, currentThreadIndex: 0 });
      }
    } else {
      const thread = {
        id: uuid(),
        messages: [],
        name: "",
        bot_id: profesorId ? profesorId : "-",
        owner_id: "sentinel",
        conversation_id: uuid(),
      };
      const newThreads = [thread, ...threads];
      if (profesorId) {
        const currentProfesor = get().profesors.find((p) => p.id == profesorId);
        const currentProfesorIndex = get().profesors.findIndex(
          (p) => p.id == profesorId
        );
        set({
          threads: newThreads,
          currentThread: thread.id,
          currentThreadIndex: 0,
          currentProfesor,
          currentProfesorIndex,
        });
      } else {
        const activeProfesors = get().profesors.filter(
          (profesor) =>
            profesor.class.toString() == get().userProfile?.class.toString()
        );
        const currentProfesorIndex = get().profesors.findIndex(
          (p) => p.id == activeProfesors[0].id
        );
        set({
          threads: newThreads,
          currentThread: thread.id,
          currentThreadIndex: 0,
          currentProfesor: activeProfesors[0],
        });
      }
    }
  },
  setThreads: (threads) => {
    const oldThreads = get().threads;
    const newThreads = threads.map((thread, index) => {
      const oldThread = oldThreads.find((t) => t.id === thread.id);
      if (oldThread) {
        const mixedThread = { ...oldThread, ...thread };
        if (get().currentThread === thread.id) {
          set({ currentThreadIndex: index });
        }
        return mixedThread;
      }
      return thread;
    });
    // console.log("Setting threads", newThreads, threads);
    set({ threads: newThreads });
  },
  addThread: (thread) =>
    set((state) => {
      const threadsCopy = [thread, ...state.threads];
      return { threads: threadsCopy };
    }),
  updateThread: (threadId, newThread) => {
    const currentThreads = get().threads;
    const threadIndex = currentThreads.findIndex(
      (thread) => thread.id === threadId
    );
    if (threadIndex !== -1) {
      // set((state) => {
      //   const threadsCopy = [...state.threads];
      //   threadsCopy[threadIndex] = newThread;
      //   return { threads: threadsCopy };
      // });
      Object.assign(currentThreads[threadIndex], newThread);
    }
  },
  currentThread: null,
  currentThreadIndex: -1,
  setCurrentThread: async (threadId, getMessages = true) => {
    set({ currentThread: threadId });

    const currentThreads = get().threads;
    const threadIndex = currentThreads.findIndex(
      (thread) => thread.id === threadId
    );

    // Check if the thread exists
    if (threadIndex == -1) {
      // Messages are already loaded, no need to fetch
      return;
    }
    // If the thread is not found, or if it is found but has no messages, fetch the messages
    set({ currentThreadIndex: threadIndex });

    if (currentThreads[threadIndex].owner_id == "sentinel") {
      if (!get().currentProfesor) {
        const activeProfesors = get().profesors.filter(
          (profesor) =>
            profesor.class.toString() == get().userProfile?.class.toString()
        );
        const prpfesorIndex = get().profesors.findIndex(
          (p) => p.id == activeProfesors[0].id
        );
        set({ currentProfesor: activeProfesors[0] });
        set({ currentProfesorIndex: prpfesorIndex });
      }
    } else {
      const profesor = get().profesors.find(
        (p) => p.id == currentThreads[threadIndex].bot_id
      );
      set({ currentProfesor: profesor });
      set({
        currentProfesorIndex: get().profesors.findIndex(
          (p) => p.id == currentThreads[threadIndex].bot_id
        ),
      });
    }

    // Check if the thread already has messages
    if (
      currentThreads[threadIndex].messages.length > 0 ||
      currentThreads[threadIndex].owner_id == "sentinel" ||
      !getMessages
    ) {
      // Messages are already loaded, no need to fetch
      return;
    }

    // Fetch the thread data from the API

    api_get_messages_from_thread(threadId, (data, error) => {
      if (data) {
        const messages = data.items;
        set((state) => {
          const threadsCopy = [...state.threads];
          if (threadIndex !== -1) {
            threadsCopy[threadIndex].messages = messages.map((message) => {
              if (message.images) {
                message.images = message.images.map((image) => {
                  // check if image is object
                  if (typeof image === "object") {
                    return image;
                  }
                  return {
                    url: image,
                  };
                });
              }
              return message;
            });
          }
          return { threads: threadsCopy };
        });
      } else {
        console.error("There was a problem fetching thread data:", error);
      }
    });
  },
  prompt: { message: "", images: [], type: "human" },
  setPrompt: (prompt) => set({ prompt }),
  dialogInactiveAccount: false,
  setDialogInactiveAccount: (dialogInactiveAccount) =>
    set({ dialogInactiveAccount }),
  settings: {
    isAudioMode: false,
    isRecording: false,
    isLoading: false,
    isTalking: false,
    isImageDialog: false,
    setImageDialog: (isImageDialog) =>
      set((state) => ({
        settings: { ...state.settings, isImageDialog },
      })),
    selectedImage: "",
    setSelectedImage: (selectedImage) =>
      set((state) => ({
        settings: { ...state.settings, selectedImage },
      })),
    discardNextAudioPrompt: false,
    bubbleText: "",
    inputAnalyzerValues: [0, 0, 0, 0],
    chatTTS: "openai",
    setChatTTS: (chatTTS) =>
      set((state) => ({
        settings: { ...state.settings, chatTTS },
      })),
    fileIsOpened: false,
    showNewTaskHint: false,
  },
  setSettings: (settings) => {
    let newSettings = { ...get().settings, ...settings };
    set({
      settings: newSettings,
    });
  },
  userProfile: null,
  setUserProfile: (userProfile) => {
    if (userProfile) {
      set((state) => ({
        userProfile: { ...(state.userProfile || {}), ...userProfile },
      }));
      const { threads } = get();
      if (threads.length > 0 && threads[0].owner_id == "sentinel") {
        // filter profesror
        const activeProfesors = get().profesors.filter(
          (profesor) =>
            profesor.class.toString() == userProfile?.class.toString()
        );
        // set current profesor
        set({ currentProfesor: activeProfesors[0] });
        const profesorIndex = get().profesors.findIndex(
          (p) => p.id == activeProfesors[0].id
        );
        set({ currentProfesorIndex: profesorIndex });
      }
    } else {
      set({ userProfile: null });
    }
  },
  shouldCheckLogInState: true,
  setShouldCheckLogInState: (shouldCheckLogInState) =>
    set({ shouldCheckLogInState }),
  sidebarActive: false,
  setSidebarActive: (sidebarActive) => set({ sidebarActive }),
  dialogUserProfile: false,
  setDialogUserProfile: (dialogUserProfile) => set({ dialogUserProfile }),
  dialogSettings: false,
  setDialogSettings: (dialogSettings) => set({ dialogSettings }),
  addImageToPrompt: (image) => {
    set((state) => {
      const prompt = { ...state.prompt };
      prompt.images.push(image);
      return { prompt };
    });
  },
  starterConversations: [],
  setStarterConversations: (starterConversations) =>
    set({ starterConversations }),
}));
