export function Loading() {
  return (
    <svg
      className="animate-softBounce animate-infinite animate-ease-in-out animate-normal"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 740.3 547.4"
      xmlSpace="preserve"
      width={"90px"}
      height={"100%"}
    >
      <g>
        <g>
          <g>
            <path
              style={{ fill: "#003340" }}
              d="M198,96.7c9.3,16.1-2.6,40.3-26.6,54.1c-23.9,13.8-50.9,12-60.1-4c-9.3-16.1,2.6-40.3,26.6-54.1
				C161.8,78.8,188.7,80.6,198,96.7z"
            />
            <path
              style={{ fill: "#003340" }}
              d="M129.3,120.8c-3.6-4.3-12.8-20.5-40.9-70.6l19.3-14.5c22,22,50.3,51,55,55.3
				C163.4,91.7,129.3,120.8,129.3,120.8z"
            />
            <path
              style={{ fill: "#C7D8EA" }}
              d="M125.8,35.5c0,19.4-15.7,35.1-35.2,35.1c-19.4,0-35.2-15.7-35.2-35.1c0-19.4,15.7-35.2,35.2-35.2
				C110.1,0.4,125.8,16.1,125.8,35.5z"
            />
            <path
              style={{ opacity: "0.46", fill: "#A3B6BF" }}
              d="M90.7,0.4c-2.8,0-5.5,0.3-8.1,0.9c15.5,3.7,27,17.6,27,34.2
				c0,16.6-11.5,30.5-27,34.2c2.6,0.6,5.3,0.9,8.1,0.9c19.4,0,35.2-15.7,35.2-35.1C125.8,16.1,110.1,0.4,90.7,0.4z"
            />
            <path
              style={{ fill: "#003340" }}
              d="M82.5,69.7c36.4,7.1,57-38.8,29.3-61.1c-7.9-6.6-18.9-9.4-29.3-7.4c21.2-6.1,44.3,9.9,44.5,34.2
				C126.8,59.6,103.9,75.9,82.5,69.7z"
            />
            <path
              style={{ opacity: "0.46", fill: "#C7D8EA" }}
              d="M90.7,32.7c0,8-6.5,14.5-14.5,14.5c-8,0-14.5-6.5-14.5-14.5c0-8,6.5-14.5,14.5-14.5
				C84.2,18.2,90.7,24.7,90.7,32.7z"
            />
          </g>
          <g>
            <path
              style={{ fill: "#003340" }}
              d="M547.9,101.2c-9.3,16.1,2.6,40.3,26.6,54.1c23.9,13.8,50.9,12,60.1-4c9.3-16.1-2.6-40.3-26.6-54.1
				C584.1,83.3,557.1,85.1,547.9,101.2z"
            />
            <path
              style={{ fill: "#003340" }}
              d="M616.6,125.3c3.6-4.3,12.8-20.5,40.9-70.6l-19.3-14.5c-23.3,23.4-52.7,53-54.1,53.9
				C584,94.1,616.6,125.3,616.6,125.3z"
            />
            <path
              style={{ fill: "#C7D8EA" }}
              d="M620,40c0,19.4,15.7,35.2,35.2,35.2c19.4,0,35.2-15.7,35.2-35.2c0-19.4-15.7-35.2-35.2-35.2
				C635.8,4.8,620,20.6,620,40z"
            />
            <path
              style={{ opacity: "0.46", fill: "#A3B6BF" }}
              d="M655.2,4.8c2.8,0,5.5,0.3,8.1,0.9c-15.5,3.7-27,17.6-27,34.2
				c0,16.6,11.5,30.5,27,34.2c-2.6,0.6-5.3,0.9-8.1,0.9C635.8,75.2,620,59.4,620,40C620,20.6,635.8,4.8,655.2,4.8z"
            />
            <path
              style={{ fill: "#003340" }}
              d="M663.3,74.2c-36.4,7.1-57-38.8-29.3-61.1c7.9-6.6,18.9-9.4,29.3-7.4C642.1-0.3,619,15.7,618.8,40
				C619,64.1,642,80.4,663.3,74.2z"
            />
            <path
              style={{ opacity: "0.46", fill: "#C7D8EA" }}
              d="M655.2,37.2c0,8,6.5,14.5,14.5,14.5c8,0,14.5-6.5,14.5-14.5s-6.5-14.5-14.5-14.5
				C661.7,22.7,655.2,29.2,655.2,37.2z"
            />
          </g>
          <g>
            <g>
              <ellipse
                style={{ fill: "#003340" }}
                cx="370.2"
                cy="329.5"
                rx="370.2"
                ry="166.2"
              />
              <g>
                <path
                  style={{ fill: "#A2BFDC" }}
                  d="M697.1,407.5c-6.6,5.5-13.8,10.9-21.7,16c19.2-55.2,27.5-116.4,25.5-168.8
						c6,5.4,11.5,10.9,16.2,16.6C717,314.6,710.4,362.3,697.1,407.5z"
                />
                <path
                  style={{ fill: "#A2BFDC" }}
                  d="M23.3,271.3c4.8-5.7,10.2-11.3,16.3-16.7c-3.1,52.9,7.5,115.2,28.6,171.1
						c-8.2-5.2-15.7-10.6-22.6-16.2C30.8,363.4,23.1,314.9,23.3,271.3z"
                />
              </g>
            </g>
            <path
              style={{ fill: "#A2BFDC" }}
              d="M266.1,529.2c64.6,24.8,152.1,24.3,205.8-1.7C615.6,457.8,692.4,338,669.7,218.2
				C658.1,157.1,590,113.3,532.5,99.7C428.3,75,317,73.5,212.2,95.5c-56.2,12-111.8,50.1-133.7,104.9
				C32.8,314.9,131.8,477.8,266.1,529.2z"
            />
            <path
              style={{ fill: "#C7D8EA" }}
              d="M511.4,190.3c-93.9-7.3-188.3-7.3-282.2,0C78.6,202,26.7,451,299.1,488.3
				c86.4,11.8,190.2,5.6,266-41.3C683.7,373.5,641.4,200.4,511.4,190.3z"
            />
            <path
              style={{ fill: "#003340" }}
              d="M374.6,489.4c-24.7,0-49.9-1.7-74.9-5.2c-143.1-19.6-192-96.9-190.1-162.7
				c2-67.3,53.5-121.9,119.9-127.1c46.6-3.6,94-5.5,140.8-5.5c0,0,0,0,0,0c46.8,0,94.2,1.8,140.8,5.5
				c56.8,4.4,102.3,43.2,115.8,98.9c14.2,58.4-10.9,117.4-64,150.3C501.7,481.4,426.8,489.4,374.6,489.4
				C374.6,489.4,374.6,489.4,374.6,489.4z"
            />
          </g>
        </g>
      </g>
      <g>
        <g transform="translate(247.5 328.2)">
          <circle style={{ fill: "#3B82F6" }} cx={0} cy={0} r={28}>
            <animateTransform
              attributeName="transform"
              type="scale"
              begin="-0.375s"
              calcMode="spline"
              keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
              values="0;1;0"
              keyTimes="0;0.5;1"
              dur="1s"
              repeatCount="indefinite"
            />
          </circle>
        </g>
        <g transform="translate(332.9 328.1)">
          <circle style={{ fill: "#F43F5E" }} cx={0} cy={0} r={28}>
            <animateTransform
              attributeName="transform"
              type="scale"
              begin="-0.25s"
              calcMode="spline"
              keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
              values="0;1;0"
              keyTimes="0;0.5;1"
              dur="1s"
              repeatCount="indefinite"
            />
          </circle>
        </g>
        <g transform="translate(417.6 327.7)">
          <circle style={{ fill: "#EAB308" }} cx={0} cy={0} r={28}>
            <animateTransform
              attributeName="transform"
              type="scale"
              begin="-0.125s"
              calcMode="spline"
              keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
              values="0;1;0"
              keyTimes="0;0.5;1"
              dur="1s"
              repeatCount="indefinite"
            />
          </circle>
        </g>
        <g transform="translate(504.6 328.7)">
          <circle style={{ fill: "#22C55E" }} cx={0} cy={0} r={28}>
            <animateTransform
              attributeName="transform"
              type="scale"
              begin="0s"
              calcMode="spline"
              keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
              values="0;1;0"
              keyTimes="0;0.5;1"
              dur="1s"
              repeatCount="indefinite"
            />
          </circle>
        </g>
      </g>
    </svg>
  );
}
