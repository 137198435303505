import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { UserCog, Settings, LogOut as LogoutIcon, MessageCircle, Bug } from "lucide-react";
import { Avatar, AvatarImage, AvatarFallback } from "@/components/ui/avatar";
import ProfileSettings from "./ProfileSettings";
import UserSettings from "./UserSettings";
import InactiveAccountPopup from "./InactiveAccountPopup";
import { useStore } from "@/store/store";
import { useNavigate } from "react-router-dom";
function UserAvatar() {
  const navigate = useNavigate();
  const {
    setDialogUserProfile,
    userProfile,
    setDialogSettings,
    dialogSettings,
  } = useStore();
  const userLinks = [
    {
      name: "Profilul meu",
      action: "dialog-profile",
      icon: UserCog,
    },
    {
      name: "Setări",
      action: "dialog-settings",
      icon: Settings,
    },
    {
      name: "Delogare",
      action: "logout",
    },
  ];

  return (
    <>
      <UserSettings />
      <ProfileSettings />
      <InactiveAccountPopup />
      <div className="md:hidden w-full font-semibold text-[#c5c5d2] text-sm">
        <div
          onClick={() => setDialogUserProfile(true)}
          className="flex items-center gap-3 mt-2 text-sm w-full h-14 px-3 cursor-pointer"
        >
          <Avatar>
            {userProfile && userProfile.avatar && <AvatarImage src={userProfile.avatar} />}
            {userProfile && userProfile.avatar == false && <AvatarFallback className="bg-x-blue-700">
              {userProfile ? userProfile?.name[0] : ''}
            </AvatarFallback>}
          </Avatar>

          <div className="text-left max-h-full overflow-hidden">
            <div className="">{userProfile?.name}</div>
          </div>
        </div>
        <div className="py-1 px-2">
          <div
            onClick={() => setDialogSettings(true)}
            className={`group flex cursor-pointer w-full items-center rounded-sm p-3 text-sm gap-3 `}
          >
            <Settings className="w-6 h-6 mr-2" aria-hidden="true" />
            Setari
          </div>
        </div>
        <div className="py-1 px-2">
          <div
            onClick={() => navigate("/logout")}
            className={` group flex cursor-pointer w-full items-center rounded-sm p-3 text-sm gap-3`}
          >
            <LogoutIcon className="w-6 h-6 mr-2" aria-hidden="true" />
            Log out
          </div>
        </div>
      </div>

      <Menu
        as="div"
        className="relative w-full hidden md:block  text-[#c5c5d2]"
      >
        <div>
          <Menu.Button className="w-full">
            {({ open }) => (
              <div
                className={`w-full flex items-center text-sm rounded-md hover:bg-x-gray-950 justify-start gap-3 h-14 px-3 ${open ? "bg-x-gray-950" : ""
                  }`}
              >
                <Avatar>
                  {userProfile && userProfile.avatar && <AvatarImage src={userProfile.avatar} />}
                  {userProfile && userProfile.avatar == false && <AvatarFallback className="bg-x-blue-700">
                    {userProfile ? userProfile?.name[0] : ''}
                  </AvatarFallback>}
                </Avatar>
                <div className="text-left max-h-full overflow-hidden font-bold">
                  <div className="">{userProfile?.name}</div>
                </div>
              </div>
            )}
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute left-0 mt-2 w-[230px] z-20 text-white origin-bottom-left bottom-[calc(100%+5px)] rounded-md bg-x-gray-950 focus:outline-none ">
            <div className="px-1">
              <Menu.Item>
                {({ active }) => (
                  <div className="py-1">
                    <div
                      onClick={() => setDialogUserProfile(true)}
                      className={`${active && "bg-x-gray-900"
                        } group flex cursor-pointer w-full items-center rounded-sm p-3 text-sm`}
                    >
                      <UserCog className="w-4 h-4 mr-2" aria-hidden="true" />
                      Profilul meu
                    </div>
                  </div>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <div className="py-1">
                    <div
                      onClick={() => setDialogSettings(true)}
                      className={`${active && "bg-x-gray-900"
                        } group flex cursor-pointer w-full items-center rounded-sm p-3 text-sm`}
                    >
                      <Settings className="w-4 h-4 mr-2" aria-hidden="true" />
                      Setari
                    </div>
                  </div>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <div className="py-1">
                    <div
                      onClick={() => window.location.href = "https://profesorulvirtual.featurebase.app"}
                      className={`${active && "bg-x-gray-900"
                        } group flex cursor-pointer w-full items-center rounded-sm p-3 text-sm`}
                    >
                      <Bug className="w-4 h-4 mr-2" aria-hidden="true" />
                      Feedback
                    </div>
                  </div>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <div className="py-1">
                    <div
                      onClick={() => window.location.href = "https://profesorul.ai/contact"}
                      className={`${active && "bg-x-gray-900"
                        } group flex cursor-pointer w-full items-center rounded-sm p-3 text-sm`}
                    >
                      <MessageCircle className="w-4 h-4 mr-2" aria-hidden="true" />
                      Contact
                    </div>
                  </div>
                )}
              </Menu.Item>
              <div className="border-t border-x-gray-900 h-1 mt-1"></div>
              <Menu.Item>
                {({ active }) => (
                  <div className="py-1">
                    <div
                      onClick={() => navigate("/logout")}
                      className={`${active && "bg-x-gray-900"
                        } group flex cursor-pointer w-full items-center rounded-sm p-3 text-sm`}
                    >
                      <LogoutIcon className="w-4 h-4 mr-2" aria-hidden="true" />
                      Log out
                    </div>
                  </div>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
}

export default UserAvatar;
