import AdminSidebar from "@/components/Sidebar/AdminSidebar";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  api_get_codes,
  api_generate_codes,
  api_delete_code,
  api_update_code,
  api_get_options,
  api_set_option
} from "@/lib/chat";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import { Switch } from "@/components/ui/switch";

function AdminCodes() {
  const [codes, setCodes] = useState({
    current_page: 1,
    items: [],
    items_per_page: 1000,
    total_items: 5,
    total_pages: 1,
  });
  const [codeValability, setCodeValability] = useState(5);
  const [newCodes, setNewCodes] = useState([]); // [1, 2, 3, 4, 5]
  const [numberOfCodes, setNumberOfCodes] = useState(5);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [codeType, setCodeType] = useState([
    {
      id: "all",
      name: "Toate",
      active: true,
    },
    {
      id: "used",
      name: "Folosite",
      active: false,
    },
    {
      id: "unused",
      name: "Neutilizate",
      active: false,
    },
  ]);

  useEffect(() => {
    api_get_options((data, error) => {
      if (data) {
        setCodeValability(parseInt(data.GEN_CODES_CODE_VALABILITY_DAYS));
      } else {
        console.log(error);
      }
    });
  }, []);

  function handleCodeTypeChange(type) {
    setPage(1);
    setCodeType(
      codeType.map((item) => {
        if (item.id === type.id) {
          return { ...item, active: true };
        } else {
          return { ...item, active: false };
        }
      })
    );
  }

  useEffect(() => {
    const activeType = codeType.find((type) => type.active);

    api_get_codes(activeType.id, page, (data, error) => {
      if (data) {
        setCodes(data);
      } else {
        console.log(error);
      }
    });
  }, [page, codeType, newCodes]);

  const handlePageChange = (page) => {
    const selected = page.selected + 1;
    setPage(selected);
  };

  const handleGenerateCodes = () => {
    setIsLoading(true);
    api_generate_codes(numberOfCodes, codeValability, (data, error) => {
      setIsLoading(false);
      if (data && data.status === "success") {
        setNewCodes(data.codes);
      } else {
        console.log(error);
      }
    });
  };

  const handleDeleteCode = (code) => {
    api_delete_code(code, (data, error) => {
      if (data && data.status === "success") {
        const activeType = codeType.find((type) => type.active);
        api_get_codes(activeType.id, page, (data, error) => {
          if (data) {
            setCodes(data);
          } else {
            console.log(error);
          }
        });
      } else {
        console.log(error);
      }
    });
  };

  const handleChangeUsed = (value) => {
    const newValue = !value.used;
    value.used = newValue;

    const codeObj = {
      id: value.code,
      used: newValue,
    };

    api_update_code(codeObj, (data, error) => {
      if (data && data.status === "success") {
        const activeType = codeType.find((type) => type.active);
        api_get_codes(activeType.id, page, (data, error) => {
          if (data) {
            const newCodesItems = codes.items.map((code) => {
              if (code.code === value.code) {
                return { ...code, used: newValue };
              }
              return code;
            });
            setCodes({ ...codes, items: newCodesItems });
          } else {
            console.log(error);
          }
        });
      } else {
        console.log(error);
      }
    });
  };

  return (
    <div className="flex">
      <AdminSidebar />
      <div className="mt-7 flex-1">
        <div className="container">
          <div className="flex justify-between items-center">
            <h1 className="text-4xl font-bold">Coduri</h1>
            <div
              className={`flex gap-3 whitespace-nowrap items-center ${isLoading ? "opacity-40 pointer-events-none" : ""
                }`}
            >

              <div className="">Generează</div>
              <Input
                max={100}
                min={1}
                onChange={(e) => {
                  setNumberOfCodes(Math.max(1, Math.min(e.target.value, 100)));
                }}
                value={numberOfCodes}
                type="number"
                placeholder="Numar de coduri"
                required
              />
              <div className="">coduri valabile</div>
              <Input
                max={1000}
                min={1}
                onChange={(e) => {
                  const newValue = Math.max(1, Math.min(parseInt(e.target.value), 1000));
                  setCodeValability(newValue);
                  api_set_option("GEN_CODES_CODE_VALABILITY_DAYS", newValue, (data, error) => {
                    if (data) {
                      console.log(data);
                    } else {
                      console.log(error);
                    }
                  });
                }}
                value={codeValability}
                type="number"
                placeholder="Valabilitate"
                required
              />
              <div>zile</div>
              <Button onClick={handleGenerateCodes}>
                {isLoading ? "Se genereaza..." : "Genereaza"}
              </Button>
            </div>
          </div>
          {newCodes.length > 0 && (
            <div className="p-7 rounded-lg mt-7 space-y-5 relative overflow-hidden">
              <div className="font-bold">
                Urmatoarele coduri au fost generate:
              </div>
              <div>
                {newCodes.map((code) => {
                  return <div key={code}>{code}</div>;
                })}
              </div>
              <div className="absolute !mt-0 pointer-events-none rounded-lg bg-green-100 top-0 left-0 right-0 bottom-0 -z-10"></div>
            </div>
          )}
          <div className="mt-7 space-y-3">
            <div className="font-bold text-md">Vezi Coduri</div>
            <div className="flex gap-7">
              {codeType.map((type) => {
                return (
                  <Button
                    onClick={() => {
                      handleCodeTypeChange(type);
                    }}
                    key={type.id}
                    className={`px-4 flex-1 py-2 rounded-md ${type.active
                      ? "pointer-events-none border"
                      : "bg-whitw text-black border border-x-gray-500 hover:text-white "
                      }`}
                  >
                    {type.name}
                  </Button>
                );
              })}
            </div>
            <div className="relative overflow-x-auto">
              <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
                <thead className="text-xs text-gray-700 uppercase bg-gray-200">
                  <tr>
                    <th scope="col" className="px-6 py-3">
                      Cod
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Folosit
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Valabilitate
                    </th>
                    <th scope="col" className="px-6 py-3"></th>
                  </tr>
                </thead>
                <tbody>
                  {codes.items.length > 0 &&
                    codes.items.map((code) => {
                      return (
                        <tr
                          key={code.code}
                          className={`bg-white border-b border-x-gray-100 even:bg-gray-50`}
                        >
                          <td className="px-6 py-4 font-bold">{code.code}</td>

                          <td className="px-6 py-4">
                            <Switch
                              onCheckedChange={() => {
                                handleChangeUsed(code);
                              }}
                              checked={code.used}
                            />
                          </td>
                          <td className="px-6 py-4">
                            {code.valability_days} zile
                          </td>
                          <td className="px-6 py-4">
                            <Button
                              variant={"link"}
                              className="text-red-500"
                              onClick={() => {
                                handleDeleteCode(code.code);
                              }}
                            >
                              Sterge
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
            <div className="sticky bg-white bottom-0">
              <div className="mx-auto max-w-[670px] w-full navigation !border-0">
                {codes.items.length > 0 && (
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel={
                      <div>
                        Next
                        <ChevronRight width={15} />
                      </div>
                    }
                    onClick={handlePageChange}
                    onPageChange={handlePageChange}
                    pageRangeDisplayed={5}
                    pageCount={codes.total_pages}
                    previousLabel={
                      <div>
                        <ChevronLeft width={15} />
                        Previous
                      </div>
                    }
                    renderOnZeroPageCount={null}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
}

export default AdminCodes;
