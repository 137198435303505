import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { useStore } from "@/store/store";
import { useState } from "react";

function InactiveAccountPopup() {
  const { userProfile, setUserProfile, dialogInactiveAccount, setDialogInactiveAccount } =
    useStore();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  return (
    <div>
      <Dialog
        open={dialogInactiveAccount}
        onOpenChange={() => setDialogInactiveAccount(false)}
      >
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>Cont inactiv</DialogTitle>
            <DialogDescription></DialogDescription>
          </DialogHeader>

          <div className="flex flex-col">
            <div>
              Contul tău este inactiv. Pentru a putea folosi aplicația, te rugăm să
              achiziționezi o licență.
            </div>
            <div>
              <a href="https://profesorul.ai" target="_blank">
                <Button variant={"default"} className="mt-3">
                  Cumpară liciență
                </Button>
              </a>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default InactiveAccountPopup;
