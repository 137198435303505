import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { Button } from "@/components/ui/button";
import { Link, useNavigate } from "react-router-dom";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { api_login } from "../lib/chat";
import { Avatar } from "@/components/ui/Robo/Avatar";
type LoginResponse = {
  status: string;
  // add other properties that the response might have
};

const formSchema = z.object({
  email: z.string().email({ message: "Email nevalid" }),
  password: z.string().min(2, {
    message: "Parola trebuie sa contina minim 2 caractere",
  }),
});

export default function ResetPassword() {
  const navigate = useNavigate();
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
    },
  });

  // 2. Define a submit handler.
  function onSubmit(values: z.infer<typeof formSchema>) {
    // Do something with the form values.
    // ✅ This will be type-safe and validated.
    api_login(values.email, values.password, (res: LoginResponse) => {
      if (res && res?.status == "success") {
        // reddirect to home
        // navigate("/");
        window.location.href = "/";
      }
    });
  }
  return (
    <div className="flex min-h-screen bg-x-blue-700 w-full">
      <div className="bg-white p-7 z-10 h-full flex min-h-screen items-center w-full sm:w-[400px]">
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="space-y-4 w-full"
          >
            <div className="space-y-3">
              <div className="flex items-center gap-3 justify-center">
                <Avatar />{" "}
                <h1 className="text-3xl font-bold">Resetare parola</h1>
              </div>
            </div>
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input type="email" autocomplete="email" placeholder="" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <Button className="w-full" type="submit">
              Resetare parola
            </Button>
            <div>
              <Link className="text-x-blue-700 hover:underline" to="/login">
                Inapoi la autentificare
              </Link>
            </div>
          </form>
        </Form>
      </div>
      <div className="flex-1">
        <div className="max-h-screen h-screen items-end flex justify-end"></div>
      </div>
    </div>
  );
}
