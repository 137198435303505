import { api_logout } from "../lib/chat";

export default function Logout() {
  api_logout((res) => {
    window.location.href = "/";
  });

  return (
    <div>Bye!</div>
  );
}
