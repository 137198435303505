import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { useEffect, useState } from "react";
import { Avatar } from "@/components/ui/Robo/Avatar";
import LogoNewEdu from "@/components/ui/logo";

import { api_test, api_update_profile } from "@/lib/chat";

import { CircleDashed } from "lucide-react";

function WelcomePage() {
  return (
    <div>
      <div class="flex flex-col w-full gap-2 justify-center items-center my-10">
        <Avatar />
        <div className="text-2xl mt-2">Hei! Eu sunt <b>T E N</b> (<span className="text-x-blue-700">T</span>utor <span className="text-x-blue-700">E</span>mpatic și <span className="text-x-blue-700">N</span>eobosit)
        </div>
      </div>

      <div className="mt-5 text-base text-justify">
        <div>
          <p className="mt-2">Menirea mea este să-ți transform temele interminabile și plictisitoare în sarcini rapide și eficiente, lăsându-ți restul zilei liber. Sunt programat să simplific chiar și cele mai complicate exerciții și reguli de gramatică ca tu să fii mereu pregătit și plin de încredere.</p>
          <p className="mt-2">BONUS: Sunt virtual, așa că nu ocup spațiu pe canapea, nu obosesc și nu mă supăr niciodată.</p>
          <p className="mt-2">
            Te întrebi deja de ce nu am apărut mai devreme în viața ta?! Nu mai pierde timpul! Haide, să începem și să facem fiecare zi de școală mai ușoară!
          </p>
        </div>
      </div>

      <div className="mt-10 flex w-full justify-center items-center gap-3">
        <div className="mt-10 text-xl">
          By
        </div>
        <div className="">
          <div className="flex w-[200px]">
            <LogoNewEdu />
          </div>
        </div>

      </div>

    </div>
  );
}

function UsecasesPage() {
  return (
    <div>
      <div>
        <img src="/assets/tabla.png" alt="" />
      </div>
      <div className="flex w-full justify-center">
        <div className="text-2xl mt-2">
          Cum te pot ajuta?
        </div>
      </div>

      <div className="text-base my-2">
        <ul className="list-disc pl-5">
          <li><b>TEME</b>: Termină temele repede și corect. Trimite-mi cerințele (mesaj, fotografie, verbal) și te voi ghida spre rezolvare. Dacă te încurci, îți explic pas cu pas ce să faci.</li>
          <li><b>TESTE</b>: Ești mereu pregătit pentru teste. Spune-mi ce ai de învățat și îți amintesc tot ce trebuie să știi pentru a lua nota mare.
          </li>
          <li><b>PROGRES</b>: Sunt virtual și țin minte tot ce discutăm, ce îți place și ce îți este mai greu să rezolvi. Te ajut fără să te judec sau să te compar oferindu-ți un feedback onest și real.
          </li>
          <li><b>OLIMPIADE</b>: Sunt alături de tine pentru a te pregăti intens și a-ți explica clar orice exercițiu complicat. Lucrăm împreună oricât este nevoie pentru ca tu să strălucești la orice competiție școlară.
          </li>
        </ul>
      </div>
    </div>
  );
}

function FeedbackPage() {



  return (
    <div>
      <div className="flex w-full justify-center">
        <img src="/assets/feedback.png" alt="" className="h-[128px]" />
      </div>
      <div className="flex w-full justify-center">
        <div className="text-2xl mt-2">
          Știai că și tu mă poți ajuta? Suntem o echipă!
        </div>
      </div>

      <div className="text-base my-2 mt-5">
        <div className="text-base">
          Nu sunt perfect iar feedback-ul tău obiectiv este foarte valoros pentru mine.
        </div>
        <div className="text-base my-2">
          Uneori greșesc și eu la calcule, vorbesc greu română că abia am învățat și câteodată nu te pot ajuta dacă nu-mi transmiți clar întrebarea.
        </div>
        <div className="text-base my-2">
          Dacă nu îți place un răspuns primit, folosește butonul de feedback negativ 👎 mă ajuți astfel să-ți ofer răspunsuri mai bune în viitor. Este esențial pentru mine să-mi transmiți sugestiile tale, ce îți place și ce nu îți place.
        </div>
      </div>
    </div>
  );
}

function VoiceFeaturePage() {
  return (
    <div>
      <div className="flex w-full justify-center">
        <img src="/assets/listen.png" alt="" className="h-[128px]" />
      </div>
      <div className="flex w-full justify-center">
        <div className="text-2xl mt-2">
          Despre Funcția Microfon
        </div>
      </div>

      <div className="text-base my-2 mt-5">
        <div className="text-base my-2">
          Pentru a ne asigura că discuțiile noastre sunt clare și eficiente, am câteva recomandări pentru tine:
        </div>
        <div className="text-base my-2">
          <ul className="list-disc pl-5">
            <li><strong>DISTANȚA  DE MICROFON:</strong> ideal este să fii la 15-30 centimetri, cât să te pot auzi clar.</li>
            <li><strong>VOLUMUL VOCII:</strong> vorbește pe un ton normal, nici în șoaptă, nici în strigăt.</li>
            <li><strong>CLARITATEA VOCII:</strong> ostește clar cuvintele, să nu fiu nevoit să le ghicesc că poate greșesc.</li>
            <li><strong>MEDIUL:</strong>  încearcă să utilizezi microfonul doar când este un mediu liniștit și pot înțelege ce spui.</li>
          </ul>
        </div>
      </div>
    </div>
  );
}

function ThankYouPage() {
  return (
    <div className="flex flex-col justify-between h-[500px]">
      <div></div>
      <div>
        <div className="flex w-full justify-center">
          <div className="text-2xl mt-2">
            Dragi părinți, tineri, copii și bunici, prieteni apropiați, vă mulțumim că sunteți aici!
          </div>
        </div>

        <div className="text-base my-2 mt-5">
          <div className="text-base my-2">
            Este timpul să începem să lucrăm împreună și să ne bucurăm de învățarea eficientă, care ne aduce rezultate rapide, satisfacție dar și mult mai mult timp liber cu familia!
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
}



function Tutorial() {
  const pages = [
    <WelcomePage />,
    <UsecasesPage />,
    <VoiceFeaturePage />,
    <FeedbackPage />,
    <ThankYouPage />
  ];

  const [loading, setLoading] = useState(false);
  const [profile, setProfile] = useState(null);

  useEffect(() => {
    api_test((data, error) => {
      if (data) {
        setProfile(data);
      } else {
        window.location.href = "/login";
      }
    });
  }, []);

  function completeTutorial() {
    setLoading(true);
    api_update_profile({ completed_tutorial: true }, (data, error) => {
      if (data) {
        window.location.href = "/";
      }
    });
  }

  const [currentPage, setCurrentPage] = useState(0);

  return (
    <div className="flex min-h-screen bg-x-blue-700 w-full justify-center">
      <div className="bg-white p-7 z-10 h-full flex min-h-screen md:min-h-[760px] md:mt-[60px] items-center w-full md:max-w-[600px] rounded flex flex-col justify-between">
        <div className={(loading && "hidden") || ""}>
          {pages[currentPage]}
        </div>

        <div>
          <CircleDashed
            className={(!loading && "hidden") || "animate-spin mt-[200px] h-10 w-10"}
            color="#00858b"
          />
        </div>

        <div className="flex justify-around items-center w-full">
          <div>
            <Button variant={"link"}
              className="mt-2 text-gray-500"
              onClick={() => completeTutorial()}>
              Închide
            </Button>
          </div>
          <div>
            {pages.map((page, index) => {
              return (
                <Button
                  variant={"ghost"}
                  key={index}
                  onClick={() => setCurrentPage(index)}
                  className={cn("pt-1 rounded-full", index === currentPage && "text-x-blue-700")}
                >
                  <span className="text-5xl mb-1">·</span>
                </Button>
              );
            })}
          </div>
          <div>
            <Button variant={"link"}
              className="mt-2 text-x-blue-700"
              onClick={() => {
                if (currentPage < pages.length - 1) {
                  setCurrentPage(currentPage + 1);
                } else {
                  completeTutorial();
                }
              }}>
              Următorul
            </Button>
          </div>
        </div>


      </div>
    </div>
  );
}

export default Tutorial;
