export function TalkingFace() {
  return (
    <svg
      width={'90px'}
      height={'100%'}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 595.4 440.3"
      xmlSpace="preserve"
    >
      <g>
        <g>
          <g>
            <path
              style={{ fill: "#003340" }}
              d="M159.2,77.8c7.5,12.9-2.1,32.4-21.4,43.5c-19.2,11.1-40.9,9.6-48.3-3.2
          c-7.5-12.9,2.1-32.4,21.4-43.5C130.1,63.4,151.7,64.9,159.2,77.8z"
            />
            <path
              style={{ fill: "#003340" }}
              d="M104,97.2c-2.9-3.5-10.3-16.5-32.9-56.8l15.5-11.7c17.7,17.7,40.4,41,44.2,44.5
          C131.4,73.8,104,97.2,104,97.2z"
            />
            <path
              style={{ fill: "#C7D8EA" }}
              d="M101.2,28.6c0,15.6-12.6,28.2-28.3,28.2c-15.6,0-28.3-12.6-28.3-28.2S57.2,0.3,72.9,0.3
          C88.5,0.4,101.2,13,101.2,28.6z"
            />
            <path
              style={{
                opacity: "0.46",
                fill: "#A3B6BF",
                enableBackground: "new",
              }}
              d="M72.9,0.4c-2.3,0-4.4,0.2-6.5,0.7
          c12.5,3,21.7,14.2,21.7,27.5s-9.2,24.5-21.7,27.5c2.1,0.5,4.3,0.7,6.5,0.7c15.6,0,28.3-12.6,28.3-28.2
          C101.2,13,88.5,0.4,72.9,0.4z"
            />
            <path
              style={{ fill: "#003340" }}
              d="M66.3,56.1C95.6,61.8,112.2,24.9,89.9,7c-6.4-5.3-15.2-7.6-23.6-6c17-4.9,35.6,8,35.8,27.5
          C102,48,83.5,61.1,66.3,56.1z"
            />
            <path
              style={{
                opacity: "0.46",
                fill: "#C7D8EA",
                enableBackground: "new",
              }}
              d="M72.9,26.4c0,6.4-5.2,11.7-11.7,11.7s-11.7-5.2-11.7-11.7
          s5.2-11.7,11.7-11.7S72.9,19.9,72.9,26.4z"
            />
          </g>
          <g>
            <path
              style={{ fill: "#003340" }}
              d="M440.6,81.5c-7.5,12.9,2.1,32.4,21.4,43.5c19.2,11.1,40.9,9.6,48.3-3.2
          c7.5-12.9-2.1-32.4-21.4-43.5C469.7,67.1,448,68.5,440.6,81.5z"
            />
            <path
              style={{ fill: "#003340" }}
              d="M495.8,100.8c2.9-3.5,10.3-16.5,32.9-56.8l-15.5-11.7c-18.7,18.8-42.4,42.6-43.5,43.3
          C469.6,75.7,495.8,100.8,495.8,100.8z"
            />
            <path
              style={{ fill: "#C7D8EA" }}
              d="M498.5,32.2c0,15.6,12.6,28.3,28.3,28.3c15.6,0,28.3-12.6,28.3-28.3c0-15.6-12.6-28.3-28.3-28.3
          C511.2,3.9,498.5,16.6,498.5,32.2z"
            />
            <path
              style={{
                opacity: "0.46",
                fill: "#A3B6BF",
                enableBackground: "new",
              }}
              d="M526.8,3.9c2.3,0,4.4,0.2,6.5,0.7
          c-12.5,3-21.7,14.2-21.7,27.5s9.2,24.5,21.7,27.5c-2.1,0.5-4.3,0.7-6.5,0.7c-15.6,0.2-28.3-12.5-28.3-28.1S511.2,3.9,526.8,3.9z"
            />
            <path
              style={{ fill: "#003340" }}
              d="M533.4,59.7c-29.3,5.7-45.8-31.2-23.6-49.1c6.4-5.3,15.2-7.6,23.6-6c-17-4.8-35.6,8-35.8,27.6
          C497.7,51.6,516.2,64.7,533.4,59.7z"
            />
            <path
              style={{
                opacity: "0.46",
                fill: "#C7D8EA",
                enableBackground: "new",
              }}
              d="M526.8,30c0,6.4,5.2,11.7,11.7,11.7s11.7-5.2,11.7-11.7
          s-5.2-11.7-11.7-11.7S526.8,23.6,526.8,30z"
            />
          </g>
          <g>
            <g>
              <ellipse
                style={{ fill: "#003340" }}
                cx="297.7"
                cy={265}
                rx="297.7"
                ry="133.6"
              />
              <g>
                <path
                  style={{ fill: "#A2BFDC" }}
                  d="M560.5,327.8c-5.3,4.4-11.1,8.8-17.4,12.9c15.4-44.4,22.1-93.6,20.5-135.7
              c4.8,4.3,9.2,8.8,13,13.3C576.5,253.1,571.2,291.4,560.5,327.8z"
                />
                <path
                  style={{ fill: "#A2BFDC" }}
                  d="M18.7,218.2c3.9-4.6,8.2-9.1,13.1-13.4c-2.5,42.5,6,92.6,23,137.6c-6.6-4.2-12.6-8.5-18.2-13
              C24.8,292.3,18.6,253.3,18.7,218.2z"
                />
              </g>
            </g>
            <path
              style={{ fill: "#A2BFDC" }}
              d="M214,425.6c51.9,19.9,122.3,19.5,165.5-1.4c115.5-56,177.3-152.4,159.1-248.7
          c-9.3-49.1-64.1-84.4-110.3-95.3c-83.8-19.9-173.3-21.1-257.6-3.4c-45.2,9.6-89.9,40.3-107.5,84.4
          C26.4,253.3,106,384.3,214,425.6z"
            />
            <path
              style={{ fill: "#C7D8EA" }}
              d="M411.2,153.1c-75.5-5.9-151.4-5.9-226.9,0c-121.1,9.4-162.8,209.6,56.2,239.6
          c69.5,9.5,152.9,4.5,213.9-33.2C549.8,300.4,515.8,161.2,411.2,153.1z"
            />
            <path
              style={{ fill: "#003340" }}
              d="M301.2,393.6c-19.9,0-40.1-1.4-60.2-4.2C125.9,373.7,86.6,311.5,88.1,258.6
          c1.6-54.1,43-98,96.4-102.2c37.5-2.9,75.6-4.4,113.2-4.4l0,0c37.6,0,75.7,1.4,113.2,4.4c45.7,3.5,82.3,34.7,93.1,79.5
          c11.4,47-8.8,94.4-51.5,120.9C403.4,387.2,343.2,393.6,301.2,393.6L301.2,393.6z"
            />
          </g>
        </g>
        <path
          style={{ fill: "#00FFFF" }}
          d="M236.4,231.5c0,12.3-10,22.3-22.3,22.3c-12.3,0-22.3-10-22.3-22.3c0-12.3,10-22.3,22.3-22.3
      C226.4,209.2,236.4,219.2,236.4,231.5z"
        />
      </g>
      <path
        style={{ fill: "#00FFFF" }}
        d="M401.5,233.8c0,12.3-10,22.3-22.3,22.3c-12.3,0-22.3-10-22.3-22.3c0-12.3,10-22.3,22.3-22.3
    C391.5,211.5,401.5,221.5,401.5,233.8z"
      />
    </svg>
  );
}
