import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Switch } from "@/components/ui/switch";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormDescription,
} from "@/components/ui/form";
import { useEffect } from "react";

import { Calendar as CalendarIcon, CircleDashed } from "lucide-react";
import "react-day-picker/dist/style.css";
import { useStore } from "@/store/store";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { api_update_profile, api_delete_account, api_use_code } from "@/lib/chat";

function formatValidUntilDate(date: string) {
  const dateObj = new Date(date);
  // format as 12 februarie 2013
  return `${dateObj.getDate()} ${dateObj.toLocaleString("ro", {
    month: "long",
  })} ${dateObj.getFullYear()}`;
}

function UserSettings() {
  const [date, setDate] = useState<Date[]>();
  const { userProfile, setUserProfile, dialogSettings, setDialogSettings } =
    useStore();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const formSchema = z.object({
    audio_output: z.boolean(),
  });
  const [accessCode, setAccessCode] = useState<string>("");
  const [isActivating, setIsActivating] = useState<boolean>(false);

  function handleDeleteAcount() {
    if (confirm("Ești sigur că vrei să îți stergi contul? Nu vei putea recupera datele!")) {
      api_delete_account((data, error) => {
        if (data) {
          window.location = "/";
        }
      });
    } else {
      console.log("Account not deleted");
    }
  }

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
  });

  useEffect(() => {
    if (userProfile) {
      console.log("userProfile", userProfile);
      form.setValue("audio_output", userProfile.audio_output);
    }
  }, [userProfile]);

  function onSubmit(values: z.infer<typeof formSchema>) {
    if (values.audio_output == userProfile?.audio_output) {
      return;
    }

    setIsLoading(true);

    if (values.audio_output) {
      gtag('event', 'audio_answers_on', {
        'event_category': 'Audio',
        'event_label': 'Enabled Audio Answers',
        'value': 1
      });
    } else {
      gtag('event', 'audio_answers_off', {
        'event_category': 'Audio',
        'event_label': 'Disabled Audio Answers',
        'value': 1
      });
    }
    api_update_profile(values, (data, error) => {
      if (data) {
        console.log("data", data);
        setUserProfile(data);
      }
      setIsLoading(false);
    });
  }

  return (
    <div>
      <Dialog
        open={dialogSettings}
        onOpenChange={() => setDialogSettings(false)}
      >
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>Setari</DialogTitle>
            <DialogDescription></DialogDescription>
          </DialogHeader>
          <Form {...form}>
            <form onChange={form.handleSubmit(onSubmit)} className="space-y-8">
              <div className="space-y-3">
                <FormField
                  control={form.control}
                  name="audio_output"
                  render={({ field }) => (
                    <div className="space-y-3">
                      <FormItem className="flex flex-row items-center justify-between rounded-lg border p-4">
                        <div className="space-y-0.5">
                          <FormLabel className="text-base">
                            Funcție de voce
                          </FormLabel>
                          <FormDescription>
                            Activează sau dezactivează funcția de voce a robotului.
                          </FormDescription>
                        </div>
                        <FormControl>
                          <Switch
                            disabled={isLoading}
                            checked={field.value}
                            onCheckedChange={field.onChange}
                          />
                        </FormControl>
                      </FormItem>
                      <FormItem className={
                        `flex flex-row items-center justify-between rounded-lg border p-4 ${userProfile?.is_admin ? "" : "hidden"}`
                      }>
                        <div className="space-y-0.5">
                          <FormLabel className="text-base">
                            Vezi gândurile profesorului
                          </FormLabel>
                          <FormDescription>
                            Activează sau dezactivează funcția depanare.
                          </FormDescription>
                        </div>
                        <FormControl>
                          <Switch
                            disabled={isLoading}
                            checked={userProfile?.debug_enabled}
                            onClick={(e) => {
                              e.preventDefault();
                              const newValue = !userProfile?.debug_enabled;
                              setUserProfile({ ...userProfile, debug_enabled: newValue });
                              api_update_profile({ debug_enabled: newValue }, (data, error) => { })
                            }}
                          />
                        </FormControl>
                      </FormItem>
                      <FormItem className="flex flex-row items-center justify-between rounded-lg border p-4">
                        <div className="space-y-0.5 flex items-start gap-3">
                          <div className="w-full">
                            <FormLabel className="text-base">
                              {userProfile?.account_licensed ? "Cont Licențiat" : "Cont Nelicențiat"}
                            </FormLabel>
                            <FormDescription>
                              <div className="w-full mt-2">
                                {userProfile?.account_licensed ? `Ai licență activă până la ${formatValidUntilDate(userProfile?.licensed_until)}.` : "Nu ai nici o liciență activă."}
                              </div>
                              <div className="flex mt-4">
                                <Input
                                  value={accessCode}
                                  onChange={(e) => setAccessCode(e.target.value)}
                                ></Input>
                              </div>
                            </FormDescription>
                          </div>
                          <div>
                            <Button variant={"link"} className="p-0 mt-5">
                              <a href="https://profesorul.ai" target="_blank">
                                Cumpără liciență
                              </a>
                            </Button>
                            <Button
                              disabled={isActivating}
                              onClick={(e) => {
                                e.preventDefault();
                                setIsActivating(true);
                                console.log("activate code: ", accessCode);
                                api_use_code(accessCode, (data, error) => {
                                  if (data.profile) {
                                    console.log("data", data);
                                    setUserProfile(data.profile);
                                    alert("Codul a fost activat cu succes!");
                                  } else {
                                    const error = data.error;
                                    alert(error);
                                  }
                                  setIsActivating(false);
                                });
                              }}>
                              <CircleDashed
                                className={(!isActivating && "hidden") || "animate-spin mr-2"}
                              />
                              Activează codul
                            </Button>
                          </div>
                        </div>
                      </FormItem>
                      <FormItem className="flex flex-row items-center justify-between rounded-lg border p-4">
                        <div className="space-y-0.5 flex items-start gap-3">
                          <div>
                            <FormLabel className="text-base">
                              Șterge Contul
                            </FormLabel>
                            <FormDescription>
                              Șterge contul tău de pe platformă și toate datele
                              asociate lui.
                            </FormDescription>
                          </div>
                          <div>
                            <Button
                              variant={"destructive"}
                              className="mt-7"
                              onClick={(e) => {
                                e.preventDefault();
                                handleDeleteAcount();
                              }}
                            >
                              Sterge
                            </Button>
                          </div>
                        </div>
                      </FormItem>
                    </div>
                  )}
                />
              </div>
            </form>
          </Form>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default UserSettings;
