import { useState, useCallback, useEffect, useRef } from "react";
import { useStore } from "@/store/store";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
} from "@/components/ui/dialog";
import { CropTool } from "@/components/ui/CropTool";
import { v4 as uuid } from "uuid";

function ImageCrop() {
  const { settings, addImageToPrompt } = useStore();
  const { setSelectedImage } = settings; // Corrected useState usage

  const handleCrop = useCallback((croppedImage: string) => {
    if (croppedImage) {
      const newImage: ImageType = {
        blob: croppedImage,
        id: uuid(),
      };
      addImageToPrompt(newImage);
    }
    setSelectedImage("");
  }, []);

  return (
    <div>
      <Dialog
        open={settings.selectedImage ? true : false}
        onOpenChange={() => setSelectedImage("")}
      >
        <DialogContent className="w-full max-w-2xl p-0 border border-gray-200 h-full md:h-auto">
          <div className="h-full flex items-center">
            <div className="w-full justify-center text-center items-center pt-4">
              {settings.selectedImage && (
                <div className="w-full bg-white p-3 md:p-7  text-center flex flex-col justify-center items-center rounded-md">
                  <CropTool src={settings.selectedImage} onCrop={handleCrop} />
                </div>
              )}
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default ImageCrop;
