import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { useState } from "react";
import { Input } from "@/components/ui/input";
import { api_register } from "../lib/chat";
import { Avatar } from "@/components/ui/Robo/Avatar";
import { Link } from "react-router-dom";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { AlertTriangle, CheckCircle, CircleDashed } from "lucide-react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";

import LogoNewEdu from "@/components/ui/logo";

const formSchema = z.object({
  email: z.string().email({ message: "Email nevalid" }),
  password: z.string().min(6, {
    message: "Parola trebuie sa contina minim 6 caractere",
  }),
  code: z.string().min(1, { message: "Codul de invitație este necesar" }),

  terms: z.boolean().refine((v) => v === true, {
    message: "Trebuie sa fii de acord cu termenii si conditiile pentru beta",
  }),
});

export default function Register() {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
      password: "",
      code: "",
      terms: false,
    },
  });
  const [message, setMessage] = useState({});
  const [open, setOpen] = useState(false);

  // 2. Define a submit handler.
  function onSubmit(values: z.infer<typeof formSchema>) {
    // Do something with the form values.
    // ✅ This will be type-safe and validated.
    console.log(values);
    setMessage({});
    api_register(values, (dataOrNull) => {
      if (dataOrNull) {
        if (dataOrNull.status == "success") {
          setMessage({
            messge: "Înregistrare reușită!",
            type: "success",
          });
          window.location.href = "/tutorial";
        } else {
          setMessage({
            messge: dataOrNull.error,
            type: "error",
          });
        }
      } else {
        setMessage({
          messge: "A apărut o eroare la înregistrare",
          type: "error",
        });
      }
    });
  }
  return (
    <div className="flex min-h-screen bg-x-blue-700 w-full">
      <Dialog open={open} onOpenChange={() => setOpen(!open)}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle className="text-center">
              Disclaimer <span className="text-x-blue-700">Profesorul.ai</span>
            </DialogTitle>
            <DialogDescription>
              <div className=" mt-3 space-y-3 text-black">
                <p>
                  Această aplicație, denumită "Profesorul Virtual", este oferită
                  de NEW EDU (PRECURSOR SRL) și este pusă la dispoziție în
                  varianta Beta pentru a fi testată înainte de lansarea
                  oficială.{" "}
                </p>
                <p>
                  În calitate de utilizator al acestei variante de testare, vă
                  rugăm să luați în considerare următoarele aspecte:
                </p>
                <ul className="list-decimal ml-5 space-y-3">
                  <li>
                    <b>Varianta de testare poate conține probleme și erori</b>:
                    Fiind o versiune Beta, aplicația poate conține probleme,
                    erori sau funcționalități neterminate. Utilizând această
                    variantă, înțelegeți și sunteți de acord că este posibil să
                    întâmpinați anumite inconveniente sau probleme în timpul
                    utilizării.
                  </li>
                  <li>
                    <b>Feedback-ul este esențial</b> : După perioada de testare,
                    echipa noastră vă va contacta pentru a solicita feedback-ul
                    dumneavoastră cu privire la experiența utilizării
                    aplicației. Prin utilizarea acestei variante Beta, sunteți
                    de acord să fiți contactat în acest scop și să împărtășiți
                    feedback-ul dumneavoastră cu echipa noastră de dezvoltare.
                  </li>
                  <li>
                    <b>Exonerare de răspundere</b>: NEW EDU (PRECURSOR SRL) și
                    echipele sale asociate nu își asumă responsabilitatea pentru
                    eventualele pierderi, daune sau consecințe cauzate de
                    utilizarea acestei variante Beta a aplicației Profesorul
                    Virtual.
                  </li>
                  <p>
                    Prin utilizarea acestei variante Beta a aplicației,
                    înțelegeți și sunteți de acord cu aceste condiții și
                    restricții, și vă angajați să furnizați feedback-ul necesar
                    pentru îmbunătățirea continuă a produsului. Vă recomandăm să
                    citiți cu atenție Termenii și Condițiile de utilizare a
                    aplicației.
                  </p>
                </ul>
              </div>
            </DialogDescription>
          </DialogHeader>
        </DialogContent>
      </Dialog>
      <div className="bg-white p-7 z-10 h-full flex min-h-screen items-center w-full sm:w-[400px]">
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="space-y-4 w-full"
          >
            <div className="space-y-3">
              <div className="flex items-center gap-3 justify-center">
                <Avatar /> <h1 className="text-3xl font-bold">Inregistrare</h1>
              </div>
            </div>
            {message && message.messge && (
              <Alert
                variant={message.type == "error" ? "destructive" : "success"}
              >
                {message.type == "error" ? (
                  <AlertTriangle className="w-5 h-5" />
                ) : (
                  <CheckCircle className="w-5 h-5" />
                )}
                <AlertTitle>
                  {message.type == "error" ? "Eroare" : "Succes"}
                </AlertTitle>
                <AlertDescription>{message.messge}</AlertDescription>
              </Alert>
            )}
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input placeholder="" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Parola</FormLabel>
                  <FormControl>
                    <Input placeholder="" {...field} type="password" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="code"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Cod access</FormLabel>
                  <FormControl>
                    <Input placeholder="" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="terms"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <input className="cursor-pointer" type="checkbox" {...field} />
                  </FormControl>
                  <FormLabel className="pl-2">
                    Sunt de acord cu{" "}
                    <b className="underline cursor-pointer text-x-blue-700" onClick={(e) => { e.preventDefault(); setOpen(true) }}>
                      termenii si conditiile pentru Profesorul AI beta
                    </b>
                    .{" "}
                  </FormLabel>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button className="w-full" type="submit">
              Inregistrare
            </Button>
            <div>
              <Link to="/login" className="text-x-blue-700 hover:underline">
                Ai deja cont? <b>Logheaza-te</b>
              </Link>
            </div>
            <div className="mt-5">&nbsp;</div>
            <div className="mt-10">
              <div className="w-full text-center mt-10">
                <a href="https://profesorul.ai" className="text-x-blue-700">profesorul.ai</a> este un proiect realizat de
              </div>
              <div className="flex justify-center w-full">
                <div className="flex w-[200px]">
                  <LogoNewEdu />
                </div>
              </div>

            </div>

          </form>
        </Form>
      </div>
      <div className="flex-1">
        <div className="max-h-screen h-screen items-end flex justify-end">
          <>
            {/*?xml version="1.0" encoding="utf-8"?*/}
            {/* Generator: Adobe Illustrator 25.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  */}
            <svg
              className="max-h-[700px] w-full"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 500 500"
              xmlSpace="preserve"
            >
              <g id="Background_Complete">
                <g>
                  <g>
                    <g>
                      <defs>
                        <path id="SVGID_1_" d="M245.3,33.2" />
                      </defs>
                      <use
                        xlinkHref="#SVGID_1_"
                        style={{ overflow: "visible", fill: "#F5F5F5" }}
                      />
                      <clipPath id="SVGID_2_">
                        <use
                          xlinkHref="#SVGID_1_"
                          style={{ overflow: "visible" }}
                        />
                      </clipPath>
                    </g>
                  </g>
                  <g>
                    <g>
                      <g>
                        <rect
                          x="32.7"
                          y="122.5"
                          style={{ fill: "#F5F5F5" }}
                          width="48.8"
                          height="96.7"
                        />
                      </g>
                    </g>
                    <g>
                      <g>
                        <rect
                          x="83.4"
                          y="122.5"
                          style={{ fill: "#F5F5F5" }}
                          width="52.7"
                          height="96.7"
                        />
                      </g>
                    </g>
                    <g>
                      <g>
                        <rect
                          x="83.4"
                          y={226}
                          style={{ fill: "#F5F5F5" }}
                          width="52.7"
                          height="93.4"
                        />
                      </g>
                    </g>
                    <g>
                      <g>
                        <polygon
                          style={{ fill: "#F5F5F5" }}
                          points="142.8,120.9 198.3,120.9 198.3,35.1 200.2,35.1 200.2,120.9 245.3,120.9 245.3,33.2 
						142.8,33.2 					"
                        />
                      </g>
                    </g>
                    <g>
                      <g>
                        <rect
                          x="200.2"
                          y="122.5"
                          style={{ fill: "#F5F5F5" }}
                          width="45.2"
                          height="96.7"
                        />
                      </g>
                    </g>
                    <g>
                      <g>
                        <rect
                          x="200.2"
                          y={226}
                          style={{ fill: "#F5F5F5" }}
                          width="45.2"
                          height="93.4"
                        />
                      </g>
                    </g>
                    <g>
                      <g>
                        <rect
                          x="32.7"
                          y={226}
                          style={{ fill: "#F5F5F5" }}
                          width="48.8"
                          height="93.4"
                        />
                      </g>
                    </g>
                    <g>
                      <g>
                        <rect
                          x="142.8"
                          y={226}
                          style={{ fill: "#F5F5F5" }}
                          width="55.5"
                          height="93.4"
                        />
                      </g>
                    </g>
                    <g>
                      <g>
                        <rect
                          x="32.7"
                          y={321}
                          style={{ fill: "#F5F5F5" }}
                          width="48.8"
                          height="84.2"
                        />
                      </g>
                    </g>
                    <g>
                      <g>
                        <rect
                          x="200.2"
                          y={321}
                          style={{ fill: "#F5F5F5" }}
                          width="45.2"
                          height="84.2"
                        />
                      </g>
                    </g>
                    <g>
                      <g>
                        <polygon
                          style={{ fill: "#F5F5F5" }}
                          points="81.5,120.9 81.5,35.1 83.4,35.1 83.4,120.9 136.1,120.9 136.1,33.2 32.7,33.2 
						32.7,120.9 					"
                        />
                      </g>
                    </g>
                    <g>
                      <g>
                        <rect
                          x="142.8"
                          y={321}
                          style={{ fill: "#F5F5F5" }}
                          width="55.5"
                          height="84.2"
                        />
                      </g>
                    </g>
                    <g>
                      <g>
                        <rect
                          x="83.4"
                          y={321}
                          style={{ fill: "#F5F5F5" }}
                          width="52.7"
                          height="84.2"
                        />
                      </g>
                    </g>
                    <g>
                      <g>
                        <rect
                          x="142.8"
                          y="122.5"
                          style={{ fill: "#F5F5F5" }}
                          width="55.5"
                          height="96.7"
                        />
                      </g>
                    </g>
                  </g>
                </g>
                <g>
                  <g>
                    <g>
                      <rect
                        x="438.4"
                        y="47.7"
                        style={{ fill: "#E0E0E0" }}
                        width="8.1"
                        height="174.8"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        style={{ fill: "#F5F5F5" }}
                        d="M320.4,49.1v173.5c0,0.1,0.1,0.1,0.1,0.1H437c0.1,0,0.1-0.1,0.1-0.1V49.1c0-0.1-0.1-0.1-0.1-0.1
					H320.5C320.5,49,320.4,49.1,320.4,49.1z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <g>
                        <path
                          style={{ fill: "#EBEBEB" }}
                          d="M438.4,224.1H319.2c-0.1,0-0.1-0.1-0.1-0.1V47.8c0-0.1,0.1-0.1,0.1-0.1h119.2
						c0.1,0,0.1,0.1,0.1,0.1v176.2C438.5,224,438.4,224.1,438.4,224.1z M321.9,221.3h113.8c0.1,0,0.1-0.1,0.1-0.1V50.5
						c0-0.1-0.1-0.1-0.1-0.1H321.9c-0.1,0-0.1,0.1-0.1,0.1v170.8C321.8,221.3,321.8,221.3,321.9,221.3z"
                        />
                      </g>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        style={{ fill: "#FFFFFF" }}
                        d="M332.2,59.8v151.9c0,0.1,0.1,0.1,0.1,0.1h93.2c0.1,0,0.1-0.1,0.1-0.1V59.8c0-0.1-0.1-0.1-0.1-0.1
					h-93.2C332.2,59.7,332.2,59.8,332.2,59.8z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        style={{ fill: "#EBEBEB" }}
                        d="M404.9,164.3h-52.3c0,0-0.1,0-0.1-0.1v-61.3c0,0,0-0.1,0.1-0.1h52.3c0,0,0.1,0,0.1,0.1
					L404.9,164.3C405,164.3,405,164.3,404.9,164.3z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <g>
                        <path
                          style={{ fill: "#E0E0E0" }}
                          d="M400.9,156.8c0.1,0.2-2.5,1.3-7.4,0.8c-2.4-0.3-5.4-1.2-8.4-3.3c-2.9-2.1-5.6-5.6-6.5-10.2
						c-1-4.6,0.6-10.3,5-14c1.1-0.9,2.4-1.7,3.8-2.2c1.5-0.6,3.1-0.7,4.7-0.5c1.6,0.2,3.3,0.8,4.7,2.1c1.4,1.3,2.2,3.5,1.9,5.5
						c-0.3,2-1.4,3.5-2.6,4.7c-1.2,1.2-2.6,2.1-4,2.9c-2.8,1.6-6,2.5-9.4,2.7c-3.4,0.2-6.9-0.5-10-2.3c-3.1-1.7-5.7-4.4-7.4-7.7
						c-1.7-3.3-2.2-7.2-1.3-10.7c0.9-3.5,2.8-6.7,5.7-8.8c1.4-1.1,3-1.9,4.7-2.4c1.8-0.4,3.6-0.5,5.3-0.2c1.8,0.4,3.6,1.2,4.8,2.7
						c1.3,1.5,1.9,3.4,1.9,5.2c0,1.8-0.5,3.5-1.3,4.9c-0.8,1.4-1.9,2.6-3,3.6c-2.3,1.9-4.8,3.2-7.4,3.9c-2.6,0.8-5.3,0.9-7.8,0.6
						c-2.5-0.4-4.9-1.3-6.8-2.6c-3.9-2.6-6.1-6.6-6.5-10.3c-0.4-3.7,0.9-6.8,2.4-8.8c1.5-2,3.3-3.1,4.5-3.6c1.2-0.5,1.9-0.4,2-0.3
						c0.1,0.2-0.6,0.4-1.5,1.1c-1,0.7-2.3,1.8-3.5,3.7c-1.1,1.9-2,4.6-1.4,7.5c0.5,2.9,2.5,6.1,5.8,8.1c3.2,2,7.6,2.5,11.9,1.1
						c2.1-0.7,4.2-1.8,6-3.4c1.7-1.5,2.9-3.5,2.8-5.6c-0.1-2.1-1.4-3.5-3.6-4c-1.1-0.2-2.3-0.1-3.4,0.2c-1.1,0.3-2.3,0.9-3.2,1.7
						c-2,1.6-3.5,3.9-4.1,6.5c-0.6,2.6-0.3,5.3,1,7.8c1.2,2.5,3.3,4.5,5.6,5.9c2.3,1.3,5.1,1.9,7.8,1.8c2.7-0.1,5.3-0.9,7.6-2.1
						c1.2-0.6,2.2-1.3,3-2.1c0.8-0.8,1.4-1.7,1.5-2.5c0.2-0.8-0.1-1.5-0.6-2c-0.6-0.6-1.5-1-2.5-1.1c-1-0.2-2.1-0.1-3,0.3
						c-1,0.3-1.9,0.9-2.8,1.5c-1.7,1.3-2.9,3.1-3.6,5c-0.7,1.9-0.9,3.8-0.6,5.7c0.6,3.8,2.7,6.9,5,8.9c2.4,2,5,3,7.2,3.6
						C398.2,156.9,400.8,156.4,400.9,156.8z"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
              <g id="Background_Simple"></g>
              <g id="Desk">
                <g>
                  <g>
                    <path
                      style={{ fill: "#455A64" }}
                      d="M11.3,456c153.8,0,311.6,0,473.4,0"
                    />
                  </g>
                  <g>
                    <g>
                      <path
                        style={{ fill: "#263238" }}
                        d="M484.7,456c0,0.1-106,0.3-236.7,0.3c-130.8,0-236.7-0.1-236.7-0.3c0-0.1,106-0.3,236.7-0.3
					C378.7,455.7,484.7,455.8,484.7,456z"
                      />
                    </g>
                  </g>
                </g>
              </g>
              <g id="Speech_Bubbles_2">
                <g>
                  <g>
                    <g>
                      <g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <path
                                          style={{ fill: "#FFFFFF" }}
                                          d="M393.5,253.5l4.3-7.3c-1.6-4.6-1.9-9.6-0.7-14.7c3.3-14.6,17.8-23.7,32.4-20.3
														c14.6,3.3,23.7,17.8,20.3,32.4c-3.3,14.6-17.8,23.7-32.4,20.3c-6.1-1.4-11.2-4.7-14.9-9.2l0,0L393.5,253.5z"
                                        />
                                      </g>
                                    </g>
                                  </g>
                                  <g>
                                    <g>
                                      <g>
                                        <g>
                                          <path
                                            style={{ fill: "#263238" }}
                                            d="M393.5,253.5c0,0,0.2,0,0.6,0.1c0.4,0.1,1,0.1,1.7,0.2c1.6,0.2,3.8,0.5,6.8,0.9
															c0.1,0,0.1,0,0.1-0.1c0,0,0,0,0-0.1c0,0,0,0,0,0l-0.2,0.1c2.2,2.7,5.7,5.9,10.8,8c2.5,1.1,5.4,1.7,8.5,1.9
															c3.1,0.2,6.4-0.1,9.8-1.1c3.3-1,6.6-2.7,9.5-5.2c2.9-2.4,5.4-5.6,7.2-9.2c3.5-7.3,3.7-16.8-0.7-24.6
															c-2.2-4-5.2-7.3-8.8-9.7c-3.6-2.4-7.7-3.9-11.7-4.4c-4.1-0.5-8.1-0.1-11.7,1.1c-3.6,1.2-6.9,3.1-9.5,5.4
															c-5.3,4.6-8.2,10.7-9,16c-0.9,5.4,0,10,1.1,13.3l0-0.1c-1.4,2.4-2.4,4.3-3.2,5.5c-0.3,0.6-0.6,1.1-0.8,1.4
															C393.6,253.3,393.5,253.5,393.5,253.5s0.1-0.2,0.3-0.5c0.2-0.3,0.5-0.8,0.9-1.4c0.8-1.2,1.8-3.1,3.2-5.4
															c0,0,0-0.1,0-0.1c-1.1-3.3-1.9-7.9-1-13.2c0.9-5.3,3.7-11.2,9-15.7c2.6-2.2,5.8-4.1,9.4-5.2c3.6-1.1,7.5-1.6,11.5-1.1
															c4,0.5,8,2,11.5,4.3c3.6,2.3,6.5,5.6,8.7,9.5c4.2,7.7,4.1,17,0.7,24.2c-1.7,3.6-4.2,6.7-7,9.1
															c-2.8,2.4-6.1,4.1-9.3,5.1c-3.3,1-6.6,1.3-9.6,1.1c-3.1-0.2-5.9-0.8-8.4-1.9c-5-2-8.5-5.1-10.7-7.8
															c0-0.1-0.1-0.1-0.2,0c0,0-0.1,0.1,0,0.1c0,0,0,0,0,0l0.1-0.2c-2.9-0.3-5.2-0.6-6.7-0.8c-0.7-0.1-1.3-0.2-1.7-0.2
															C393.8,253.5,393.5,253.5,393.5,253.5z"
                                          />
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g>
                    <g>
                      <g>
                        <g>
                          <path
                            style={{ fill: "#263238" }}
                            d="M414.5,231.9h4.1c0,0,0-3.5,3.6-3.5c1.9,0,2.8,1.2,3.2,2.1c0.5,1.1,0.3,2.3-0.3,3.3
							c-0.7,1.2-2.2,2.2-5,1.7v9.3h4.1v-5.4c0,0,5.7-1.5,5.7-7.8c0-6-5.4-7.4-7.7-7.4C420.4,224.2,414.4,224.6,414.5,231.9z"
                          />
                        </g>
                      </g>
                    </g>
                    <g>
                      <g>
                        <g>
                          <rect
                            x="420.1"
                            y="246.7"
                            style={{ fill: "#263238" }}
                            width="4.1"
                            height="3.9"
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
                <g>
                  <g>
                    <g>
                      <g>
                        <path
                          style={{ fill: "#FFFFFF" }}
                          d="M452.1,305.6c-2.8-13.9-16.7-21.9-29.7-18.2c-12.8,3.7-20.1,17.7-15.9,30.2
						c1.4,4.2,3.9,7.8,7.1,10.5c0.1,0.1,0.2,0.2,0.4,0.3c0,0,0,0,0,0c5.5,3.8,12.3,5.6,18.9,4.7c2.2-0.3,4.4-0.9,6.2-1.7
						C448.8,326.9,454.2,316.1,452.1,305.6z"
                        />
                      </g>
                      <g>
                        <g>
                          <path
                            style={{ fill: "#263238" }}
                            d="M452.1,305.6c0,0,0,0.1,0.1,0.4c0,0.3,0.1,0.6,0.2,1.1c0.2,1,0.3,2.4,0.2,4.3
							c-0.1,1.9-0.4,4.2-1.3,6.7c-0.9,2.5-2.3,5.3-4.6,7.8c-1.1,1.3-2.4,2.5-3.9,3.6c-1.5,1-3.2,2.1-5,2.8c-3.7,1.4-8.1,1.7-12.5,1
							c-2.2-0.4-4.4-1-6.6-1.9c-1.1-0.5-2.1-1-3.2-1.6c-0.5-0.3-1-0.6-1.5-1c-0.5-0.3-1-0.8-1.4-1.2c-3.6-3.3-6.2-7.9-7.1-13
							c-0.5-2.5-0.5-5.1-0.2-7.6c0.1-1.3,0.5-2.5,0.8-3.7c0.2-0.6,0.4-1.2,0.6-1.7c0.2-0.6,0.5-1.1,0.7-1.7c1.1-2.2,2.4-4.2,4-5.9
							l0.6-0.7l0.6-0.6c0.4-0.4,0.8-0.8,1.3-1.2c0.9-0.7,1.8-1.4,2.8-1.9c3.8-2.4,8.1-3.3,12.1-3.4c1-0.1,2,0.1,2.9,0.2
							c1,0.1,1.9,0.3,2.8,0.5c0.9,0.3,1.8,0.5,2.6,0.8c0.8,0.4,1.6,0.6,2.4,1c3,1.5,5.4,3.5,7.2,5.5c0.8,1,1.6,2,2.2,3
							c0.7,0.9,1.1,1.9,1.5,2.7c0.2,0.4,0.4,0.8,0.5,1.2c0.1,0.4,0.3,0.8,0.4,1.1c0.3,0.7,0.4,1.3,0.5,1.8c0.1,0.5,0.2,0.8,0.3,1.1
							C452.1,305.5,452.1,305.6,452.1,305.6c0,0,0-0.1-0.1-0.4c-0.1-0.3-0.2-0.6-0.3-1.1c-0.1-0.5-0.3-1.1-0.5-1.7
							c-0.1-0.3-0.3-0.7-0.4-1.1c-0.1-0.4-0.3-0.8-0.5-1.2c-0.4-0.8-0.9-1.8-1.5-2.7c-0.6-1-1.4-1.9-2.2-2.9c-1.8-2-4.1-3.9-7.1-5.4
							c-0.7-0.4-1.5-0.7-2.3-1c-0.8-0.3-1.7-0.5-2.5-0.8c-0.9-0.1-1.8-0.4-2.7-0.5c-0.9-0.1-1.9-0.2-2.9-0.1c-3.9,0-8.1,1-11.8,3.3
							c-1,0.5-1.8,1.2-2.7,1.9c-0.4,0.3-0.8,0.8-1.3,1.1l-0.6,0.6l-0.6,0.6c-1.6,1.7-2.9,3.7-3.9,5.8c-0.2,0.5-0.5,1.1-0.7,1.6
							c-0.2,0.6-0.4,1.1-0.6,1.7c-0.3,1.2-0.6,2.4-0.8,3.6c-0.3,2.4-0.3,5,0.2,7.4c0.9,5,3.5,9.5,7,12.7c0.4,0.4,0.9,0.8,1.4,1.1
							c0.5,0.3,1,0.6,1.5,0.9c1,0.6,2,1.1,3.1,1.6c2.1,0.9,4.3,1.6,6.4,1.9c4.3,0.7,8.6,0.3,12.3-1c1.8-0.7,3.5-1.7,4.9-2.7
							c1.5-1.1,2.8-2.2,3.9-3.5c2.2-2.5,3.6-5.2,4.6-7.7c0.9-2.5,1.3-4.8,1.4-6.6c0.1-1.9,0-3.3-0.1-4.3c-0.1-0.5-0.1-0.8-0.1-1.1
							C452.1,305.8,452.1,305.6,452.1,305.6z"
                          />
                        </g>
                      </g>
                    </g>
                    <g>
                      <g>
                        <g>
                          <g>
                            <path
                              style={{ fill: "#263238" }}
                              d="M421.2,305.9h3.6c0,0,0-3,3.1-3.1c1.6,0,2.5,1,2.8,1.9c0.4,0.9,0.3,2-0.3,2.9
								c-0.6,1-1.9,1.9-4.4,1.5l0,8.1h3.6v-4.7c0,0,5-1.3,5-6.8c0-5.3-4.7-6.5-6.7-6.5C426.4,299.2,421.1,299.6,421.2,305.9z"
                            />
                          </g>
                        </g>
                      </g>
                      <g>
                        <g>
                          <g>
                            <rect
                              x="426.1"
                              y="318.9"
                              style={{ fill: "#263238" }}
                              width="3.6"
                              height="3.4"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        style={{ fill: "#FFFFFF" }}
                        d="M401.1,310c0,2.6-2.1,4.8-4.8,4.8c-2.6,0-4.8-2.1-4.8-4.8c0-2.6,2.1-4.8,4.8-4.8
					C399,305.2,401.1,307.3,401.1,310z"
                      />
                    </g>
                    <g>
                      <g>
                        <path
                          style={{ fill: "#263238" }}
                          d="M401.1,310c-0.1,0,0-0.4-0.2-1.1c-0.2-0.7-0.7-1.7-1.8-2.5c-1-0.8-2.7-1.2-4.3-0.6
						c-1.6,0.6-3,2.3-3,4.3c0,2,1.4,3.7,3,4.3c1.6,0.6,3.3,0.2,4.3-0.6c1.1-0.8,1.6-1.8,1.8-2.5C401.1,310.4,401.1,310,401.1,310
						c0,0,0,0.1,0,0.3c0,0.2,0,0.5-0.1,0.9c-0.1,0.7-0.6,1.9-1.7,2.8c-1.1,0.9-2.9,1.4-4.7,0.8c-1.8-0.6-3.4-2.5-3.3-4.7
						c0-2.2,1.5-4.1,3.3-4.7c1.8-0.6,3.6-0.1,4.7,0.8c1.1,0.9,1.6,2,1.7,2.8c0.1,0.4,0.1,0.7,0.1,0.9
						C401.1,309.9,401.1,310,401.1,310z"
                        />
                      </g>
                    </g>
                  </g>
                </g>
                <g>
                  <g>
                    <g>
                      <g>
                        <path
                          style={{ fill: "#FFFFFF" }}
                          d="M407.7,175.3c-2.9-14.3-17.2-22.6-30.6-18.7c-13.2,3.8-20.7,18.2-16.4,31.2
						c1.5,4.4,4,8,7.3,10.8c0.1,0.1,0.2,0.2,0.4,0.3c0,0,0,0,0,0c5.7,3.9,12.6,5.8,19.5,4.9c2.3-0.3,4.5-0.9,6.4-1.8
						C404.3,197.2,409.9,186.1,407.7,175.3z"
                        />
                      </g>
                      <g>
                        <g>
                          <path
                            style={{ fill: "#263238" }}
                            d="M407.7,175.3c0,0,0,0.1,0.1,0.4c0.1,0.3,0.1,0.7,0.2,1.1c0.2,1,0.3,2.5,0.2,4.4
							c-0.1,1.9-0.5,4.3-1.4,6.9c-0.9,2.6-2.4,5.4-4.7,8.1c-1.1,1.3-2.5,2.5-4,3.7c-1.6,1.1-3.3,2.1-5.2,2.8
							c-3.9,1.4-8.4,1.8-12.9,1.1c-2.3-0.4-4.6-1-6.8-2c-1.1-0.5-2.2-1-3.3-1.7c-0.5-0.3-1.1-0.6-1.6-1c-0.5-0.3-1-0.8-1.5-1.2
							c-3.7-3.4-6.4-8.1-7.4-13.4c-0.5-2.6-0.5-5.3-0.2-7.8c0.1-1.3,0.5-2.5,0.8-3.8c0.2-0.6,0.4-1.2,0.6-1.8
							c0.2-0.6,0.5-1.2,0.8-1.7c1.1-2.3,2.4-4.3,4.1-6.1l0.6-0.7l0.7-0.6c0.4-0.4,0.9-0.8,1.3-1.2c0.9-0.7,1.9-1.4,2.9-2
							c3.9-2.4,8.4-3.4,12.4-3.5c1-0.1,2,0.1,3,0.2c1,0.1,1.9,0.3,2.9,0.5c0.9,0.3,1.8,0.5,2.7,0.8c0.8,0.4,1.7,0.6,2.4,1.1
							c3.1,1.6,5.6,3.6,7.4,5.7c0.9,1.1,1.7,2.1,2.3,3.1c0.7,1,1.1,2,1.5,2.8c0.2,0.4,0.4,0.9,0.5,1.3c0.1,0.4,0.3,0.8,0.4,1.1
							c0.3,0.7,0.4,1.3,0.5,1.8c0.1,0.5,0.2,0.8,0.3,1.1C407.7,175.2,407.7,175.3,407.7,175.3c0,0-0.1-0.1-0.1-0.4
							c-0.1-0.3-0.2-0.6-0.3-1.1c-0.1-0.5-0.3-1.1-0.6-1.8c-0.1-0.3-0.3-0.7-0.4-1.1c-0.1-0.4-0.3-0.8-0.6-1.2
							c-0.4-0.9-0.9-1.8-1.6-2.8c-0.6-1-1.4-2-2.3-3c-1.8-2-4.3-4-7.4-5.5c-0.8-0.4-1.6-0.7-2.4-1c-0.8-0.3-1.7-0.5-2.6-0.8
							c-0.9-0.2-1.8-0.4-2.8-0.5c-1-0.1-2-0.2-3-0.2c-4,0-8.3,1-12.2,3.4c-1,0.5-1.9,1.3-2.8,2c-0.5,0.4-0.9,0.8-1.3,1.2l-0.6,0.6
							l-0.6,0.7c-1.6,1.7-3,3.8-4,6c-0.2,0.6-0.6,1.1-0.7,1.7c-0.2,0.6-0.4,1.2-0.6,1.8c-0.3,1.2-0.7,2.4-0.8,3.7
							c-0.3,2.5-0.4,5.1,0.2,7.7c0.9,5.1,3.6,9.8,7.2,13.1c0.5,0.4,0.9,0.8,1.4,1.2c0.5,0.3,1,0.7,1.5,1c1,0.6,2.1,1.2,3.2,1.6
							c2.2,1,4.4,1.6,6.6,2c4.5,0.7,8.9,0.4,12.7-1c1.9-0.7,3.6-1.7,5.1-2.8c1.5-1.1,2.8-2.3,4-3.6c2.3-2.6,3.8-5.4,4.7-7.9
							c0.9-2.6,1.3-4.9,1.4-6.8c0.1-1.9,0-3.4-0.1-4.4c-0.1-0.5-0.1-0.9-0.2-1.1C407.7,175.4,407.7,175.3,407.7,175.3z"
                          />
                        </g>
                      </g>
                    </g>
                    <g>
                      <g>
                        <g>
                          <g>
                            <path
                              style={{ fill: "#263238" }}
                              d="M375.9,175.6h3.7c0,0,0-3.1,3.2-3.2c1.7,0,2.5,1.1,2.9,1.9c0.4,1,0.3,2.1-0.3,3
								c-0.6,1-2,2-4.5,1.5v8.4h3.7v-4.9c0,0,5.1-1.3,5.1-7c0-5.4-4.9-6.7-6.9-6.7C381.2,168.7,375.8,169.1,375.9,175.6z"
                            />
                          </g>
                        </g>
                      </g>
                      <g>
                        <g>
                          <g>
                            <rect
                              x="380.9"
                              y={189}
                              style={{ fill: "#263238" }}
                              width="3.7"
                              height="3.5"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        style={{ fill: "#FFFFFF" }}
                        d="M367.5,208.1c0,2.7-2.2,4.9-4.9,4.9s-4.9-2.2-4.9-4.9c0-2.7,2.2-4.9,4.9-4.9
					S367.5,205.4,367.5,208.1z"
                      />
                    </g>
                    <g>
                      <g>
                        <path
                          style={{ fill: "#263238" }}
                          d="M367.5,208.1c-0.1,0,0-0.4-0.3-1.2c-0.2-0.7-0.7-1.8-1.8-2.6c-1.1-0.8-2.8-1.3-4.5-0.6
						c-1.7,0.6-3.1,2.3-3.1,4.4c0,2.1,1.4,3.8,3.1,4.4c1.7,0.6,3.4,0.2,4.5-0.6c1.1-0.8,1.6-1.9,1.8-2.6
						C367.5,208.5,367.5,208.1,367.5,208.1c0,0,0,0.1,0,0.3c0,0.2,0,0.5-0.1,0.9c-0.2,0.8-0.6,1.9-1.8,2.8c-1.1,0.9-3,1.5-4.9,0.8
						c-1.8-0.6-3.5-2.6-3.4-4.9c0-2.3,1.6-4.2,3.4-4.9c1.9-0.7,3.7-0.1,4.9,0.8c1.2,0.9,1.6,2.1,1.8,2.8c0.1,0.4,0.1,0.7,0.1,0.9
						C367.6,208,367.6,208.1,367.5,208.1z"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
              <g id="Speech_Bubbles_1">
                <g>
                  <g>
                    <g>
                      <path
                        style={{ fill: "#FFFFFF" }}
                        d="M172.5,133.9h-67c-9.7,0-17.5,7.8-17.5,17.5v33.1c0,9.7,7.8,17.5,17.5,17.5h49.8l8.6,14.3l8-14.3
					h0.5c9.7,0,17.5-7.8,17.5-17.5v-33.1C190,141.8,182.2,133.9,172.5,133.9z"
                      />
                    </g>
                    <g>
                      <g>
                        <path
                          style={{ fill: "#263238" }}
                          d="M172.5,133.9c0,0,0.1,0,0.4,0c0.3,0,0.7,0,1.2,0c0.5,0,1.2,0.2,2,0.3c0.8,0.2,1.7,0.4,2.7,0.8
						c1.9,0.7,4.3,2,6.4,4.2c1.1,1.1,2,2.4,2.9,3.9c0.8,1.5,1.4,3.3,1.8,5.2c0.4,1.9,0.3,4,0.3,6.1c0,2.1,0,4.4,0,6.7
						c0,4.7,0,9.7,0,15.1c0,2.7,0,5.5,0,8.3c-0.1,2.9-0.8,5.9-2.3,8.5c-0.7,1.3-1.7,2.6-2.8,3.7c-1.1,1.1-2.3,2.1-3.7,2.9
						c-1.4,0.8-2.9,1.5-4.5,1.8c-0.8,0.2-1.6,0.3-2.4,0.5c-0.8,0.1-1.7,0.1-2.5,0.1l0.2-0.1c-2.5,4.5-5.2,9.3-8,14.3l-0.2,0.4
						l-0.2-0.4c-2.7-4.5-5.6-9.3-8.6-14.3l0.2,0.1c-13.1,0-27.1,0-41.6,0c-2.8,0-5.6,0-8.4,0c-2.8,0-5.6-0.8-8.1-2
						c-4.9-2.6-8.4-7.5-9.2-12.9l-0.2-1l-0.1-1c0-0.7,0-1.3,0-2c0-1.3,0-2.6,0-3.9c0-2.6,0-5.2,0-7.7c0-5.1,0-10.1,0-15
						c0-1.2,0-2.4,0-3.6c0-1.2,0-2.4,0.1-3.6c0.3-2.4,1-4.7,2.2-6.7c2.3-4.1,6.2-7,10.4-8.2c0.5-0.1,1.1-0.3,1.6-0.4
						c0.5-0.1,1.1-0.2,1.6-0.2c1.1-0.1,2.1-0.1,3.2-0.1c2.1,0,4.1,0,6.2,0c4,0,7.9,0,11.6,0c14.8,0,26.7,0.1,35,0.1
						c4.1,0,7.3,0,9.5,0c1.1,0,1.9,0,2.5,0C172.2,133.9,172.5,133.9,172.5,133.9s-0.3,0-0.8,0c-0.6,0-1.4,0-2.5,0
						c-2.2,0-5.4,0-9.5,0c-8.3,0-20.2,0-35,0.1c-3.7,0-7.6,0-11.6,0c-2,0-4.1,0-6.2,0c-0.5,0-1,0-1.6,0c-0.5,0-1,0.1-1.6,0.1
						c-0.5,0.1-1,0.2-1.6,0.2c-0.5,0.1-1,0.3-1.6,0.4c-4.1,1.2-7.9,4.1-10.2,8c-1.1,2-1.9,4.2-2.1,6.5c-0.1,1.2-0.1,2.3-0.1,3.6
						c0,1.2,0,2.4,0,3.6c0,4.9,0,9.9,0,15c0,2.6,0,5.1,0,7.7c0,1.3,0,2.6,0,3.9c0,0.7,0,1.3,0,2l0,1l0.1,1c0.8,5.2,4.2,10,9,12.5
						c2.4,1.2,5.1,1.9,7.8,2c2.8,0,5.6,0,8.4,0c14.5,0,28.5,0,41.6,0h0.1l0.1,0.1c3,4.9,5.9,9.8,8.6,14.3l-0.4,0
						c2.8-4.9,5.5-9.8,8.1-14.3l0.1-0.1l0.1,0c0.8,0,1.6-0.1,2.4-0.1c0.8-0.1,1.6-0.2,2.4-0.4c1.6-0.4,3-1,4.4-1.8
						c1.4-0.7,2.6-1.8,3.7-2.8c1.1-1.1,2-2.3,2.7-3.6c1.5-2.6,2.2-5.5,2.3-8.3c0-2.9,0-5.6,0-8.3c0-5.4,0-10.4,0-15.1
						c0-2.3,0-4.6,0-6.7c0-2.1,0.1-4.2-0.3-6.1c-0.3-1.9-0.9-3.6-1.7-5.2c-0.8-1.5-1.8-2.8-2.8-3.9c-2.1-2.2-4.4-3.5-6.3-4.2
						c-1-0.4-1.9-0.6-2.6-0.8c-0.8-0.1-1.4-0.3-2-0.3c-0.5,0-0.9-0.1-1.2-0.1C172.7,133.9,172.5,133.9,172.5,133.9z"
                        />
                      </g>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        style={{ fill: "#263238" }}
                        d="M176,151.9c0,0.1-16.6,0.3-37,0.3c-20.4,0-37-0.1-37-0.3s16.6-0.3,37-0.3
					C159.4,151.6,176,151.7,176,151.9z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        style={{ fill: "#263238" }}
                        d="M176,159.9c0,0.1-16.6,0.3-37,0.3c-20.4,0-37-0.1-37-0.3s16.6-0.3,37-0.3
					C159.4,159.7,176,159.8,176,159.9z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        style={{ fill: "#263238" }}
                        d="M176,167.9c0,0.1-16.6,0.3-37,0.3c-20.4,0-37-0.1-37-0.3s16.6-0.3,37-0.3
					C159.4,167.7,176,167.8,176,167.9z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        style={{ fill: "#263238" }}
                        d="M176,176c0,0.1-16.6,0.3-37,0.3c-20.4,0-37-0.1-37-0.3s16.6-0.3,37-0.3
					C159.4,175.7,176,175.8,176,176z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        style={{ fill: "#263238" }}
                        d="M176,184c0,0.1-16.6,0.3-37,0.3c-20.4,0-37-0.1-37-0.3c0-0.1,16.6-0.3,37-0.3
					C159.4,183.7,176,183.9,176,184z"
                      />
                    </g>
                  </g>
                </g>
                <g>
                  <g>
                    <g>
                      <path
                        style={{ fill: "#FFFFFF" }}
                        d="M224.7,70.1h-75.6c-7.3,0-13.2,5.9-13.2,13.2v26.6c0,7.3,5.9,13.2,13.2,13.2h54.1l8.6,14.3
					l8-14.3h4.8c7.3,0,13.2-5.9,13.2-13.2V83.3C237.9,76,232,70.1,224.7,70.1z"
                      />
                    </g>
                    <g>
                      <g>
                        <path
                          style={{ fill: "#263238" }}
                          d="M224.7,70.1c0,0,0.5,0,1.3,0c0.9,0.1,2.2,0.3,3.8,0.9c1.6,0.6,3.4,1.8,5,3.7
						c0.8,1,1.5,2.1,2.1,3.4c0.5,1.3,0.9,2.8,1,4.4c0.1,6.4,0.1,14.1,0.1,23c0,1.1,0,2.2,0,3.4c0,1.1,0,2.3-0.2,3.5
						c-0.5,2.3-1.6,4.7-3.3,6.5c-0.9,0.9-1.8,1.8-3,2.4c-1.1,0.7-2.3,1.2-3.6,1.5c-0.6,0.2-1.3,0.3-2,0.3c-0.7,0-1.4,0.1-2,0.1
						c-1.3,0-2.7,0-4.1,0l0.2-0.1c-2.5,4.5-5.2,9.3-8,14.3l-0.2,0.4l-0.2-0.3c-2.7-4.5-5.6-9.3-8.6-14.3l0.2,0.1
						c-14.3,0-29.8,0-45.9,0c-2.6,0-5.2,0-7.8,0c-2.6,0-5.3-0.6-7.5-2c-2.2-1.4-4-3.4-5.1-5.7c-1.2-2.3-1.4-5-1.3-7.4
						c0-4.9,0-9.8,0-14.5c0-2.4,0-4.7,0-7.1c0-1.2,0-2.3,0-3.5c0-1.2,0.2-2.3,0.5-3.4c0.6-2.2,1.9-4.2,3.4-5.8
						c1.6-1.6,3.6-2.8,5.7-3.4c1.1-0.3,2.1-0.5,3.2-0.5c1.1-0.1,2.1,0,3.2,0c2.1,0,4.1,0,6.2,0c8.1,0,15.6,0,22.5,0
						c13.7,0,24.8,0.1,32.5,0.1c3.8,0,6.8,0,8.8,0c1,0,1.8,0,2.3,0C224.4,70.1,224.7,70.1,224.7,70.1s-0.3,0-0.8,0
						c-0.5,0-1.3,0-2.3,0c-2,0-5,0-8.8,0c-7.7,0-18.8,0-32.5,0.1c-6.9,0-14.4,0-22.5,0c-2,0-4.1,0-6.2,0c-2.1,0-4.2-0.1-6.2,0.6
						c-2,0.6-3.9,1.7-5.5,3.3c-1.5,1.6-2.7,3.5-3.3,5.6c-0.6,2.1-0.5,4.5-0.5,6.8c0,2.3,0,4.7,0,7.1c0,4.8,0,9.6,0,14.5
						c0,1.2,0,2.5,0.2,3.7c0.2,1.2,0.5,2.4,1.1,3.5c1,2.2,2.8,4.2,4.9,5.5c2.1,1.3,4.6,2,7.2,2c2.6,0,5.2,0,7.8,0
						c16.1,0,31.6,0,45.9,0h0.1l0.1,0.1c3,4.9,5.9,9.8,8.6,14.3l-0.4,0c2.8-5,5.5-9.7,8.1-14.3l0.1-0.1l0.1,0c1.4,0,2.7,0,4.1,0
						c0.7,0,1.3,0,2-0.1c0.6-0.1,1.3-0.1,1.9-0.3c1.3-0.3,2.4-0.8,3.5-1.4c1.1-0.6,2.1-1.4,2.9-2.3c1.7-1.8,2.8-4,3.2-6.3
						c0.2-1.1,0.3-2.3,0.2-3.4c0-1.1,0-2.3,0-3.4c0-8.9,0.1-16.6,0-23c-0.1-1.6-0.4-3-1-4.3c-0.6-1.3-1.3-2.4-2-3.4
						c-1.6-1.9-3.4-3-4.9-3.7C226.6,69.9,224.7,70.2,224.7,70.1z"
                        />
                      </g>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        style={{ fill: "#FFFFFF" }}
                        d="M152,88.7c24.7,0,49.3,0,74,0"
                      />
                    </g>
                    <g>
                      <g>
                        <path
                          style={{ fill: "#263238" }}
                          d="M226,88.7c0,0.1-16.6,0.3-37,0.3c-20.4,0-37-0.1-37-0.3c0-0.1,16.6-0.3,37-0.3
						C209.5,88.5,226,88.6,226,88.7z"
                        />
                      </g>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        style={{ fill: "#FFFFFF" }}
                        d="M152,96.8c24.7,0,49.3,0,74,0"
                      />
                    </g>
                    <g>
                      <g>
                        <path
                          style={{ fill: "#263238" }}
                          d="M226,96.8c0,0.1-16.6,0.3-37,0.3c-20.4,0-37-0.1-37-0.3c0-0.1,16.6-0.3,37-0.3
						C209.5,96.5,226,96.6,226,96.8z"
                        />
                      </g>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        style={{ fill: "#FFFFFF" }}
                        d="M152,104.8c24.7,0,49.3,0,74,0"
                      />
                    </g>
                    <g>
                      <g>
                        <path
                          style={{ fill: "#263238" }}
                          d="M226,104.8c0,0.1-16.6,0.3-37,0.3c-20.4,0-37-0.1-37-0.3s16.6-0.3,37-0.3
						C209.5,104.5,226,104.7,226,104.8z"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
              <g id="Character_2">
                <g>
                  <g>
                    <g>
                      <g>
                        <path
                          style={{ fill: "#263238" }}
                          d="M386,281.9c-1-1.1-2.2-2.2-2.5-3.6c-0.3-1.4,0.4-2.8,0.7-4.2c0.8-3.9-1.6-8.2-5.3-9.5
						c-2.2-0.7-4.9-0.8-6-2.8c-1.1-2,0.3-4.3,1.1-6.4c1.3-3.5,1-7.5-0.9-10.7c-0.3-0.7-0.6-1.5-0.9-2.2c-2.8-5.6-8.6-9.3-14.4-9.3
						c-0.9-4.6-4.7-8.1-8.9-9c0-1.6-0.3-3.2-1.1-4.7c-2.1-4.1-6.6-6.6-11.2-7.3c-6.6-1.1-13.7,1.3-18.3,6.1c-2.5,2.6-4.6,6-8,7.2
						c-3,1-6.3,0.1-9.5,0.5c-4.2,0.5-7.8,3.5-10,7.2c-2.2,3.7-3.1,7.9-3.6,12.2c-0.5,3.9-0.8,8.1-3.5,11c-2.6,2.8-6.8,3.3-10,5.3
						c-6.1,3.7-8.1,12.9-4.1,18.8c1.3,1.9,3.1,3.6,3.3,5.9c0.1,2.1-1.2,3.9-1.8,5.9c-1.6,5.1,2.6,11.2,7.9,11.5
						c2.7,0.2,5.6-0.9,8,0.3c2.4,1.2,3.3,4.1,4.9,6.3c3,4.3,9.4,6,14.1,3.7c2-1,3.9-2.6,6.1-2.4c2.2,0.1,3.9,2,6.1,2.5
						c2.8,0.6,5.5-1.5,7.2-3.9c1.7-2.4,2.9-5.1,5-7.1c2.8-2.5,6.8-3.1,10.4-4.4c5.1-1.8,9.7-5.1,13.2-9.3l-0.4,20c3,0,4.7-1,5.8-2.6
						c0.6,0.4,1.3,0.8,2,1c3.5,1.2,7.7-0.1,9.9-3.1c1.2-1.5,1.9-3.5,3.5-4.5c1.4-0.9,3.2-0.8,4.9-1.1c3.6-0.7,6.8-3.4,8.1-6.8
						C389.1,288.8,388.4,284.7,386,281.9z"
                        />
                      </g>
                      <g>
                        <g>
                          <g>
                            <g>
                              <path
                                style={{ fill: "#FFBF9D" }}
                                d="M318.9,235.2L318.9,235.2c-4.1,2.6-13.5,14.1-13.7,17.5l1,68.7c-0.2,5.2-1,9.6,2,13.8l0,0
									c8.4,10,27.8,11.2,32.2,3c0.3-0.6,0.6-1.2,0.7-1.7c0.1-0.7,0.3-1.1,0.4-1.7c1.2-5.8,1-11.4,1-11.4c0.6-6.7,0.8-9.5,0.8-9.4
									c0,0,14.4-1.3,18.7-19.6c2.1-9.1,2.8-24.2,2.9-36.6c0-2.1-0.5-4.5-1.5-7.1c-5.3-14.3-17.6-21.2-32.3-19.9
									C327.4,231.1,321.7,232.8,318.9,235.2z"
                              />
                            </g>
                          </g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <g>
                                          <g>
                                            <g>
                                              <g>
                                                <g>
                                                  <g>
                                                    <g>
                                                      <g>
                                                        <g>
                                                          <path
                                                            style={{
                                                              fill: "#263238",
                                                            }}
                                                            d="M343.7,283.7c0-0.1,1.6-0.3,4.2-0.4c0.7,0,1.3-0.1,1.4-0.5
																							c0.2-0.5,0-1.2-0.3-2c-0.5-1.6-1-3.3-1.6-5.1c-2.2-7.3-3.7-13.2-3.4-13.3c0.3-0.1,2.3,5.7,4.4,13
																							c0.5,1.8,1,3.5,1.5,5.1c0.2,0.8,0.6,1.6,0.1,2.6c-0.2,0.5-0.7,0.8-1.1,0.9c-0.4,0.1-0.8,0.1-1.1,0.1
																							C345.3,283.9,343.7,283.8,343.7,283.7z"
                                                          />
                                                        </g>
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <g>
                                          <g>
                                            <g>
                                              <g>
                                                <g>
                                                  <g>
                                                    <g>
                                                      <g>
                                                        <path
                                                          style={{
                                                            fill: "#FF9A6C",
                                                          }}
                                                          d="M343.3,314c0,0-12.5-0.1-24.3-8.4c0,0,5.3,13.3,23.9,12.9L343.3,314z"
                                                        />
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <g>
                                          <g>
                                            <g>
                                              <g>
                                                <g>
                                                  <g>
                                                    <g>
                                                      <g>
                                                        <path
                                                          style={{
                                                            fill: "#FF9A6C",
                                                          }}
                                                          d="M342.2,289.8c-0.8-1.3-2.4-2.1-4-2c-1.1,0-2.3,0.5-3,1.3c-0.7,0.9-1,2.1-0.4,3
																						c0.6,1,2,1.4,3.3,1.1c1.2-0.3,2.4-1,3.4-1.7c0.3-0.2,0.6-0.4,0.8-0.7c0.2-0.3,0.3-0.7,0.1-1"
                                                        />
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <g>
                                          <g>
                                            <g>
                                              <g>
                                                <g>
                                                  <g>
                                                    <g>
                                                      <g>
                                                        <g>
                                                          <path
                                                            style={{
                                                              fill: "#263238",
                                                            }}
                                                            d="M336.1,284.9c0.4,0,0.2,2.8,2.4,4.9c2.2,2.1,5.2,2.1,5.2,2.4
																							c0,0.2-0.7,0.5-1.9,0.4c-1.2,0-3-0.6-4.3-1.9c-1.4-1.3-1.9-3-1.9-4.1C335.5,285.5,335.9,284.9,336.1,284.9z"
                                                          />
                                                        </g>
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <g>
                                          <g>
                                            <g>
                                              <g>
                                                <g>
                                                  <path
                                                    style={{ fill: "#263238" }}
                                                    d="M335.6,257.1c-0.3,0.7-2.8,0.2-5.9,0.3c-3,0.1-5.5,0.8-5.9,0.1
																			c-0.2-0.3,0.3-0.9,1.3-1.5c1-0.6,2.6-1.1,4.4-1.2c1.8-0.1,3.4,0.3,4.5,0.9C335.2,256.2,335.8,256.8,335.6,257.1z"
                                                  />
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <g>
                                          <g>
                                            <g>
                                              <g>
                                                <g>
                                                  <path
                                                    style={{ fill: "#263238" }}
                                                    d="M361.1,257.1c-0.6,0.5-2.6-0.2-5.2-0.4c-2.5-0.3-4.7,0-5.1-0.6
																			c-0.2-0.3,0.2-0.9,1.2-1.3c1-0.5,2.5-0.8,4.3-0.6c1.7,0.2,3.2,0.8,4,1.5C361.1,256.2,361.3,256.9,361.1,257.1z"
                                                  />
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <path
                                        style={{ fill: "#FFBF9D" }}
                                        d="M308.9,276.6c0.1-1.4-1.3-4.7-2.6-5.1c-3.6-0.8-10.2-1-10.9,8.2
													c-1,12.6,11.9,11,11.9,10.7C307.4,290.1,308.6,281.4,308.9,276.6z"
                                      />
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <path
                                          style={{ fill: "#FF9A6C" }}
                                          d="M303.9,285.3c-0.1,0-0.2,0.1-0.6,0.3c-0.4,0.1-1,0.2-1.6-0.1c-1.3-0.6-2.2-2.8-2.1-5.1
														c0.1-1.2,0.4-2.2,0.9-3.1c0.5-0.9,1.1-1.5,1.8-1.6c0.7-0.1,1.1,0.3,1.2,0.7c0.1,0.4,0,0.6,0.1,0.6c0,0,0.3-0.2,0.2-0.7
														c0-0.3-0.2-0.6-0.4-0.8c-0.3-0.3-0.7-0.4-1.2-0.4c-1,0-1.9,0.8-2.5,1.8c-0.6,1-1,2.2-1,3.5c-0.1,2.5,1,5,2.8,5.7
														c0.9,0.3,1.6,0.1,2-0.2C303.8,285.6,303.9,285.4,303.9,285.3z"
                                        />
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                        <g>
                          <g>
                            <path
                              style={{ fill: "#455A64" }}
                              d="M303.9,252.7C303.9,252.1,303.6,253.3,303.9,252.7L303.9,252.7z"
                            />
                          </g>
                          <g>
                            <path
                              style={{ fill: "#FF725E" }}
                              d="M355.6,235c-4.7-3.2-8.5-6.8-20-6.8c-8.8,0-15.3,5.1-22.3,11.2c-9,7.8-14.6,20.5-10.9,31.8
								l0,0l3.8,1.5l3.6-8.6c6.2-13.4,19-24.7,32.8-25.3c1.4-0.1,2.7-0.1,3.8,0.1c9.1,0.9,18.5,17.6,18.5,17.6
								S366.6,242.4,355.6,235z"
                            />
                          </g>
                        </g>
                      </g>
                      <g>
                        <path
                          style={{ fill: "#263238" }}
                          d="M306.2,272.6c0.2,2.6,0.6,5,0.8,7.6c0.1,1.1,0.4,2.5,1.4,2.8c0.8,0.2,1.6-0.3,2-1
						c0.4-0.7,0.5-1.5,0.6-2.3c0.8-7.9,1.4-14,2.2-21.9C310.9,261,308.5,266.5,306.2,272.6z"
                        />
                      </g>
                      <g>
                        <g>
                          <path
                            style={{ fill: "#E0E0E0" }}
                            d="M304.1,292.5c0,0,0.1,0.3-0.1,0.7c-0.2,0.4-0.6,1-1.3,1.3c-0.7,0.3-1.7,0.4-2.7,0.1
							c-1-0.3-2-1.2-2.4-2.3c-0.4-1.2-0.2-2.4,0.3-3.3c0.5-0.9,1.4-1.5,2.2-1.7c0.8-0.2,1.4-0.2,1.8-0.1c0.4,0.1,0.6,0.2,0.6,0.2
							c0,0.1-1-0.3-2.3,0.2c-0.7,0.2-1.4,0.7-1.9,1.6c-0.5,0.8-0.6,1.9-0.2,2.9c0.3,1,1.2,1.7,2.1,2.1c0.9,0.3,1.8,0.3,2.4,0
							c0.7-0.3,1.1-0.7,1.3-1C304,292.7,304.1,292.5,304.1,292.5z"
                          />
                        </g>
                      </g>
                      <g>
                        <path
                          style={{ fill: "#263238" }}
                          d="M330.7,241.9c0,0,10,2.8,13.3,1.7c3.8-1.2,5.5-4.1,5.5-4.1S341.7,236.1,330.7,241.9z"
                        />
                      </g>
                      <g>
                        <g>
                          <path
                            style={{ fill: "#263238" }}
                            d="M386,267.5c0,0,0.5-0.4,1.1-1.3c0.6-0.9,1.2-2.4,1.1-4.3c-0.1-1.9-1.3-4.2-3.4-5.7
							c-2.1-1.6-5.1-2.3-8.2-2.1l-0.4,0l0.2-0.4c1-2.1,2-4.3,2.3-6.8c0.3-2,0.2-4-0.4-5.8c-0.6-1.8-1.7-3.3-3-4.4
							c-2.7-2.3-5.8-3.1-8.5-3.3c-2.6-0.2-4.8,0-6.3,0.2c-0.7,0.1-1.3,0.2-1.7,0.2c-0.4,0.1-0.6,0.1-0.6,0.1c0,0,0.2-0.1,0.6-0.2
							c0.4-0.1,1-0.2,1.7-0.3c1.5-0.2,3.6-0.5,6.3-0.3c2.7,0.2,6,1,8.7,3.3c1.4,1.2,2.6,2.7,3.2,4.6c0.6,1.9,0.7,4,0.4,6
							c-0.4,2.6-1.4,4.9-2.4,7l-0.2-0.4c3.2-0.2,6.3,0.6,8.5,2.3c2.2,1.7,3.4,4,3.5,6c0.1,2-0.6,3.6-1.2,4.5
							C386.6,267.2,386,267.5,386,267.5z"
                          />
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            style={{ fill: "#263238" }}
                            d="M282.5,288.7c0,0-0.1,0-0.3,0c-0.2,0-0.5,0-0.8,0c-0.7,0-1.7-0.1-3.1-0.2
							c-1.3-0.2-3-0.4-4.8-1.1c-1.8-0.7-3.8-1.8-5.2-3.8c-1.5-2-2-4.7-1.9-7.6c0-1.4,0.2-2.9,0.4-4.4c0.2-1.5,0.4-3,0.9-4.6
							c0.5-1.5,1.4-3,2.8-4c1.4-1.1,3.3-1.1,5-1.1c1.7,0,3.5,0.1,4.9-0.9c1.4-1,2.1-2.8,2.2-4.5c0.2-1.8-0.1-3.6-0.2-5.4
							c-0.1-1.8,0-3.6,0.2-5.3c0.5-3.4,1.5-6.7,3.1-9.5c1.5-2.8,3.5-5.2,5.7-7c2.2-1.8,4.6-3.1,6.8-3.9c4.6-1.6,8.6-1.5,11.2-1
							c2.6,0.5,4,1.3,4,1.3c0,0-0.1,0-0.3-0.1c-0.2-0.1-0.4-0.2-0.8-0.3c-0.7-0.2-1.7-0.5-3-0.7c-2.6-0.4-6.6-0.6-11.1,1.1
							c-2.2,0.8-4.5,2.1-6.7,3.9c-2.1,1.8-4,4.1-5.5,6.9c-1.5,2.8-2.5,5.9-3,9.3c-0.2,1.7-0.3,3.4-0.2,5.2c0.1,1.8,0.4,3.6,0.2,5.4
							c-0.2,1.8-0.9,3.7-2.4,4.9c-0.8,0.5-1.7,0.8-2.6,0.9c-0.9,0.1-1.8,0.1-2.6,0.1c-1.7,0-3.4,0-4.7,1c-1.3,0.9-2.2,2.3-2.7,3.8
							c-0.5,1.5-0.7,3-0.9,4.5c-0.2,1.5-0.4,2.9-0.4,4.3c-0.1,2.8,0.4,5.4,1.8,7.3c1.4,1.9,3.3,3,5,3.7c1.8,0.7,3.4,1,4.7,1.2
							c1.3,0.2,2.4,0.3,3.1,0.3c0.3,0,0.6,0.1,0.8,0.1C282.4,288.7,282.5,288.7,282.5,288.7z"
                          />
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            style={{ fill: "#263238" }}
                            d="M314.5,239.4c0,0-0.3,0.4-0.8,1.1c-0.3,0.3-0.6,0.8-1,1.3c-0.4,0.5-0.8,1.1-1.2,1.8
							c-0.9,1.3-1.9,2.9-2.8,4.8c-0.9,1.9-1.9,4-2.7,6.3c-0.8,2.3-1.3,4.5-1.7,6.6c-0.4,2.1-0.6,3.9-0.7,5.5
							c-0.1,0.8-0.1,1.5-0.1,2.1c0,0.6,0,1.2,0,1.6c0,0.9,0,1.4,0,1.4c0,0-0.1-0.5-0.2-1.4c0-0.4-0.1-1-0.1-1.6c0-0.6,0-1.4,0-2.2
							c0-1.6,0.2-3.5,0.6-5.6c0.4-2.1,0.9-4.4,1.7-6.7c0.8-2.3,1.7-4.5,2.7-6.3c1-1.9,2-3.5,2.9-4.8c0.4-0.7,0.9-1.2,1.3-1.7
							c0.4-0.5,0.7-0.9,1-1.3C314.2,239.7,314.5,239.4,314.5,239.4z"
                          />
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            style={{ fill: "#263238" }}
                            d="M324.6,234.6c0,0-0.4,0.4-1.2,1.2c-0.4,0.4-0.9,0.8-1.4,1.3c-0.5,0.5-1.2,1.1-1.8,1.8
							c-1.3,1.4-2.8,3.1-4.4,5.1c-1.5,2-3.1,4.3-4.6,6.9c-1.4,2.6-2.6,5.1-3.5,7.5c-0.9,2.4-1.6,4.6-2.1,6.4
							c-0.3,0.9-0.4,1.8-0.6,2.5c-0.2,0.7-0.3,1.4-0.4,1.9c-0.2,1.1-0.3,1.6-0.4,1.6c0,0,0-0.6,0.2-1.7c0.1-0.5,0.2-1.2,0.3-1.9
							c0.2-0.8,0.3-1.6,0.5-2.6c0.4-1.9,1.1-4.1,2-6.5c0.9-2.4,2.1-5,3.5-7.5c1.5-2.6,3.1-4.9,4.6-6.9c1.6-2,3.1-3.7,4.5-5.1
							c0.7-0.7,1.3-1.3,1.9-1.8c0.6-0.5,1.1-0.9,1.5-1.3C324.1,235,324.6,234.6,324.6,234.6z"
                          />
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            style={{ fill: "#455A64" }}
                            d="M289.3,294c0,0-0.1,0.1-0.3,0.2c-0.2,0.1-0.5,0.3-0.9,0.5c-0.8,0.4-2,0.8-3.7,0.8
							c-1.6,0-3.7-0.4-5.7-1.8c-1.9-1.3-3.6-3.7-4.2-6.6c-0.3-1.5-0.4-3-0.1-4.6c0.3-1.6,0.8-3.2,1.7-4.7c0.9-1.5,2-2.9,3.4-4.2
							c1.4-1.3,3-2.2,4.7-3.2c1.7-0.9,3.4-1.9,4.8-3.4c1.4-1.4,2.3-3.3,2.8-5.3c0.5-2,0.8-4.1,0.9-6.3c0.1-2.2,0.1-4.4,0.3-6.6
							c0.2-2.2,0.6-4.5,1.5-6.5c0.8-2.1,2.3-3.8,4.1-5c1.8-1.2,3.8-1.9,5.7-2.5c2-0.6,3.9-1,5.7-1.6c1.8-0.6,3.6-1.3,5-2.3
							c0.4-0.3,0.7-0.5,1-0.9c0.3-0.3,0.6-0.6,0.9-0.9c0.5-0.7,1-1.4,1.4-2.1c0.9-1.5,1.7-3,2.8-4.2c1.1-1.2,2.4-2.1,3.8-2.7
							c2.7-1.2,5.6-1.2,7.8-0.5c2.3,0.7,3.9,2,5,3.2c1.1,1.2,1.7,2.4,2,3.2c0.3,0.8,0.4,1.3,0.4,1.3c0,0-0.1-0.4-0.5-1.2
							c-0.4-0.8-1-1.9-2.1-3.1c-1.1-1.2-2.7-2.4-4.9-3.1c-2.2-0.7-5-0.6-7.6,0.5c-1.3,0.6-2.6,1.4-3.7,2.6c-1.1,1.2-1.8,2.6-2.7,4.1
							c-0.4,0.7-0.9,1.5-1.5,2.2c-0.3,0.4-0.6,0.7-0.9,1c-0.3,0.3-0.7,0.6-1.1,0.9c-1.5,1.1-3.3,1.9-5.2,2.4c-1.9,0.6-3.8,1-5.7,1.6
							c-1.9,0.6-3.9,1.3-5.6,2.4c-1.7,1.1-3.1,2.8-3.9,4.8c-0.8,2-1.2,4.1-1.5,6.3c-0.2,2.2-0.2,4.4-0.3,6.6
							c-0.1,2.2-0.4,4.3-0.9,6.4c-0.6,2-1.5,4-3,5.5c-1.4,1.5-3.2,2.5-4.9,3.4c-1.7,0.9-3.3,1.9-4.7,3.1c-1.4,1.2-2.5,2.6-3.3,4
							c-0.9,1.5-1.4,3-1.7,4.6c-0.2,1.6-0.2,3.1,0.1,4.5c0.6,2.8,2.2,5.1,4,6.5c1.9,1.3,3.9,1.8,5.5,1.8c1.6,0,2.9-0.4,3.6-0.7
							C288.9,294.3,289.2,294,289.3,294z"
                          />
                        </g>
                      </g>
                    </g>
                    <g>
                      <g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <g>
                                          <g>
                                            <g>
                                              <g>
                                                <g>
                                                  <g>
                                                    <g>
                                                      <path
                                                        style={{
                                                          fill: "#263238",
                                                        }}
                                                        d="M358.2,271.9c0,1.3-1,2.4-2.4,2.5c-1.4,0.1-2.5-0.9-2.6-2.2c0-1.3,1-2.4,2.4-2.5
																					C357,269.5,358.1,270.5,358.2,271.9z"
                                                      />
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                    <g>
                      <g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <g>
                                          <g>
                                            <g>
                                              <g>
                                                <g>
                                                  <g>
                                                    <g>
                                                      <g>
                                                        <path
                                                          style={{
                                                            fill: "#263238",
                                                          }}
                                                          d="M360.3,264.3c-0.3,0.3-2.2-1.1-4.9-1.1c-2.7,0-4.6,1.4-4.9,1
																						c-0.1-0.1,0.2-0.7,1-1.4c0.8-0.6,2.3-1.2,3.9-1.2c1.7,0,3.1,0.6,3.9,1.2C360.2,263.5,360.4,264.1,360.3,264.3z
																						"
                                                        />
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                    <g>
                      <g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <g>
                                          <g>
                                            <g>
                                              <g>
                                                <g>
                                                  <g>
                                                    <g>
                                                      <path
                                                        style={{
                                                          fill: "#263238",
                                                        }}
                                                        d="M335,271.9c0,1.3-1,2.4-2.4,2.5c-1.4,0.1-2.5-0.9-2.6-2.2c0-1.3,1-2.4,2.4-2.5
																					C333.8,269.5,334.9,270.5,335,271.9z"
                                                      />
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                    <g>
                      <g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <g>
                                          <g>
                                            <g>
                                              <g>
                                                <g>
                                                  <g>
                                                    <g>
                                                      <g>
                                                        <path
                                                          style={{
                                                            fill: "#263238",
                                                          }}
                                                          d="M334.9,264.3c-0.3,0.3-2.2-1.1-4.9-1.1c-2.7,0-4.6,1.4-4.9,1
																						c-0.1-0.1,0.2-0.7,1-1.4c0.8-0.6,2.3-1.2,3.9-1.2c1.7,0,3.1,0.6,3.9,1.2C334.8,263.5,335.1,264.1,334.9,264.3z
																						"
                                                        />
                                                      </g>
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g>
                    <g>
                      <g>
                        <g>
                          <path
                            style={{ fill: "#FF725E" }}
                            d="M380.4,348.2c-11.6-14.1-21.6-16.1-21.6-16.1l0.1,50.3l36.9,46.7l15-38.4
							C410.8,390.6,388.2,357.7,380.4,348.2z"
                          />
                        </g>
                      </g>
                    </g>
                    <g>
                      <path
                        style={{ fill: "#FF725E" }}
                        d="M342.4,328.1l16.5,4c0,0,8.5,49.5,5.4,62.3c-2.6,10.9-11.1,61.2-11.1,61.2h-102l34.2-98.2
					c0,0,0.2-21.6-5.1-31.6c3.2-0.8,14-2.8,23.3-4.5c0,0,6.2,7.7,20.8,10C339.2,333.6,342.4,328.1,342.4,328.1z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ fill: "#455A64" }}
                        d="M362.7,369.6h-64.1c-5.6,32.8-19.2,45.3-43.3,64.3l-4,21.8h102l5.9-34.2l-0.3-12.6L362.7,369.6z"
                      />
                    </g>
                    <g>
                      <g>
                        <g>
                          <path
                            style={{ fill: "#FF725E" }}
                            d="M282.9,325.5c0,0-24.4,2-33.1,41.9c-4,18.1-9,39.2-10.4,53.4c-1,10.3,2.6,24.6,12.6,26.9
							l18,2.3c8,0.1,13.6-6.4,15.3-13.4l2.8-11.1c1.9-13.6-8.4-14.8-14.3-14.7l12.8-50.7l2.5-24.5L282.9,325.5z"
                          />
                        </g>
                      </g>
                    </g>
                    <g>
                      <g>
                        <g>
                          <g>
                            <path
                              style={{ fill: "#263238" }}
                              d="M366.1,359.4c0,0,0.1,0.2,0.1,0.6c0.1,0.4,0.1,0.9,0.2,1.6c0.1,0.4,0.1,0.7,0.2,1.2
								c0,0.4,0.1,0.9,0.1,1.4c0.1,1,0.2,2.1,0.3,3.4c0.2,2.5,0.2,5.5,0.1,8.9c-0.1,3.3-0.5,7-1.1,10.8c-0.6,3.8-1.3,7.4-2,10.7
								c-0.7,3.3-1.4,6.2-2,8.6c-0.6,2.4-1.1,4.4-1.5,5.8c-0.2,0.7-0.3,1.2-0.4,1.6c-0.1,0.4-0.2,0.5-0.2,0.5c0,0,0-0.2,0.1-0.6
								c0.1-0.4,0.2-0.9,0.4-1.6c0.3-1.4,0.8-3.4,1.4-5.8c0.6-2.5,1.3-5.4,1.9-8.6c0.7-3.3,1.4-6.9,1.9-10.7
								c0.6-3.8,0.9-7.5,1.1-10.8c0.1-3.3,0.2-6.3,0-8.8c0-1.3-0.1-2.4-0.2-3.4c0-0.5-0.1-1-0.1-1.4c0-0.4-0.1-0.8-0.1-1.2
								c-0.1-0.7-0.1-1.2-0.2-1.6C366.1,359.6,366.1,359.4,366.1,359.4z"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                    <g>
                      <g>
                        <g>
                          <g>
                            <path
                              style={{ fill: "#263238" }}
                              d="M254.5,424.8c0,0,0-0.4,0.2-1c0.2-0.6,0.5-1.5,0.9-2.6c1-2.1,2.8-5,5.8-7.1
								c2.9-2.1,6.2-3.1,8.5-3.3c1.2-0.1,2.1-0.1,2.8-0.1c0.7,0,1,0.1,1,0.1c0,0.1-1.5-0.1-3.8,0.2c-2.3,0.3-5.5,1.3-8.3,3.4
								c-2.9,2.1-4.8,4.8-5.8,6.9C254.9,423.4,254.6,424.8,254.5,424.8z"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                    <g>
                      <g>
                        <g>
                          <g>
                            <path
                              style={{ fill: "#263238" }}
                              d="M260.2,404.9c0,0,0.2,0,0.6-0.1c0.4,0,0.9,0,1.6,0.1c1.4,0.1,3.2,0.5,5.1,1.3
								c1.9,0.8,3.5,1.8,4.6,2.7c0.5,0.4,0.9,0.8,1.2,1.1c0.3,0.3,0.4,0.4,0.4,0.5c0,0-0.7-0.5-1.7-1.3c-1.1-0.8-2.7-1.8-4.5-2.6
								c-1.9-0.8-3.7-1.2-5-1.4C261,405,260.2,405,260.2,404.9z"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                    <g>
                      <g>
                        <g>
                          <g>
                            <path
                              style={{ fill: "#263238" }}
                              d="M285.4,353.2c0,0,0-0.4-0.1-1.1c0-0.7,0-1.7-0.1-2.9c-0.2-2.4-0.6-5.8-1.6-9.4
								c-1-3.6-2.4-6.7-3.5-8.9c-0.6-1.1-1-2-1.4-2.5c-0.3-0.6-0.5-0.9-0.5-0.9c0,0,0.2,0.3,0.6,0.9c0.4,0.6,0.9,1.4,1.5,2.5
								c1.2,2.1,2.6,5.3,3.6,8.9c1,3.6,1.4,7,1.5,9.5c0,1.2,0,2.2,0,2.9C285.4,352.8,285.4,353.2,285.4,353.2z"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                    <g style={{ opacity: "0.3" }}>
                      <g>
                        <g>
                          <g>
                            <path d="M365,392.9c6.2-10.5,6.3-24.2,0.9-35.2c1.6,11.7,1.5,23.6-1.1,35.1" />
                          </g>
                        </g>
                      </g>
                    </g>
                    <g>
                      <g>
                        <g>
                          <g>
                            <path
                              style={{ fill: "#263238" }}
                              d="M280.7,445.6c0,0-0.4,0.5-1.3,1.3c-0.9,0.7-2.4,1.6-4.5,2.1c-2,0.6-4.6,0.8-7.3,0.6
								c-2.8-0.2-5.9-0.5-9-1c-3.2-0.5-6.2-1.1-8.8-2c-1.3-0.5-2.3-1.3-3.2-2.2c-0.9-0.8-1.7-1.7-2.3-2.6c-1.2-1.7-2-3.2-2.5-4.3
								c-0.2-0.5-0.4-1-0.5-1.3c-0.1-0.3-0.2-0.4-0.2-0.4c0,0,0,0.2,0.1,0.5c0.1,0.3,0.2,0.7,0.5,1.3c0.4,1.1,1.2,2.7,2.4,4.4
								c0.6,0.9,1.4,1.8,2.3,2.7c0.9,0.9,2,1.7,3.3,2.3c2.7,1,5.7,1.6,8.9,2.1c3.2,0.5,6.3,0.8,9.1,1c2.8,0.1,5.4-0.1,7.4-0.7
								c2.1-0.6,3.6-1.5,4.5-2.3c0.4-0.4,0.8-0.7,1-1C280.6,445.8,280.7,445.6,280.7,445.6z"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                    <g>
                      <g>
                        <g>
                          <g>
                            <path
                              style={{ fill: "#263238" }}
                              d="M284.7,365.9c-0.1,0-2.7,9.9-5.7,22.3c-3.1,12.3-5.5,22.3-5.4,22.4c0.1,0,2.7-9.9,5.7-22.3
								C282.4,376,284.8,366,284.7,365.9z"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                    <g>
                      <g>
                        <g>
                          <g>
                            <path
                              style={{ fill: "#263238" }}
                              d="M287.8,422.2c0,0,0.1-0.3,0.1-0.8c0-0.5,0.1-1.3,0.1-2.3c-0.1-1-0.4-2.1-1.1-3.2
								c-0.4-0.5-0.7-1.1-1.3-1.6c-0.5-0.5-1.1-0.9-1.7-1.3c-2.6-1.4-5.2-2-7.1-2.3c-0.9-0.1-1.7-0.2-2.3-0.2c-0.5,0-0.8,0-0.8,0
								c0,0.1,1.2,0.1,3,0.4c1.8,0.3,4.4,1,6.9,2.4c0.6,0.4,1.2,0.8,1.7,1.2c0.5,0.5,0.9,1,1.2,1.5c0.7,1,1,2.1,1.1,3
								c0.1,0.9,0.1,1.7,0.1,2.2C287.8,421.9,287.8,422.2,287.8,422.2z"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                    <g>
                      <g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <path
                                    style={{ fill: "#FFBF9D" }}
                                    d="M337.1,420.8C337.1,420.8,337.1,420.8,337.1,420.8C337.1,420.8,337.1,420.8,337.1,420.8z"
                                  />
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <path
                                    style={{ fill: "#FFBF9D" }}
                                    d="M336.8,421C336.8,421,336.8,421,336.8,421C336.8,421,336.8,421,336.8,421z"
                                  />
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <path
                                    style={{ fill: "#FFBF9D" }}
                                    d="M337.1,420.9c-0.1,0-0.2,0.1-0.2,0.1C336.9,420.9,337,420.9,337.1,420.9z"
                                  />
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <path
                                    style={{ fill: "#FFBF9D" }}
                                    d="M337.2,420.8C337.2,420.8,337.1,420.8,337.2,420.8C337.2,420.8,337.2,420.8,337.2,420.8z"
                                  />
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <path
                                    style={{ fill: "#FFBF9D" }}
                                    d="M360.1,441.8c-2-3.7-17.2-13.5-17.2-13.5l4.1-1l4.6,1.9c0,0,0.3,1.9,2,4.3
											c1.7,2.4,4.5-0.1,4.5-0.1l-1.9-9.3l-9-5.4c0,0-8.5,1.3-9.9,2l-3.6,3.3c-1.6,1.2-6.2,6.2-7.4,6.3
											c-1.3,0.1-40.7-8.7-40.7-8.7l0,0c-5.4,4.3-8.3,12.8-9.1,18.9c0,0-1,3,0.1,4.7l48.4,3.8l10.3,2.8c0,0,5.7,2.3,8.4,2.9
											c3.4,0.7,5.7-0.4,5.8-2.3c0.1-1.9-2.6-2.1-2.6-2.1s-4.3-1.6-6.6-2.9c-2.3-1.3-3.7-4.1-3.7-4.1l3.7,0.4c0,0,7.8,5.4,10.7,7
											c2.9,1.6,3.5-1.3,3.7-2.2c0.2-0.9-8.7-9.7-8.7-9.7s8.5,5.3,10.1,6.6c1.5,1.3,3.8,1.7,4.3,0.8
											C360.7,445.5,362,445.5,360.1,441.8z"
                                  />
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <path
                                    style={{ fill: "#FFBF9D" }}
                                    d="M336.7,421C336.7,421,336.7,421,336.7,421C336.7,421,336.7,421,336.7,421z"
                                  />
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <path
                                    style={{ fill: "#FFBF9D" }}
                                    d="M336.6,421.1C336.6,421.1,336.6,421.1,336.6,421.1L336.6,421.1z"
                                  />
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <path
                                    style={{ fill: "#FFBF9D" }}
                                    d="M336.6,421C336.6,421.1,336.6,421.1,336.6,421C336.6,421.1,336.6,421.1,336.6,421z"
                                  />
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                      <g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <path
                                    style={{ fill: "#FF9A6C" }}
                                    d="M318,442.7c0,0,0-0.2,0-0.6c0-0.2,0-0.4,0-0.7c0-0.3,0.1-0.6,0.2-0.9
											c0.3-1.4,1.2-3.1,2.5-4.8c1.3-1.7,2.7-3,3.7-3.9c1-0.9,1.7-1.4,1.8-1.4c0,0-0.6,0.6-1.6,1.6c-1,0.9-2.3,2.3-3.6,3.9
											c-1.3,1.6-2.2,3.3-2.5,4.6C318,441.8,318.1,442.7,318,442.7z"
                                  />
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                      <g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <path
                                    style={{ fill: "#FF9A6C" }}
                                    d="M342.5,428.1c0,0.1-1.2,0.2-2.6-0.2c-1.4-0.5-2.3-1.3-2.2-1.4c0.1-0.1,1,0.6,2.3,1
											C341.3,427.9,342.5,428,342.5,428.1z"
                                  />
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                      <g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <path
                                    style={{ fill: "#FF9A6C" }}
                                    d="M345,438.2c0,0.1-1.8-0.5-3.9-1.4c-2.1-0.9-3.8-1.6-3.8-1.7c0-0.1,1.8,0.5,3.9,1.4
											C343.4,437.3,345.1,438.1,345,438.2z"
                                  />
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                      <g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <path
                                    style={{ fill: "#FF9A6C" }}
                                    d="M336.8,443.4c0,0.1-0.9-0.3-2-0.7c-1.1-0.4-2-0.7-1.9-0.8c0-0.1,1,0.1,2.1,0.5
											C336.1,442.8,336.9,443.3,336.8,443.4z"
                                  />
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                      <g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <path
                                    style={{ fill: "#FF9A6C" }}
                                    d="M343.8,438.9c0,0.1-0.8,0.4-1.5,1.3c-0.7,0.9-0.8,1.8-0.9,1.8c0,0-0.1-0.2,0-0.6
											c0.1-0.4,0.2-0.9,0.6-1.4c0.4-0.5,0.8-0.8,1.2-1C343.5,438.8,343.8,438.8,343.8,438.9z"
                                  />
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                      <g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <path
                                    style={{ fill: "#FF9A6C" }}
                                    d="M347.4,433.2c0,0.1-0.6,0.5-1.1,1.2c-0.5,0.8-0.4,1.5-0.6,1.5c-0.1,0-0.3-0.8,0.2-1.7
											C346.5,433.4,347.3,433.1,347.4,433.2z"
                                  />
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                      <g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <path
                                    style={{ fill: "#FF9A6C" }}
                                    d="M346.1,423.1c0,0-0.2-0.1-0.3-0.5c-0.1-0.3-0.2-0.8,0-1.3c0.1-0.5,0.4-0.9,0.6-1.2
											c0.2-0.2,0.4-0.3,0.5-0.3c0.1,0.1-0.6,0.6-0.8,1.6C345.9,422.3,346.2,423.1,346.1,423.1z"
                                  />
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                      <g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <path
                                    style={{ fill: "#263238" }}
                                    d="M275.9,446.7c0,0,0-0.4,0.1-1.1c0-0.4,0-0.8,0.1-1.3c0-0.5,0-1.1,0.1-1.7
											c0.2-2.5,0.8-6,2.1-9.7c0.7-1.8,1.5-3.5,2.3-5c0.4-0.7,0.8-1.4,1.3-2c0.2-0.3,0.4-0.6,0.6-0.9c0.2-0.3,0.4-0.5,0.6-0.8
											c1.6-2,2.8-3,2.8-3.1c0,0-0.3,0.2-0.9,0.7c-0.3,0.2-0.6,0.5-1,0.9c-0.4,0.4-0.8,0.8-1.2,1.3c-0.2,0.2-0.4,0.5-0.6,0.8
											c-0.2,0.3-0.4,0.6-0.6,0.9c-0.5,0.6-0.9,1.3-1.3,2c-0.9,1.5-1.7,3.2-2.4,5c-1.3,3.7-1.9,7.3-2,9.8
											c-0.1,0.6-0.1,1.2-0.1,1.7c0,0.5,0,0.9,0,1.3C275.9,446.3,275.9,446.7,275.9,446.7z"
                                  />
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                    <g style={{ opacity: "0.3" }}>
                      <path d="M309.5,376.3v1.3l-7.4,0.5l-3.7-29.7c0,0-1.6-10-2.6-13.6c-1-3.6-5.4-10.9-5.4-10.9l3.2-0.6l6.3,10.3L309.5,376.3z" />
                    </g>
                    <g>
                      <g>
                        <path
                          style={{ fill: "#455A64" }}
                          d="M310.5,376.3c0,0-3.3-27.6-6.4-38.6c-3.1-11-7.9-14.9-7.9-14.9l-4.7,0.9c0,0,4.7,5.8,6.6,15.9
						c1.8,10.1,5.2,37.1,5.2,37.1L310.5,376.3z"
                        />
                      </g>
                      <g>
                        <g>
                          <path
                            style={{ fill: "#263238" }}
                            d="M310.5,376.3c0,0-0.1,0-0.4,0c-0.3,0-0.8,0.1-1.4,0.1c-1.3,0.1-3.1,0.2-5.4,0.3l-0.1,0l0-0.1
							c-0.7-5-1.7-12.6-3-21.8c-0.7-4.6-1.4-9.7-2.3-15c-0.5-2.7-1.2-5.3-2.2-8c-1-2.6-2.3-5.3-4-7.6l-0.3-0.4l-0.3-0.3l0.4-0.1
							c1.6-0.3,3.2-0.6,4.7-0.9l0.1,0l0.1,0.1c1.5,1.4,2.6,3,3.6,4.6c1,1.7,1.8,3.4,2.5,5.1c1.4,3.4,2.3,6.9,3,10.2
							c1.4,6.6,2.2,12.6,3,17.6c0.7,5,1.2,9,1.5,11.8c0.2,1.4,0.3,2.4,0.4,3.2c0,0.3,0.1,0.6,0.1,0.8
							C310.5,376.2,310.5,376.3,310.5,376.3c0,0,0-0.1-0.1-0.3c0-0.2-0.1-0.5-0.1-0.8c-0.1-0.8-0.3-1.8-0.4-3.2
							c-0.4-2.8-0.9-6.8-1.7-11.8c-0.8-5-1.7-11-3.1-17.6c-0.7-3.3-1.6-6.7-3-10.1c-0.7-1.7-1.5-3.4-2.5-5c-1-1.6-2.1-3.2-3.5-4.5
							l0.2,0.1c-1.5,0.3-3.1,0.6-4.7,0.9l0.2-0.4l0.3,0.4c1.8,2.4,3.1,5.1,4.1,7.8c1,2.7,1.8,5.4,2.2,8.1c0.9,5.3,1.6,10.4,2.3,15
							c1.3,9.3,2.2,16.9,2.8,21.8l-0.1-0.1c2.3-0.1,4.1-0.1,5.3-0.2c0.6,0,1,0,1.4,0C310.3,376.3,310.5,376.3,310.5,376.3z"
                          />
                        </g>
                      </g>
                    </g>
                    <g>
                      <g>
                        <path
                          style={{ fill: "#455A64" }}
                          d="M358.8,332.1c0,0,1.1,30.1-2.7,45.6l-7.2-0.2c0,0,5.4-37.5,4.6-46.8
						C353.6,330.6,356.6,330.6,358.8,332.1z"
                        />
                      </g>
                      <g>
                        <g>
                          <path
                            style={{ fill: "#263238" }}
                            d="M358.8,332.1c0,0-0.4-0.3-1.3-0.6c-0.9-0.3-2.2-0.7-4-0.8l0.1-0.1c0.3,4.2-0.2,10.8-1,18.9
							c-0.8,8.1-2,17.7-3.5,28l-0.2-0.3c0.1,0,0.1,0,0.2,0c2.4,0.1,4.8,0.2,7,0.2l-0.3,0.2c1.4-6.3,2-12.4,2.3-17.8
							c0.4-5.5,0.5-10.4,0.5-14.5c0.1-4.1,0-7.4,0-9.7c0-1.1,0-2,0-2.6C358.8,332.4,358.8,332.1,358.8,332.1c0,0,0,0.3,0.1,0.9
							c0,0.6,0.1,1.5,0.1,2.6c0.1,2.3,0.1,5.6,0.1,9.7c0,4.1-0.1,9-0.4,14.5c-0.3,5.5-0.9,11.6-2.3,17.9l0,0.2l-0.2,0
							c-2.2-0.1-4.6-0.1-7-0.2c-0.1,0-0.1,0-0.2,0l-0.3,0l0-0.3c1.4-10.3,2.6-19.9,3.5-28c0.9-8.1,1.4-14.6,1.3-18.8l0-0.1l0.1,0
							c1.8,0.1,3.2,0.5,4,0.9c0.4,0.2,0.7,0.4,0.9,0.5C358.7,332,358.8,332.1,358.8,332.1z"
                          />
                        </g>
                      </g>
                    </g>
                    <g>
                      <path
                        style={{ fill: "#E0E0E0" }}
                        d="M351.3,373.3c0,1.2,1,2.2,2.2,2.2s2.2-1,2.2-2.2s-1-2.2-2.2-2.2S351.3,372.1,351.3,373.3z"
                      />
                      <path
                        style={{ fill: "#263238" }}
                        d="M353.5,375.5c-1.2,0-2.2-1-2.2-2.2c0-1.2,1-2.2,2.2-2.2c1.2,0,2.2,1,2.2,2.2
					C355.6,374.5,354.7,375.5,353.5,375.5z M353.5,371.2c-1.2,0-2.1,1-2.1,2.1c0,1.2,1,2.1,2.1,2.1c1.2,0,2.1-1,2.1-2.1
					C355.6,372.1,354.6,371.2,353.5,371.2z"
                      />
                    </g>
                    <g>
                      <circle
                        style={{ fill: "#E0E0E0" }}
                        cx="306.4"
                        cy="372.6"
                        r="2.2"
                      />
                      <path
                        style={{ fill: "#263238" }}
                        d="M306.4,374.7c-1.2,0-2.2-1-2.2-2.2c0-1.2,1-2.2,2.2-2.2c1.2,0,2.2,1,2.2,2.2
					C308.6,373.8,307.6,374.7,306.4,374.7z M306.4,370.4c-1.2,0-2.1,1-2.1,2.1s1,2.1,2.1,2.1c1.2,0,2.1-1,2.1-2.1
					S307.6,370.4,306.4,370.4z"
                      />
                    </g>
                    <g>
                      <g>
                        <path
                          style={{ fill: "#263238" }}
                          d="M349.4,395.3c0,0,0,0.1,0,0.3c0,0.2,0,0.6,0,1c0,0.8,0,2.1-0.2,3.7c-0.1,1.6-0.3,3.6-1,5.8
						c-0.6,2.2-1.9,4.6-3.8,6.8c-3.6,4.6-10.1,8.1-17.8,9.1c-1.9,0.2-3.9,0.3-5.9,0.1c-2-0.2-4.1-0.6-6-1.6c-3.8-1.8-7-5.1-9.1-9
						c-2.1-4-2.9-8.3-3.1-12.5c0-1.1-0.1-2.1,0-3.1c0.1-0.5,0.2-1.1,0.4-1.6c0.2-0.5,0.7-0.9,1.2-1.2c1.1-0.4,2.1-0.2,3-0.2
						c1,0,1.9,0.1,2.9,0.1c7.6,0.3,14.5,0.6,20.3,0.8c5.7,0.3,10.4,0.5,13.7,0.6c1.6,0.1,2.8,0.1,3.7,0.2c0.4,0,0.7,0,1,0.1
						c0.2,0,0.3,0,0.3,0c0,0-0.1,0-0.3,0c-0.2,0-0.6,0-1,0c-0.9,0-2.1-0.1-3.7-0.1c-3.3-0.1-7.9-0.3-13.7-0.4
						c-5.8-0.2-12.6-0.4-20.3-0.7c-1,0-1.9-0.1-2.9-0.1c-1,0-2-0.2-2.8,0.2c-0.8,0.4-1.2,1.3-1.3,2.3c-0.1,1-0.1,2,0,3
						c0.2,4.1,1,8.4,3,12.3c2,3.8,5.1,7,8.8,8.8c1.8,0.9,3.8,1.4,5.8,1.5c2,0.2,3.9,0.1,5.8-0.1c7.5-0.9,13.9-4.4,17.5-8.8
						c1.9-2.2,3.2-4.5,3.8-6.7c0.7-2.2,0.9-4.1,1.1-5.7c0.2-1.6,0.2-2.9,0.3-3.7c0-0.4,0-0.7,0.1-1
						C349.4,395.5,349.4,395.3,349.4,395.3z"
                        />
                      </g>
                    </g>
                    <g style={{ opacity: "0.3" }}>
                      <g>
                        <path
                          d="M293.5,423.4c0.5-3.7-0.6-7.4-2.9-10.3s-6-4.8-9.7-5c-0.4-12.6,0.5-25.3,2.8-37.7l-10,40c0,0,13.9,0.2,14.2,11.8
						L293.5,423.4z"
                        />
                      </g>
                    </g>
                  </g>
                  <g style={{ opacity: "0.3" }}>
                    <g>
                      <path
                        d="M304.1,271.1c1.1-8.2,3.9-16.1,8.4-23.1c0.5-0.8,1-1.7,0.5-2.5c-0.4-0.7-1.4-0.8-2.1-0.5c-0.7,0.3-1.2,1-1.7,1.6
					c-4.9,6.9-6.8,15.9-5.1,24.2"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <path
                                  style={{ fill: "#E0E0E0" }}
                                  d="M476.9,386.4l-14.1,69.5h-98.1H340c-1.4,0-2.5-1.1-2.5-2.5l0,0c0-1.4,1.1-2.5,2.5-2.5h24.7
										l13.1-64.6c0.3-1.2,1.3-2.1,2.6-2.1l93.9-1C476,383.1,477.3,384.7,476.9,386.4z"
                                />
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                      <g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <path
                                    style={{ fill: "#263238" }}
                                    d="M476.9,386.4c0,0,0-0.1,0-0.2c0-0.1,0-0.4,0-0.6c0-0.5-0.3-1.4-1.3-2
											c-0.2-0.1-0.5-0.2-0.8-0.3c-0.3-0.1-0.6-0.1-1-0.1c-0.7,0-1.4,0-2.2,0c-1.6,0-3.4,0-5.5,0c-8.3,0.1-20.4,0.2-35.5,0.3
											c-7.5,0.1-15.8,0.2-24.7,0.2c-4.5,0-9.1,0.1-13.9,0.1c-2.4,0-4.8,0-7.3,0.1c-1.2,0-2.5,0-3.7,0c-0.3,0-0.6,0-1,0
											c-0.3,0-0.7,0.1-0.9,0.3c-0.6,0.3-1.1,0.8-1.3,1.4c-0.1,0.3-0.2,0.6-0.3,0.9l-0.2,0.9c-0.1,0.6-0.3,1.2-0.4,1.9
											c-0.3,1.3-0.5,2.5-0.8,3.8c-0.5,2.5-1,5.1-1.6,7.7c-1.1,5.2-2.1,10.5-3.3,16c-2.2,10.9-4.5,22.2-6.9,33.9l0.2-0.1
											c-4.6,0-9.4,0-14.2,0c-2.4,0-4.9,0-7.3,0l-1.8,0h-0.9c-0.3,0-0.6,0-1,0.1c-0.6,0.1-1.2,0.5-1.6,1.1
											c-0.4,0.5-0.5,1.2-0.4,1.9c0.1,0.8,0.6,1.5,1.3,1.9c0.4,0.2,0.8,0.3,1.2,0.3l1.1,0c1.5,0,3,0,4.5,0c3,0,6,0,8.9,0
											c5.9,0,11.7,0,17.5,0c11.5,0,22.6,0,33.2,0c21.3,0,40.8,0,57.7,0l0.1,0l0-0.1c4.3-21.3,7.8-38.7,10.3-50.7
											c1.2-6,2.2-10.7,2.8-13.9c0.3-1.6,0.6-2.8,0.7-3.6C476.9,386.8,476.9,386.4,476.9,386.4s-0.1,0.4-0.3,1.2
											c-0.2,0.8-0.4,2-0.8,3.6c-0.7,3.2-1.6,7.9-2.9,13.9c-2.5,12-6,29.4-10.4,50.7l0.1-0.1c-16.9,0-36.3,0-57.7,0
											c-10.7,0-21.8,0-33.2,0c-5.7,0-11.6,0-17.5,0c-3,0-5.9,0-8.9,0c-1.5,0-3,0-4.5,0l-1.1,0c-0.3,0-0.7-0.1-1-0.3
											c-0.6-0.3-1.1-1-1.2-1.7c-0.1-0.6,0-1.2,0.4-1.6c0.3-0.5,0.8-0.8,1.4-0.9c0.3-0.1,0.6,0,0.9-0.1l0.9,0h1.8
											c2.5,0,4.9,0,7.3,0c4.8,0,9.6,0,14.2,0h0.1l0-0.1c2.4-11.7,4.7-23,6.9-33.9c1.1-5.4,2.2-10.8,3.2-16
											c0.5-2.6,1.1-5.2,1.6-7.7c0.3-1.3,0.5-2.5,0.8-3.8c0.1-0.6,0.3-1.3,0.4-1.9l0.2-0.9c0.1-0.3,0.1-0.6,0.2-0.9
											c0.2-0.5,0.7-1,1.2-1.3c0.3-0.1,0.6-0.2,0.8-0.2c0.3,0,0.6,0,0.9,0c1.2,0,2.5,0,3.7,0c2.5,0,4.9-0.1,7.3-0.1
											c4.8-0.1,9.4-0.1,13.9-0.2c8.9-0.1,17.2-0.2,24.7-0.3c15.1-0.2,27.2-0.3,35.5-0.4c2.1,0,3.9-0.1,5.5-0.1
											c0.8,0,1.5,0,2.2,0c0.3,0,0.7,0,1,0.1c0.3,0.1,0.6,0.2,0.8,0.3c0.9,0.6,1.2,1.4,1.3,2C477,386.1,476.9,386.4,476.9,386.4z
											"
                                  />
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                    <g>
                      <g>
                        <g>
                          <g>
                            <g>
                              <path
                                style={{ fill: "#FFFFFF" }}
                                d="M420.4,418.2c0,2.2,1.8,4.1,4.1,4.1c2.2,0,4.1-1.8,4.1-4.1c0-2.2-1.8-4.1-4.1-4.1
									C422.2,414.1,420.4,415.9,420.4,418.2z"
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                    <g>
                      <g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <path
                                  style={{ fill: "#263238" }}
                                  d="M476.8,386.9c0,0-0.1,0-0.4,0c-0.3,0-0.7,0-1.2,0c-1.1,0-2.7,0-4.8,0
										c-4.2,0-10.2,0-17.6,0.1c-14.8,0.1-35.3,0.2-57.9,0.4c-2.8,0-5.6,0-8.4,0.1c-0.7,0-1.4,0-2.1,0c-0.3,0-0.7,0-1.1,0.1
										c-0.4,0.1-0.7,0.2-1,0.4c-0.6,0.4-1.1,1-1.2,1.7c-0.2,0.7-0.3,1.3-0.4,2c-1,5.2-2,10.3-3,15.1c-1.9,9.6-3.6,18.3-5,25.5
										c-1.4,7.3-2.6,13.2-3.4,17.2c-0.4,2-0.7,3.6-0.9,4.7c-0.1,0.5-0.2,0.9-0.2,1.2c0,0.3-0.1,0.4-0.1,0.4s0-0.1,0.1-0.4
										c0.1-0.3,0.1-0.7,0.3-1.2c0.2-1.1,0.5-2.7,1-4.7c0.8-4.1,2-10,3.5-17.2c1.5-7.3,3.2-15.9,5.1-25.5c1-4.8,2-9.9,3-15.1
										c0.1-0.6,0.3-1.3,0.4-2c0.2-0.6,0.6-1.2,1.1-1.5c0.3-0.2,0.6-0.3,0.9-0.4c0.3-0.1,0.6,0,1-0.1c0.7,0,1.4,0,2.1,0
										c2.8,0,5.5,0,8.4-0.1c22.6-0.2,43.1-0.4,57.9-0.5c7.4-0.1,13.4-0.1,17.6-0.2c2.1,0,3.7-0.1,4.8-0.1c0.5,0,1,0,1.2,0
										C476.7,386.9,476.8,386.9,476.8,386.9z"
                                />
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
              <g id="Character_1">
                <g>
                  <g>
                    <g>
                      <g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <path
                                  style={{ fill: "#263238" }}
                                  d="M277.5,202.8c3.4,2,8.3,1,10.7-2.1c2.4-3.2,1.9-8.2-1.1-10.8c3.3-1.3,5.5-4.8,5.3-8.3
										c-0.2-3.5-2.8-6.8-6.2-7.7c1.3-3.2,0.8-7.1-1.3-9.9c-2.1-2.8-5.7-4.3-9.2-3.9c-2.1-4.6-7.7-7.2-12.6-6
										c-2.8-2-6.9-1.8-9.6,0.3c-2.7,2.1-3.8,6.1-2.5,9.3"
                                />
                              </g>
                            </g>
                          </g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <path
                                        style={{ fill: "#FFBF9D" }}
                                        d="M278.1,204.3c6-16.9-1.9-35.6-18.3-43l-1.6-0.7c-18-6.9-33.9,4.5-40.3,22.7l-28,62.6l0,0
													l14.4,46.4l21.6-23l10.5-12.1c0,0,18,5,25.8-11.8C266.1,237.3,272.5,220,278.1,204.3z"
                                      />
                                    </g>
                                  </g>
                                </g>
                              </g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <g>
                                          <path
                                            style={{ fill: "#FF9A6C" }}
                                            d="M236.6,257.2c0,0-11.1-4.2-19.2-16.4c0,0-1.8,13.9,15.7,20L236.6,257.2z"
                                          />
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <g>
                                          <g>
                                            <g>
                                              <g>
                                                <path
                                                  style={{ fill: "#263238" }}
                                                  d="M266.8,217.6c-0.5,1.3-2.1,1.9-3.4,1.4c-1.4-0.5-2-2-1.5-3.3
																		c0.5-1.3,2.1-1.9,3.4-1.4C266.7,214.8,267.3,216.3,266.8,217.6z"
                                                />
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <g>
                                          <g>
                                            <g>
                                              <g>
                                                <g>
                                                  <path
                                                    style={{ fill: "#263238" }}
                                                    d="M270.8,210.9c-0.4,0.2-1.7-2-4.2-3.2c-2.6-1.2-5.1-0.7-5.2-1.2
																			c-0.1-0.2,0.5-0.6,1.6-0.9c1.1-0.2,2.7-0.2,4.3,0.5c1.6,0.7,2.7,1.9,3.2,2.9C271,210.1,271,210.8,270.8,210.9z"
                                                  />
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <g>
                                          <g>
                                            <g>
                                              <g>
                                                <path
                                                  style={{ fill: "#263238" }}
                                                  d="M243,207.5c-0.5,1.3-2.1,1.9-3.4,1.4c-1.4-0.5-2-2-1.5-3.3c0.5-1.3,2.1-1.9,3.4-1.4
																		C242.9,204.7,243.5,206.2,243,207.5z"
                                                />
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <g>
                                          <g>
                                            <g>
                                              <g>
                                                <g>
                                                  <path
                                                    style={{ fill: "#263238" }}
                                                    d="M245.1,199.9c-0.4,0.2-1.7-2-4.2-3.2c-2.6-1.2-5.1-0.7-5.2-1.2
																			c-0.1-0.2,0.5-0.6,1.6-0.9c1.1-0.2,2.7-0.2,4.3,0.5c1.6,0.7,2.7,1.9,3.2,2.9C245.3,199.1,245.3,199.8,245.1,199.9
																			z"
                                                  />
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <g>
                                          <g>
                                            <g>
                                              <g>
                                                <path
                                                  style={{ fill: "#263238" }}
                                                  d="M245.9,223.3c0.1-0.2,1.8,0.3,4.5,1.1c0.7,0.2,1.4,0.4,1.7,0
																		c0.4-0.4,0.4-1.2,0.4-2.2c0.1-1.9,0.2-3.9,0.3-5.9c0.5-8.4,1.2-15.2,1.5-15.2c0.3,0,0.2,6.8-0.3,15.2
																		c-0.1,2.1-0.3,4-0.4,5.9c-0.1,0.9,0,1.9-0.8,2.7c-0.4,0.4-1,0.6-1.5,0.5c-0.5-0.1-0.9-0.2-1.2-0.4
																		C247.4,224.2,245.8,223.5,245.9,223.3z"
                                                />
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <path
                                          style={{ fill: "#263238" }}
                                          d="M249.9,191.4c-0.6,0.5-2.5-1.2-5.2-2.5c-2.7-1.4-5.2-1.9-5.1-2.7c0-0.4,0.8-0.7,2-0.8
														c1.2-0.1,2.9,0.2,4.5,1c1.6,0.8,2.8,2,3.4,3C250.1,190.4,250.2,191.2,249.9,191.4z"
                                        />
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <path
                                          style={{ fill: "#263238" }}
                                          d="M275.3,201.9c-0.8,0.3-2.7-1.2-5.2-2.4c-2.5-1.3-4.9-1.7-5.1-2.6
														c-0.1-0.4,0.5-0.8,1.7-1c1.2-0.1,3,0.1,4.7,1c1.7,0.8,3,2.1,3.7,3.1C275.6,200.9,275.6,201.7,275.3,201.9z"
                                        />
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <path
                                        style={{ fill: "#FFBF9D" }}
                                        d="M213.3,200.9c1.1-2.6-0.2-5.7-2.9-6.6c-3.3-1.1-7.6-0.9-10.3,5.7
													c-4.8,11.7,7.9,14.1,8.1,13.8C208.2,213.5,211.3,205.8,213.3,200.9z"
                                      />
                                    </g>
                                  </g>
                                </g>
                              </g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <path
                                        style={{ fill: "#FF9A6C" }}
                                        d="M207.4,208.3c0-0.1-0.3,0.1-0.7,0.1c-0.4,0-1-0.1-1.5-0.6c-1-1-1.3-3.4-0.5-5.5
													c0.4-1.1,1.1-2,1.8-2.7c0.7-0.7,1.5-1.1,2.2-0.9c0.7,0.1,1,0.7,1,1.1c0,0.4-0.1,0.6-0.1,0.6c0,0,0.3-0.1,0.4-0.6
													c0-0.3,0-0.6-0.2-0.9c-0.2-0.3-0.6-0.6-1-0.8c-0.9-0.3-2.1,0.2-2.9,0.9c-0.8,0.7-1.6,1.8-2.1,3
													c-0.9,2.4-0.6,5.1,0.9,6.3c0.7,0.6,1.5,0.6,2,0.5C207.2,208.6,207.4,208.3,207.4,208.3z"
                                      />
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                            <g>
                              <path
                                style={{ fill: "#263238" }}
                                d="M279.4,176c0-0.3,0-0.7,0-1c-0.4-2.5-2.1-4.7-4.3-5.9c-1.1-1.3-2.2-2.5-3.3-3.5
									c-0.4-3.5-2.4-6.7-5.4-8.5c-3.4-2-7.9-1.9-11.3,0.1c-0.5-0.1-1-0.3-1.5-0.4c-1.6-2.8-4.4-4.9-7.6-5.5
									c-3.5-0.7-7.4,0.4-10,2.9c-2.5-2-6.2-2.4-9-0.9c-2.8,1.5-4.6,4.7-4.3,7.9c-2.6,0.2-5.3,1.5-6.4,3.9c-1.1,2.2,0,5.4,2.3,6.1
									c-2.9,5.3-5.2,12.1-7.5,18c-1.6,4.1,2.4,8.8,1.6,11.7c-4.5,14.8,3.5,9.1,18.4-19c0.4-0.1,0.8-0.2,1.2-0.3
									c0.9-0.3,1.3-0.9,2.1-1.3c1-0.5,2,0.3,2.9,0.8c3.2,1.9,7.7,1,10-1.8c0.3,1.7,2.3,2.5,4,2.3c1.7-0.3,3.1-1.2,4.7-1.9
									c1.5-0.7,3.4-1.1,4.8-0.2c0.8,0.5,1.4,1.4,2.3,1.7c1.6,0.5,3.1-0.9,4-2.3c0.8-1.2,1.6-2.5,2.8-3.1c1.1,0,2.2,0.1,3.3,0.5
									c0.5,0.3,1.1,0.7,1.6,1c0.2,0.2,0.5,0.3,0.8,0.4c0.3,0.3,0.6,0.6,0.9,0.9c4,4.8,3.7,17.3,3.7,17.3
									C283.4,189.3,282.3,182.1,279.4,176z"
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                    <g>
                      <g>
                        <path
                          style={{ fill: "#FFFFFF" }}
                          d="M246.3,157.5c0,0,0.1-0.1,0.3-0.2c0.2-0.2,0.5-0.4,0.9-0.6c0.8-0.5,2-1,3.7-1.2
						c1.7-0.2,3.8,0.1,5.7,1.3c1.9,1.2,3.7,3.2,4.4,5.8l-0.3-0.2c1.1-0.4,2.4-0.7,3.7-0.8c3.1-0.3,6.2,0.7,8.2,2.5
						c2.1,1.8,3.1,4.2,3.1,6.3c0,2.1-0.7,3.6-1.4,4.5c-0.3,0.5-0.7,0.8-0.9,1c-0.2,0.2-0.4,0.3-0.4,0.3c0,0,0.5-0.4,1.1-1.3
						c0.6-0.9,1.3-2.4,1.2-4.4c0-2-1-4.3-3-5.9c-2-1.7-4.9-2.6-7.9-2.3c-1.2,0.1-2.4,0.4-3.5,0.8l-0.3,0.1l-0.1-0.3
						c-0.6-2.5-2.3-4.4-4.1-5.6c-1.8-1.2-3.9-1.4-5.5-1.3c-1.6,0.1-2.9,0.6-3.7,1C246.7,157.2,246.3,157.5,246.3,157.5z"
                        />
                      </g>
                    </g>
                    <g>
                      <g>
                        <path
                          style={{ fill: "#FFFFFF" }}
                          d="M221,161.2c0,0,0.1,0,0.2-0.1c0.1-0.1,0.3-0.2,0.6-0.3c0.5-0.2,1.3-0.5,2.4-0.5
						c1.1,0,2.4,0.1,3.7,0.8c1.4,0.6,2.8,1.8,3.6,3.5l-0.4,0c1.5-2,4.3-3.4,7.4-3.6c1.5-0.1,3.1,0.1,4.7,0.6c1.6,0.5,3,1.3,4.4,2.4
						c2.6,2,4.6,4.6,6.1,7.1l-0.4,0c2.2-2.1,5.1-3.1,7.7-3.1c2.6,0,4.7,0.9,6.2,2c1.5,1.1,2.4,2.3,3,3.1c0.3,0.4,0.4,0.8,0.5,1
						c0.1,0.2,0.2,0.4,0.1,0.4c0,0-0.3-0.5-0.8-1.3c-0.6-0.8-1.5-1.9-3-2.9c-1.5-1-3.6-1.8-6.1-1.8c-2.4,0-5.2,1-7.3,3l-0.2,0.2
						l-0.2-0.3c-1.5-2.4-3.4-5-6-6.9c-1.3-1-2.8-1.8-4.2-2.3c-1.5-0.5-3-0.7-4.5-0.6c-3,0.1-5.6,1.5-7.1,3.4l-0.2,0.3l-0.1-0.3
						c-0.7-1.6-2-2.8-3.3-3.4c-1.3-0.7-2.6-0.9-3.6-0.9C222,160.5,221,161.3,221,161.2z"
                        />
                      </g>
                    </g>
                    <g>
                      <g>
                        <path
                          style={{ fill: "#455A64" }}
                          d="M270.3,175c0,0-0.1,0.1-0.4,0.4c-0.3,0.2-0.6,0.6-1,1.1c-0.4,0.5-0.9,1.2-1.5,2
						c-0.6,0.8-1.3,1.7-2.4,2.3c-0.6,0.3-1.3,0.4-2,0.1c-0.7-0.2-1.3-0.6-1.9-1c-0.6-0.4-1.3-0.7-2-0.7c-0.7,0-1.5,0.2-2.3,0.5
						c-0.8,0.3-1.5,0.7-2.3,1.1c-0.8,0.4-1.6,0.9-2.6,1.1c-1,0.2-2,0-2.9-0.3c-0.5-0.2-0.9-0.4-1.4-0.7c-0.4-0.3-0.8-0.8-0.9-1.4
						l0.5,0.1c-0.5,0.6-1,1.1-1.6,1.6c-0.6,0.4-1.4,0.8-2.1,1c-1.5,0.4-3.2,0.4-4.8-0.1c-0.7-0.2-1.3-0.5-1.9-0.8
						c-0.6-0.3-1.1-0.7-1.7-0.8c-0.6-0.1-1.1,0.1-1.7,0.4c-0.6,0.3-1.2,0.6-1.8,0.8c-2.4,0.8-5,1.1-7.2,0.1c-2.1-1-3.6-2.9-4.4-4.8
						c-0.8-1.9-0.9-3.9-0.5-5.5l0.1,0.2c-1.6-0.4-2.8-1.5-3.3-2.6c-0.6-1.1-0.6-2.3-0.4-3.2c0.2-0.9,0.6-1.5,0.8-1.8
						c0.1-0.2,0.3-0.3,0.3-0.4c0.1-0.1,0.1-0.1,0.1-0.1c0.1,0-0.8,0.7-1.1,2.4c-0.2,0.8-0.1,1.9,0.4,3c0.6,1.1,1.7,2.1,3.2,2.4
						l0.2,0l0,0.2c-0.3,1.6-0.2,3.5,0.6,5.3c0.8,1.8,2.2,3.5,4.2,4.5c2,0.9,4.5,0.7,6.8-0.1c0.6-0.2,1.1-0.4,1.7-0.7
						c0.5-0.3,1.3-0.6,2-0.5c0.4,0,0.7,0.1,1,0.3l0.8,0.5c0.6,0.3,1.2,0.6,1.8,0.7c1.5,0.5,3.1,0.5,4.5,0.1c0.7-0.2,1.4-0.5,2-0.9
						c0.6-0.4,1.1-0.9,1.5-1.5l0.4-0.5l0.1,0.6c0.1,0.9,1,1.4,1.9,1.7c0.9,0.3,1.8,0.4,2.7,0.3c0.9-0.1,1.6-0.6,2.4-1
						c0.8-0.4,1.6-0.8,2.3-1.1c0.8-0.3,1.6-0.6,2.4-0.6c0.9,0,1.6,0.4,2.2,0.8c0.6,0.4,1.2,0.8,1.8,1c0.6,0.2,1.2,0.2,1.8-0.1
						c1.1-0.5,1.7-1.5,2.3-2.2c0.6-0.8,1.1-1.4,1.5-1.9c0.4-0.5,0.8-0.9,1.1-1.1C270.1,175.1,270.2,175,270.3,175z"
                        />
                      </g>
                    </g>
                  </g>
                  <g>
                    <path
                      style={{ fill: "#263238" }}
                      d="M26,395c0,0-5.2,13.8-7,17.5c-1.8,3.7,0,43.5,0,43.5h117.7l1.7-35l-56.7-34.2L26,395z"
                    />
                  </g>
                  <g>
                    <g>
                      <path
                        style={{ fill: "#FFBF9D" }}
                        d="M276.1,339.3l46.3,8.7c0,0-0.9,20-0.3,19.5c0.6-0.5-64.9-0.6-64.9-0.6L276.1,339.3z"
                      />
                    </g>
                    <g>
                      <g>
                        <path
                          style={{ fill: "#FFBF9D" }}
                          d="M321.6,348l32.1,9.9l14.1,9.9l12.6,13.5c0,0-0.1,3.8-4.9,1.4c-4.7-2.4-9.4-6.8-9.4-6.8l9.4,17.2
						c0,0-3.8,3.8-6.8-1.7C365.6,386,361,379,361,379l-2.8-2.1c0,0,2.9,5.2,2.9,6.6c0,1.4-2.9,9.2-2.9,9.2l-4.6,6.3
						c0,0-2.8-0.3-2.6-3.2c0.2-2.9,1.9-5,1.9-5l-5.6,4.9c0,0-2-1.1-0.3-4.7l2.1-2.2l0.7-3.5l-1.7-2.7c0,0-4.3,0.3-7.7-4.7
						c-3.4-5-8.5-4-15.8-8.4c-7.3-4.3-6.6-9.1-6.6-9.1L321.6,348z"
                        />
                      </g>
                      <g>
                        <g>
                          <path
                            style={{ fill: "#FF9A6C" }}
                            d="M338.9,360.3c0.1,0,0.1,1.6,1.2,3.9c0.6,1.1,1.4,2.4,2.5,3.6c1.1,1.2,2.5,2.4,4,3.7
							c1.5,1.2,3,2.3,4,3.7c1,1.4,1.4,3.1,1,4.4c-0.4,1.3-1.4,2-2.1,2.2c-0.4,0.1-0.6,0.1-0.8,0.1c-0.2,0-0.3-0.1-0.3-0.1
							c0-0.1,0.4,0,1.1-0.2c0.6-0.2,1.5-0.9,1.8-2.1c0.3-1.2-0.1-2.7-1-4c-1-1.3-2.5-2.4-4-3.6c-1.5-1.2-2.9-2.5-4-3.7
							c-1.1-1.3-2-2.6-2.5-3.8c-0.5-1.2-0.8-2.2-0.9-2.9C338.8,360.7,338.8,360.3,338.9,360.3z"
                          />
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            style={{ fill: "#FF9A6C" }}
                            d="M365.9,375.2c0,0-0.3-0.1-0.9-0.4c-0.5-0.3-1.3-0.7-2.3-1.2c-1.9-1.1-4.5-2.6-7.3-4.3
							c-2.2-1.4-4.2-2.8-5.8-3.9l0.2,0c-1.4,0.4-2.6,0.8-3.4,1c-0.8,0.2-1.3,0.3-1.3,0.3c0,0,0.4-0.2,1.2-0.5c0.8-0.3,2-0.7,3.4-1.2
							l0.1,0l0.1,0.1c1.7,1.1,3.7,2.4,5.9,3.8c2.8,1.8,5.3,3.3,7.2,4.5C364.8,374.4,365.9,375.1,365.9,375.2z"
                          />
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            style={{ fill: "#FF9A6C" }}
                            d="M358,375.7c0.1,0.1-0.9,1.2-2.6,1.6c-1.7,0.5-3.1,0-3-0.1c0-0.2,1.3,0.1,2.9-0.4
							C356.9,376.5,357.9,375.6,358,375.7z"
                          />
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            style={{ fill: "#FF9A6C" }}
                            d="M352.7,379c0.1,0,1.1,2.6,2.1,5.9c0.3,0.9,0,1.8-0.3,2.5c-0.3,0.7-0.7,1.3-0.9,1.8
							c-0.5,1-0.4,1.7-0.5,1.7c-0.1,0-0.3-0.7,0.2-1.8c0.2-0.5,0.5-1.1,0.8-1.8c0.3-0.7,0.5-1.4,0.2-2.2
							C353.3,381.8,352.5,379.1,352.7,379z"
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        style={{ fill: "#455A64" }}
                        d="M22.8,388.1L64,343.6c0,0,54.3-93.3,65.1-101.9c27-21.5,57.7-10.2,57.7-10.2l10.9-4.2l18.4,46.8
					c0,0-6.8,10-7.5,12c-0.7,2-82.6,125.9-82.6,125.9l-29.3,41c0,0-45.4-8.5-64.8-33.3C23.2,408.4,22.8,388.1,22.8,388.1z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ fill: "#455A64" }}
                        d="M220.8,273l6.1-5.2l0.1,15.8l10.5,14.8c0,0-30.7,36.9-37,43.1c-6.3,6.2-56.7,66-56.7,66
					s-2.7,28.4-6.1,36.5c-3.4,8-26.3,5.9-26.3,5.9l11.4-32.6l4.7-23.5l57.2-75.7l22.5-29.4L220.8,273z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ fill: "#455A64" }}
                        d="M205.5,323.7c0,0.9,31.1,30.7,31.1,30.7s2.2,5.6,4.8,6.6c2.6,0.9,15.4,6.9,15.4,6.9L277,339
					c0,0-10.2-9.4-13.1-11c-2.9-1.6-9.8-9.7-9.8-9.7l-23.3-28l-17.9,9.1L205.5,323.7z"
                      />
                    </g>
                    <g>
                      <g>
                        <path
                          style={{ fill: "#263238" }}
                          d="M207.2,288.6c0,0,0-0.1,0.1-0.3c0.1-0.2,0.3-0.5,0.5-0.8c0.4-0.7,1-1.7,1.8-3
						c1.6-2.6,3.8-6.2,6.5-10.7l0.2,0.3c-4.9,1.3-10.7,2.8-16.9,4.5l-0.2,0.1l-0.1-0.2c-0.6-2.2-1.2-4.4-1.8-6.8
						c-2.9-11.1-5.5-21.2-7.4-28.5c-0.9-3.6-1.7-6.6-2.2-8.7c-0.2-1-0.4-1.8-0.6-2.4c-0.1-0.5-0.2-0.8-0.2-0.8c0,0,0.1,0.3,0.3,0.8
						c0.2,0.6,0.4,1.4,0.7,2.3c0.6,2.1,1.4,5,2.4,8.6c2,7.3,4.6,17.4,7.6,28.5c0.6,2.3,1.2,4.6,1.8,6.8l-0.3-0.2
						c6.3-1.6,12.1-3.2,17-4.4l0.5-0.1l-0.3,0.4c-2.8,4.4-5.1,8-6.7,10.6c-0.8,1.2-1.4,2.2-1.9,2.9c-0.2,0.3-0.4,0.6-0.5,0.8
						C207.2,288.6,207.2,288.6,207.2,288.6z"
                        />
                      </g>
                    </g>
                    <g>
                      <g>
                        <path
                          style={{ fill: "#263238" }}
                          d="M227,283c0,0-0.2,0.2-0.5,0.5c-0.4,0.4-1,0.8-1.6,1.4c-1.5,1.2-3.5,2.8-5.9,4.8l-0.4,0.3
						l0.1-0.5c0.3-3,0.7-6.5,1.1-10.3c0.1-1.1,0.2-2.2,0.4-3.2l0.4,0.2c-3.6,3.4-6.8,6.4-9.1,8.5c-1.1,1-2,1.8-2.7,2.5
						c-0.6,0.6-1,0.9-1,0.9c0,0,0.3-0.4,0.9-1c0.7-0.7,1.6-1.5,2.6-2.6c2.2-2.2,5.4-5.2,9-8.7l0.5-0.5l-0.1,0.7
						c-0.1,1-0.2,2.1-0.3,3.2c-0.4,3.7-0.8,7.2-1.2,10.2l-0.4-0.2c2.4-1.9,4.5-3.4,6-4.6c0.7-0.5,1.2-0.9,1.7-1.3
						C226.7,283.2,226.9,283,227,283z"
                        />
                      </g>
                    </g>
                    <g>
                      <g>
                        <path
                          style={{ fill: "#263238" }}
                          d="M230.8,305.3c0,0-0.1,0.2-0.3,0.4c-0.2,0.3-0.5,0.7-0.9,1.2c-0.8,1-1.9,2.4-3.3,4.2
						c-2.8,3.5-6.8,8.3-11.2,13.5c-4.5,5.2-8.6,9.8-11.7,13.1c-1.5,1.7-2.8,3-3.6,3.9c-0.4,0.4-0.7,0.8-1,1
						c-0.2,0.2-0.4,0.3-0.4,0.3c0,0,0.1-0.2,0.3-0.4c0.3-0.3,0.6-0.7,0.9-1.1c0.9-1,2.1-2.4,3.5-4c3-3.4,7.1-8,11.6-13.2
						c4.5-5.2,8.5-9.9,11.4-13.4c1.4-1.7,2.6-3.1,3.4-4.1c0.4-0.4,0.7-0.8,0.9-1.1C230.6,305.4,230.8,305.3,230.8,305.3z"
                        />
                      </g>
                    </g>
                    <g>
                      <g>
                        <path
                          style={{ fill: "#263238" }}
                          d="M262.5,328.2c0,0-0.2,0.4-0.8,1.2c-0.5,0.7-1.3,1.8-2.3,3c-2,2.5-4.8,5.9-8.1,9.5
						c-3.3,3.6-6.5,6.6-8.8,8.8c-1.2,1.1-2.1,1.9-2.8,2.5c-0.7,0.6-1.1,0.9-1.1,0.8c0,0,0.3-0.4,1-1c0.7-0.7,1.6-1.6,2.7-2.6
						c2.3-2.2,5.4-5.3,8.7-8.8c3.3-3.6,6.1-6.9,8.2-9.3c1-1.2,1.8-2.1,2.4-2.9C262.2,328.6,262.5,328.2,262.5,328.2z"
                        />
                      </g>
                    </g>
                    <g>
                      <g>
                        <path
                          style={{ fill: "#263238" }}
                          d="M106.7,440.3c0,0,0.1-0.1,0.2-0.4c0.2-0.3,0.4-0.7,0.8-1.2c0.7-1,1.7-2.5,2.9-4.4
						c2.6-3.8,6.3-9.4,10.9-16.2c9.2-13.7,22-32.5,36-53.4c13.9-21,26.5-39.9,34.9-54.2c4.3-7,8-12.6,10.8-16.3
						c1.4-1.8,2.5-3.2,3.3-4.2c0.4-0.4,0.7-0.8,0.9-1.1c0.2-0.2,0.3-0.3,0.3-0.3s-0.1,0.1-0.3,0.4c-0.2,0.3-0.5,0.6-0.9,1.1
						c-0.8,0.9-1.9,2.4-3.2,4.2c-2.7,3.7-6.4,9.3-10.7,16.3C184.5,325,171.9,344,158,365c-14,20.9-26.8,39.7-36.1,53.4
						c-4.7,6.8-8.4,12.3-11,16.1c-1.3,1.9-2.3,3.3-3,4.4c-0.3,0.5-0.6,0.9-0.8,1.1C106.8,440.2,106.7,440.3,106.7,440.3z"
                        />
                      </g>
                    </g>
                    <g>
                      <g>
                        <path
                          style={{ fill: "#263238" }}
                          d="M109,390.7c0,0-0.2-0.1-0.4-0.3c-0.3-0.3-0.7-0.6-1.2-0.9c-1-0.8-2.4-2-4.1-3.6
						c-3.5-3.1-8.1-7.5-13-12.6c-4.9-5.1-9-10-11.9-13.7c-1.4-1.8-2.6-3.3-3.3-4.3c-0.4-0.5-0.6-0.9-0.9-1.2
						c-0.2-0.3-0.3-0.4-0.3-0.4c0,0,0.1,0.1,0.4,0.4c0.3,0.3,0.6,0.7,0.9,1.1c0.9,1.1,2,2.5,3.5,4.2c2.9,3.6,7.1,8.4,12,13.5
						c4.8,5.1,9.4,9.6,12.8,12.7c1.7,1.5,3,2.8,4,3.7c0.4,0.4,0.8,0.7,1.1,1C108.9,390.5,109,390.7,109,390.7z"
                        />
                      </g>
                    </g>
                  </g>
                  <g>
                    <g>
                      <g>
                        <path
                          style={{ fill: "#263238" }}
                          d="M172.8,246.2c0,0-0.4-0.7-1.3-1.7c-0.9-1.1-2.4-2.5-4.5-3.8c-2.1-1.3-5-2.4-8.2-3.2
						c-3.2-0.8-6.9-1.4-10.7-1.3c-3.8,0.2-7.4,1.1-10.3,2.7c-2.9,1.6-5.2,3.6-6.8,5.5c-1.6,1.9-2.7,3.7-3.3,4.9c-0.7,1.2-1,2-1,2
						c0,0,0-0.2,0.2-0.5c0.1-0.3,0.3-0.9,0.6-1.5c0.6-1.3,1.6-3.1,3.2-5.1c1.6-2,3.9-4.1,6.9-5.7c3-1.7,6.6-2.7,10.5-2.8
						c3.9-0.2,7.6,0.5,10.9,1.3c3.3,0.8,6.1,2,8.3,3.3c2.2,1.4,3.6,2.9,4.5,4c0.4,0.6,0.7,1,0.9,1.4
						C172.7,246,172.8,246.2,172.8,246.2z"
                        />
                      </g>
                    </g>
                    <g>
                      <g>
                        <path
                          style={{ fill: "#455A64" }}
                          d="M122.7,259.5c0,0-1.8,61.8,0,91.1c0.5,8.9,3.9,14.9,4.5,15.1c1.8,0.5,38.1-2.5,38.1-2.5
						s0.3-11.1,3-15.3c2.7-4.2-4.3-2.4-4.3-2.4s15-76.2,13.1-90.2"
                        />
                      </g>
                      <g>
                        <g>
                          <path
                            style={{ fill: "#263238" }}
                            d="M177.3,255.2c0,0,0.1,0.6,0.2,1.7c0,1.2,0.1,2.9-0.1,5.1c-0.2,4.4-0.9,10.8-2,18.9
							c-2.2,16.1-6,38.6-11,64.6l-0.3-0.3c0.9-0.2,1.8-0.4,2.8-0.4c0.5,0,1,0,1.5,0.2c0.2,0.1,0.5,0.2,0.7,0.5
							c0.2,0.2,0.3,0.5,0.3,0.8c0,0.6-0.3,1-0.5,1.4c-0.3,0.4-0.5,0.8-0.7,1.2c-0.4,0.8-0.7,1.7-0.9,2.7c-1,3.7-1.3,7.6-1.5,11.5
							l0,0.2l-0.2,0c-4.9,0.4-10.2,0.8-15.5,1.2c-4.5,0.3-8.9,0.7-13.2,1c-2.2,0.1-4.3,0.3-6.5,0.4c-0.5,0-1.1,0-1.6,0
							c-0.3,0-0.5,0-0.8,0l-0.4,0c-0.1,0-0.4-0.2-0.5-0.3c-0.8-0.9-1.2-1.9-1.6-2.8c-1.7-3.9-2.5-8.1-2.8-12.2c-0.1-2-0.2-4-0.3-6
							c-0.1-2-0.1-4-0.2-5.9c-0.4-15.5-0.3-29.5-0.1-41.3c0.1-11.7,0.4-21.2,0.5-27.8c0.1-3.3,0.2-5.8,0.2-7.6c0-0.9,0.1-1.5,0.1-2
							c0-0.4,0-0.7,0-0.7s0,0.2,0,0.7c0,0.5,0,1.1,0,2c0,1.7-0.1,4.3-0.1,7.6c-0.1,6.6-0.3,16.1-0.4,27.8
							c-0.1,11.7-0.1,25.7,0.3,41.2c0.1,1.9,0.1,3.9,0.2,5.9c0.1,2,0.2,4,0.3,6c0.3,4,1.1,8.1,2.7,12c0.4,0.9,0.9,1.9,1.5,2.7
							c0,0.1,0.1,0.1,0.1,0.1c0,0,0.1,0,0.1,0l0.4,0c0.2,0,0.5,0,0.8,0c0.5,0,1.1,0,1.6,0c2.1-0.1,4.3-0.2,6.5-0.4
							c4.4-0.3,8.8-0.6,13.2-1c5.3-0.4,10.5-0.8,15.5-1.2l-0.2,0.3c0.2-4,0.5-7.9,1.5-11.6c0.2-0.9,0.6-1.9,1-2.7
							c0.4-0.9,1.1-1.6,1.2-2.5c0-0.4-0.2-0.7-0.6-0.8c-0.4-0.1-0.8-0.2-1.3-0.2c-0.9,0-1.8,0.2-2.7,0.3l-0.4,0.1l0.1-0.4
							c5-26,8.9-48.5,11.2-64.5c1.1-8,1.9-14.4,2.1-18.8c0.2-2.2,0.2-3.9,0.1-5.1c0-0.6-0.1-1-0.1-1.3
							C177.3,255.4,177.3,255.2,177.3,255.2z"
                          />
                        </g>
                      </g>
                    </g>
                    <g>
                      <g>
                        <path
                          style={{ fill: "#263238" }}
                          d="M165.3,345.8c0,0-0.6,0.1-1.6,0.3c-1,0.2-2.5,0.4-4.3,0.6c-3.7,0.5-8.7,0.9-14.3,1.1
						c-5.6,0.2-10.7,0.2-14.4,0.1c-1.8-0.1-3.3-0.2-4.4-0.2c-1-0.1-1.6-0.1-1.6-0.2c0,0,0.6,0,1.6,0c1.1,0,2.6,0,4.4,0.1
						c3.7,0,8.8,0,14.4-0.2c5.6-0.2,10.7-0.6,14.3-1c1.8-0.2,3.2-0.3,4.3-0.4C164.7,345.9,165.3,345.8,165.3,345.8z"
                        />
                      </g>
                    </g>
                    <g>
                      <g>
                        <polygon
                          style={{ fill: "#FFBF9D" }}
                          points="127.3,365.7 148.6,455.5 170,455.5 164,363.4 					"
                        />
                      </g>
                      <g>
                        <path
                          style={{ fill: "#FFBF9D" }}
                          d="M168.1,442.8l21.3,3.7l19.2,5.8c1.8,0.6,2.9,1.6,2.2,3.9l0,0l-62.1-0.7v-10.8L168.1,442.8z"
                        />
                      </g>
                      <g>
                        <g>
                          <path
                            style={{ fill: "#FF9A6C" }}
                            d="M202.1,456.1c-0.1,0,0.1-0.4,0-1.2c-0.1-0.8-0.7-1.8-2.1-2.3c-1.3-0.5-3-0.5-4.9-0.7
							c-1.9-0.2-3.9-0.4-6.1-0.6c-4.3-0.5-8.2-0.9-11.1-1.2c-1.3-0.2-2.4-0.3-3.3-0.4c-0.8-0.1-1.2-0.2-1.2-0.2c0,0,0.4,0,1.2,0
							c0.9,0.1,2,0.2,3.4,0.3c2.8,0.3,6.8,0.6,11.1,1.1c2.2,0.2,4.2,0.5,6.1,0.7c0.9,0.1,1.8,0.2,2.7,0.3c0.8,0.1,1.6,0.2,2.3,0.5
							c1.4,0.6,2.1,1.8,2.1,2.6c0,0.4,0,0.7-0.1,0.9C202.2,456,202.1,456.1,202.1,456.1z"
                          />
                        </g>
                      </g>
                    </g>
                    <g style={{ opacity: "0.3" }}>
                      <path
                        d="M165.3,382.4l7.5-32.8v-10.3l-2.8-1.8l6.6-67.2l-12.4,75.2l1.5-0.1c1.7-0.1,3.8-0.5,3.4,1.1l-2.3,5.1l-2.6,13.6
					L165.3,382.4z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        style={{ fill: "#FF9A6C" }}
                        d="M235.8,224.2c-2.4,2.5-3.1,6.1-1.1,8.9c0.7,1,1.8,1.7,3,1.8c1.4,0.2,2.9,0.1,4-1
					c1.1-1,2.1-2.1,2.5-3.5C241.9,228.7,238.1,226.1,235.8,224.2z"
                      />
                    </g>
                    <g>
                      <g>
                        <path
                          style={{ fill: "#263238" }}
                          d="M240.9,234.5c0,0-0.3,0.2-1,0.4c-0.7,0.1-1.7,0.2-2.9-0.3c-1.2-0.4-2.4-1.4-3.1-2.9
						c-0.7-1.5-0.8-3.5-0.1-5.2c0.4-0.9,0.9-1.7,1.5-2.4l0.2-0.2l0.2,0.1c2.4,1.8,4.5,3.4,6,4.6s2.4,1.9,2.3,1.9
						c0,0.1-1-0.6-2.5-1.7c-1.6-1.1-3.7-2.6-6.2-4.4l0.3,0c-0.6,0.6-1,1.4-1.4,2.2c-0.7,1.7-0.6,3.5,0.1,4.8
						c0.6,1.4,1.8,2.4,2.8,2.8c1.1,0.5,2.1,0.5,2.7,0.4C240.5,234.6,240.9,234.5,240.9,234.5z"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </>
        </div>
      </div>
    </div>
  );
}
