import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Avatar, AvatarImage, AvatarFallback } from "@/components/ui/avatar";

import { useEffect, useRef } from "react";

import { format, set } from "date-fns";
import { Calendar as CalendarIcon, CircleDashed } from "lucide-react";
import { ro } from "date-fns/locale";
import { cn } from "@/lib/utils";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { useStore } from "@/store/store";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { api_update_profile, api_upload_user_avatar } from "@/lib/chat";

function ProfileSettings() {
  const inputFileRef = useRef<HTMLInputElement>(null);
  const [date, setDate] = useState<Date[]>();
  const [uploading, setUploading] = useState<boolean>(false);
  const { userProfile, setUserProfile } = useStore();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const formSchema = z.object({
    name: z.string().min(2, {
      message: "Numele trebuie să aibă cel puțin 2 caractere.",
    }),
    birthday: z.date({
      required_error: "Introdu o dată de naștere validă.",
    }),
    class: z.string(),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: "",
      class: "1",
    },
  });

  useEffect(() => {
    if (userProfile) {
      form.setValue("name", userProfile.name);
      form.setValue("class", userProfile.class);
      form.setValue("birthday", new Date(userProfile.birthday));
    }
  }, [userProfile]);

  const classes = [1, 2, 3, 4, 5, 6, 7, 8];

  const { setValue, watch } = form;

  function handleDateSelect(selectedDate) {
    const year = selectedDate.getFullYear();
    const month = selectedDate.getMonth();
    const day = selectedDate.getDate();

    // Create a new Date object with the UTC equivalent of the local date
    const dateWithoutTimezone = new Date(Date.UTC(year, month, day));

    // Set the date and value using the adjusted Date object
    setDate(dateWithoutTimezone);
    setValue("birthday", dateWithoutTimezone, { shouldValidate: true });
  }

  const birthday = watch("birthday");

  useEffect(() => {
    if (birthday) {
      setDate(birthday);
    }
  }, [birthday]);

  const { dialogUserProfile, setDialogUserProfile } = useStore();

  function onSubmit(values: z.infer<typeof formSchema>) {
    setIsLoading(true);
    api_update_profile(values, (data, error) => {
      if (data) {
        setUserProfile(data);
      }
      setIsLoading(false);
    });
  }

  function resizeImage(dataUrl, maxWidth, maxHeight, callback) {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();

    img.onload = function () {
      const width = img.width;
      const height = img.height;
      const newWidth = width > height ? maxWidth : (maxWidth * width) / height;
      const newHeight = width > height ? (maxHeight * height) / width : maxHeight;
      canvas.width = newWidth;
      canvas.height = newHeight;
      ctx.drawImage(img, 0, 0, newWidth, newHeight);
      const resizedDataUrl = canvas.toDataURL('image/png');
      callback(resizedDataUrl);
    };

    img.src = dataUrl;
  }

  function handleAvatarUpload(event: React.ChangeEvent<HTMLInputElement>) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = function (e) {
      resizeImage(e.target.result, 512, 512, (resizedDataUrl) => {
        inputFileRef.current.value = null;
        setUploading(true);
        api_upload_user_avatar(resizedDataUrl, (data, error) => {
          if (data) {
            setUserProfile(data);
          } else {
            console.log(error);
          }
          setUploading(false);
        });
      });
    };
    reader.readAsDataURL(file);
  }

  return (
    <div>
      <Dialog
        open={dialogUserProfile}
        onOpenChange={() => setDialogUserProfile(false)}
      >
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>Editeaza-ti profilul</DialogTitle>
            <DialogDescription></DialogDescription>
          </DialogHeader>


          <div className="flex justify-between items-center">
            <Avatar>
              {userProfile && userProfile.avatar && <AvatarImage src={userProfile.avatar} />}
              {userProfile && userProfile.avatar == false && <AvatarFallback className="bg-x-blue-700">
                {userProfile ? userProfile?.name[0] : ''}
              </AvatarFallback>}
            </Avatar>
            <input
              ref={inputFileRef}
              id="avatar-file-upload"
              type="file"
              accept="image/*"
              lang="ro"
              title="Alege poza"
              className="hidden"
              onChange={(e) => handleAvatarUpload(e)}
            />
            <Button onClick={() => inputFileRef.current?.click()}
              disabled={uploading}>
              <CircleDashed
                className={(!uploading && "hidden") || "animate-spin"}
              />
              <span className={(uploading && "hidden") || ""}>Încarcă avatar</span>
            </Button>

          </div>

          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="space-y-8 h-full flex flex-col mt-3"
            >
              <div className="space-y-3">
                <FormField
                  control={form.control}
                  name="name"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Nume</FormLabel>
                      <FormControl>
                        <Input placeholder="Andrei" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="birthday"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Zi de naștere </FormLabel>
                      <FormControl>
                        <div>
                          <Popover>
                            <PopoverTrigger asChild>
                              <Button
                                variant={"outline"}
                                className={cn(
                                  "w-full justify-start text-left font-normal",
                                  !date && "text-muted-foreground"
                                )}
                              >
                                <CalendarIcon className="mr-2 h-4 w-4" />
                                {date ? (
                                  format(date, "dd MMM yyyy")
                                ) : (
                                  <span>Selecteaza o zi</span>
                                )}
                              </Button>
                            </PopoverTrigger>
                            <PopoverContent
                              align="start"
                              side="top"
                              className="w-auto p-0"
                            >
                              <DayPicker
                                locale={ro}
                                classNames={{
                                  caption_label:
                                    "flex items-center text-sm font-medium",
                                  dropdown: "rdp-dropdown bg-card",
                                  dropdown_icon: "ml-2",
                                  dropdown_year: "rdp-dropdown_year ml-3",
                                  button: "",
                                  button_reset: "",
                                }}
                                mode="single"
                                selected={date}
                                onSelect={handleDateSelect}
                                captionLayout="dropdown-buttons"
                                fromYear={2000}
                                toYear={new Date().getFullYear()}
                              />
                            </PopoverContent>
                          </Popover>
                        </div>
                      </FormControl>

                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="class"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Clasa</FormLabel>
                      <FormControl>
                        <Select
                          value={field.value}
                          onValueChange={field.onChange}
                          defaultValue={field.value}
                        >
                          <SelectTrigger className="w-full">
                            <SelectValue placeholder="Clasa">
                              {field.value}
                            </SelectValue>
                          </SelectTrigger>
                          <SelectContent>
                            {classes.map((item) => (
                              <SelectItem key={item} value={item.toString()}>
                                {item}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <Button
                disabled={isLoading}
                className="w-full gap-2 md:!mt-7 !mt-auto"
                type="submit"
              >
                <CircleDashed
                  className={(!isLoading && "hidden") || "animate-spin"}
                />
                <span className={(isLoading && "hidden") || ""}>Salvează</span>
              </Button>
            </form>
          </Form>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default ProfileSettings;
