import * as React from "react"

function LogoNewEdu() {
	return (
		<>
			<svg
				version="1.1"
				id="Layer_1"
				xmlns="http://www.w3.org/2000/svg"
				xmlnsXlink="http://www.w3.org/1999/xlink"
				x="0px"
				y="0px"
				width="340px"
				height="121px"
				viewBox="0 0 340 121"
				enableBackground="new 0 0 340 121"
				xmlSpace="preserve"
			>
				<g id="SCUT_1_">
					<path
						fill="none"
						d="M119.298,94.552c-1.087,0.004-1.308-0.218-1.307-1.302c0.035-33.247,0.035-66.493,0-99.738
		c-0.001-1.085,0.219-1.305,1.307-1.304c33.324,0.036,66.646,0.036,99.97,0c1.089,0,1.308,0.218,1.307,1.304
		c-0.037,33.245-0.037,66.491,0,99.738c0.001,1.084-0.218,1.306-1.307,1.302c-16.662-0.042-33.324-0.028-49.983-0.028
		C152.623,94.523,135.96,94.51,119.298,94.552z M211.631,32.99c1.003-7.719,1.065-15.436-0.843-23.08
		c-0.27-1.077-0.749-1.635-1.73-2.052c-9.545-4.069-19.488-6.465-29.839-7.288c-14.516-1.152-28.285,1.799-41.638,7.25
		c-1.085,0.442-1.571,1.061-1.845,2.172c-1.62,6.544-1.629,13.177-1.235,19.84c0.099,1.666,0.925,0.925,1.633,0.478
		c6.467-4.079,13.42-7.024,20.947-8.384c15.689-2.832,29.865,0.478,42.108,10.921c3.645,3.108,6.333,6.985,8.277,11.364
		c0.262,0.592,0.544,1.176,0.896,1.936C210.041,41.772,211.055,37.413,211.631,32.99z M200.477,63.559 M201.05,51.52
		c-5.398-13.675-15.498-21.98-29.71-24.924c-12.869-2.667-24.683,0.298-34.994,8.623c-0.463,0.374-1.237,0.631-1.069,1.627
		c14.091-9.891,28.895-11.552,43.827-2.933c13.507,7.796,19.26,20.061,17.506,35.892 M147.253,51.581c1.97,0,3.94,0.011,5.912-0.004
		c1.516-0.011,3.034-0.05,4.548-0.084c0.873-0.021,1.403-0.387,1.265-1.35c-0.546-3.768-1.095-7.536-1.648-11.304
		c-0.225-1.526-0.725-1.543-2.028-0.706c-6.082,3.91-9.432,9.951-12.863,15.955c-0.419,0.731-0.201,1.398,0.146,2.067
		c1.729,3.338,3.746,6.499,5.862,9.602c0.496,0.725,1.142,1.229,2.112,1.198c2.735-0.086,5.472-0.148,8.207-0.197
		c0.934-0.018,1.549-0.345,1.5-1.377c-0.156-3.204-0.325-6.408-0.738-9.593c-0.135-1.027-0.626-1.426-1.62-1.593
		c-2.573-0.434-5.212-0.342-7.751-1.021 M190.661,63.837c-1.783,0.883-3.512,1.711-5.214,2.598
		c-0.993,0.517-0.854,1.508-0.856,2.412c-0.01,2.679-0.513,5.246-1.504,7.71 M182.439,76.52c0-2.216,0.015-4.43-0.005-6.644
		c-0.021-2.205-0.356-2.417-2.495-1.784c-4.494,1.329-9.113,2.043-13.766,2.429c-1.643,0.138-2.256,0.808-2.381,2.297
		c-0.223,2.654-0.67,5.275-1.262,7.873c-0.364,1.593,0.82,2.983,2.372,2.708c7.306-1.298,14.479-2.945,20.867-7.037
		c2.95-1.888,4.12-4.657,4.645-7.833 M168.736,65.628c3.684-0.503,7.212-1.634,10.797-2.545c1.345-0.342,1.867-0.992,1.287-2.489
		c-0.896-2.307-1.684-4.653-2.667-6.932c-0.512-1.189-1.27-1.539-2.425-1.334c-3.103,0.551-6.145,1.431-9.297,1.729
		c-0.855,0.081-1.276,0.597-1.274,1.464c0.004,2.36,0.001,4.72,0.001,7.08C165.05,66.159,165.046,66.13,168.736,65.628z
		 M173.664,48.642c0.737-0.293,0.733-0.716,0.358-1.257c-2.754-3.971-6.046-7.399-10.197-9.944
		c-0.254-0.156-0.546-0.344-0.841-0.126c-0.36,0.265-0.23,0.628-0.146,0.988c0.836,3.532,1.13,7.149,1.672,10.726
		c0.133,0.874,0.213,1.75,1.65,1.641C168.564,50.167,171.174,49.638,173.664,48.642z M186.087,47.272
		c-0.334-0.546-0.685-0.356-0.987,0.05c-0.895,1.197-2.104,1.96-3.444,2.57c-0.855,0.39-1.073,0.976-0.636,1.9
		c1.213,2.555,2.209,5.199,2.9,7.946c0.243,0.967,0.613,1.312,1.632,0.691c1.226-0.747,2.169-1.792,3.25-2.688
		c0.559-0.464,0.937-1.108,0.928-2.053C189.195,52.701,187.731,49.947,186.087,47.272z M172.277,90.465
		c0.483,0.335,0.977,0.457,1.528,0.178c3.412-1.728,6.301-4.118,8.854-7.055c-5.068,1.296-10.19,2.044-15.911,2.459
		C168.777,87.931,170.502,89.229,172.277,90.465z"
					/>
					<path
						fill="#111111"
						d="M208.362,46.147c1.679-4.375,2.692-8.734,3.269-13.158c1.003-7.72,1.065-15.436-0.842-23.08
		c-0.27-1.077-0.75-1.635-1.73-2.052c-9.546-4.069-19.489-6.465-29.839-7.288c-14.517-1.152-28.286,1.798-41.639,7.25
		c-1.083,0.442-1.571,1.061-1.845,2.172c-1.62,6.544-1.629,13.177-1.235,19.84c0.099,1.666,0.926,0.925,1.634,0.478
		c6.466-4.079,13.42-7.025,20.946-8.384c15.69-2.832,29.865,0.478,42.108,10.921c3.645,3.108,6.333,6.985,8.277,11.364
		C207.728,44.804,208.01,45.388,208.362,46.147z"
					/>
					<path
						fill="#111111"
						d="M135.277,36.846c14.091-9.891,28.895-11.552,43.827-2.933c13.507,7.796,19.26,20.061,17.506,35.892
		c1.692-1.983,3.457-5.532,4.641-7.655c2.118-4.25,1.433-6.494-0.2-10.63c-5.399-13.675-15.498-21.98-29.71-24.924
		c-12.869-2.667-24.684,0.299-34.994,8.623"
					/>
					<path
						fill="#00858C"
						d="M147.253,51.581c1.969,0,3.94,0.011,5.912-0.003c1.516-0.011,3.031-0.05,4.547-0.084
		c0.873-0.021,1.402-0.388,1.264-1.35c-0.545-3.768-1.094-7.537-1.647-11.304c-0.223-1.527-0.724-1.544-2.028-0.707
		c-6.083,3.91-9.432,9.951-12.863,15.955c-0.419,0.732-0.2,1.398,0.146,2.067c1.728,3.338,3.747,6.501,5.862,9.602
		c0.493,0.725,1.142,1.229,2.113,1.198c2.735-0.085,5.472-0.146,8.207-0.197c0.934-0.018,1.548-0.345,1.498-1.375
		c-0.155-3.206-0.324-6.408-0.737-9.595c-0.134-1.026-0.625-1.424-1.62-1.593c-2.573-0.434-5.212-0.341-7.751-1.021
		C149.111,52.896,148.3,52.515,147.253,51.581z"
					/>
					<path
						fill="#00858C"
						d="M183.03,76.554c-0.421,0.479-0.513,0.22-0.591-0.034c0-2.216,0.015-4.43-0.005-6.644
		c-0.021-2.205-0.356-2.417-2.495-1.784c-4.494,1.329-9.113,2.043-13.766,2.429c-1.643,0.138-2.256,0.808-2.381,2.297
		c-0.223,2.654-0.67,5.275-1.262,7.873c-0.364,1.593,0.82,2.983,2.372,2.708c7.306-1.298,14.478-2.945,20.867-7.037
		c2.95-1.888,4.12-4.657,4.645-7.833c0.249-1.51,0.457-2.576,0.246-4.691c-1.783,0.883-3.513,1.71-5.214,2.598
		c-0.993,0.517-0.854,1.508-0.859,2.412"
					/>
					<path
						fill="#00858C"
						d="M165.158,62.601c-0.108,3.559-0.112,3.529,3.578,3.027c3.683-0.503,7.212-1.634,10.795-2.545
		c1.347-0.341,1.869-0.992,1.289-2.489c-0.896-2.307-1.684-4.653-2.667-6.932c-0.512-1.189-1.27-1.539-2.426-1.334
		c-3.103,0.551-6.144,1.431-9.296,1.729c-0.857,0.082-1.276,0.597-1.274,1.464C165.161,57.88,165.158,60.24,165.158,62.601z"
					/>
					<path
						fill="#00858C"
						d="M166.16,50.669c2.404-0.502,5.014-1.032,7.504-2.028c0.737-0.294,0.733-0.716,0.358-1.257
		c-2.754-3.971-6.046-7.399-10.197-9.944c-0.252-0.156-0.546-0.344-0.841-0.126c-0.36,0.265-0.23,0.628-0.146,0.988
		c0.836,3.532,1.13,7.149,1.672,10.726C164.643,49.902,164.723,50.779,166.16,50.669z"
					/>
					<path
						fill="#00858C"
						d="M189.729,55.687c-0.534-2.986-1.998-5.74-3.643-8.414c-0.334-0.546-0.685-0.357-0.987,0.049
		c-0.895,1.197-2.104,1.96-3.443,2.571c-0.855,0.389-1.074,0.976-0.637,1.899c1.213,2.556,2.209,5.199,2.901,7.945
		c0.242,0.967,0.612,1.312,1.631,0.691c1.227-0.747,2.17-1.792,3.25-2.688C189.36,57.276,189.738,56.632,189.729,55.687z"
					/>
					<path
						fill="#00858C"
						d="M138.615,47.762c0.567-0.834,1.003-1.853,1.461-2.551c1.815-3.885,5.166-7.223,8.284-10.16
		c0.408-0.385,1.126-0.849,0.75-1.486c-0.332-0.562-1.884,0.226-2.364,0.355c-3.689,1.162-6.872,2.943-9.92,5.157
		c-0.374,0.27-0.635,0.614-0.517,1.074C136.965,42.693,137.452,45.291,138.615,47.762z"
					/>
					<path
						fill="#00858C"
						d="M166.003,33.391c2.981,2.071,5.554,4.059,7.71,6.551c1.354,1.563,2.787,3.694,3.965,5.376
		c0.28,0.401,0.398,0.776,0.909,0.657c1.495-0.494,2.287-1.741,3.072-2.858c0.547-0.781,0.077-1.335-0.486-1.877
		c-3.202-3.072-7.331-5.497-11.464-7.029C168.662,33.82,167.473,33.375,166.003,33.391z"
					/>
					<path
						fill="#00858C"
						d="M166.748,86.047c2.029,1.885,3.754,3.183,5.529,4.418c0.484,0.335,0.977,0.457,1.528,0.178
		c3.412-1.728,6.301-4.118,8.854-7.055C177.591,84.883,172.469,85.632,166.748,86.047z"
					/>
					<path
						fill="#00858C"
						d="M152.314,70.764c2.329,3.068,5.166,6.148,7.941,9.186c0.361-2.738,0.308-5.419,0.244-7.925
		c-0.016-0.641-0.425-1.021-1.054-1.027C157.162,70.972,154.729,70.764,152.314,70.764z"
					/>
					<polygon
						fill="#FFFFFF"
						points="203.514,8.934 204.601,11.848 207.716,11.981 205.275,13.914 206.111,16.91 203.514,15.191 
		200.917,16.91 201.751,13.914 199.31,11.981 202.425,11.848 	"
					/>
					<polygon
						fill="#FFFFFF"
						points="189.161,4.771 190.25,7.685 193.365,7.817 190.924,9.751 191.76,12.747 189.161,11.027 
		186.565,12.747 187.401,9.751 184.957,7.817 188.072,7.685 	"
					/>
					<polygon
						fill="#FFFFFF"
						points="173.322,3.199 174.412,6.113 177.525,6.245 175.083,8.179 175.919,11.174 173.322,9.456 
		170.725,11.174 171.561,8.179 169.118,6.245 172.232,6.113 	"
					/>
					<polygon
						fill="#FFFFFF"
						points="157.223,5.037 158.311,7.951 161.427,8.083 158.985,10.017 159.821,13.012 157.223,11.294 
		154.625,13.012 155.462,10.017 153.02,8.083 156.135,7.951 	"
					/>
					<polygon
						fill="#FFFFFF"
						points="143.066,8.947 144.153,11.861 147.269,11.994 144.827,13.927 145.663,16.924 143.066,15.205 
		140.468,16.924 141.304,13.927 138.862,11.994 141.976,11.861 	"
					/>
				</g>
				<g>
					<path
						fill="#111111"
						d="M11.115,81.563c0,2.752,1.028,4.588,3.087,5.507c0.262,0.129,0.756,0.316,1.478,0.555
		c0.723,0.24,1.259,0.461,1.61,0.657c0.35,0.196,0.525,0.447,0.525,0.753c0,0.611-0.701,0.982-2.103,1.113
		c-0.088,0-0.459-0.033-1.116-0.098c-0.658-0.066-1.491-0.13-2.498-0.198c-1.007-0.065-1.97-0.098-2.89-0.098
		c-0.919,0-1.839,0.032-2.758,0.098c-0.919,0.068-1.676,0.132-2.267,0.198c-0.59,0.064-0.93,0.098-1.018,0.098
		C1.722,90.017,1,89.646,1,89.034c0-0.306,0.175-0.557,0.525-0.753s0.887-0.417,1.609-0.657c0.722-0.238,1.214-0.426,1.477-0.555
		c2.058-0.919,3.087-2.755,3.087-5.507V64.461c0-3.057-0.306-4.958-0.919-5.701l-2.431-2.687c-0.262-0.305-0.581-0.656-0.952-1.049
		c-0.373-0.393-0.657-0.699-0.854-0.917c-0.197-0.219-0.405-0.447-0.625-0.688c-0.219-0.24-0.373-0.447-0.459-0.622
		c-0.088-0.175-0.131-0.328-0.131-0.458c0-0.481,0.328-0.721,0.986-0.721h6.37c0.875,0,2.037,0.786,3.481,2.359l17.405,19.46
		c3.327,3.714,5.233,5.572,5.714,5.572c0.264,0,0.428-0.264,0.494-0.787c0.065-0.524,0.098-1.792,0.098-3.8V58.433
		c0-0.481-0.033-0.907-0.098-1.278c-0.066-0.371-0.131-0.688-0.197-0.951c-0.067-0.262-0.22-0.501-0.46-0.72
		c-0.241-0.218-0.406-0.382-0.493-0.492c-0.088-0.108-0.352-0.25-0.788-0.425c-0.438-0.174-0.734-0.285-0.887-0.328
		c-0.154-0.042-0.548-0.152-1.182-0.327c-0.635-0.174-1.062-0.284-1.281-0.328c-0.701-0.219-1.051-0.59-1.051-1.114
		c0-0.349,0.24-0.634,0.723-0.852c0.48-0.219,1.007-0.328,1.576-0.328c0.876,0,1.894,0.055,3.054,0.165
		c1.159,0.109,2.135,0.164,2.923,0.164c0.789,0,1.784-0.055,2.99-0.164c1.202-0.109,2.176-0.165,2.921-0.165
		c0.57,0,1.095,0.109,1.578,0.328c0.48,0.218,0.722,0.503,0.722,0.852c0,0.523-0.328,0.895-0.986,1.114
		c-0.262,0.088-0.646,0.196-1.149,0.328c-0.503,0.131-0.853,0.218-1.05,0.262c-0.197,0.044-0.482,0.132-0.854,0.262
		c-0.373,0.131-0.624,0.252-0.754,0.36c-0.132,0.11-0.318,0.241-0.559,0.394c-0.241,0.154-0.406,0.328-0.493,0.523
		c-0.087,0.197-0.188,0.427-0.295,0.689c-0.11,0.262-0.187,0.557-0.23,0.884c-0.043,0.328-0.065,0.71-0.065,1.147v26.145
		c0,1.137,0.033,2.195,0.098,3.178c0.065,0.984,0.099,1.717,0.099,2.197c0,0.523-0.439,0.787-1.314,0.787
		c-0.438,0-1.193-0.352-2.266-1.051c-1.073-0.698-2.178-1.66-3.318-2.883l-19.31-21.428c-0.92-1.048-1.555-1.572-1.905-1.572
		c-0.307,0-0.459,1.484-0.459,4.457V81.563z"
					/>
					<path
						fill="#111111"
						d="M59.392,66.688c0,1.354,0.502,2.03,1.51,2.03h5.647c0.658,0,1.238-0.085,1.741-0.262
		c0.503-0.174,0.876-0.358,1.117-0.557c0.241-0.197,0.624-0.534,1.149-1.016c0.13-0.13,0.338-0.359,0.624-0.687
		c0.285-0.328,0.536-0.569,0.756-0.722c0.218-0.153,0.438-0.23,0.657-0.23c0.481,0,0.723,0.438,0.723,1.312
		c0,0.131-0.044,0.711-0.131,1.737c-0.089,1.027-0.131,1.714-0.131,2.063s0.009,1.005,0.032,1.966
		c0.021,0.961,0.032,1.551,0.032,1.769c0,1.138-0.285,1.703-0.853,1.703c-0.176,0-0.351-0.063-0.525-0.195
		c-0.176-0.132-0.34-0.306-0.493-0.524c-0.153-0.217-0.285-0.416-0.394-0.589c-0.11-0.174-0.241-0.404-0.394-0.689
		c-0.154-0.283-0.252-0.447-0.296-0.491c-0.568-0.918-1.335-1.376-2.298-1.376h-6.897c-0.614,0-1.029,0.109-1.248,0.326
		c-0.22,0.221-0.328,0.636-0.328,1.247v7.34c0,1.834,0.383,3.331,1.148,4.487c0.767,1.159,1.894,1.738,3.383,1.738h7.159
		c1.226,0,2.234-0.101,3.021-0.297c0.789-0.196,1.489-0.632,2.103-1.31c0.613-0.678,1.061-1.267,1.347-1.77
		c0.284-0.501,0.732-1.408,1.346-2.72c0.394-0.831,0.985-1.244,1.773-1.244c0.613,0,0.92,0.546,0.92,1.637
		c0,0.831-0.328,2.152-0.986,3.965c-0.656,1.813-1.162,3.003-1.51,3.571c-0.22,0.394-0.373,0.654-0.46,0.786
		c-0.089,0.131-0.241,0.241-0.46,0.328c-0.219,0.086-0.502,0.131-0.853,0.131c-0.438,0-3.416-0.055-8.934-0.164
		c-5.517-0.108-9.633-0.164-12.348-0.164c-0.919,0-1.96,0.023-3.119,0.065c-1.161,0.045-2.146,0.099-2.957,0.165
		c-0.81,0.064-1.258,0.098-1.346,0.098c-1.445-0.131-2.168-0.502-2.168-1.113c0-0.306,0.175-0.557,0.526-0.753
		c0.349-0.196,0.887-0.417,1.609-0.657c0.723-0.238,1.215-0.426,1.478-0.555c2.058-0.919,3.087-2.755,3.087-5.507v-23.13
		c0-0.394-0.012-0.754-0.033-1.082c-0.022-0.329-0.099-0.633-0.229-0.917c-0.131-0.284-0.23-0.513-0.296-0.688
		c-0.065-0.175-0.229-0.35-0.491-0.525c-0.263-0.174-0.438-0.305-0.526-0.393c-0.087-0.087-0.339-0.208-0.754-0.36
		c-0.417-0.153-0.701-0.251-0.855-0.295c-0.153-0.043-0.504-0.141-1.051-0.294c-0.548-0.153-0.93-0.251-1.149-0.295
		c-0.701-0.219-1.05-0.59-1.05-1.114c0-0.787,0.765-1.18,2.298-1.18h18.852c1.051,0,2.55-0.054,4.499-0.164
		c1.948-0.109,3.055-0.164,3.318-0.164c1.006,0,1.575,0.307,1.707,0.918c0.394,1.572,0.591,3.364,0.591,5.374
		c0,1.136-0.352,1.703-1.052,1.703c-0.832,0-1.621-0.829-2.365-2.49c-0.701-1.441-2.474-2.162-5.321-2.162h-7.815
		c-0.832,0-1.379,0.109-1.642,0.327c-0.263,0.219-0.394,0.568-0.394,1.048V66.688z"
					/>
					<path
						fill="#111111"
						d="M110.951,53.649c-0.745-0.263-1.117-0.656-1.117-1.18c0-0.787,0.547-1.18,1.642-1.18
		c0.305,0,0.875,0.044,1.708,0.132c0.831,0.087,1.421,0.131,1.772,0.131s1.051-0.043,2.102-0.131
		c1.051-0.088,1.751-0.132,2.103-0.132c1.095,0,1.641,0.393,1.641,1.18c0,0.48-0.372,0.852-1.116,1.114
		c-1.401,0.437-2.474,1.147-3.218,2.129c-0.745,0.983-1.423,2.304-2.036,3.966l-3.283,9.041l2.626,7.014
		c0.789,2.141,1.444,3.211,1.971,3.211c0.744,0,1.554-1.312,2.43-3.933l4.861-14.679c0.743-2.316,1.116-3.942,1.116-4.883
		c0-0.938-0.394-1.539-1.182-1.8c-0.746-0.263-1.116-0.656-1.116-1.18c0-0.787,0.525-1.18,1.577-1.18
		c0.306,0,0.919,0.044,1.838,0.132c0.919,0.087,1.533,0.131,1.839,0.131c0.307,0,0.985-0.043,2.037-0.131
		c1.051-0.088,1.73-0.132,2.036-0.132c1.095,0,1.643,0.393,1.643,1.18c0,0.48-0.373,0.852-1.117,1.114
		c-1.402,0.437-2.476,1.147-3.219,2.129c-0.744,0.983-1.424,2.304-2.036,3.966l-8.145,22.409c-2.19,5.941-3.637,8.912-4.335,8.912
		c-0.57,0-1.403-1.507-2.496-4.521l-3.481-9.174l-1.708,4.782c-2.189,5.941-3.635,8.912-4.335,8.912
		c-0.569,0-1.401-1.507-2.497-4.521l-9.982-26.475c-0.482-1.31-0.866-2.248-1.15-2.817c-0.284-0.567-0.822-1.201-1.609-1.9
		c-0.787-0.698-1.838-1.267-3.152-1.704c-0.701-0.219-1.051-0.59-1.051-1.114c0-0.349,0.241-0.634,0.723-0.852
		c0.482-0.219,1.006-0.328,1.576-0.328c0.351,0,1.171,0.044,2.463,0.132c1.292,0.087,2.09,0.131,2.396,0.131
		c0.306,0,0.986-0.043,2.038-0.131c1.05-0.088,1.75-0.132,2.101-0.132c1.531,0,2.299,0.328,2.299,0.983
		c0,0.655-0.331,1.092-0.986,1.311c-0.352,0.131-0.603,0.305-0.756,0.524c-0.154,0.219-0.219,0.502-0.198,0.852
		c0.023,0.351,0.109,0.753,0.264,1.212c0.153,0.458,0.338,0.973,0.558,1.54c0.088,0.176,0.152,0.329,0.197,0.46
		c0.044,0.13,0.088,0.262,0.132,0.392l6.437,17.17c0.789,2.141,1.445,3.211,1.971,3.211c0.744,0,1.553-1.312,2.429-3.933l0.46-1.374
		l-5.125-13.633c-0.131-0.306-0.337-0.884-0.624-1.735c-0.285-0.853-0.492-1.441-0.623-1.77c-0.132-0.328-0.329-0.753-0.592-1.278
		c-0.263-0.524-0.537-0.906-0.821-1.146c-0.285-0.24-0.581-0.404-0.887-0.492c-0.7-0.174-1.051-0.546-1.051-1.114
		c0-0.787,0.483-1.18,1.446-1.18c0.306,0,1.006,0.044,2.102,0.132c1.094,0.087,1.794,0.131,2.102,0.131
		c0.306,0,0.941-0.043,1.904-0.131c0.964-0.088,1.599-0.132,1.905-0.132c0.569,0,1.094,0.109,1.576,0.328
		c0.482,0.218,0.723,0.503,0.723,0.852c0,0.523-0.329,0.895-0.985,1.114c-0.57,0.175-0.932,0.492-1.084,0.949
		c-0.154,0.459-0.165,0.973-0.034,1.541c0.132,0.568,0.394,1.398,0.789,2.489l2.233,6.03l1.445-4.26
		c0.395-1.223,0.689-2.184,0.886-2.883c0.198-0.699,0.306-1.333,0.328-1.9c0.022-0.567-0.064-0.993-0.262-1.278
		C111.772,53.988,111.432,53.779,110.951,53.649z"
					/>
					<path
						fill="#00858C"
						d="M226.743,66.688c0,1.354,0.504,2.03,1.511,2.03h5.649c0.656,0,1.236-0.085,1.74-0.262
		c0.504-0.174,0.875-0.358,1.117-0.557c0.24-0.197,0.623-0.534,1.149-1.016c0.13-0.13,0.338-0.359,0.623-0.687
		c0.285-0.328,0.537-0.569,0.757-0.722c0.219-0.153,0.436-0.23,0.656-0.23c0.48,0,0.722,0.438,0.722,1.312
		c0,0.131-0.045,0.711-0.132,1.737c-0.089,1.027-0.131,1.714-0.131,2.063s0.01,1.005,0.033,1.966
		c0.022,0.961,0.034,1.551,0.034,1.769c0,1.138-0.287,1.703-0.854,1.703c-0.177,0-0.352-0.063-0.526-0.195
		c-0.176-0.132-0.34-0.306-0.492-0.524c-0.155-0.217-0.285-0.416-0.395-0.589c-0.11-0.174-0.241-0.404-0.395-0.689
		c-0.154-0.283-0.253-0.447-0.295-0.491c-0.57-0.918-1.335-1.376-2.299-1.376h-6.896c-0.615,0-1.029,0.109-1.249,0.326
		c-0.219,0.221-0.328,0.636-0.328,1.247v7.34c0,1.834,0.384,3.331,1.149,4.487c0.767,1.159,1.893,1.738,3.384,1.738h7.159
		c1.225,0,2.232-0.101,3.021-0.297c0.787-0.196,1.486-0.632,2.101-1.31c0.613-0.678,1.062-1.267,1.348-1.77
		c0.284-0.501,0.731-1.408,1.347-2.72c0.394-0.831,0.984-1.244,1.772-1.244c0.613,0,0.92,0.546,0.92,1.637
		c0,0.831-0.327,2.152-0.985,3.965c-0.656,1.813-1.16,3.003-1.512,3.571c-0.219,0.394-0.371,0.654-0.459,0.786
		c-0.088,0.131-0.241,0.241-0.46,0.328c-0.219,0.086-0.503,0.131-0.854,0.131c-0.438,0-3.415-0.055-8.933-0.164
		c-5.518-0.108-9.635-0.164-12.349-0.164c-0.92,0-1.959,0.023-3.119,0.065c-1.162,0.045-2.146,0.099-2.957,0.165
		c-0.81,0.064-1.258,0.098-1.346,0.098c-1.445-0.131-2.167-0.502-2.167-1.113c0-0.306,0.174-0.557,0.523-0.753
		c0.352-0.196,0.888-0.417,1.611-0.657c0.722-0.238,1.215-0.426,1.478-0.555c2.058-0.919,3.086-2.755,3.086-5.507v-23.13
		c0-0.394-0.01-0.754-0.031-1.082c-0.022-0.329-0.099-0.633-0.229-0.917c-0.134-0.284-0.231-0.513-0.297-0.688
		c-0.066-0.175-0.23-0.35-0.493-0.525c-0.262-0.174-0.437-0.305-0.525-0.393c-0.087-0.087-0.339-0.208-0.755-0.36
		s-0.7-0.251-0.854-0.295c-0.152-0.043-0.503-0.141-1.05-0.294c-0.549-0.153-0.931-0.251-1.15-0.295
		c-0.7-0.219-1.05-0.59-1.05-1.114c0-0.787,0.765-1.18,2.298-1.18h18.852c1.052,0,2.55-0.054,4.499-0.164
		c1.947-0.109,3.054-0.164,3.317-0.164c1.006,0,1.575,0.307,1.706,0.918c0.396,1.572,0.593,3.364,0.593,5.374
		c0,1.136-0.352,1.703-1.052,1.703c-0.833,0-1.621-0.829-2.364-2.49c-0.7-1.441-2.475-2.162-5.319-2.162h-7.818
		c-0.831,0-1.377,0.109-1.641,0.327c-0.263,0.219-0.395,0.568-0.395,1.048V66.688z"
					/>
					<path
						fill="#00858C"
						d="M271.997,50.831c3.722,0,7.235,0.732,10.543,2.195c3.305,1.464,6.032,3.615,8.178,6.455
		c2.145,2.841,3.218,6.051,3.218,9.634c0,2.883-0.417,5.592-1.247,8.125c-0.833,2.533-2.061,4.795-3.679,6.78
		c-1.621,1.989-3.767,3.562-6.437,4.72c-2.672,1.157-5.715,1.736-9.13,1.736c-2.365,0-4.894-0.121-7.588-0.361
		c-2.691-0.239-4.411-0.36-5.154-0.36c-0.877,0-1.896,0.032-3.055,0.098c-1.161,0.068-2.147,0.132-2.957,0.198
		c-0.81,0.064-1.259,0.098-1.346,0.098c-1.445-0.131-2.168-0.502-2.168-1.113c0-0.306,0.175-0.557,0.526-0.753
		c0.351-0.196,0.887-0.417,1.609-0.657c0.723-0.238,1.215-0.426,1.477-0.555c2.058-0.919,3.089-2.755,3.089-5.507v-23.13
		c0-0.481-0.033-0.896-0.1-1.246c-0.064-0.348-0.131-0.655-0.198-0.917c-0.064-0.262-0.218-0.501-0.458-0.72
		c-0.241-0.218-0.418-0.393-0.525-0.524c-0.11-0.131-0.373-0.272-0.787-0.426c-0.419-0.152-0.715-0.262-0.888-0.328
		c-0.176-0.066-0.559-0.174-1.15-0.327c-0.591-0.153-1.019-0.272-1.279-0.36c-0.701-0.219-1.053-0.59-1.053-1.114
		c0-0.787,0.766-1.18,2.299-1.18c0.7,0,1.588,0.012,2.661,0.032c1.073,0.023,1.937,0.033,2.593,0.033
		c1.358,0,3.524-0.086,6.505-0.261C268.473,50.918,270.64,50.831,271.997,50.831z M264.115,55.875v23.198
		c0,3.189,0.931,5.396,2.792,6.618c1.86,1.224,4.435,1.834,7.719,1.834c3.677,0,6.589-1.725,8.735-5.176
		c2.145-3.45,3.218-7.426,3.218-11.927c0-4.761-1.62-8.726-4.859-11.893c-3.241-3.167-7.206-4.751-11.89-4.751
		c-1.533,0-2.868,0.187-4.008,0.558C264.686,54.708,264.115,55.221,264.115,55.875z"
					/>
					<path
						fill="#00858C"
						d="M333.672,57.777v15.86c0,5.066-1.51,9.13-4.531,12.187c-3.022,3.058-6.962,4.586-11.823,4.586
		c-5.517,0-9.359-1.483-11.527-4.456c-2.167-2.97-3.25-7.208-3.25-12.712V58.433c0-1.528-0.241-2.545-0.723-3.049
		c-0.481-0.501-1.773-1.103-3.876-1.801c-0.701-0.219-1.052-0.59-1.052-1.114c0-0.349,0.241-0.634,0.724-0.852
		c0.481-0.219,1.007-0.328,1.576-0.328c0.876,0,1.761,0.055,2.66,0.165c0.895,0.109,1.74,0.164,2.528,0.164
		c0.789,0,2.045-0.055,3.777-0.164c1.729-0.109,2.967-0.165,3.71-0.165c0.568,0,1.095,0.109,1.578,0.328
		c0.479,0.218,0.722,0.503,0.722,0.852c0,0.523-0.329,0.895-0.987,1.114c-1.487,0.481-2.594,1.071-3.315,1.769
		c-0.722,0.699-1.082,1.682-1.082,2.949v13.105c0,5.55,0.688,9.557,2.067,12.024c1.38,2.47,3.995,3.701,7.85,3.701
		c4.248,0,7.181-1.44,8.801-4.323c1.619-2.883,2.43-7.121,2.43-12.713V57.908c0-1.135-0.13-1.966-0.395-2.49
		c-0.261-0.524-0.535-0.852-0.82-0.983c-0.285-0.13-1.04-0.393-2.266-0.786c-0.744-0.263-1.117-0.656-1.117-1.18
		c0-0.349,0.242-0.634,0.724-0.852c0.48-0.219,1.006-0.328,1.576-0.328c0.351,0,1.072,0.055,2.168,0.165
		c1.093,0.109,1.794,0.164,2.102,0.164c0.305,0,1.007-0.055,2.101-0.164c1.094-0.109,1.796-0.165,2.102-0.165
		c0.57,0,1.095,0.109,1.577,0.328c0.481,0.218,0.724,0.503,0.724,0.852c0,0.48-0.373,0.852-1.117,1.114
		c-1.533,0.481-2.519,0.983-2.955,1.507C333.891,55.614,333.672,56.51,333.672,57.777z"
					/>
				</g>
				<rect
					id="Linie_2"
					x="2.921"
					y="99.221"
					fill="#111111"
					width="334.405"
					height="1.386"
				/>
				<rect
					id="Linie_1"
					x="2.903"
					y="119.614"
					fill="#111111"
					width="334.406"
					height="1.386"
				/>
				<g>
					<path
						fill="#111111"
						d="M3.307,105.093l0.855,0.028c0.561,0.019,0.928,0.028,1.103,0.028c0.154,0,0.809-0.019,1.963-0.057
		l0.055,0.05v0.356l-0.055,0.049c-0.314,0.011-0.558,0.023-0.729,0.042c-0.171,0.021-0.295,0.055-0.372,0.107
		c-0.079,0.053-0.13,0.175-0.155,0.366c-0.025,0.192-0.038,0.458-0.038,0.802l-0.013,1.634v3.292l0.006,1.387
		c0.005,0.535,0.019,0.882,0.041,1.037c0.023,0.156,0.056,0.262,0.099,0.313c0.043,0.053,0.127,0.097,0.25,0.133
		c0.124,0.034,0.427,0.063,0.91,0.082l0.055,0.042v0.356l-0.055,0.055c-0.023,0-0.423-0.013-1.204-0.041
		c-0.324-0.011-0.578-0.014-0.759-0.014c-0.142,0-0.795,0.018-1.958,0.055l-0.055-0.055v-0.356l0.055-0.042
		c0.424-0.02,0.707-0.045,0.848-0.072c0.141-0.028,0.236-0.063,0.284-0.108c0.048-0.047,0.087-0.142,0.117-0.289
		c0.03-0.147,0.047-0.476,0.051-0.981l0.007-1.5v-3.292L4.6,107.112c-0.004-0.541-0.017-0.889-0.039-1.045
		c-0.019-0.157-0.052-0.261-0.095-0.313c-0.044-0.051-0.126-0.094-0.249-0.128c-0.124-0.032-0.427-0.059-0.91-0.078l-0.055-0.049
		v-0.356L3.307,105.093z"
					/>
					<path
						fill="#111111"
						d="M9.478,115.19l-0.063-0.065V114.8l0.063-0.058c0.278-0.004,0.5-0.021,0.667-0.051
		c0.166-0.028,0.273-0.075,0.321-0.143c0.047-0.065,0.079-0.172,0.096-0.319c0.016-0.146,0.031-0.478,0.048-0.996
		c0.016-0.515,0.024-0.887,0.024-1.109v-5.745c0-0.242-0.043-0.42-0.126-0.536c-0.085-0.115-0.2-0.189-0.347-0.222
		c-0.146-0.029-0.394-0.048-0.746-0.051l-0.054-0.058v-0.335l0.054-0.064c0.629,0.024,1.043,0.036,1.239,0.036
		c0.46,0,0.84-0.012,1.136-0.036l2.182,2.944l3.209,4.181c0.389,0.508,0.782,0.99,1.184,1.442v-5.502
		c0-0.171-0.008-0.535-0.023-1.093c-0.017-0.555-0.035-0.908-0.059-1.058c-0.022-0.15-0.063-0.251-0.119-0.302
		c-0.057-0.052-0.164-0.094-0.322-0.118c-0.157-0.026-0.371-0.041-0.64-0.047l-0.054-0.057v-0.327l0.054-0.064
		c0.371,0.024,0.897,0.036,1.582,0.036c0.624,0,1.078-0.012,1.361-0.036l0.063,0.064v0.327l-0.055,0.057
		c-0.302,0.006-0.534,0.022-0.698,0.055c-0.164,0.03-0.267,0.081-0.308,0.152c-0.042,0.07-0.069,0.175-0.086,0.312
		c-0.015,0.14-0.031,0.466-0.047,0.982c-0.017,0.518-0.025,0.888-0.025,1.116v3.378l0.008,1.954c0.009,0.64,0.018,1.282,0.028,1.928
		c-0.229-0.081-0.624-0.181-1.184-0.298l-0.486-0.599l-1.458-1.848l-4.639-6.021v5.451c0,0.22,0.007,0.607,0.024,1.167
		c0.015,0.559,0.034,0.897,0.058,1.016c0.022,0.12,0.052,0.202,0.089,0.246c0.037,0.046,0.115,0.087,0.236,0.125
		c0.121,0.039,0.371,0.061,0.749,0.064l0.055,0.058v0.332l-0.055,0.059c-0.237-0.035-0.621-0.05-1.149-0.05
		C10.583,115.141,9.988,115.155,9.478,115.19z"
					/>
					<path
						fill="#111111"
						d="M22.614,112.694l0.055-0.059h0.335l0.054,0.059c0.022,0.606,0.053,0.977,0.093,1.112
		c0.038,0.136,0.16,0.277,0.369,0.428c0.208,0.148,0.482,0.27,0.828,0.364c0.344,0.096,0.692,0.144,1.044,0.144
		c0.483,0,0.917-0.094,1.303-0.278c0.385-0.185,0.685-0.455,0.9-0.813c0.214-0.359,0.321-0.76,0.321-1.205
		c0-0.313-0.051-0.586-0.153-0.817c-0.103-0.232-0.246-0.418-0.431-0.559c-0.185-0.141-0.396-0.244-0.634-0.314
		c-0.236-0.067-0.59-0.142-1.06-0.222c-0.457-0.076-0.813-0.145-1.072-0.207c-0.257-0.061-0.514-0.157-0.769-0.284
		c-0.256-0.129-0.47-0.291-0.64-0.487c-0.171-0.195-0.308-0.438-0.411-0.726c-0.103-0.286-0.154-0.593-0.154-0.92
		c0-0.887,0.296-1.613,0.89-2.18c0.592-0.565,1.388-0.851,2.388-0.851c0.396,0,0.828,0.051,1.296,0.153
		c0.469,0.103,0.893,0.252,1.276,0.451l0.048,0.087c-0.101,0.436-0.162,1.056-0.185,1.861l-0.062,0.057H27.89l-0.055-0.051
		c-0.008-0.567-0.023-0.925-0.041-1.071c-0.018-0.146-0.226-0.33-0.622-0.548c-0.398-0.218-0.829-0.327-1.293-0.327
		c-0.389,0-0.751,0.08-1.088,0.241c-0.338,0.162-0.594,0.415-0.768,0.761c-0.173,0.347-0.26,0.701-0.26,1.06
		c0,0.28,0.052,0.527,0.158,0.743c0.105,0.217,0.24,0.388,0.407,0.511c0.167,0.127,0.36,0.221,0.581,0.282
		c0.223,0.062,0.628,0.138,1.215,0.227c0.808,0.119,1.395,0.263,1.763,0.431c0.366,0.169,0.661,0.436,0.882,0.801
		s0.332,0.804,0.332,1.324c0,1.008-0.396,1.851-1.188,2.521c-0.791,0.674-1.75,1.01-2.877,1.01c-0.945,0-1.771-0.175-2.484-0.527
		l-0.041-0.091C22.557,114.485,22.59,113.789,22.614,112.694z"
					/>
					<path
						fill="#111111"
						d="M39.091,105.093l0.061,0.063c-0.041,0.428-0.061,1.173-0.061,2.232l-0.055,0.058h-0.322l-0.054-0.063
		l-0.076-0.654c-0.037-0.351-0.064-0.564-0.086-0.645c-0.02-0.076-0.053-0.135-0.099-0.169c-0.046-0.037-0.193-0.064-0.444-0.089
		c-0.251-0.024-0.532-0.036-0.842-0.036h-1.294c-0.036,1.071-0.055,1.69-0.055,1.854v4.146l0.014,1.387
		c0.005,0.535,0.017,0.883,0.039,1.041c0.02,0.16,0.052,0.263,0.096,0.313c0.043,0.051,0.126,0.093,0.249,0.129
		c0.124,0.034,0.429,0.063,0.917,0.082l0.048,0.042v0.356l-0.048,0.055c-1.168-0.037-1.819-0.055-1.956-0.055
		c-0.146,0-0.797,0.018-1.951,0.055l-0.055-0.055v-0.356l0.055-0.042c0.429-0.02,0.713-0.045,0.852-0.072
		c0.14-0.028,0.233-0.063,0.281-0.108c0.047-0.047,0.087-0.142,0.119-0.289c0.033-0.147,0.048-0.476,0.048-0.981l0.015-1.5v-4.146
		c0-0.073-0.018-0.693-0.055-1.854H33.41c-0.418,0-0.781,0.009-1.087,0.028c-0.305,0.02-0.481,0.068-0.53,0.146
		c-0.048,0.079-0.093,0.333-0.134,0.765l-0.068,0.654l-0.062,0.063h-0.315l-0.055-0.058c-0.009-1.142-0.029-1.886-0.062-2.232
		l0.062-0.063c0.795,0.038,2.116,0.057,3.963,0.057C37.005,105.149,38.328,105.131,39.091,105.093z"
					/>
					<path
						fill="#111111"
						d="M41.223,105.093l0.855,0.028c0.561,0.019,0.928,0.028,1.103,0.028c0.154,0,0.809-0.019,1.963-0.057
		l0.055,0.05v0.356l-0.055,0.049c-0.314,0.011-0.558,0.023-0.729,0.042c-0.17,0.021-0.295,0.055-0.374,0.107
		c-0.076,0.053-0.128,0.175-0.153,0.366c-0.025,0.192-0.038,0.458-0.038,0.802l-0.013,1.634v3.292l0.007,1.387
		c0.005,0.535,0.019,0.882,0.042,1.037c0.022,0.156,0.055,0.262,0.099,0.313c0.042,0.053,0.127,0.097,0.25,0.133
		c0.123,0.034,0.426,0.063,0.91,0.082l0.055,0.042v0.356l-0.055,0.055c-0.022,0-0.424-0.013-1.204-0.041
		c-0.325-0.011-0.576-0.014-0.759-0.014c-0.142,0-0.794,0.018-1.958,0.055l-0.055-0.055v-0.356l0.055-0.042
		c0.425-0.02,0.707-0.045,0.849-0.072c0.141-0.028,0.237-0.063,0.284-0.108c0.048-0.047,0.086-0.142,0.116-0.289
		s0.047-0.476,0.051-0.981l0.007-1.5v-3.292l-0.013-1.387c-0.005-0.541-0.018-0.889-0.038-1.045
		c-0.02-0.157-0.053-0.261-0.095-0.313c-0.043-0.051-0.127-0.094-0.25-0.128c-0.123-0.032-0.427-0.059-0.911-0.078l-0.055-0.049
		v-0.356L41.223,105.093z"
					/>
					<path
						fill="#111111"
						d="M55.201,105.093l0.063,0.063c-0.042,0.428-0.063,1.173-0.063,2.232l-0.054,0.058h-0.322l-0.055-0.063
		l-0.076-0.654c-0.037-0.351-0.066-0.564-0.085-0.645c-0.021-0.076-0.054-0.135-0.099-0.169c-0.047-0.037-0.194-0.064-0.445-0.089
		c-0.251-0.024-0.532-0.036-0.842-0.036h-1.293c-0.037,1.071-0.055,1.69-0.055,1.854v4.146l0.014,1.387
		c0.005,0.535,0.018,0.883,0.038,1.041c0.021,0.16,0.053,0.263,0.096,0.313c0.042,0.051,0.127,0.093,0.25,0.129
		c0.123,0.034,0.428,0.063,0.917,0.082l0.047,0.042v0.356l-0.047,0.055c-1.167-0.037-1.82-0.055-1.957-0.055
		c-0.147,0-0.796,0.018-1.95,0.055l-0.056-0.055v-0.356l0.056-0.042c0.428-0.02,0.712-0.045,0.851-0.072
		c0.139-0.028,0.232-0.063,0.28-0.108c0.048-0.047,0.089-0.142,0.121-0.289c0.031-0.147,0.047-0.476,0.047-0.981l0.013-1.5v-4.146
		c0-0.073-0.018-0.693-0.054-1.854h-1.02c-0.42,0-0.783,0.009-1.088,0.028c-0.305,0.02-0.481,0.068-0.53,0.146
		c-0.048,0.079-0.092,0.333-0.133,0.765l-0.068,0.654l-0.062,0.063h-0.315l-0.055-0.058c-0.008-1.142-0.029-1.886-0.061-2.232
		l0.061-0.063c0.794,0.038,2.115,0.057,3.963,0.057C53.117,105.149,54.44,105.131,55.201,105.093z"
					/>
					<path
						fill="#111111"
						d="M57.136,105.143l0.055-0.05c1.131,0.038,1.784,0.057,1.957,0.057c0.155,0,0.81-0.019,1.964-0.057
		l0.055,0.05v0.356l-0.055,0.049c-0.315,0.011-0.558,0.023-0.729,0.042c-0.171,0.021-0.296,0.055-0.373,0.107
		c-0.078,0.053-0.129,0.175-0.155,0.366c-0.025,0.192-0.037,0.458-0.037,0.802l-0.014,1.634v2.262c0,0.743,0.014,1.289,0.044,1.64
		c0.03,0.347,0.094,0.641,0.191,0.879c0.099,0.239,0.248,0.446,0.449,0.62s0.487,0.314,0.862,0.422
		c0.374,0.11,0.799,0.164,1.273,0.164c0.501,0,0.971-0.07,1.406-0.215c0.436-0.146,0.768-0.356,0.997-0.631
		c0.228-0.275,0.377-0.638,0.447-1.084c0.071-0.449,0.106-1.071,0.106-1.867v-2.189c0-0.371-0.005-0.879-0.018-1.525
		c-0.011-0.648-0.039-1.019-0.082-1.116c-0.042-0.097-0.123-0.172-0.24-0.222c-0.116-0.049-0.438-0.077-0.968-0.088l-0.055-0.057
		v-0.335l0.055-0.063c0.675,0.038,1.216,0.057,1.623,0.057c0.347,0,0.884-0.019,1.614-0.057l0.062,0.057v0.342l-0.062,0.057
		c-0.429,0.011-0.708,0.028-0.837,0.053c-0.131,0.027-0.221,0.066-0.274,0.117c-0.052,0.053-0.091,0.134-0.116,0.242
		c-0.025,0.108-0.042,0.424-0.051,0.939l-0.021,1.6v2.16c0,0.863-0.032,1.544-0.094,2.042c-0.065,0.496-0.208,0.906-0.429,1.226
		c-0.222,0.319-0.483,0.588-0.786,0.804c-0.303,0.217-0.693,0.382-1.167,0.497c-0.475,0.117-0.967,0.175-1.478,0.175
		c-0.638,0-1.203-0.073-1.694-0.22c-0.49-0.147-0.876-0.344-1.16-0.584c-0.283-0.243-0.485-0.497-0.607-0.764
		c-0.124-0.268-0.205-0.583-0.241-0.945c-0.036-0.364-0.054-0.916-0.054-1.66v-2.73l-0.015-1.387
		c-0.004-0.541-0.017-0.889-0.037-1.045c-0.021-0.157-0.053-0.261-0.097-0.313c-0.043-0.051-0.125-0.094-0.249-0.128
		c-0.123-0.032-0.426-0.059-0.91-0.078l-0.055-0.049V105.143z"
					/>
					<path
						fill="#111111"
						d="M77.493,105.093l0.062,0.063c-0.042,0.428-0.062,1.173-0.062,2.232l-0.055,0.058h-0.321l-0.054-0.063
		l-0.077-0.654c-0.035-0.351-0.064-0.564-0.084-0.645c-0.021-0.076-0.054-0.135-0.099-0.169c-0.045-0.037-0.194-0.064-0.445-0.089
		s-0.531-0.036-0.842-0.036h-1.293c-0.036,1.071-0.054,1.69-0.054,1.854v4.146l0.014,1.387c0.004,0.535,0.017,0.883,0.036,1.041
		c0.022,0.16,0.053,0.263,0.097,0.313c0.043,0.051,0.126,0.093,0.249,0.129c0.124,0.034,0.429,0.063,0.918,0.082l0.047,0.042v0.356
		l-0.047,0.055c-1.168-0.037-1.82-0.055-1.958-0.055c-0.146,0-0.796,0.018-1.95,0.055l-0.055-0.055v-0.356l0.055-0.042
		c0.428-0.02,0.712-0.045,0.852-0.072c0.138-0.028,0.232-0.063,0.28-0.108c0.048-0.047,0.088-0.142,0.12-0.289
		c0.031-0.147,0.048-0.476,0.048-0.981l0.013-1.5v-4.146c0-0.073-0.018-0.693-0.055-1.854h-1.019c-0.42,0-0.783,0.009-1.088,0.028
		c-0.306,0.02-0.482,0.068-0.531,0.146c-0.047,0.079-0.093,0.333-0.133,0.765l-0.069,0.654l-0.062,0.063h-0.314l-0.055-0.058
		c-0.01-1.142-0.031-1.886-0.062-2.232l0.062-0.063c0.793,0.038,2.114,0.057,3.961,0.057
		C75.408,105.149,76.731,105.131,77.493,105.093z M71.93,118.099l-0.028-0.079l0.158-0.349l0.068-0.035
		c0.265,0.1,0.536,0.148,0.815,0.148c0.483,0,0.725-0.191,0.725-0.576c0-0.346-0.224-0.519-0.671-0.519
		c-0.154,0-0.303,0.026-0.444,0.078l-0.096-0.071l0.165-0.348c0.305-0.124,0.645-0.187,1.02-0.187c0.305,0,0.562,0.091,0.769,0.268
		c0.208,0.179,0.313,0.408,0.313,0.694c0,0.365-0.153,0.652-0.456,0.86c-0.303,0.209-0.684,0.312-1.146,0.312
		C72.7,118.296,72.303,118.23,71.93,118.099z"
					/>
					<path
						fill="#111111"
						d="M79.625,105.093l0.856,0.028c0.561,0.019,0.929,0.028,1.101,0.028c0.156,0,0.811-0.019,1.965-0.057
		l0.055,0.05v0.356l-0.055,0.049c-0.314,0.011-0.558,0.023-0.729,0.042c-0.171,0.021-0.295,0.055-0.373,0.107
		s-0.129,0.175-0.155,0.366c-0.025,0.192-0.038,0.458-0.038,0.802l-0.013,1.634v3.292l0.008,1.387
		c0.003,0.535,0.017,0.882,0.041,1.037c0.023,0.156,0.055,0.262,0.099,0.313c0.043,0.053,0.127,0.097,0.25,0.133
		c0.123,0.034,0.426,0.063,0.91,0.082l0.055,0.042v0.356l-0.055,0.055c-0.023,0-0.425-0.013-1.204-0.041
		c-0.325-0.011-0.578-0.014-0.761-0.014c-0.14,0-0.793,0.018-1.957,0.055l-0.054-0.055v-0.356l0.054-0.042
		c0.425-0.02,0.708-0.045,0.849-0.072c0.141-0.028,0.236-0.063,0.284-0.108c0.047-0.047,0.087-0.142,0.116-0.289
		c0.03-0.147,0.046-0.476,0.051-0.981l0.007-1.5v-3.292l-0.013-1.387c-0.006-0.541-0.017-0.889-0.039-1.045
		c-0.02-0.157-0.053-0.261-0.095-0.313c-0.044-0.051-0.126-0.094-0.25-0.128c-0.124-0.032-0.426-0.059-0.911-0.078l-0.054-0.049
		v-0.356L79.625,105.093z"
					/>
					<path
						fill="#111111"
						d="M85.845,105.143l0.055-0.05c1.564,0.028,2.565,0.042,3.002,0.042c1.498,0,2.83-0.014,3.996-0.042
		l0.035,0.05c-0.113,0.454-0.198,1.168-0.254,2.14l-0.04,0.058h-0.335l-0.061-0.058c0.003-0.042,0.006-0.079,0.006-0.112
		c0-0.403-0.029-0.786-0.088-1.152c-0.288-0.131-0.685-0.22-1.194-0.26c-0.509-0.041-0.892-0.061-1.147-0.061
		c-0.36,0-0.82,0.026-1.381,0.078l-0.028,1.139l-0.021,1.3l-0.02,1.443c0.493,0.029,0.946,0.045,1.361,0.045
		c0.42,0,0.771-0.016,1.054-0.045c0.283-0.028,0.449-0.057,0.497-0.088c0.048-0.031,0.083-0.091,0.109-0.178
		c0.025-0.089,0.047-0.243,0.065-0.466l0.027-0.442l0.055-0.056h0.315l0.053,0.056c-0.009,0.517-0.019,1.038-0.034,1.559
		l0.034,1.599l-0.053,0.059h-0.315l-0.055-0.059l-0.047-0.425c-0.042-0.332-0.072-0.531-0.09-0.594
		c-0.018-0.065-0.06-0.121-0.126-0.168c-0.065-0.047-0.236-0.086-0.51-0.113c-0.274-0.029-0.649-0.044-1.128-0.044
		c-0.266,0-0.669,0.012-1.212,0.035c-0.023,0.4-0.034,1.063-0.034,1.992c0,0.996,0.011,1.728,0.034,2.196h1.259
		c1.108,0,1.802-0.014,2.08-0.043c0.278-0.028,0.542-0.096,0.794-0.205c0.041-0.109,0.107-0.362,0.202-0.758
		c0.094-0.396,0.146-0.643,0.159-0.743l0.069-0.05h0.329l0.055,0.05c-0.041,0.203-0.081,0.561-0.12,1.066
		c-0.04,0.509-0.058,0.941-0.058,1.303l-0.048,0.055c-0.871-0.028-2.011-0.041-3.422-0.041l-1.86-0.014
		c-0.433,0-0.886,0.018-1.355,0.055l-0.055-0.055v-0.235l0.055-0.071c0.269-0.143,0.423-0.232,0.461-0.267
		c0.04-0.036,0.07-0.45,0.096-1.244c0.026-0.794,0.039-1.399,0.039-1.817v-2.609l-0.007-1.614c0-0.351-0.005-0.636-0.015-0.857
		c-0.008-0.219-0.025-0.377-0.048-0.472c-0.022-0.094-0.048-0.161-0.077-0.199c-0.031-0.037-0.078-0.07-0.144-0.097
		c-0.067-0.025-0.181-0.047-0.339-0.067l-0.52-0.042l-0.055-0.049V105.143z"
					/>
					<path
						fill="#111111"
						d="M100.417,105.143l0.054-0.05c0.79,0.038,1.328,0.057,1.615,0.057l3.202-0.071
		c0.913,0,1.661,0.09,2.245,0.267c0.584,0.179,1.104,0.486,1.557,0.925s0.798,0.949,1.033,1.529c0.234,0.58,0.353,1.199,0.353,1.858
		c0,0.64-0.108,1.272-0.322,1.899c-0.214,0.625-0.52,1.187-0.913,1.682c-0.395,0.497-0.845,0.895-1.349,1.197
		c-0.502,0.305-1.009,0.507-1.515,0.608s-1.024,0.151-1.553,0.151c-0.356,0-1.026-0.013-2.011-0.041
		c-0.325-0.011-0.539-0.014-0.643-0.014c-0.316,0-0.697,0.018-1.144,0.055l-0.054-0.055v-0.235l0.054-0.071
		c0.269-0.143,0.423-0.232,0.461-0.267c0.04-0.036,0.073-0.45,0.097-1.244c0.026-0.794,0.039-1.399,0.039-1.817v-2.609l-0.007-1.614
		c0-0.351-0.005-0.636-0.015-0.857c-0.008-0.219-0.025-0.377-0.047-0.472c-0.022-0.094-0.05-0.161-0.079-0.199
		c-0.03-0.037-0.077-0.07-0.144-0.097c-0.066-0.025-0.18-0.047-0.338-0.067l-0.521-0.042l-0.054-0.049V105.143z M102.949,114.422
		c0.464,0.094,1,0.143,1.608,0.143c1.063,0,1.912-0.185,2.549-0.556c0.637-0.369,1.111-0.917,1.426-1.641
		c0.315-0.728,0.473-1.544,0.473-2.455c0-1.341-0.345-2.382-1.033-3.121c-0.69-0.738-1.775-1.109-3.258-1.109
		c-0.612,0-1.199,0.047-1.765,0.144c-0.038,0.775-0.055,1.654-0.055,2.63v2.232l0.013,2.091
		C102.907,113.186,102.921,113.735,102.949,114.422z"
					/>
					<path
						fill="#111111"
						d="M112.958,105.143l0.056-0.05c1.564,0.028,2.566,0.042,3.004,0.042c1.495,0,2.827-0.014,3.996-0.042
		l0.032,0.05c-0.113,0.454-0.197,1.168-0.251,2.14l-0.041,0.058h-0.336l-0.061-0.058c0.003-0.042,0.007-0.079,0.007-0.112
		c0-0.403-0.03-0.786-0.091-1.152c-0.287-0.131-0.684-0.22-1.194-0.26c-0.507-0.041-0.89-0.061-1.146-0.061
		c-0.36,0-0.821,0.026-1.382,0.078l-0.027,1.139l-0.021,1.3l-0.021,1.443c0.493,0.029,0.947,0.045,1.361,0.045
		c0.42,0,0.771-0.016,1.054-0.045c0.283-0.028,0.449-0.057,0.497-0.088c0.046-0.031,0.084-0.091,0.108-0.178
		c0.025-0.089,0.047-0.243,0.065-0.466l0.028-0.442l0.054-0.056h0.315l0.055,0.056c-0.009,0.517-0.021,1.038-0.036,1.559
		l0.036,1.599l-0.055,0.059h-0.315l-0.054-0.059l-0.047-0.425c-0.043-0.332-0.071-0.531-0.09-0.594
		c-0.019-0.065-0.06-0.121-0.126-0.168c-0.066-0.047-0.236-0.086-0.51-0.113c-0.274-0.029-0.651-0.044-1.129-0.044
		c-0.264,0-0.668,0.012-1.211,0.035c-0.022,0.4-0.035,1.063-0.035,1.992c0,0.996,0.012,1.728,0.035,2.196h1.258
		c1.11,0,1.803-0.014,2.082-0.043c0.277-0.028,0.542-0.096,0.794-0.205c0.041-0.109,0.108-0.362,0.202-0.758
		c0.093-0.396,0.146-0.643,0.16-0.743l0.067-0.05h0.33l0.055,0.05c-0.041,0.203-0.082,0.561-0.12,1.066
		c-0.04,0.509-0.058,0.941-0.058,1.303l-0.048,0.055c-0.872-0.028-2.012-0.041-3.421-0.041l-1.861-0.014
		c-0.435,0-0.886,0.018-1.355,0.055l-0.056-0.055v-0.235l0.056-0.071c0.269-0.143,0.422-0.232,0.461-0.267
		c0.039-0.036,0.071-0.45,0.096-1.244c0.024-0.794,0.037-1.399,0.037-1.817v-2.609l-0.007-1.614c0-0.351-0.005-0.636-0.013-0.857
		c-0.01-0.219-0.025-0.377-0.048-0.472c-0.024-0.094-0.048-0.161-0.079-0.199c-0.029-0.037-0.078-0.07-0.144-0.097
		c-0.066-0.025-0.178-0.047-0.339-0.067l-0.52-0.042l-0.056-0.049V105.143z"
					/>
					<path
						fill="#111111"
						d="M127.824,105.143l0.056-0.05c1.563,0.028,2.564,0.042,3.002,0.042c1.497,0,2.829-0.014,3.996-0.042
		l0.034,0.05c-0.115,0.454-0.198,1.168-0.253,2.14l-0.041,0.058h-0.335l-0.062-0.058c0.005-0.042,0.007-0.079,0.007-0.112
		c0-0.403-0.029-0.786-0.088-1.152c-0.288-0.131-0.686-0.22-1.195-0.26c-0.508-0.041-0.89-0.061-1.146-0.061
		c-0.36,0-0.821,0.026-1.381,0.078l-0.028,1.139l-0.021,1.3l-0.019,1.443c0.491,0.029,0.946,0.045,1.361,0.045
		c0.42,0,0.771-0.016,1.053-0.045c0.283-0.028,0.449-0.057,0.497-0.088c0.047-0.031,0.083-0.091,0.109-0.178
		c0.025-0.089,0.047-0.243,0.066-0.466l0.026-0.442l0.054-0.056h0.315l0.054,0.056c-0.009,0.517-0.019,1.038-0.034,1.559
		l0.034,1.599l-0.054,0.059h-0.315l-0.054-0.059l-0.048-0.425c-0.041-0.332-0.07-0.531-0.088-0.594
		c-0.019-0.065-0.061-0.121-0.126-0.168s-0.236-0.086-0.51-0.113c-0.274-0.029-0.651-0.044-1.129-0.044
		c-0.265,0-0.669,0.012-1.21,0.035c-0.023,0.4-0.034,1.063-0.034,1.992c0,0.996,0.011,1.728,0.034,2.196h1.258
		c1.109,0,1.803-0.014,2.081-0.043c0.278-0.028,0.542-0.096,0.794-0.205c0.041-0.109,0.108-0.362,0.201-0.758
		c0.094-0.396,0.148-0.643,0.161-0.743l0.069-0.05h0.329l0.055,0.05c-0.041,0.203-0.081,0.561-0.12,1.066
		c-0.039,0.509-0.058,0.941-0.058,1.303l-0.049,0.055c-0.871-0.028-2.011-0.041-3.421-0.041l-1.862-0.014
		c-0.434,0-0.885,0.018-1.354,0.055l-0.056-0.055v-0.235l0.056-0.071c0.269-0.143,0.423-0.232,0.462-0.267
		c0.039-0.036,0.07-0.45,0.095-1.244c0.026-0.794,0.038-1.399,0.038-1.817v-2.609l-0.007-1.614c0-0.351-0.004-0.636-0.013-0.857
		c-0.009-0.219-0.026-0.377-0.048-0.472c-0.023-0.094-0.048-0.161-0.079-0.199c-0.029-0.037-0.078-0.07-0.143-0.097
		c-0.067-0.025-0.18-0.047-0.339-0.067l-0.519-0.042l-0.056-0.049V105.143z"
					/>
					<path
						fill="#111111"
						d="M137.491,105.143l0.056-0.05c0.788,0.038,1.327,0.057,1.615,0.057l3.202-0.071
		c0.912,0,1.66,0.09,2.244,0.267c0.584,0.179,1.103,0.486,1.557,0.925c0.455,0.438,0.799,0.949,1.033,1.529
		c0.236,0.58,0.352,1.199,0.352,1.858c0,0.64-0.106,1.272-0.32,1.899c-0.215,0.625-0.521,1.187-0.914,1.682
		c-0.395,0.497-0.843,0.895-1.349,1.197c-0.503,0.305-1.01,0.507-1.515,0.608c-0.506,0.102-1.025,0.151-1.554,0.151
		c-0.355,0-1.027-0.013-2.011-0.041c-0.325-0.011-0.539-0.014-0.644-0.014c-0.315,0-0.696,0.018-1.142,0.055l-0.055-0.055v-0.235
		l0.055-0.071c0.268-0.143,0.422-0.232,0.461-0.267c0.04-0.036,0.07-0.45,0.097-1.244c0.024-0.794,0.037-1.399,0.037-1.817v-2.609
		l-0.007-1.614c0-0.351-0.005-0.636-0.015-0.857c-0.008-0.219-0.024-0.377-0.047-0.472c-0.022-0.094-0.048-0.161-0.077-0.199
		c-0.031-0.037-0.079-0.07-0.146-0.097c-0.067-0.025-0.178-0.047-0.338-0.067l-0.52-0.042l-0.056-0.049V105.143z M140.024,114.422
		c0.465,0.094,1,0.143,1.608,0.143c1.062,0,1.912-0.185,2.548-0.556c0.637-0.369,1.112-0.917,1.427-1.641
		c0.315-0.728,0.473-1.544,0.473-2.455c0-1.341-0.346-2.382-1.034-3.121c-0.688-0.738-1.775-1.109-3.257-1.109
		c-0.612,0-1.201,0.047-1.765,0.144c-0.037,0.775-0.055,1.654-0.055,2.63v2.232l0.013,2.091
		C139.982,113.186,139.997,113.735,140.024,114.422z"
					/>
					<path
						fill="#111111"
						d="M149.739,105.143l0.054-0.05c1.131,0.038,1.785,0.057,1.957,0.057c0.155,0,0.811-0.019,1.964-0.057
		l0.055,0.05v0.356l-0.055,0.049c-0.315,0.011-0.558,0.023-0.729,0.042c-0.171,0.021-0.296,0.055-0.373,0.107
		c-0.078,0.053-0.128,0.175-0.155,0.366c-0.023,0.192-0.036,0.458-0.036,0.802l-0.015,1.634v2.262c0,0.743,0.016,1.289,0.044,1.64
		c0.03,0.347,0.094,0.641,0.192,0.879c0.099,0.239,0.247,0.446,0.448,0.62c0.202,0.174,0.489,0.314,0.863,0.422
		c0.373,0.11,0.797,0.164,1.272,0.164c0.501,0,0.971-0.07,1.406-0.215c0.436-0.146,0.767-0.356,0.996-0.631
		c0.228-0.275,0.376-0.638,0.448-1.084c0.071-0.449,0.105-1.071,0.105-1.867v-2.189c0-0.371-0.005-0.879-0.017-1.525
		c-0.012-0.648-0.039-1.019-0.083-1.116c-0.042-0.097-0.123-0.172-0.239-0.222c-0.115-0.049-0.439-0.077-0.968-0.088l-0.054-0.057
		v-0.335l0.054-0.063c0.674,0.038,1.215,0.057,1.622,0.057c0.346,0,0.885-0.019,1.615-0.057l0.062,0.057v0.342l-0.062,0.057
		c-0.429,0.011-0.708,0.028-0.838,0.053c-0.131,0.027-0.222,0.066-0.273,0.117c-0.054,0.053-0.092,0.134-0.118,0.242
		c-0.025,0.108-0.041,0.424-0.05,0.939l-0.022,1.6v2.16c0,0.863-0.031,1.544-0.096,2.042c-0.062,0.496-0.204,0.906-0.428,1.226
		c-0.219,0.319-0.482,0.588-0.786,0.804c-0.304,0.217-0.693,0.382-1.167,0.497c-0.474,0.117-0.966,0.175-1.478,0.175
		c-0.639,0-1.203-0.073-1.691-0.22c-0.491-0.147-0.879-0.344-1.162-0.584c-0.283-0.243-0.486-0.497-0.608-0.764
		c-0.125-0.268-0.204-0.583-0.24-0.945c-0.036-0.364-0.055-0.916-0.055-1.66v-2.73l-0.014-1.387
		c-0.004-0.541-0.018-0.889-0.037-1.045c-0.021-0.157-0.052-0.261-0.096-0.313c-0.042-0.051-0.127-0.094-0.25-0.128
		c-0.123-0.032-0.426-0.059-0.911-0.078l-0.054-0.049V105.143z"
					/>
					<path
						fill="#111111"
						d="M171.381,113.938l-0.259,0.66c-1.013,0.537-2.095,0.804-3.244,0.804c-1.648,0-2.985-0.485-4.013-1.457
		c-1.029-0.972-1.545-2.243-1.545-3.811c0-1.081,0.258-2.025,0.771-2.833c0.514-0.811,1.19-1.413,2.032-1.815
		c0.841-0.399,1.786-0.6,2.837-0.6c0.605,0,1.205,0.067,1.799,0.2c0.592,0.133,1.088,0.291,1.483,0.475l0.048,0.071
		c-0.104,0.267-0.201,0.921-0.293,1.971l-0.049,0.056h-0.32l-0.055-0.056l-0.07-1.209c-0.331-0.276-0.734-0.489-1.207-0.645
		c-0.473-0.153-0.963-0.229-1.475-0.229c-0.77,0-1.461,0.172-2.072,0.514c-0.611,0.345-1.086,0.842-1.425,1.493
		c-0.337,0.652-0.505,1.45-0.505,2.395c0,0.946,0.197,1.78,0.595,2.495c0.396,0.715,0.938,1.264,1.626,1.648
		c0.686,0.384,1.487,0.576,2.404,0.576c0.406,0,0.796-0.04,1.17-0.121c0.373-0.081,0.686-0.18,0.935-0.297
		c0.247-0.118,0.487-0.264,0.715-0.435L171.381,113.938z"
					/>
					<path
						fill="#111111"
						d="M173.473,115.195l-0.061-0.055v-0.37l0.061-0.058c0.333-0.005,0.548-0.021,0.645-0.053
		c0.096-0.031,0.18-0.093,0.256-0.185c0.076-0.093,0.227-0.395,0.455-0.907l0.72-1.622l1.859-4.379
		c0.352-0.856,0.701-1.716,1.048-2.573h0.445l2.92,7.138l0.39,0.953c0.115,0.27,0.246,0.564,0.395,0.881
		c0.149,0.318,0.251,0.506,0.309,0.562c0.058,0.058,0.127,0.102,0.213,0.133c0.083,0.031,0.271,0.048,0.563,0.053l0.055,0.058v0.37
		l-0.055,0.055c-0.482-0.041-0.92-0.063-1.307-0.063c-0.757,0.023-1.515,0.047-2.271,0.063l-0.057-0.055v-0.37l0.057-0.058
		c0.509-0.009,0.83-0.032,0.957-0.067c0.128-0.035,0.191-0.118,0.191-0.245c0-0.104-0.031-0.242-0.096-0.412l-0.841-2.168h-3.962
		l-0.747,1.819c-0.133,0.326-0.197,0.566-0.197,0.716c0,0.121,0.063,0.209,0.185,0.266c0.123,0.057,0.434,0.087,0.93,0.092
		l0.063,0.058v0.37l-0.057,0.055c-0.587-0.037-1.104-0.055-1.547-0.055C174.489,115.141,173.983,115.158,173.473,115.195z
		 M176.595,111.207h3.46l-1.716-4.287L176.595,111.207z"
					/>
					<path
						fill="#111111"
						d="M193.733,105.093l0.062,0.063c-0.039,0.428-0.062,1.173-0.062,2.232l-0.056,0.058h-0.321l-0.054-0.063
		l-0.076-0.654c-0.036-0.351-0.064-0.564-0.085-0.645c-0.021-0.076-0.054-0.135-0.101-0.169c-0.045-0.037-0.194-0.064-0.443-0.089
		c-0.252-0.024-0.531-0.036-0.842-0.036h-1.293c-0.038,1.071-0.056,1.69-0.056,1.854v4.146l0.015,1.387
		c0.003,0.535,0.018,0.883,0.036,1.041c0.021,0.16,0.054,0.263,0.097,0.313c0.043,0.051,0.127,0.093,0.251,0.129
		c0.121,0.034,0.427,0.063,0.916,0.082l0.047,0.042v0.356l-0.047,0.055c-1.168-0.037-1.82-0.055-1.958-0.055
		c-0.146,0-0.795,0.018-1.949,0.055l-0.055-0.055v-0.356l0.055-0.042c0.429-0.02,0.713-0.045,0.852-0.072
		c0.138-0.028,0.233-0.063,0.282-0.108c0.047-0.047,0.085-0.142,0.119-0.289c0.031-0.147,0.047-0.476,0.047-0.981l0.014-1.5v-4.146
		c0-0.073-0.018-0.693-0.055-1.854h-1.021c-0.419,0-0.781,0.009-1.087,0.028s-0.482,0.068-0.53,0.146
		c-0.049,0.079-0.092,0.333-0.134,0.765l-0.069,0.654l-0.061,0.063h-0.314l-0.055-0.058c-0.011-1.142-0.029-1.886-0.062-2.232
		l0.062-0.063c0.793,0.038,2.113,0.057,3.961,0.057C191.647,105.149,192.971,105.131,193.733,105.093z M188.17,118.099l-0.026-0.079
		l0.157-0.349l0.068-0.035c0.264,0.1,0.536,0.148,0.813,0.148c0.483,0,0.726-0.191,0.726-0.576c0-0.346-0.224-0.519-0.67-0.519
		c-0.155,0-0.303,0.026-0.446,0.078l-0.095-0.071l0.164-0.348c0.305-0.124,0.645-0.187,1.02-0.187c0.306,0,0.561,0.091,0.769,0.268
		c0.209,0.179,0.313,0.408,0.313,0.694c0,0.365-0.152,0.652-0.454,0.86c-0.304,0.209-0.686,0.312-1.147,0.312
		C188.941,118.296,188.544,118.23,188.17,118.099z"
					/>
					<path
						fill="#111111"
						d="M195.867,105.093l0.855,0.028c0.56,0.019,0.928,0.028,1.102,0.028c0.155,0,0.808-0.019,1.964-0.057
		l0.054,0.05v0.356l-0.054,0.049c-0.315,0.011-0.559,0.023-0.73,0.042c-0.169,0.021-0.294,0.055-0.371,0.107
		c-0.078,0.053-0.13,0.175-0.154,0.366c-0.025,0.192-0.04,0.458-0.04,0.802l-0.011,1.634v3.292l0.005,1.387
		c0.006,0.535,0.019,0.882,0.043,1.037c0.022,0.156,0.055,0.262,0.097,0.313c0.045,0.053,0.128,0.097,0.251,0.133
		c0.125,0.034,0.428,0.063,0.911,0.082l0.054,0.042v0.356l-0.054,0.055c-0.022,0-0.425-0.013-1.205-0.041
		c-0.323-0.011-0.577-0.014-0.759-0.014c-0.142,0-0.795,0.018-1.957,0.055l-0.057-0.055v-0.356l0.057-0.042
		c0.424-0.02,0.706-0.045,0.848-0.072c0.142-0.028,0.235-0.063,0.285-0.108c0.047-0.047,0.086-0.142,0.115-0.289
		c0.03-0.147,0.046-0.476,0.051-0.981l0.007-1.5v-3.292l-0.013-1.387c-0.006-0.541-0.017-0.889-0.039-1.045
		c-0.02-0.157-0.052-0.261-0.094-0.313c-0.044-0.051-0.127-0.094-0.25-0.128c-0.125-0.032-0.427-0.059-0.91-0.078l-0.057-0.049
		v-0.356L195.867,105.093z"
					/>
					<path
						fill="#111111"
						d="M202.085,105.143l0.057-0.05c1.563,0.028,2.564,0.042,3.003,0.042c1.497,0,2.828-0.014,3.995-0.042
		l0.035,0.05c-0.116,0.454-0.199,1.168-0.254,2.14l-0.041,0.058h-0.334l-0.063-0.058c0.004-0.042,0.007-0.079,0.007-0.112
		c0-0.403-0.03-0.786-0.088-1.152c-0.288-0.131-0.688-0.22-1.194-0.26c-0.509-0.041-0.892-0.061-1.147-0.061
		c-0.36,0-0.82,0.026-1.381,0.078l-0.028,1.139l-0.021,1.3l-0.02,1.443c0.49,0.029,0.945,0.045,1.361,0.045
		c0.419,0,0.771-0.016,1.054-0.045c0.283-0.028,0.447-0.057,0.496-0.088c0.047-0.031,0.083-0.091,0.11-0.178
		c0.024-0.089,0.046-0.243,0.064-0.466l0.026-0.442l0.055-0.056h0.315l0.054,0.056c-0.009,0.517-0.019,1.038-0.033,1.559
		l0.033,1.599l-0.054,0.059h-0.315l-0.055-0.059l-0.048-0.425c-0.04-0.332-0.071-0.531-0.089-0.594
		c-0.018-0.065-0.061-0.121-0.127-0.168c-0.065-0.047-0.235-0.086-0.508-0.113c-0.274-0.029-0.652-0.044-1.131-0.044
		c-0.265,0-0.668,0.012-1.21,0.035c-0.023,0.4-0.034,1.063-0.034,1.992c0,0.996,0.011,1.728,0.034,2.196h1.259
		c1.107,0,1.802-0.014,2.08-0.043c0.278-0.028,0.542-0.096,0.793-0.205c0.041-0.109,0.109-0.362,0.203-0.758
		c0.092-0.396,0.147-0.643,0.159-0.743l0.069-0.05h0.328l0.055,0.05c-0.04,0.203-0.081,0.561-0.119,1.066
		c-0.041,0.509-0.059,0.941-0.059,1.303l-0.047,0.055c-0.873-0.028-2.012-0.041-3.423-0.041l-1.859-0.014
		c-0.436,0-0.887,0.018-1.356,0.055l-0.054-0.055v-0.235l0.054-0.071c0.27-0.143,0.424-0.232,0.461-0.267
		c0.04-0.036,0.072-0.45,0.097-1.244c0.025-0.794,0.038-1.399,0.038-1.817v-2.609l-0.008-1.614c0-0.351-0.004-0.636-0.013-0.857
		c-0.009-0.219-0.025-0.377-0.048-0.472c-0.022-0.094-0.051-0.161-0.078-0.199c-0.03-0.037-0.078-0.07-0.144-0.097
		c-0.067-0.025-0.181-0.047-0.339-0.067l-0.52-0.042l-0.057-0.049V105.143z"
					/>
					<path
						fill="#111111"
						d="M211.451,117.188c0.283-0.489,0.486-0.855,0.61-1.102c0.122-0.247,0.23-0.507,0.323-0.78
		c0.094-0.272,0.156-0.481,0.186-0.628c0.029-0.147,0.072-0.45,0.127-0.91c0.022-0.01,0.149-0.042,0.383-0.101
		c0.32-0.079,0.657-0.188,1.014-0.327l0.171,0.2c-0.581,1.397-1.267,2.581-2.061,3.548l-0.582,0.276L211.451,117.188z"
					/>
					<path
						fill="#111111"
						d="M221.762,105.093l0.855,0.028c0.561,0.019,0.927,0.028,1.102,0.028c0.154,0,0.81-0.019,1.963-0.057
		l0.056,0.05v0.356l-0.056,0.049c-0.314,0.011-0.557,0.023-0.729,0.042c-0.17,0.021-0.294,0.055-0.373,0.107
		c-0.077,0.053-0.128,0.175-0.153,0.366c-0.024,0.192-0.038,0.458-0.038,0.802l-0.014,1.634v3.292l0.007,1.387
		c0.005,0.535,0.017,0.882,0.042,1.037c0.021,0.156,0.056,0.262,0.099,0.313c0.044,0.053,0.127,0.097,0.25,0.133
		c0.123,0.034,0.427,0.063,0.909,0.082l0.056,0.042v0.356l-0.056,0.055c-0.021,0-0.423-0.013-1.205-0.041
		c-0.324-0.011-0.575-0.014-0.758-0.014c-0.143,0-0.794,0.018-1.957,0.055l-0.055-0.055v-0.356l0.055-0.042
		c0.423-0.02,0.706-0.045,0.848-0.072c0.142-0.028,0.237-0.063,0.284-0.108c0.048-0.047,0.088-0.142,0.116-0.289
		c0.029-0.147,0.047-0.476,0.052-0.981l0.007-1.5v-3.292l-0.014-1.387c-0.005-0.541-0.018-0.889-0.037-1.045
		c-0.02-0.157-0.054-0.261-0.096-0.313c-0.045-0.051-0.128-0.094-0.25-0.128c-0.123-0.032-0.427-0.059-0.91-0.078l-0.055-0.049
		v-0.356L221.762,105.093z"
					/>
					<path
						fill="#111111"
						d="M235.738,105.093l0.063,0.063c-0.04,0.428-0.063,1.173-0.063,2.232l-0.053,0.058h-0.322l-0.055-0.063
		l-0.076-0.654c-0.035-0.351-0.065-0.564-0.085-0.645c-0.02-0.076-0.055-0.135-0.1-0.169c-0.044-0.037-0.192-0.064-0.444-0.089
		c-0.25-0.024-0.531-0.036-0.842-0.036h-1.293c-0.036,1.071-0.055,1.69-0.055,1.854v4.146l0.014,1.387
		c0.005,0.535,0.018,0.883,0.039,1.041c0.019,0.16,0.052,0.263,0.094,0.313c0.044,0.051,0.128,0.093,0.25,0.129
		c0.124,0.034,0.43,0.063,0.917,0.082l0.049,0.042v0.356l-0.049,0.055c-1.168-0.037-1.819-0.055-1.957-0.055
		c-0.146,0-0.796,0.018-1.949,0.055l-0.056-0.055v-0.356l0.056-0.042c0.427-0.02,0.713-0.045,0.852-0.072
		c0.139-0.028,0.232-0.063,0.279-0.108c0.05-0.047,0.089-0.142,0.121-0.289c0.031-0.147,0.048-0.476,0.048-0.981l0.014-1.5v-4.146
		c0-0.073-0.018-0.693-0.056-1.854h-1.019c-0.421,0-0.783,0.009-1.088,0.028c-0.306,0.02-0.483,0.068-0.531,0.146
		c-0.047,0.079-0.092,0.333-0.133,0.765l-0.067,0.654l-0.063,0.063h-0.315l-0.055-0.058c-0.009-1.142-0.029-1.886-0.062-2.232
		l0.062-0.063c0.795,0.038,2.115,0.057,3.962,0.057C233.655,105.149,234.978,105.131,235.738,105.093z"
					/>
					<path
						fill="#111111"
						d="M252.857,114.429c-0.369,0.269-0.752,0.575-1.15,0.918c-0.146,0.032-0.306,0.05-0.479,0.05
		c-0.407,0-0.813-0.089-1.224-0.265c-0.412-0.174-0.848-0.46-1.308-0.853c-0.603,0.465-1.108,0.768-1.519,0.911
		c-0.412,0.142-0.85,0.212-1.314,0.212c-0.926,0-1.635-0.287-2.126-0.865c-0.489-0.574-0.733-1.234-0.733-1.979
		c0-1.27,0.715-2.307,2.147-3.108c-0.273-0.681-0.41-1.3-0.41-1.854c0-0.228,0.029-0.445,0.092-0.65
		c0.062-0.206,0.131-0.366,0.21-0.48c0.076-0.112,0.211-0.257,0.402-0.431c0.192-0.172,0.377-0.337,0.556-0.489
		c0.177-0.154,0.348-0.265,0.509-0.328c0.161-0.064,0.337-0.096,0.523-0.096c0.42,0,0.736,0.124,0.946,0.375
		c0.215,0.247,0.319,0.569,0.319,0.961c0,0.992-0.664,1.956-1.99,2.895c0.286,0.603,0.72,1.28,1.299,2.036
		c0.58,0.75,1.091,1.308,1.533,1.669c0.466-0.531,0.698-1.112,0.698-1.743c0-0.25-0.028-0.464-0.087-0.641
		c-0.056-0.172-0.131-0.291-0.221-0.353c-0.091-0.058-0.254-0.086-0.487-0.086c-0.245-0.005-0.49-0.01-0.73-0.015l-0.062-0.094
		l0.444-0.676c0.27,0.043,0.659,0.065,1.17,0.065c0.27,0,0.677-0.012,1.224-0.036c0.559-0.019,1.082-0.029,1.574-0.029l0.063,0.065
		c-0.069,0.28-0.113,0.567-0.137,0.861l-0.069,0.056c-0.524-0.09-1.09-0.136-1.695-0.136c-0.023,0-0.093,0.003-0.207,0.007
		c0.055,0.199,0.083,0.411,0.083,0.635c0,0.834-0.382,1.651-1.145,2.451c0.836,0.622,1.658,0.932,2.465,0.932
		c0.118,0,0.222-0.015,0.309-0.046c0.085-0.03,0.226-0.101,0.417-0.21l0.083,0.043l0.047,0.222L252.857,114.429z M248.253,113.917
		c-0.848-0.934-1.458-1.665-1.824-2.192c-0.368-0.529-0.718-1.103-1.05-1.719c-0.452,0.331-0.761,0.667-0.924,1.01
		c-0.163,0.341-0.246,0.693-0.246,1.053c0,0.65,0.227,1.229,0.682,1.741c0.451,0.512,1,0.769,1.644,0.769
		C247.123,114.578,247.696,114.355,248.253,113.917z M246.083,108.867c0.375-0.273,0.682-0.596,0.923-0.967
		c0.242-0.369,0.363-0.722,0.363-1.059c0-0.231-0.069-0.445-0.208-0.643c-0.141-0.197-0.32-0.296-0.544-0.296
		c-0.233,0-0.439,0.102-0.62,0.303s-0.269,0.505-0.269,0.906C245.729,107.571,245.847,108.156,246.083,108.867z"
					/>
					<path
						fill="#111111"
						d="M259.986,105.093l0.855,0.028c0.56,0.019,0.928,0.028,1.101,0.028c0.154,0,0.81-0.019,1.964-0.057
		l0.054,0.05v0.356l-0.054,0.049c-0.313,0.011-0.558,0.023-0.729,0.042c-0.17,0.021-0.295,0.055-0.373,0.107
		c-0.076,0.053-0.128,0.175-0.153,0.366c-0.024,0.192-0.039,0.458-0.039,0.802l-0.012,1.634v3.292l0.006,1.387
		c0.005,0.535,0.019,0.882,0.042,1.037c0.022,0.156,0.055,0.262,0.099,0.313c0.043,0.053,0.128,0.097,0.25,0.133
		c0.125,0.034,0.427,0.063,0.91,0.082l0.054,0.042v0.356l-0.054,0.055c-0.022,0-0.425-0.013-1.205-0.041
		c-0.322-0.011-0.577-0.014-0.759-0.014c-0.142,0-0.794,0.018-1.956,0.055l-0.057-0.055v-0.356l0.057-0.042
		c0.424-0.02,0.705-0.045,0.848-0.072c0.142-0.028,0.235-0.063,0.284-0.108c0.047-0.047,0.087-0.142,0.116-0.289
		s0.046-0.476,0.051-0.981l0.007-1.5v-3.292l-0.013-1.387c-0.007-0.541-0.017-0.889-0.039-1.045
		c-0.021-0.157-0.051-0.261-0.096-0.313c-0.042-0.051-0.126-0.094-0.249-0.128c-0.124-0.032-0.426-0.059-0.909-0.078l-0.057-0.049
		v-0.356L259.986,105.093z"
					/>
					<path
						fill="#111111"
						d="M266.155,115.19l-0.062-0.065V114.8l0.062-0.058c0.278-0.004,0.501-0.021,0.67-0.051
		c0.165-0.028,0.271-0.075,0.319-0.143c0.048-0.065,0.08-0.172,0.095-0.319c0.017-0.146,0.033-0.478,0.047-0.996
		c0.019-0.515,0.026-0.887,0.026-1.109v-5.745c0-0.242-0.043-0.42-0.126-0.536c-0.085-0.115-0.2-0.189-0.346-0.222
		c-0.147-0.029-0.396-0.048-0.747-0.051l-0.055-0.058v-0.335l0.055-0.064c0.631,0.024,1.043,0.036,1.239,0.036
		c0.461,0,0.839-0.012,1.136-0.036l2.184,2.944l3.208,4.181c0.389,0.508,0.783,0.99,1.184,1.442v-5.502
		c0-0.171-0.008-0.535-0.023-1.093c-0.016-0.555-0.036-0.908-0.058-1.058c-0.023-0.15-0.064-0.251-0.121-0.302
		c-0.057-0.052-0.162-0.094-0.319-0.118c-0.158-0.026-0.372-0.041-0.642-0.047l-0.054-0.057v-0.327l0.054-0.064
		c0.371,0.024,0.896,0.036,1.581,0.036c0.626,0,1.079-0.012,1.361-0.036l0.062,0.064v0.327l-0.055,0.057
		c-0.301,0.006-0.534,0.022-0.697,0.055c-0.165,0.03-0.269,0.081-0.31,0.152c-0.04,0.07-0.069,0.175-0.084,0.312
		c-0.016,0.14-0.032,0.466-0.049,0.982c-0.016,0.518-0.023,0.888-0.023,1.116v3.378l0.008,1.954c0.009,0.64,0.018,1.282,0.026,1.928
		c-0.228-0.081-0.623-0.181-1.183-0.298l-0.486-0.599l-1.458-1.848l-4.639-6.021v5.451c0,0.22,0.007,0.607,0.023,1.167
		c0.016,0.559,0.035,0.897,0.058,1.016c0.023,0.12,0.053,0.202,0.089,0.246c0.038,0.046,0.114,0.087,0.236,0.125
		c0.12,0.039,0.37,0.061,0.75,0.064l0.054,0.058v0.332l-0.054,0.059c-0.238-0.035-0.621-0.05-1.15-0.05
		C267.262,115.141,266.666,115.155,266.155,115.19z"
					/>
					<path
						fill="#111111"
						d="M279.08,110.24c0-0.71,0.099-1.374,0.295-1.991c0.196-0.616,0.529-1.186,1.003-1.709
		c0.472-0.523,1.039-0.93,1.707-1.216c0.665-0.286,1.419-0.431,2.259-0.431c1.555,0,2.793,0.439,3.715,1.319
		c0.922,0.879,1.381,2.078,1.381,3.601c0,1.071-0.226,2.033-0.676,2.888c-0.453,0.854-1.098,1.518-1.941,1.99
		c-0.84,0.474-1.785,0.711-2.835,0.711c-0.744,0-1.428-0.128-2.054-0.388c-0.626-0.258-1.156-0.644-1.598-1.156
		c-0.44-0.512-0.76-1.103-0.957-1.776C279.179,111.41,279.08,110.795,279.08,110.24z M280.504,109.771
		c0,0.578,0.09,1.202,0.271,1.867c0.181,0.667,0.449,1.238,0.807,1.713c0.359,0.476,0.771,0.832,1.238,1.064
		c0.469,0.231,1,0.348,1.599,0.348c0.706,0,1.337-0.167,1.888-0.505c0.554-0.336,0.971-0.858,1.259-1.567s0.432-1.505,0.432-2.387
		c0-0.955-0.164-1.822-0.492-2.594c-0.329-0.773-0.796-1.335-1.403-1.686c-0.607-0.35-1.279-0.525-2.019-0.525
		c-1.108,0-1.981,0.36-2.62,1.08C280.822,107.3,280.504,108.364,280.504,109.771z"
					/>
					<path
						fill="#111111"
						d="M295.879,115.268c-0.07-0.208-0.354-1.004-0.856-2.389l-2.326-5.979c-0.265-0.684-0.432-1.071-0.501-1.164
		c-0.067-0.092-0.342-0.153-0.818-0.188l-0.063-0.064v-0.327l0.063-0.063c0.696,0.038,1.289,0.057,1.778,0.057
		c0.456,0,1.06-0.019,1.813-0.057l0.054,0.057v0.392l-0.054,0.056c-0.553,0-0.883,0.02-0.99,0.054
		c-0.106,0.035-0.161,0.104-0.161,0.204c0,0.056,0.073,0.31,0.221,0.756c0.146,0.449,0.275,0.827,0.39,1.134l2.142,5.625
		l1.786-4.663c0.123-0.318,0.304-0.831,0.54-1.536c0.236-0.708,0.356-1.132,0.356-1.274c0-0.118-0.056-0.192-0.169-0.227
		c-0.11-0.034-0.444-0.073-1.002-0.121l-0.061-0.057v-0.342l0.061-0.057c0.799,0.038,1.307,0.057,1.526,0.057
		c0.179,0,0.639-0.019,1.383-0.057l0.054,0.057v0.342l-0.054,0.057c-0.329,0.014-0.527,0.039-0.595,0.078
		c-0.068,0.039-0.13,0.112-0.184,0.22c-0.052,0.109-0.215,0.483-0.489,1.124l-2.134,5.46c-0.479,1.22-0.819,2.166-1.02,2.838
		H295.879z"
					/>
					<path
						fill="#111111"
						d="M301.658,115.195l-0.062-0.055v-0.37l0.062-0.058c0.333-0.005,0.548-0.021,0.644-0.053
		c0.097-0.031,0.182-0.093,0.258-0.185c0.074-0.093,0.226-0.395,0.454-0.907l0.719-1.622l1.86-4.379
		c0.353-0.856,0.699-1.716,1.049-2.573h0.443l2.922,7.138l0.39,0.953c0.115,0.27,0.246,0.564,0.395,0.881
		c0.147,0.318,0.251,0.506,0.307,0.562c0.058,0.058,0.128,0.102,0.212,0.133c0.086,0.031,0.273,0.048,0.565,0.053l0.055,0.058v0.37
		l-0.055,0.055c-0.484-0.041-0.92-0.063-1.307-0.063c-0.759,0.023-1.515,0.047-2.271,0.063l-0.057-0.055v-0.37l0.057-0.058
		c0.51-0.009,0.83-0.032,0.957-0.067c0.129-0.035,0.191-0.118,0.191-0.245c0-0.104-0.031-0.242-0.095-0.412l-0.841-2.168h-3.965
		l-0.745,1.819c-0.132,0.326-0.196,0.566-0.196,0.716c0,0.121,0.061,0.209,0.184,0.266c0.122,0.057,0.434,0.087,0.931,0.092
		l0.062,0.058v0.37l-0.057,0.055c-0.587-0.037-1.103-0.055-1.545-0.055C302.676,115.141,302.17,115.158,301.658,115.195z
		 M304.779,111.207h3.461l-1.717-4.287L304.779,111.207z"
					/>
					<path
						fill="#111111"
						d="M321.918,105.093l0.062,0.063c-0.04,0.428-0.062,1.173-0.062,2.232l-0.055,0.058h-0.321l-0.055-0.063
		l-0.075-0.654c-0.037-0.351-0.064-0.564-0.086-0.645c-0.02-0.076-0.053-0.135-0.099-0.169c-0.046-0.037-0.195-0.064-0.444-0.089
		c-0.252-0.024-0.531-0.036-0.843-0.036h-1.292c-0.037,1.071-0.056,1.69-0.056,1.854v4.146l0.015,1.387
		c0.004,0.535,0.018,0.883,0.036,1.041c0.021,0.16,0.054,0.263,0.097,0.313c0.043,0.051,0.126,0.093,0.251,0.129
		c0.122,0.034,0.428,0.063,0.916,0.082l0.047,0.042v0.356l-0.047,0.055c-1.169-0.037-1.82-0.055-1.957-0.055
		c-0.146,0-0.795,0.018-1.951,0.055l-0.054-0.055v-0.356l0.054-0.042c0.431-0.02,0.713-0.045,0.853-0.072
		c0.139-0.028,0.233-0.063,0.28-0.108c0.049-0.047,0.088-0.142,0.12-0.289s0.049-0.476,0.049-0.981l0.014-1.5v-4.146
		c0-0.073-0.02-0.693-0.056-1.854h-1.02c-0.421,0-0.782,0.009-1.089,0.028c-0.305,0.02-0.481,0.068-0.529,0.146
		c-0.049,0.079-0.093,0.333-0.136,0.765l-0.067,0.654l-0.061,0.063h-0.314l-0.056-0.058c-0.009-1.142-0.028-1.886-0.06-2.232
		l0.06-0.063c0.794,0.038,2.114,0.057,3.963,0.057C319.834,105.149,321.157,105.131,321.918,105.093z M316.355,118.099l-0.028-0.079
		l0.158-0.349l0.067-0.035c0.265,0.1,0.538,0.148,0.815,0.148c0.483,0,0.724-0.191,0.724-0.576c0-0.346-0.221-0.519-0.669-0.519
		c-0.155,0-0.303,0.026-0.445,0.078l-0.095-0.071l0.164-0.348c0.305-0.124,0.645-0.187,1.02-0.187c0.306,0,0.562,0.091,0.77,0.268
		c0.207,0.179,0.312,0.408,0.312,0.694c0,0.365-0.151,0.652-0.456,0.86c-0.303,0.209-0.683,0.312-1.145,0.312
		C317.126,118.296,316.729,118.23,316.355,118.099z"
					/>
					<path
						fill="#111111"
						d="M324.052,105.093l0.854,0.028c0.562,0.019,0.93,0.028,1.104,0.028c0.153,0,0.808-0.019,1.961-0.057
		l0.056,0.05v0.356l-0.056,0.049c-0.313,0.011-0.556,0.023-0.728,0.042c-0.17,0.021-0.296,0.055-0.373,0.107
		c-0.078,0.053-0.129,0.175-0.154,0.366c-0.025,0.192-0.038,0.458-0.038,0.802l-0.014,1.634v3.292l0.008,1.387
		c0.005,0.535,0.018,0.882,0.04,1.037c0.024,0.156,0.058,0.262,0.099,0.313c0.046,0.053,0.127,0.097,0.251,0.133
		c0.124,0.034,0.429,0.063,0.909,0.082l0.056,0.042v0.356l-0.056,0.055c-0.021,0-0.423-0.013-1.204-0.041
		c-0.322-0.011-0.576-0.014-0.757-0.014c-0.145,0-0.796,0.018-1.959,0.055l-0.055-0.055v-0.356l0.055-0.042
		c0.425-0.02,0.707-0.045,0.849-0.072c0.141-0.028,0.236-0.063,0.285-0.108c0.048-0.047,0.085-0.142,0.116-0.289
		c0.028-0.147,0.046-0.476,0.051-0.981l0.006-1.5v-3.292l-0.014-1.387c-0.004-0.541-0.017-0.889-0.037-1.045
		c-0.02-0.157-0.053-0.261-0.096-0.313c-0.043-0.051-0.127-0.094-0.248-0.128c-0.125-0.032-0.43-0.059-0.912-0.078l-0.055-0.049
		v-0.356L324.052,105.093z"
					/>
					<path
						fill="#111111"
						d="M330.271,105.143l0.054-0.05c1.565,0.028,2.566,0.042,3.004,0.042c1.496,0,2.829-0.014,3.996-0.042
		l0.034,0.05c-0.115,0.454-0.197,1.168-0.252,2.14l-0.041,0.058h-0.336l-0.061-0.058c0.004-0.042,0.007-0.079,0.007-0.112
		c0-0.403-0.031-0.786-0.09-1.152c-0.288-0.131-0.686-0.22-1.194-0.26c-0.508-0.041-0.891-0.061-1.147-0.061
		c-0.359,0-0.82,0.026-1.38,0.078l-0.028,1.139l-0.021,1.3l-0.021,1.443c0.492,0.029,0.947,0.045,1.363,0.045
		c0.419,0,0.771-0.016,1.053-0.045c0.282-0.028,0.447-0.057,0.497-0.088c0.047-0.031,0.082-0.091,0.108-0.178
		c0.024-0.089,0.047-0.243,0.065-0.466l0.026-0.442l0.056-0.056h0.313l0.056,0.056c-0.009,0.517-0.021,1.038-0.035,1.559
		l0.035,1.599l-0.056,0.059h-0.313l-0.056-0.059l-0.048-0.425c-0.041-0.332-0.071-0.531-0.088-0.594
		c-0.019-0.065-0.062-0.121-0.128-0.168c-0.067-0.047-0.236-0.086-0.508-0.113c-0.274-0.029-0.652-0.044-1.13-0.044
		c-0.265,0-0.669,0.012-1.212,0.035c-0.022,0.4-0.034,1.063-0.034,1.992c0,0.996,0.012,1.728,0.034,2.196h1.259
		c1.108,0,1.802-0.014,2.081-0.043c0.277-0.028,0.542-0.096,0.793-0.205c0.042-0.109,0.107-0.362,0.201-0.758
		c0.095-0.396,0.148-0.643,0.162-0.743l0.067-0.05h0.329l0.056,0.05c-0.042,0.203-0.083,0.561-0.121,1.066
		c-0.039,0.509-0.058,0.941-0.058,1.303l-0.048,0.055c-0.872-0.028-2.013-0.041-3.421-0.041l-1.861-0.014
		c-0.434,0-0.886,0.018-1.356,0.055l-0.054-0.055v-0.235l0.054-0.071c0.27-0.143,0.425-0.232,0.463-0.267
		c0.038-0.036,0.071-0.45,0.097-1.244c0.024-0.794,0.037-1.399,0.037-1.817v-2.609l-0.007-1.614c0-0.351-0.005-0.636-0.015-0.857
		c-0.008-0.219-0.024-0.377-0.048-0.472c-0.022-0.094-0.049-0.161-0.078-0.199c-0.029-0.037-0.077-0.07-0.145-0.097
		c-0.065-0.025-0.18-0.047-0.337-0.067l-0.521-0.042l-0.054-0.049V105.143z"
					/>
				</g>
			</svg>
		</>
	);
}

export default LogoNewEdu;