import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { Button } from "@/components/ui/button";
import { useState } from "react";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { api_test, api_create_thread, api_delete_thread, api_get_threads, api_generate_codes, api_get_codes, api_get_profesors, api_update_profesor, api_delete_profesor, api_get_subjects, api_get_users, api_get_user, api_get_user_activity } from "../lib/chat";
import { v4 as uuid } from "uuid";

const formSchema = z.object({
});

export default function ApiTest() {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
    },
  });


  const [threadId, setThreadId] = useState(null);
  const [allThreads, setAllThreads] = useState([]);
  const [allCodes, setAllCodes] = useState([]);
  const [nrCodes, setNrCodes] = useState(3);
  const [profesors, setProfesors] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [users, setUsers] = useState();
  const [user, setUser] = useState();
  const [messages, setMessages] = useState();


  // 2. Define a submit handler.
  function onSubmit(values: z.infer<typeof formSchema>) {
    // Do something with the form values.
    // ✅ This will be type-safe and validated.
    console.log("boop");
    // TO DO: get current bot id from global state
    const current_bot_id = "559b3eb2-1711-4fe9-99bc-110829366971";
    api_create_thread(current_bot_id, uuid(), (data, error) => {
      if (data) {
        console.log(data);
        setThreadId(data.id);
      } else {
        console.log(error);
      }
    });
  }


  function deleteThread() {
    if (threadId) {
      api_delete_thread(threadId, (data, error) => {
        if (data) {
          console.log(data);
        } else {
          console.log(error);
        }
      });
      setThreadId(null);
    }
  }

  function getAllThreads() {
    console.log("get all threads");
    api_get_threads(1, (data, error) => {
      if (data) {
        console.log(data);
        setAllThreads(data);
      } else {
        console.log(error);
      }
    });
  }

  function genCodes() {
    console.log("gen codes");
    api_generate_codes(1, (data, error) => {
      if (data) {
        console.log(data);
        getCodes();
      } else {
        console.log(error);
      }
    });
  }

  function getCodes() {
    console.log("get codes");
    api_get_codes("all", 1, (data, error) => {
      if (data) {
        console.log(data);
        setAllCodes(data);
      } else {
        console.log(error);
      }
    });
  }

  return (
    <div className="bg-gray-300 min-h-screen flex justify-start items-center">
      <div className="max-w-md container">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
            <Button className="w-full" type="submit">New Thread</Button>
          </form>
        </Form>
        <div>
          Thread id: {threadId}
        </div>
        <div>
          <Button className="w-full" onClick={deleteThread}>Delete Thread</Button>
        </div>
        <div>
          <Button className="w-full" onClick={getAllThreads}>Get all threads</Button>
          <pre className="w-full">{JSON.stringify(allThreads, null, 2)}</pre>
        </div>

        <div className="flex">
          {/* input for nrCodes*/}
          <Input
            type="number"
            label="Nr codes"
            value={nrCodes}
            className="w-full"
            onChange={(e) => {
              setNrCodes(e.target.value);
            }}
          />
          <Button className="w-full" onClick={genCodes}>Gen codes</Button>
        </div>
        <div>
          <Button className="w-full" onClick={getCodes}>Get codes</Button>
          <pre className="w-full">{JSON.stringify(allCodes, null, 2)}</pre>
        </div>

        {/* test api_get_profesors */}
        <div>
          <Button className="w-full" onClick={() => {
            api_get_profesors((data, error) => {
              if (data) {
                console.log(data);
                setProfesors(data);
              } else {
                console.log(error);
              }
            });
          }}>Get profesors</Button>
          <pre className="w-full">{JSON.stringify(profesors, null, 2)}</pre>
        </div>

        {/* test apt_get_subjects */}
        <div>
          <Button className="w-full" onClick={() => {
            api_get_subjects((data, error) => {
              if (data) {
                console.log(data);
                setSubjects(data.items);
              } else {
                console.log(error);
              }
            });
          }}>Get subjects</Button>
          <pre className="w-full">{JSON.stringify(subjects, null, 2)}</pre>
        </div>

        {/* test api_get_users */}
        <div>
          <Button className="w-full" onClick={() => {
            api_get_users(1, 'all', (data, error) => {
              if (data) {
                console.log(data);
                setUsers(data.items);
              } else {
                console.log(error);
              }
            });
          }}>Get users</Button>
          <pre className="w-full">{JSON.stringify(users, null, 2)}</pre>
        </div>

        {/* test api_get_users */}
        <div>
          <Button className="w-full" onClick={() => {
            api_get_user("me", (data, error) => {
              if (data) {
                console.log(data);
                setUser(data);
              } else {
                console.log(error);
              }
            });
          }}>Get user</Button>
          <pre className="w-full">{JSON.stringify(user, null, 2)}</pre>
        </div>

        {/* test api_get_messages */}
        <div>
          <Button className="w-full" onClick={() => {
            api_get_user_activity("me" /*in app please send actual user id*/, 1, (data, error) => {
              if (data) {
                console.log(data);
                setMessages(data);
              } else {
                console.log(error);
              }
            });
          }}>Get messages</Button>
          <pre className="w-full">{JSON.stringify(messages, null, 2)}</pre>
        </div>

      </div>
    </div>
  );
}
