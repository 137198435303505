import { SettingsType } from "@/types/types";
import { Message, Thread } from "@/types/types";
import { ca } from "date-fns/locale";
import { Settings } from "lucide-react";
import { v4 as uuid } from "uuid";

interface ChatMessage {
  type: string;
  message: string;
}

interface Chat {
  bot_id: string;
  messages: ChatMessage[]; // Array of ChatMessage objects
}

export const chat: Chat = {
  bot_id: "559b3eb2-1711-4fe9-99bc-110829366971",
};

let API_ROOT = "";
if (
  window.location.origin === "http://localhost:5173" ||
  window.location.origin === "http://127.0.0.1:5173"
) {
  API_ROOT = "http://127.0.0.1:5050";
}

if (window.location.href.includes("trycloudflare.com")) {
  console.log("window.location.href.includes");
  API_ROOT = "https://shark-app-sim9g.ondigitalocean.app/";
}
console.log(API_ROOT);
export const bubbleThinkingMessages = [
  "Analizez întrebarea...",
  "Procesez informațiile...",
  "Examinând detaliile...",
  "Evaluând contextul...",
  "Gândind la răspuns...",
  "Căutând informații...",
  "Clarificând aspectele...",
  "Formulând răspunsul...",
  "Verificând datele...",
  "Preparând răspunsul...",
  "Analizând subtilități...",
  "Investigând răspunsuri...",
  "Consultând resurse...",
  "Elaborând explicația...",
  "Asamblând informații...",
  "Calibrând răspunsul...",
  "Sincronizând cunoștințele...",
  "Optimizând claritatea...",
  "Filtrând informațiile...",
  "Finalizând gândurile...",
];

export function getRandomBubbleThinkingMessage() {
  const i = Math.floor(Math.random() * bubbleThinkingMessages.length);
  return bubbleThinkingMessages[i];
}

export function rest_api(
  method,
  path,
  data,
  callback,
  headers = {},
  file = null
) {
  // Prepare the options for fetch
  const options = {
    method: method,
    headers: {
      "Content-Type": "application/json",
      ...headers,
    },
    credentials: "include",
  };

  // Add body if method is not GET or HEAD
  if (method !== "GET" && method !== "HEAD") {
    options.body = JSON.stringify(data);
  }

  // Add file if present
  if (file) {
    const formData = new FormData();
    formData.append("file", file);
    options.body = formData;
    delete options.headers["Content-Type"]; // Let the browser set it
  }

  // Make the fetch call
  fetch(path, options)
    .then((response) => {
      if (!response.ok) {
        throw new Error(`Request failed with status code ${response.status}`);
      }
      return response.json();
    })
    .then((data) => callback(data, null))
    .catch((error) => {
      console.log(error);
      callback(null, error);
    });
}

export function api_register(values, callback) {
  rest_api("POST", API_ROOT + "/register", values, (data, error) => {
    if (data) {
      callback(data);
    } else {
      console.log(error);
      callback(null);
    }
  });
}

export function api_login(email: string, password: string, callback) {
  rest_api(
    "POST",
    API_ROOT + "/login",
    {
      email: email,
      password: password,
    },
    (data, error) => {
      if (data) {
        callback(data);
      } else {
        console.log(error);
        callback(null);
      }
    }
  );
}

export function api_logout(callback) {
  rest_api("POST", API_ROOT + "/logout", {}, (data, error) => {
    if (data) {
      callback(data);
    } else {
      console.log(error);
      callback(null);
    }
  });
}

export function api_test(callback) {
  rest_api("POST", API_ROOT + "/test_login", {}, (data, error) => {
    if (data) {
      callback(data);
    } else {
      console.log(error);
      callback(null);
    }
  });
}

export function api_set_feedback_for_message(
  message_id: string,
  downvoted: boolean,
  callback: any
) {
  rest_api(
    "POST",
    API_ROOT + "/set_feedback_for_message",
    {
      message_id: message_id,
      downvoted: downvoted,
    },
    callback
  );
}

export function api_delete_account(callback) {
  rest_api("POST", API_ROOT + "/delete_account", {}, callback);
}

export function audio_to_text(
  base64_audio: string,
  callback: (text: string | null) => void
): void {
  rest_api(
    "POST",
    API_ROOT + "/audio_to_text",
    {
      audio: base64_audio,
      skip: skipAmount > 0 ? skipAmount : false,
    },
    (data, error) => {
      if (data) {
        callback(data.text);
      } else {
        console.log(error);
        callback(null);
      }
    }
  );
}
export function get_answer(
  thread: Thread,
  chatTTS: string,
  callback: (data: any) => void
): void {
  rest_api(
    "POST",
    API_ROOT + "/chat",
    {
      bot_id: thread.bot_id,
      messages: thread.messages.map((message) => {
        const messageCopy = { ...message };
        if (!message.images) {
          delete messageCopy.images;
        } else {
          messageCopy.images = message.images.map((image) => {
            if ("url" in image) {
              return image.url;
            } else {
              return image.blob;
            }
          });
        }
        return messageCopy;
      }),
      thread_id: thread.id,
      audio_answer: true,
      chatTTS: chatTTS,
    },
    (data, error) => {
      if (data) {
        callback(data);
      } else {
        console.log(error);
        let answer = {
          answer: null,
          audio: false,
          error: error,
        };
        callback(answer);
      }
    }
  );
}

export function api_get_chatbots(callback) {
  rest_api("POST", API_ROOT + "/chatbots", {}, (data: any, error: any) => {
    if (data) {
      callback(data);
    } else {
      console.log(error);
      callback(null);
    }
  });
}

export function api_use_code(code, callback) {
  rest_api("POST", API_ROOT + "/use_code", { code: code }, callback);
}

// Assuming API_ROOT is already defined in your chat.ts file

export function api_create_thread(
  bot_id,
  thread_id,
  callback: (data: any, error: any) => void
): void {
  rest_api(
    "POST",
    API_ROOT + "/create_thread",
    {
      bot_id: bot_id,
      id: thread_id,
    },
    callback
  );
}

export function api_delete_thread(
  threadId: string,
  callback: (data: any, error: any) => void
): void {
  rest_api("POST", API_ROOT + "/delete_thread", { id: threadId }, callback);
}

export function api_get_threads(page, callback) {
  rest_api("POST", API_ROOT + "/get_threads", { page: page }, callback);
}

export function api_get_messages_from_thread(
  threadId: string,
  callback: (data: any, error: any) => void
): void {
  rest_api(
    "POST",
    API_ROOT + "/get_messages_from_thread",
    { id: threadId },
    callback
  );
}

export function api_update_profile(
  values: object,
  callback: (data: any, error: any) => void
) {
  rest_api("POST", API_ROOT + "/update_profile", values, callback);
}

export function api_upload_user_avatar(avatar: string, callback: any) {
  rest_api(
    "POST",
    API_ROOT + "/upload_user_avatar",
    { avatar: avatar },
    callback
  );
}

export function api_update_thread(
  values: object,
  callback: (data: any, error: any) => void
) {
  rest_api("POST", API_ROOT + "/update_thread", values, callback);
}

export function api_speak_message(
  message: string,
  onStart: () => void,
  onEnd: () => void
) {
  rest_api(
    "POST",
    API_ROOT + "/speak_message",
    { message: message },
    (data, error) => {
      if (data) {
        playBase64Wav(data.audio, onStart, onEnd);
      }
    }
  );
}

export function api_generate_codes(nr_codes, valability_days, callback) {
  rest_api(
    "POST",
    API_ROOT + "/admin/generate_codes",
    {
      nr_codes: nr_codes,
      valability_days: valability_days,
    },
    callback
  );
}

export function api_get_codes(code_type, page, callback) {
  rest_api(
    "POST",
    API_ROOT + "/admin/get_codes",
    {
      type: code_type,
      page: page,
    },
    callback
  );
}

export function api_delete_code(code_id, callback) {
  rest_api("POST", API_ROOT + "/admin/delete_code", { id: code_id }, callback);
}

export function api_update_code(code_data, callback) {
  rest_api("POST", API_ROOT + "/admin/update_code", code_data, callback);
}

export function api_get_conversation_starters(callback) {
  rest_api("POST", API_ROOT + "/conversation-starters", {}, callback);
}

export function api_get_profesors(callback) {
  rest_api("POST", API_ROOT + "/admin/get_profesors", {}, callback);
}

export function api_update_profesor(profesor, callback) {
  console.log("api_update_profesor");
  rest_api("POST", API_ROOT + "/admin/update_profesor", profesor, callback);
}

export function api_delete_profesor(profesor_id, callback) {
  rest_api(
    "POST",
    API_ROOT + "/admin/delete_profesor",
    { id: profesor_id },
    callback
  );
}

export function api_create_profesor(bot_id, subject_id, class_id, callback) {
  rest_api(
    "POST",
    API_ROOT + "/admin/create_profesor",
    { bot_id: bot_id, subject: subject_id, class: class_id },
    callback
  );
}

export function api_get_subjects(callback) {
  rest_api("POST", API_ROOT + "/admin/get_subjects", {}, callback);
}

export function api_get_users(page, segment, callback) {
  rest_api(
    "POST",
    API_ROOT + "/admin/get_users",
    { page: page, segment: segment },
    callback
  );
}

export function api_get_user(user_id, callback) {
  rest_api(
    "POST",
    API_ROOT + "/admin/get_user",
    { user_id: user_id },
    callback
  );
}

export function api_get_user_activity(user_id, page, callback) {
  rest_api(
    "POST",
    API_ROOT + "/admin/get_user_activity",
    {
      user_id: user_id,
      page: page,
    },
    callback
  );
}

export function api_delete_user(user_id, callback) {
  rest_api(
    "POST",
    API_ROOT + "/admin/delete_user",
    {
      user_id: user_id,
    },
    (data, error) => {
      if (data) {
        callback(data);
      } else {
        console.log(error);
        callback(null);
      }
    }
  );
}

export function api_get_options(callback: any) {
  rest_api("POST", API_ROOT + "/admin/get_options", {}, callback);
}

export function api_set_option(key: string, value: any, callback: any) {
  rest_api(
    "POST",
    API_ROOT + "/admin/set_option",
    { key: key, value: value },
    callback
  );
}

export function api_search_users(search, callback) {
  rest_api(
    "POST",
    API_ROOT + "/admin/search_users",
    { search: search },
    callback
  );
}

export let mediaRecorder: MediaRecorder;
let myStream: MediaStream;
let audioChunks: BlobPart[] = [];
let animationFrameId: number;
let skipAmount = -1;

export function startRecording(
  setSettings: any,
  settings: any,
  callback = null
) {
  console.log("startRecording");
  audioChunks = [];
  skipAmount = -1;
  navigator.mediaDevices
    .getUserMedia({ audio: true })
    .then((stream) => {
      myStream = stream;
      setSettings({
        bubbleText: "Te ascult!",
      });

      console.log("start");

      const audioContext = new AudioContext();
      const source = audioContext.createMediaStreamSource(stream);
      const analyzer = audioContext.createAnalyser();
      let lastTimeNotQuiet = Date.now();
      let lastTimeQuiet = Date.now();
      let minSpeechMS = 200;
      let recordStarted = Date.now();
      let promptHasEnoughSpeech = false;
      source.connect(analyzer);
      analyzer.fftSize = 32; // Adjusted for 16 frequency bars (fftSize should be a power of 2)
      const bufferLength = analyzer.frequencyBinCount;
      let dataArray = new Uint8Array(bufferLength);
      if (mediaRecorder != null) {
        mediaRecorder.stream
          .getTracks()
          .forEach((track: MediaStreamTrack) => track.stop());
      }
      mediaRecorder = new MediaRecorder(stream);
      mediaRecorder.start(100);

      mediaRecorder.addEventListener("dataavailable", (event) => {
        audioChunks.push(event.data);
      });

      const MIN_HEIGHT = 30; // Minimum height of the bars
      const DECAY_RATE = 0.25; // Decay rate for smoother transition
      const SCALE_FACTOR = 0.2; // Scale factor to reduce sensitivity

      const barHeights = new Array(dataArray.length).fill(MIN_HEIGHT); // Initialize bar heights

      const updateBars = () => {
        analyzer.getByteFrequencyData(dataArray);
        dataArray = dataArray.sort();
        let idx = dataArray.length - 1;
        for (let i = 0; i < bufferLength; i += 2) {
          dataArray[i] ^= dataArray[idx];
          dataArray[idx] ^= dataArray[i];
          dataArray[i] ^= dataArray[idx];
          idx--;
        }

        for (let i = 0; i < bufferLength / 2; i++) {
          for (let j = i + 1; j < bufferLength / 2; j++) {
            if (dataArray[i] > dataArray[j]) {
              dataArray[i] ^= dataArray[j];
              dataArray[j] ^= dataArray[i];
              dataArray[i] ^= dataArray[j];
            }
          }
        }

        for (let i = bufferLength / 2; i < bufferLength; i++) {
          for (let j = i + 1; j < bufferLength; j++) {
            if (dataArray[i] < dataArray[j]) {
              dataArray[i] ^= dataArray[j];
              dataArray[j] ^= dataArray[i];
              dataArray[i] ^= dataArray[j];
            }
          }
        }

        let isQuiet = true;

        for (let i = 0; i < bufferLength; i++) {
          // Apply a scaling factor to reduce sensitivity, especially for lower frequencies
          let scaledHeight =
            dataArray[Math.min(i + 5, bufferLength - 1)] * SCALE_FACTOR;

          if (i == 3) {
            scaledHeight *= 1.3;
          }
          // Calculate new height with decay
          let newHeight = Math.max(scaledHeight, barHeights[i] * DECAY_RATE);

          // Apply minimum height threshold
          barHeights[i] = newHeight > MIN_HEIGHT ? newHeight : MIN_HEIGHT;

          // console.log("barHeights[i]: ", barHeights[i]);

          let bar = document.getElementById(`bar-recording-${i}`);
          if (bar) {
            bar.style.height = barHeights[i] - MIN_HEIGHT + 10 + "px";
            if (barHeights[i] > MIN_HEIGHT) {
              isQuiet = false;
            }
          }
        }

        if (!isQuiet) {
          lastTimeNotQuiet = Date.now();
          if (Date.now() - lastTimeQuiet > minSpeechMS) {
            promptHasEnoughSpeech = true;
          }
          if (!promptHasEnoughSpeech) {
            if (Date.now() - recordStarted > 2000) {
              skipAmount = Date.now() - recordStarted - 1500;
            }
          }
        } else {
          lastTimeQuiet = Date.now();
          if (Date.now() - lastTimeNotQuiet > 2000) {
            if (callback && promptHasEnoughSpeech) {
              callback();
              callback = null;
            }
          }
        }

        if (mediaRecorder) {
          requestAnimationFrame(updateBars);
        } else {
          console.log("mediaRecorder: ", mediaRecorder != null);
        }
      };

      updateBars();
    })
    .catch((err) => console.error("Error in startRecording:", err));
}

let source: any = null;
let analyser: any = null;

let audioContext; // Asigură-te că este accesibil global sau în cadrul unei clase/componente

function initAudioContext() {
  audioContext = new (window.AudioContext || window.webkitAudioContext)();
}

function unlockAudioContext() {
  if (!audioContext) {
    initAudioContext();
  }
  if (audioContext && audioContext.state === "suspended") {
    audioContext.resume().then(() => {
      console.log("Playback resumed successfully");
    });
  }
}

document.addEventListener("touchend", unlockAudioContext, false);
document.addEventListener("click", unlockAudioContext, false);

export function playBase64Wav(base64audio, onStart = null, onEnded = null) {
  if (!audioContext) {
    initAudioContext();
  }

  fetch(`data:audio/wav;base64,${base64audio}`)
    .then((response) => response.arrayBuffer())
    .then((arrayBuffer) => audioContext.decodeAudioData(arrayBuffer))
    .then((audioBuffer) => {
      let source = audioContext.createBufferSource();
      source.buffer = audioBuffer;
      source.connect(audioContext.destination);
      source.start(0);
      if (onStart) {
        onStart();
      }
      analyser = audioContext.createAnalyser();

      source.connect(analyser);
      analyser.fftSize = 32;
      const bufferLength = analyser.frequencyBinCount;
      let dataArray = new Uint8Array(bufferLength);

      let playbackFinished = false;
      source.onended = () => {
        playbackFinished = true;
        if (onEnded) {
          onEnded();
        }
      };

      const MIN_HEIGHT = 30; // Minimum height of the bars
      const DECAY_RATE = 0.25; // Decay rate for smoother transition
      const SCALE_FACTOR = 0.2; // Scale factor to reduce sensitivity

      const barHeights = new Array(dataArray.length).fill(MIN_HEIGHT); // Initialize bar heights

      const updateBars = () => {
        dataArray = dataArray.sort();

        let idx = dataArray.length - 1;
        for (let i = 0; i < bufferLength; i += 2) {
          dataArray[i] ^= dataArray[idx];
          dataArray[idx] ^= dataArray[i];
          dataArray[i] ^= dataArray[idx];
          idx--;
        }

        for (let i = 0; i < bufferLength / 2; i++) {
          for (let j = i + 1; j < bufferLength / 2; j++) {
            if (dataArray[i] > dataArray[j]) {
              dataArray[i] ^= dataArray[j];
              dataArray[j] ^= dataArray[i];
              dataArray[i] ^= dataArray[j];
            }
          }
        }

        for (let i = bufferLength / 2; i < bufferLength; i++) {
          for (let j = i + 1; j < bufferLength; j++) {
            if (dataArray[i] < dataArray[j]) {
              dataArray[i] ^= dataArray[j];
              dataArray[j] ^= dataArray[i];
              dataArray[i] ^= dataArray[j];
            }
          }
        }

        for (let i = 0; i < bufferLength; i++) {
          // Apply a scaling factor to reduce sensitivity, especially for lower frequencies
          let scaledHeight =
            dataArray[Math.min(i + 5, bufferLength - 1)] * SCALE_FACTOR;

          if (i == 3) {
            scaledHeight *= 1.3;
          }
          // Calculate new height with decay
          let newHeight = Math.max(scaledHeight, barHeights[i] * DECAY_RATE);

          // Apply minimum height threshold
          barHeights[i] = newHeight > MIN_HEIGHT ? newHeight : MIN_HEIGHT;

          let bar = document.getElementById(`bar-play-${i}`);
          if (bar) {
            bar.style.height = (barHeights[i] - MIN_HEIGHT) / 6 + 4 + "px";
          }
        }
      };

      const logSoundLevels = () => {
        if (playbackFinished) {
          return;
        }
        analyser.getByteFrequencyData(dataArray);

        updateBars();

        if (!playbackFinished) {
          requestAnimationFrame(logSoundLevels);
        }
      };
      logSoundLevels();
    });
}

export function stopRecording(
  setSettings,
  updateThread,
  thread,
  settings,
  setPrompt,
  currentProfesor,
  currentThreadIndex,
  threads,
  navigate
): Promise<string> {
  console.log("stopRecording");

  return new Promise((resolve) => {
    try {
      console.log("thread: ", thread);
      mediaRecorder.addEventListener("stop", () => {
        console.log("stopRecording2");
        let shouldDiscard = settings.discardNextAudioPrompt;
        setSettings({
          isLoading: !shouldDiscard,
          discardNextAudioPrompt: false,
        });
        console.log("stopRecording3");

        if (!shouldDiscard) {
          console.log("stopRecording4");
          const audioBlob = new Blob(audioChunks, { type: "audio/wav" });
          const reader = new FileReader();
          reader.readAsDataURL(audioBlob);
          reader.onloadend = () => {
            console.log("stopRecording5");
            let base64data = reader.result as string;
            //setAudioBase(base64data);

            base64data = base64data.split(",")[1];
            // playBase64Wav(base64data);
            audio_to_text(base64data, (text: string | null) => {
              function handleAudioInput(forThread: Thread) {
                gtag("event", "sent_audio_message", {
                  event_category: "Messaging",
                  event_label: "Sent Audio",
                  value: 1,
                });

                const newMessage: Message = {
                  type: "human",
                  message: text,
                  id: uuid(),
                };
                const currentThread = threads[currentThreadIndex];

                const newThread = {
                  ...forThread,
                  messages: [...forThread.messages, newMessage],
                };
                updateThread(currentThread.id, newThread);

                handleNewMessages(
                  newThread,
                  updateThread,
                  setSettings,
                  settings
                );
                setPrompt({ message: "", images: [], type: "human" });
              }
              if (thread.owner_id == "sentinel") {
                console.log("currentProfesor: ", currentProfesor);
                api_create_thread(
                  currentProfesor.id,
                  thread.id,
                  (data, error) => {
                    if (data) {
                      navigate(`/c/${data.id}`);
                      const newThread = { ...data, messages: [] };
                      updateThread(thread.id, newThread);
                      handleAudioInput(newThread);
                    } else {
                      console.log(error);
                    }
                  }
                );
              } else {
                handleAudioInput(thread);
              }
            });
            resolve(base64data);
          };
        } else {
          resolve("");
        }

        cancelAnimationFrame(animationFrameId);
        if (mediaRecorder != null) {
          mediaRecorder.stream
            .getTracks()
            .forEach((track: MediaStreamTrack) => track.stop());
        }
      });

      if (mediaRecorder != null) {
        mediaRecorder.stop();
        mediaRecorder.stream
          .getTracks()
          .forEach((track: MediaStreamTrack) => track.stop());
      }
      if (myStream && myStream.getTracks) {
        console.log("myStream.getTracks().forEach(track => track.stop());");
        myStream.getTracks().forEach((track) => track.stop());
        myStream = null;
      }
    } catch (error) {
      console.log("error: ", error);
    }

    mediaRecorder = null;
  });
}

let newTaskHintTimeout = null;

export function clearNewTaskHintTimeout() {
  if (newTaskHintTimeout) {
    clearTimeout(newTaskHintTimeout);
  }
}

function maybeHandleNewTaskMessage(message: Message, setSettings) {
  if (message.type == "tool" && message.message.includes("NEW_TASK")) {
    clearNewTaskHintTimeout();
    newTaskHintTimeout = setTimeout(
      () => {
        console.log("showNewTaskHint");
        setSettings({
          showNewTaskHint: true,
        });
      },
      3 * 60 * 1000
    );
  }
}

export function handleNewMessages(
  thread,
  updateThread,
  setSettings,
  settings,
  callback = null
) {
  setSettings({
    isLoading: true,
    bubbleText: getRandomBubbleThinkingMessage(),
  });
  if (thread.isError) {
    updateThread(thread.id, {
      isError: false,
    });
  }
  get_answer(thread, settings.chatTTS, (data: any) => {
    if (data.answer) {
      const functionCallMessages: Message[] = [];
      if (data.new_messages) {
        data.new_messages.forEach((msg: Message) => {
          const newMessage: Message = {
            ...msg,
            id: uuid(),
          };
          maybeHandleNewTaskMessage(newMessage, setSettings);
          functionCallMessages.push(newMessage);
        });
      }

      const newMessage: Message = {
        ...data.answer,
      };
      const lastHumanMessage = thread.messages[thread.messages.length - 1];
      if (lastHumanMessage.images) {
        lastHumanMessage.images = lastHumanMessage.images.map(
          (image, index) => {
            return {
              ...image,
              url: data.input_message.images[index],
            };
          }
        );
      }
      const newThread: Thread = {
        ...thread,
        messages: [
          ...thread.messages.slice(0, thread.messages.length - 1),
          lastHumanMessage,
          ...functionCallMessages,
          newMessage,
        ],
      };
      if (data.thread_name) {
        newThread.name = data.thread_name;
      }
      updateThread(thread.id, newThread);
      if (callback) {
        callback();
      }
      if (data.audio) {
        gtag("event", "received_audio_reply", {
          event_category: "Messaging",
          event_label: "Received Audio",
          value: 1,
        });
        playBase64Wav(
          data.audio,
          () => {
            setSettings({
              isLoading: false,
              isTalking: true,
              bubbleText: "",
            });
          },
          () => {
            setSettings({
              isTalking: false,
            });
            if (settings.isAudioMode) {
              setTimeout(() => {
                setSettings({
                  isRecording: settings.isAudioMode,
                });
              }, 1200);
            }
          }
        );
      } else {
        gtag("event", "received_text_reply", {
          event_category: "Messaging",
          event_label: "Received Text",
          value: 1,
        });

        setSettings({
          isLoading: false,
        });

        setTimeout(() => {
          console.log("2start...");
          if (settings.isAudioMode) {
            setSettings({
              isRecording: true,
            });
          }
        }, 2000);
      }
    } else {
      console.log("o crapat...");
      updateThread(thread.id, {
        isError: true,
        errorMessage: data.error,
      });
      setSettings({
        isLoading: false,
      });
    }
  });
}
