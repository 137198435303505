import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import LatexMarkdown from "./ui/LatexMarkdown";

export type MessageType = {
  type: string;
  message: string;
  images: string[];
};

function classForMessageType(message: MessageType) {
  if (message.type === "human") {
    return "border-blue-100 bg-indigo-200 ml-auto";
  } else if (message.type === "tool") {
    if (message.name == "math_draw") {
      return "border-x-gray-200 bg-white md:max-w-[100%]";
    }
    return "border-x-gray-200 bg-green-200";
  } else {
    if (message.message.trim() === "") {
      return "hidden";
    }
    return "border-x-gray-200 bg-white";
  }
}

function classForMessageComponent(message: MessageType) {
  if (message.type == "tool" && message.name == "math_draw") {
    return "hidden";
  }
  return "";
}

const ChatMessage = ({ message }: { message: MessageType }) => {
  const userType = message.type;
  const style = classForMessageType(message);
  const text = message.message;
  const images = message.images;
  const markdown = text;

  return (
    <div className="group w-full text-gray-800 ">
      <div className={`text-base gap-4 md:gap-6 flex w-full`}>
        <div
          className={`border rounded-3xl flex flex-row  max-w-[70%] gap-4 p-3 px-5 w-auto ${style}`}
        >
          <div className="relative flex flex-col gap-1 md:gap-3">
            <div className="flex flex-grow flex-col gap-3">
              <div className="min-h-20 flex flex-col items-start gap-4 whitespace-pre-wrap break-words">
                <div className="markdown prose w-full break-words dark:prose-invert dark">
                  {images?.length > 0 && (
                    <div className="flex flex-row flex-wrap gap-2 p-2">
                      {images.map((image, index) => (
                        <div
                          key={+"image_" + index}
                          className={`relative flex-shrink-0 h-auto rounded-md w-full`}
                        >
                          <Zoom>
                            <img
                              className="rounded-md w-full min-w-[100px]"
                              src={image}
                            />
                          </Zoom>
                        </div>
                      ))}
                    </div>
                  )}
                  {userType == "tool" && message.name == "math_draw" && (
                    <div dangerouslySetInnerHTML={{ __html: message.message }}></div>
                  )}
                  <div className={classForMessageComponent(message)}>
                    <LatexMarkdown content={markdown} />
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatMessage;
