import { Menu, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { ChevronDown } from "lucide-react";
import { useStore } from "@/store/store";
import { useNavigate } from "react-router-dom";
function ChoseProfessor() {
  const navigate = useNavigate();
  const {
    profesors,
    currentProfesor,
    setCurrentProfesor,
    currentThread,
    setCurrentThread,
    setThreads,
    threads,
    addEmptyThread,
    userProfile,
    setDialogInactiveAccount
  } = useStore();

  const [activeProfesors, setActiveProfesors] = useState([]);

  useEffect(() => {
    if (profesors && profesors.length > 0) {
      console.log(userProfile);
      setActiveProfesors(
        profesors.filter((profesor) => profesor.class.toString() == userProfile?.class.toString())
      );
    }
    console.log("activeProfesors", activeProfesors);
  }, [profesors, userProfile]);
  return (
    <Menu
      as="div"
      className={`relative w-full ${profesors && profesors.length > 0 ? "block" : "hidden"
        }`}
    >
      <div>
        <Menu.Button className="w-full">
          {({ open }) => (
            <div
              className={`w-fit ml-auto flex items-center text-sm rounded-md hover:bg-x-gray-50 justify-start gap-3 h-14 px-3 ${open ? "bg-x-gray-50" : ""
                }`}
            >
              <div className="text-left max-h-full overflow-hidden flex justify-between font-medium">
                <div className="flex items-center justify-center gap-2">
                  <span className="hide-on-xs">
                    {currentProfesor && currentProfesor.emoji}
                  </span>
                  {currentProfesor && currentProfesor.name}
                </div>
                <ChevronDown
                  strokeWidth={3}
                  className="h-4 self-end text-x-gray-500"
                />
              </div>
            </div>
          )}
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute overflow-auto  scrollbar scrollbar-thumb-x-gray-200 scrollbar-w-[5px] scrollbar-thumb-rounded-md max-h-60 z-20 right-0 mt-2 w-[220px] shadow-sm origin-top-right border border-x-gray-100 top-[calc(100%+5px)] rounded-md bg-white text-black focus:outline-none">
          <div className="px-1 divide-y divide-x-gray-100">
            {activeProfesors &&
              activeProfesors.map((link) => (
                <Menu.Item key={link.id}>
                  {({ active }) => (
                    <div className="py-1">
                      <div
                        onClick={(e) => {
                          if (!userProfile?.account_licensed) {
                            e.preventDefault();
                            setDialogInactiveAccount(true);
                            return;
                          }
                          gtag('event', 'changed_teacher', {
                            'event_category': 'Interaction',
                            'event_label': 'Teacher Change',
                            'value': 1
                          });
                          const thread = threads.find(
                            (t) => t.id === currentThread
                          );
                          if (thread.owner_id !== "sentinel") {
                            addEmptyThread(link.id);
                            navigate("/");
                          } else {
                            setCurrentProfesor(link);
                          }
                        }}
                        className={`${active && "bg-x-gray-50"
                          } group flex gap-2 cursor-pointer w-full items-center rounded-sm p-3 text-sm`}
                      >
                        <span>{link.emoji}</span>
                        {link.name}
                      </div>
                    </div>
                  )}
                </Menu.Item>
              ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

export default ChoseProfessor;
