import * as z from "zod";
import { Button } from "@/components/ui/button";
import { useState, useRef } from "react";
import { Input } from "@/components/ui/input";
import { CropTool } from "@/components/ui/CropTool"

export default function CropToolTest() {
  const imageInputRef = useRef<HTMLInputElement>(null);
  const [selectedImage, setSelectedImage] = useState<string | null>(null); // Corrected useState usage

  function handleFileChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      console.log(file);
      const reader = new FileReader();
      reader.onload = function(e) {
        setSelectedImage(e.target.result as string);
      };
      reader.readAsDataURL(file);
    }
  }

  return (
    <div className="bg-gray-300 min-h-screen flex justify-start items-center">

      <input
        type="file"
        accept="image/*"
        ref={imageInputRef}
        onChange={handleFileChange}
      />

      {selectedImage && <CropTool src={selectedImage} />}


    </div>
  );
}
