import ChatMessage from "./ChatMessage";
import { useStore } from "@/store/store";
import ChatTextarea from "./ChatTextarea";
import { useEffect, useRef } from "react";
import Sidebar from "../Sidebar/Sidebar";
import Navbar from "../Navbar/Navbar";
import { ShieldAlert } from "lucide-react";
import { useState } from "react";

import {
  api_test,
  api_get_threads,
  api_get_chatbots,
  api_get_conversation_starters,
  handleNewMessages,
} from "@/lib/chat";
import { useNavigate, useParams } from "react-router-dom";
import { ProfesorType } from "@/types/types";


function ChatWrapper() {
  const {
    settings,
    setSettings,
    threads,
    setThreads,
    updateThread,
    currentThread,
    currentThreadIndex,
    setCurrentThread,
    userProfile,
    setUserProfile,
    shouldCheckLogInState,
    setShouldCheckLogInState,
    setProfesors,
    currentProfesor,
    setCurrentProfesor,
    profesors,
    addEmptyThread,
    setStarterConversations,
    starterConversations,
    setPrompt,
    prompt,
  } = useStore();

  const slug = useParams<{ slug: string }>();
  const navigate = useNavigate();

  function getErrorMessage(thread: Thread) {
    if (thread.errorMessage == "LIMIT_NUMBER_OF_MESSAGES") {
      return "Ai trimis prea multe mesaje. Încearcă mai târziu.";
    }
    if (thread.errorMessage == "MESSAGE_TOO_LONG") {
      return "Mesajul este prea lung.";
    }
    return "A aparut o eroare.";
  }

  function canRetrySendMessage(thread: Thread) {
    if (thread.errorMessage == "LIMIT_NUMBER_OF_MESSAGES") {
      return false;
    }
    if (thread.errorMessage == "MESSAGE_TOO_LONG") {
      return true;
    }
    return true;
  }

  function retryOnErrorRemovesMessage(thread: Thread) {
    if (thread.errorMessage == "MESSAGE_TOO_LONG") {
      return true;
    }
    return false;
  }


  const messagesEndRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    console.log("currentThread", currentThread);
    messagesEndRef.current?.scrollIntoView({
      behavior: "instant",
      block: "center",
      inline: "nearest",
    });
  }, [currentThread, threads, settings.isTalking, settings.isLoading]);

  useEffect(() => {
    // check local storare
    const cachedStarters = JSON.parse(
      localStorage.getItem("conversationStarters")
    );
    if (cachedStarters) {
      setStarterConversations(cachedStarters);
    }
    api_get_conversation_starters((data, error) => {
      if (data && data.length > 0) {
        const starters = data;
        if (!cachedStarters) {
          setStarterConversations(starters);
        }
        // save starters to local storage
        localStorage.setItem("conversationStarters", JSON.stringify(starters));
      }
    });
  }, []);

  function updateThreads() {
    api_get_threads(1, (data, error) => {
      if (data) {
        const newThreads = data.items.map((thread) => {
          const oldThread = threads.find((t) => t.id === thread.id);
          if (oldThread) {
            return {
              ...thread,
              messages: oldThread.messages,
              wasSentinel: false,
            };
          }
          return { ...thread, messages: [] };
        });
        // console.log("newThreads", newThreads);
        setThreads(newThreads);

        if (slug.threadId) {
          if (currentThread !== slug.threadId) {
            setCurrentThread(slug.threadId);
          }
        } else {
          addEmptyThread();
        }
      }
    });
  }

  useEffect(() => {
    if (settings.isTalking == false && settings.isLoading == false) {
      if (
        threads &&
        threads[currentThreadIndex] &&
        threads[currentThreadIndex].wasSentinel
      ) {
        updateThreads();
      }
    }
  }, [settings.isTalking, settings.isLoading]);

  function setupFeaturebase(profile: UserProfile) {
    Featurebase(
      "identify",
      {
        organization: "profesorulvirtual",

        email: profile.email,
        name: profile.name,
        id: profile.user_id
      },
      (err) => {
        // Callback function. Called when identify completed.
        if (err) {
          console.error(err);
        } else {
          // console.log("Data sent successfully!");
        }
      }
    );
  }

  useEffect(() => {
    if (shouldCheckLogInState) {
      setShouldCheckLogInState(false);
      api_test((data, error) => {
        if (data && data.status === "success") {
          if (
            !data.profile.name ||
            !data.profile.birthday ||
            !data.profile.class
          ) {
            navigate("/user-setup");
          }
          setUserProfile(data.profile);
          setupFeaturebase(data.profile);
          gtag('set', { 'user_id': data.profile.user_id });
          updateThreads();
          api_get_chatbots((data) => {
            if (data) {
              const profesors: ProfesorType[] = data.map((profesor) => {
                return { ...profesor, messages: [] };
              });
              setProfesors(profesors || []);
            }
          });
        } else {
          console.log(data);
          navigate("/login");
        }
      });
    }
  }, [
    shouldCheckLogInState,
    setShouldCheckLogInState,
    userProfile,
    setUserProfile,
  ]);

  useEffect(() => {
    if (currentThread && profesors && profesors.length > 0) {
      if (slug.threadId) {
        const profesorId = threads[currentThreadIndex].bot_id;
        const profesor = profesors.find((p) => p.id === profesorId);
        setCurrentProfesor(profesor);
      } else {
        const activeProfesors = profesors.filter(
          (profesor) =>
            profesor.class.toString() === userProfile?.class.toString()
        );
        if (!currentProfesor) {
          setCurrentProfesor(activeProfesors[0]);
        }
      }
    }
  }, [currentThread, profesors]);

  function handleStarterMessage(title: string) {
    gtag('event', 'clicked_on_objective_card', {
      'event_category': 'Interaction',
      'event_label': 'Objective Card Click',
      'value': 1
    });
    starterConversations.map((item) => {
      if (item.title == title) {
        setPrompt({
          message: item.description,
          images: [],
          type: "human",
        });
      }
    });
  }

  return (
    <div className="chat-wrapper flex h-full">
      <Sidebar />
      <main
        className={` flex-1 flex flex-col bg-[linear-gradient(rgba(241,241,241,0.83)95%,rgba(255,255,255,1)),url('/assets/pattern_2.jpg')] bg-[length:500px] relative`}
      >
        <Navbar />

        <div className="flex-1 relative flex-grow overflow-auto scrollbar scrollbar-thumb-x-gray-200 scrollbar-w-[10px] scrollbar-thumb-rounded-lg">
          <div className=" flex flex-1 px-0 md:px-7 flex-col h-full">
            <div className="mx-auto max-w-[670px] w-full flex-1 flex flex-wrap ">
              <div className="w-full relative px-4 md:px-0 space-y-3 pb-14 pt-10">
                {currentThreadIndex !== -1 &&
                  threads[currentThreadIndex] &&
                  threads[currentThreadIndex].messages &&
                  threads[currentThreadIndex].messages.map((message) => (
                    <ChatMessage key={message.id} messageData={message} />
                  ))}
              </div>
            </div>

            <div className="relative z-10 md:px-0 px-2 grid grid-cols-2 gap-2 flex-wrap mb-2 max-w-[670px] mx-auto">
              {starterConversations.length > 0 &&
                prompt.message == "" &&
                prompt.images.length == 0 &&
                !slug.threadId &&
                currentThreadIndex !== -1 &&
                threads[currentThreadIndex] &&
                threads[currentThreadIndex].messages.length == 0 &&
                starterConversations.map((item) => {
                  return (
                    <div
                      onClick={() => handleStarterMessage(item.title)}
                      key={item.title}
                      className="bg-white/60 cursor-pointer hover:shadow-md transition-all col-span-2 p-3 rounded-md border border-gray-300 md:col-span-1"
                    >
                      <div className="font-bold">{item.title}</div>
                      <div>{item.description}</div>
                    </div>
                  );
                })}
            </div>

            <div className="sticky z-10 pb-0 md:pb-3 bottom-0 pt-0 w-full max-w-[670px] mx-auto rounded-t-md">
              <div className="mt-auto w-full md:mb-3">
                <ChatTextarea />
                {threads &&
                  threads[currentThreadIndex] &&
                  threads[currentThreadIndex].isError && (
                    <div
                      className="flex justify-center  gap-1 text-center text-x-pink-700 bg-x-pink-100 w-full rounded-md p-4"
                      onClick={() => {
                        if (canRetrySendMessage(threads[currentThreadIndex])) {
                          if (retryOnErrorRemovesMessage(threads[currentThreadIndex])) {
                            const lastMessage = threads[currentThreadIndex].messages.pop();
                            setPrompt({
                              message: lastMessage?.message || "",
                              images: lastMessage?.images || [],
                              type: "human",
                            });
                            threads[currentThreadIndex].isError = false;
                            setSettings({ ...settings, isLoading: false });
                          } else {
                            handleNewMessages(
                              threads[currentThreadIndex],
                              updateThread,
                              setSettings,
                              settings
                            );
                          }
                        }
                      }}
                    >
                      <div>
                        <ShieldAlert />
                      </div>
                      <div>{getErrorMessage(threads[currentThreadIndex])}</div>
                      <div className={"flex gap-1 underline " + (canRetrySendMessage(threads[currentThreadIndex]) ? '' : 'hidden')} >
                        <div>Încearcă din nou.</div>
                      </div>
                    </div>
                  )}
              </div>
            </div>
            <div className="fixed from-white to-transparent pointer-events-none bg-gradient-to-t bottom-0 left-0 right-0  h-[100px] md:h-[200px]"></div>
            <div ref={messagesEndRef} />
          </div>
        </div>
      </main>
    </div>
  );
}

export default ChatWrapper;
