import { useRef, useState, useEffect } from "react";
import { Button } from "./button";
import { Slider } from "./slider";

const round = (num) => Math.round(num * 100) / 100;

const CropTool = ({ src, onCrop }) => {
  const canvasRef = useRef(null);
  const offscreenCanvasRef = useRef(null);
  const [image, setImage] = useState(null);
  const [transform, setTransform] = useState({ scale: 1, x: 0, y: 0 });
  const [selectedArea, setSelectedArea] = useState({
    left: 0.05,
    top: 0.05,
    right: 0.95,
    bottom: 0.95,
  });
  const [minScale, setMinScale] = useState(0.5);
  const [autoHeight, setAutoHeight] = useState(600);
  const tint = "rgba(0, 0, 0, 0.5)"; // 50% opacity black
  // Constants for the corner lines
  const cornerLineLength = 20; // Length of the corner lines
  const cornerLineWidth = 4; // Stroke width of the corner lines
  const [debug, setDebug] = useState("");
  const [debugEnabled, setDebugEnabled] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setImage(img);
      const canvas = canvasRef.current;
      const imgAspect = img.width / img.height;
      let newCanvasHeight = canvas.width / imgAspect;
      const screenHeight = window.innerHeight;
      const minEmptySpace = 200;
      if (newCanvasHeight + minEmptySpace > screenHeight) {
        newCanvasHeight = screenHeight - minEmptySpace;
      }
      setAutoHeight(newCanvasHeight);
      const scaleX = canvas.width / img.width;
      const scaleY = newCanvasHeight / img.height;
      const scaleToFit = Math.min(scaleX, scaleY);
      setMinScale(scaleToFit);
      const x = canvas.width / 2 - (img.width / 2) * scaleToFit;
      const y = newCanvasHeight / 2 - (img.height / 2) * scaleToFit;
      setTransform({ scale: scaleToFit, x, y });
    };
    img.src = src;
    console.log("loading image from src...");
  }, [src]);

  useEffect(() => {
    drawImage();
  }, [image, transform, selectedArea]);

  const drawImage = () => {
    if (image) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear the canvas.

      // Black background
      ctx.fillStyle = "black";
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      // Draw the image without any clipping, so it appears normal everywhere.
      ctx.setTransform(
        transform.scale,
        0,
        0,
        transform.scale,
        transform.x,
        transform.y
      );
      ctx.drawImage(image, 0, 0);
      ctx.setTransform(1, 0, 0, 1, 0, 0); // Reset transform after drawing.

      // Create a path for the clipping region
      const { left, top, right, bottom } = selectedArea;
      const x = left * canvas.width;
      const y = top * canvas.height;
      const width = (right - left) * canvas.width;
      const height = (bottom - top) * canvas.height;

      // Fill outside of clipping region with tint
      ctx.fillStyle = tint;
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      ctx.save(); // Save the current context state
      ctx.beginPath();
      ctx.rect(x, y, width, height);
      ctx.clip(); // Clip to the path

      // Draw the image inside the clipping region, which will be normal
      ctx.setTransform(
        transform.scale,
        0,
        0,
        transform.scale,
        transform.x,
        transform.y
      );
      ctx.drawImage(image, 0, 0);
      ctx.restore(); // Restore the context state, removing the clipping region

      // Draw corner lines
      ctx.strokeStyle = "white";
      ctx.lineWidth = cornerLineWidth;
      ctx.lineCap = "round";

      // Top-left corner
      ctx.beginPath();
      ctx.moveTo(x, y);
      ctx.lineTo(x + cornerLineLength, y);
      ctx.moveTo(x, y);
      ctx.lineTo(x, y + cornerLineLength);

      // Top-right corner
      ctx.moveTo(x + width, y);
      ctx.lineTo(x + width - cornerLineLength, y);
      ctx.moveTo(x + width, y);
      ctx.lineTo(x + width, y + cornerLineLength);

      // Bottom-left corner
      ctx.moveTo(x, y + height);
      ctx.lineTo(x + cornerLineLength, y + height);
      ctx.moveTo(x, y + height);
      ctx.lineTo(x, y + height - cornerLineLength);

      // Bottom-right corner
      ctx.moveTo(x + width, y + height);
      ctx.lineTo(x + width - cornerLineLength, y + height);
      ctx.moveTo(x + width, y + height);
      ctx.lineTo(x + width, y + height - cornerLineLength);

      ctx.stroke(); // Apply the stroke to the path
    }
  };

  const handleDrag = (e) => {
    console.log("handleDrag");
    console.log(e);
    let startX, startY, moveX, moveY;

    // Determine if the event is touch or mouse and set initial positions
    if (e.type.startsWith("touch")) {
      startX = e.touches[0].clientX;
      startY = e.touches[0].clientY;
    } else {
      // Mouse event
      startX = e.clientX;
      startY = e.clientY;
    }

    console.log("startX", startX);
    console.log("startY", startY);

    if (e.passive) {
      e.preventDefault();
    }

    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const scaleX = canvas.width / image.width;
    const scaleY = canvas.height / image.height;
    const cornerTouchDistance = cornerLineLength + 30;

    // Get mouse position relative to the canvas
    let mouseX = (startX - rect.left) / scaleX;
    let mouseY = (startY - rect.top) / scaleY;

    // Calculate corners of the selected area
    const corners = {
      topLeft: {
        x: selectedArea.left * image.width,
        y: selectedArea.top * image.height,
      },
      topRight: {
        x: selectedArea.right * image.width,
        y: selectedArea.top * image.height,
      },
      bottomLeft: {
        x: selectedArea.left * image.width,
        y: selectedArea.bottom * image.height,
      },
      bottomRight: {
        x: selectedArea.right * image.width,
        y: selectedArea.bottom * image.height,
      },
    };

    // Check if the drag is within the cornerTouchDistance of any corner
    let activeCorner;
    Object.entries(corners).forEach(([corner, { x, y }]) => {
      const distance =
        Math.sqrt(Math.pow(mouseX - x, 2) + Math.pow(mouseY - y, 2)) *
        transform.scale;
      console.log("distance", distance);
      if (distance < cornerTouchDistance) {
        activeCorner = corner;
      }
    });

    const onMove = (moveEvent) => {
      let moveX, moveY;

      if (moveEvent.type.startsWith("touch")) {
        moveX = moveEvent.touches[0].clientX;
        moveY = moveEvent.touches[0].clientY;
      } else {
        moveX = moveEvent.clientX;
        moveY = moveEvent.clientY;
      }

      console.log("startX", moveX);
      console.log("startY", moveY);

      // Compute the difference from the start position
      const dx = (moveX - startX) / canvasRef.current.width;
      const dy = (moveY - startY) / canvasRef.current.height;
      const mdx = moveX - startX;
      const mdy = moveY - startY;
      startX = moveX;
      startY = moveY;

      const minSelectedAreaWidth = 0.1;
      const minSelectedAreaHeight = (0.1 * canvas.width) / canvas.height;

      setSelectedArea((prevSelectedArea) => {
        const newSelectedArea = { ...prevSelectedArea };
        if (activeCorner) {
          // Adjust the selected area
          switch (activeCorner) {
            case "topLeft":
              newSelectedArea.left = Math.max(
                0,
                Math.min(
                  newSelectedArea.right - minSelectedAreaWidth,
                  newSelectedArea.left + dx
                )
              );
              newSelectedArea.top = Math.max(
                0,
                Math.min(
                  newSelectedArea.bottom - minSelectedAreaHeight,
                  newSelectedArea.top + dy
                )
              );
              break;
            case "topRight":
              newSelectedArea.right = Math.min(
                1,
                Math.max(
                  newSelectedArea.left + minSelectedAreaWidth,
                  newSelectedArea.right + dx
                )
              );
              newSelectedArea.top = Math.max(
                0,
                Math.min(
                  newSelectedArea.bottom - minSelectedAreaHeight,
                  newSelectedArea.top + dy
                )
              );
              break;
            case "bottomLeft":
              newSelectedArea.left = Math.max(
                0,
                Math.min(
                  newSelectedArea.right - minSelectedAreaWidth,
                  newSelectedArea.left + dx
                )
              );
              newSelectedArea.bottom = Math.min(
                1,
                Math.max(
                  newSelectedArea.top + minSelectedAreaHeight,
                  newSelectedArea.bottom + dy
                )
              );
              break;
            case "bottomRight":
              newSelectedArea.right = Math.min(
                1,
                Math.max(
                  newSelectedArea.left + minSelectedAreaWidth,
                  newSelectedArea.right + dx
                )
              );
              newSelectedArea.bottom = Math.min(
                1,
                Math.max(
                  newSelectedArea.top + minSelectedAreaHeight,
                  newSelectedArea.bottom + dy
                )
              );
              break;
          }
        } else {
          // Adjust the transform for moving the entire image
          setTransform((prevTransform) => {
            let newX = prevTransform.x + mdx;
            let newY = prevTransform.y + mdy;
            return {
              ...prevTransform,
              x: newX,
              y: newY,
            };
          });
        }
        return newSelectedArea;
      });
    };

    const onEnd = () => {
      // Remove event listeners on drag end
      document.removeEventListener("mousemove", onMove);
      document.removeEventListener("mouseup", onEnd);
      document.removeEventListener("touchmove", onMove);
      document.removeEventListener("touchend", onEnd);
    };

    // Setup event listeners for move and end events
    document.addEventListener("mousemove", onMove);
    document.addEventListener("mouseup", onEnd);
    document.addEventListener("touchmove", onMove, { passive: false }); // Note: passive: false allows preventDefault
    document.addEventListener("touchend", onEnd);
  };

  const handleZoomChange = (e) => {
    const newScale = parseFloat(e);
    const canvas = canvasRef.current;

    // Compute the center of the current view in the image's coordinate space
    const centerX = (-transform.x + canvas.width / 2) / transform.scale;
    const centerY = (-transform.y + canvas.height / 2) / transform.scale;

    // Calculate the new offset to keep the center point stationary
    const newX = -(centerX * newScale - canvas.width / 2);
    const newY = -(centerY * newScale - canvas.height / 2);

    setTransform({
      scale: newScale,
      x: newX,
      y: newY,
    });
  };

  useEffect(() => {
    renderSelectedArea();
  }, [selectedArea, transform, image]);

  const renderSelectedArea = () => {
    if (image) {
      const offscreenCanvas = offscreenCanvasRef.current;
      const ctx = offscreenCanvas.getContext("2d");
      const { left, top, right, bottom } = selectedArea;
      const scale = transform.scale;
      const offsetX = transform.x;
      const offsetY = transform.y;

      const canvas = canvasRef.current;

      // The dimensions of the selected area on the main canvas
      const selectedWidth = (right - left) * (canvas.width / scale);
      const selectedHeight = (bottom - top) * (canvas.height / scale);

      let targetWidth = selectedWidth;
      let targetHeight = selectedHeight;
      if (targetWidth > 1024) {
        const factor = 1024 / targetWidth;
        targetWidth = 1024;
        targetHeight = targetHeight * factor;
      }
      if (targetHeight > 1024) {
        const factor = 1024 / targetHeight;
        targetHeight = 1024;
        targetWidth = targetWidth * factor;
      }

      offscreenCanvas.width = targetWidth;
      offscreenCanvas.height = targetHeight;

      const extraScaleFactor = selectedWidth / targetWidth;

      const imgX = (offsetX - left * canvas.width) / scale;
      const imgY = (offsetY - top * canvas.height) / scale;
      const imgWidth = image.width;
      const imgHeight = image.height;

      // console.log()

      ctx.clearRect(0, 0, offscreenCanvas.width, offscreenCanvas.height);
      ctx.fillStyle = "black";
      ctx.fillRect(0, 0, offscreenCanvas.width, offscreenCanvas.height);
      ctx.drawImage(
        /*image=*/ image,
        /*sx=*/ imgX / extraScaleFactor,
        /*sy=*/ imgY / extraScaleFactor,
        imgWidth / extraScaleFactor,
        imgHeight / extraScaleFactor
      );

      setDebug(
        `imgX: ${round(imgX)}, imgY: ${round(imgY)}, imgWidth: ${round(
          imgWidth
        )}, imgHeight: ${round(imgHeight)} selectedWidth: ${round(
          selectedWidth
        )}, selectedHeight: ${round(selectedHeight)} targetWidth: ${round(
          targetWidth
        )}, targetHeight: ${round(targetHeight)}`
      );
    }
  };

  const internalCropImage = () => {
    renderSelectedArea();
    let base64Image = offscreenCanvasRef.current.toDataURL("image/png");
    base64Image = base64Image.split(";base64,").pop();
    return base64Image;
  };

  function saveCrop() {
    console.log("saveCrop");
    if (onCrop) {
      onCrop(internalCropImage());
    }
  }

  // prevent pinch and zoom
  document.addEventListener(
    "touchstart",
    function (event) {
      if (event.touches.length > 1) {
        event.preventDefault();
      }
    },
    { passive: false }
  );

  document.addEventListener(
    "touchend",
    function (event) {
      var now = new Date().getTime();
      var lastTouchEnd = 0;
      if (now - lastTouchEnd <= 300) {
        event.preventDefault();
      }
      lastTouchEnd = now;
    },
    false
  );

  document.addEventListener("gesturestart", function (event) {
    event.preventDefault();
  });

  return (
    <div className="bg-white w-full mt-3 md:mt-0 ">
      <div className="flex w-full">
        <div className="w-full">
          <canvas
            ref={canvasRef}
            width={() => {
              console.log("canvasRef", canvasRef);
              return canvasRef.current?.width;
            }}
            height={autoHeight}
            onMouseDown={handleDrag}
            onTouchStart={handleDrag}
            style={{ cursor: "grab" }}
            className="rounded-md mx-auto"
          />
          <Slider
            onValueChange={handleZoomChange}
            value={[transform.scale]}
            min={minScale}
            max={3}
            step={0.01}
            className="mt-5 mx-auto max-w-[calc(100%-50px)] md:max-w-full"
          />
        </div>
      </div>
      <div className="flex gap-2 justify-end mt-5">
        <Button
          onClick={() => {
            onCrop(null);
          }}
          className="bg-x-gray-300"
        >
          Anuleaza
        </Button>
        <Button onClick={saveCrop}>Crop</Button>
      </div>
      <div className={debugEnabled ? "" : "hidden"}>
        <div>{debug}</div>
        <canvas
          className=""
          ref={offscreenCanvasRef}
          // style={{ display: 'none' }} // Hide the offscreen canvas
        />
      </div>
    </div>
  );
};

export { CropTool };
