import ChatWrapper from "@/components/Main/ChatWrapper";
import Login from "./pages/login";
import Logout from "./pages/logout";
import Register from "./pages/register";
import ApiTest from "./pages/api_test";
import { Routes, Route } from "react-router-dom";
import UserSetup from "./pages/user-setup";
import ResetPassword from "./pages/reset-password";
import AdminCodes from "./pages/admin/codes";
import AdminProfessors from "./pages/admin/professors";
import AdminUser from "./pages/admin/user";
import AdminUsers from "./pages/admin/users";
import CropToolTest from "./pages/crop";
import AdminSettings from "./pages/admin/settings";
import Tutorial from "./pages/tutorial";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<ChatWrapper />} />
        <Route path="/c/:threadId" element={<ChatWrapper />} />
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/register" element={<Register />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/user-setup" element={<UserSetup />} />
        <Route path="/tutorial" element={<Tutorial />} />
        <Route path="/api_test" element={<ApiTest />} />
        <Route path="/admin/" element={<AdminUsers />} />
        <Route path="/admin/codes" element={<AdminCodes />} />
        <Route path="/admin/professors" element={<AdminProfessors />} />
        <Route path="/admin/users/:user_id" element={<AdminUser />} />
        <Route path="/admin/users" element={<AdminUsers />} />
        <Route path="/admin/settings" element={<AdminSettings />} />
        <Route path="/crop" element={<CropToolTest />} />
      </Routes>
    </>
  );
}

export default App;
